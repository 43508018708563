import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import PrimarySearch from "components/Common/PrimarySearch";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../../assets/images/plus-icon.svg";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import editIcon from "../../../assets/images/edit-icon-white.svg";
import {
  getPayoutMethodsList,
  detelePayoutMethod,
} from "services/SellerPayoutMethodService";
import PrimaryTable from "components/Common/PrimaryTable";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";

function SellerPaymentMethods() {
  const [payoutMehthods, setPayoutMethods] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [selectedId, setSelectedId] = useState("");
  const [searchVal, setSearchVal] = useState(null);
  const user = useSelector(state => state.auth?.user);
  const permissions = user?.permissions?.map(item => item.name);
  const searchParams = new URLSearchParams(window.location?.search);

  useEffect(()=>{
		setPageTitle("Payout Methods - Inspire Uplift")
	},[]);

  useEffect(() => {

    const page = searchParams.get('page') || undefined;
    const search = searchParams.get("search") || undefined;
    setSearchVal(search);
    setPageNo(page || 1);
    payoutMethdosList(page,search);
  }, [window?.location?.search]);

  const payoutMethdosList = (page, search) => {
    setLoading(true);
    getPayoutMethodsList(page, search)
      .then(res => {
        setPayoutMethods(res.data?.payouts?.data);
        setPageCount(res.data?.payouts?.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const history = useNavigate();

  const handleConfirm = () => {
    const filterArr = payoutMehthods?.filter(item => item.id !== selectedId);
    toast.dismiss();
    setLoading(true);
    detelePayoutMethod(selectedId)
      .then(res => {
        setShowDeleteModal(!showDeleteModal);
        PrimaryToast({ type: "success", message: res?.data?.message });
        setSelectedId("");
        setPayoutMethods(filterArr);
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log(err);
        PrimaryToast({
          type: "error",
          message: err?.responsive?.data?.message,
        });
      })

  };
  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) =>  <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>,
    },
    {
      dataField: "name",
      text: "Title",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => {
        let status;
        switch (row.is_active) {
          case 1:
            status = "Active";
            break;
          case 0:
            status = "Inactive";
            break;
          default:
            status = "Inactive";
        }

        return (
          <div
            className={`status ${
              status === "Active"
                ? "success-status"
                : status === "Inactive"
                ? "danger-status"
                : ""
            } w-max-content`}
          >
            {status}
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex  align-items-center">
          {permissions?.includes("payout-edit") && (
            <div
              className="action-btn edit-icon-bg me-1"
              onClick={() => history(`/seller/payout-methods/edit/${row.id}`)}
            >
              <img src={editIcon} className="" alt="edit icon" />
            </div>
          )}
          {permissions?.includes("payout-delete") && (
            <div
              className="action-btn delete-btn-bg cursor-pointer"
              onClick={() => {
                setSelectedId(row.id);
                setShowDeleteModal(!showDeleteModal);
              }}
            >
              <img src={deleteIcon} className="" alt="delete icon" />
            </div>
          )}
        </div>
      ),
    },
  ];

  const data = payoutMehthods?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleSearch = e => {
    let value = e.target.value;
    setSearchVal(value);

  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if(searchParams?.get('page')){
          searchParams?.delete('page');
      };
      if(searchVal){
          searchParams?.set('search', searchVal);
      }
      else{
          searchParams?.delete('search');
      }
      history(`/seller/payout-methods?${searchParams?.toString()}`)
  }
  }

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this payout method?"}
          onConfirm={handleConfirm}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <TopHeading heading={"Payout Methods"} />
        {permissions?.includes("payout-create") && (
          <Button
            className="rounded"
            color="primary"
            onClick={() => {
              history("/seller/payout-methods/create");
            }}
          >
            <img alt="plus icon" src={plusIcon} className="me-2" />
            Create New Payout Method
          </Button>
        )}
      </div>
      <Card>
        <CardBody>
          <PrimarySearch
            placeholder={"Search by Name"}
            handleChange={handleSearch}
            handleKeyPress={handleKeyPress}
            value={searchVal}
          />
          <div className="mt-3">
            <PrimaryTable
              columns={columns}
              data={data}
              headerWrapperClasses={"thead-light"}
              responsive
              bordered={false}
              keyField="id"
            />
          </div>
          {payoutMehthods?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default SellerPaymentMethods;
