import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Card, CardBody, Button } from "reactstrap";
import PrimarySearch from "components/Common/PrimarySearch";
import BootstrapTable from "react-bootstrap-table-next";
import rectangeArrow from "../../assets/images/rectangle-arrow.svg";
import "./dispute.scss";
import { getAllDisputes, disputeResources } from "services/DisputeServices";
import { openLinkInNewTab } from "utils/linkHandling";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { useNavigate } from "react-router-dom";
import DateRangePicker from "components/Common/DateRangePicker";
import moment from "moment";

function DisputeManagement() {
  const [loading, setLoading] = useState(false);
  const [disputeData, setDisputeData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [searchVal, setSearch] = useState();
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [issue, setIssue] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const params = new URLSearchParams(window?.location?.search);
  const history = useNavigate();
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [disputeResourcesData, setDisputeResourcesData] = useState([]);

  useEffect(() => {
    getDisputeResources();
    const page = params?.get("page") || undefined;
    const search = params?.get("search") || undefined;
    const typeParam = params?.get("type") || undefined;
    const issueParam = params?.get("issue") || undefined;
    const statusParam = params?.get("status") || undefined;
    const startDate = params.get("start") || undefined;
    const endDate = params.get("end") || undefined;
    setPageNo(page || 1);
    setSearch(search);
    setDates([
      {
        startDate: moment(startDate || new Date(), "DD-MM-YYYY").toDate(),
        endDate: moment(endDate || new Date(), "DD-MM-YYYY").toDate(),
        key: "selection",
      },
    ]);
    setStatus(statusParam);
    setIssue(issueParam);
    setType(typeParam);
    setLoading(true);
    getAllDisputes(
      page,
      search,
      typeParam,
      statusParam,
      issueParam,
      startDate,
      endDate
    )
      .then(res => {
        setDisputeData(res?.data);
        setPageCount(res?.last_page);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, [window?.location?.search]);

  const getDisputeResources = async () => {
    disputeResources()
      .then(res => {
        setDisputeResourcesData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => <div className="font-semi-bold">{(pageNo - 1) * 15 + row?.index}</div>,
    },
    {
      dataField: "dispute_id",
      text: "Case ID",
    },
    {
      dataField: "seller_store_name",
      text: "Store Name",
    },
    {
      dataField: "seller_product_variant_title",
      text: "Item Name",
      formatter:(cell, row) => <div className="product-title-container">{row.seller_product_variant_title}</div>
    },
    {
      dataField: "dispute_type",
      text: "Type",
    },
    {
      dataField: "dispute_issue",
      text: "Request",
    },
    {
      dataField: "dispute_status",
      text: "Status",
      formatter: (cell, row) => {
        return (
          <div
            className={`status w-max-content ${(row?.dispute_status == "In Process" && row?.dispute_iu_action != 1)
                ? "warning-status"
                : (row?.dispute_status == "Close" || row?.dispute_iu_action == 1)
                  ? "danger-status"
                  : row?.dispute_status == "Complete"
                    ? "success-status"
                    : ""
              }`}
          >
            {row?.dispute_iu_action == 1
              ? "Response Required"
              : row?.dispute_status}
          </div>
        );
      },
    },
    {
      dataField: "dispute_date",
      text: "Date",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex align-items-center">
            <Button
              className="d-flex align-items-center dispute-action-btn me-2"
              onClick={() =>
                openLinkInNewTab(
                  `/disputes/managments/response/${row.dispute_id}`
                )
              }
            >
              <img src={rectangeArrow} alt="rectangeArrow" />
              <div className="ms-2">Respond</div>
            </Button>
          </div>
        );
      },
    },
  ];

  const data = disputeData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (params?.get("page")) {
        params.delete("page");
      }
      if (searchVal) {
        params?.set("search", searchVal);
      } else {
        params.delete("search");
      }
      history(`?${params.toString()}`);
    }
  };

  const handleDatePicker = item => {
    setDates([item.selection]);
  };

  const handleApply = () => {
    let startDate = moment(dates[0].startDate).format("DD-MM-YYYY");
    let endDate = moment(dates[0].endDate).format("DD-MM-YYYY");

    if (searchVal) {
      params?.set("search", searchVal);
    } else {
      params.delete("search");
    }
    if (status) {
      params?.set("status", status);
    } else {
      params?.delete("status");
    }

    if (type) {
      params?.set("type", type);
    } else {
      params?.delete("type");
    }

    if (issue) {
      params?.set("issue", issue);
    } else {
      params?.delete("issue");
    }

    params.set("start", startDate);
    params.set("end", endDate);

    history(`?${params.toString()}`);
  };

  const handleRemoveStatus = () => {
    history(`/disputes/managments`);
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-3">
        <TopHeading heading={"Disputes Management"} />
      </div>
      <Card>
        <CardBody>
          <div className="d-flex align-items-start ">
            <div className="me-2 mb-2 d-flex align-items-center">
              <PrimarySearch
                value={searchVal}
                handleChange={e => setSearch(e.target.value)}
                handleKeyPress={handleKeyPress}
              />
            </div>
            <div className="d-flex flex-wrap">
              <div className="position-relative ms-3 mb-2">
                <div
                  className="form-control cursor-pointer rounded-full"
                  style={{ color: "#74788D", width: "max-content" }}
                  onClick={() => setShowCalendar(true)}
                >
                  {moment(dates[0].startDate).format("DD MMM YYYY")} -{" "}
                  {moment(dates[0].endDate).format("DD MMM YYYY")}
                </div>

                {showCalendar && (
                  <>
                    <div
                      className="position-fixed top-0 start-0 w-100 h-100 mb-2"
                      style={{ zIndex: "10" }}
                      onClick={() => setShowCalendar(false)}
                    ></div>
                    <DateRangePicker
                      dates={dates}
                      handleDatePicker={handleDatePicker}
                      handleSubmit={() => setShowCalendar(false)}
                      maxDate={new Date()}
                    />
                  </>
                )}
              </div>
              <select
                className="primary-select-option mb-2 ms-1"
                name="type"
                value={type || ""}
                onChange={e => {
                  setType(e.target.value);
                }}
                onKeyPress={handleApply}
              >
                <option value={""}>Search By Type</option>
                {disputeResourcesData?.order_dispute_types?.map(
                  (item, index) => {
                    return (
                      <option value={item?.id} key={index}>
                        {item?.name}
                      </option>
                    );
                  }
                )}
              </select>
              <select
                className="primary-select-option mb-2 ms-1"
                name="status"
                value={status || ""}
                onChange={e => {
                  setStatus(e.target.value);
                }}
                onKeyPress={handleApply}
              >
                <option value={""}>Search By Status</option>
                {disputeResourcesData?.order_dispute_statuses?.map(
                  (statuses, index) => {
                    return (
                      <option value={statuses?.id} key={index}>
                        {statuses?.name}
                      </option>
                    );
                  }
                )}

                <option value={1}>Approved</option>
                <option value={2}>Rejected</option>
              </select>
              <select
                className="primary-select-option ms-1 mb-2"
                name="issue"
                value={issue || ""}
                onChange={e => {
                  setIssue(e.target.value);
                }}
                onKeyPress={handleApply}
              >
                <option value={""}>Search By issue</option>
                {disputeResourcesData?.order_dispute_issues?.map(
                  (issue, index) => {
                    return (
                      <option value={issue?.id} key={index}>
                        {issue?.name}
                      </option>
                    );
                  }
                )}
              </select>
              <Button
                color="primary"
                className={`btn-rounded ms-3`}
                onClick={handleApply}
              >
                Apply Filter
              </Button>
              {Array.from(params.keys()).length > 0 && (
                <Button
                  color="secondary"
                  className={`btn-rounded  ms-3`}
                  onClick={handleRemoveStatus}
                >
                  Reset Filter
                </Button>
              )}
            </div>
          </div>
          <div className="mt-3 table-responsive">
            <BootstrapTable
              columns={columns}
              data={data}
              keyField={"id"}
              responsive
              bordered={false}
              striped={false}
              classes={"table align-middle table-nowrap"}
              headerWrapperClasses={"thead-light"}
            />
          </div>
          {disputeData?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default DisputeManagement;
