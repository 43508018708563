import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";


export const getCachesData = () => {
    return getApi({
        url:`admin/cache`,
        data: {
            is_admin: true,
        }
    })
    .then(res => res.data)
};

export const clearCustomCache = (cache) => {

    return axiosInstance({
        url: `${siteApiUrl}admin/cache`,
        method: "POST",
        data: {
            cache_keys:[cache],
            is_admin:true,
        },
		withCredentials:true,
        // data: data,
        headers: getHttpHeaders(),
    });
};