import React from "react";
import TopHeading from "components/Common/TopHeading";
import PrimarySearch from "components/Common/PrimarySearch";
import { Button, Card, CardBody } from "reactstrap";
import PlusIcon from "../../../assets/images/plus-icon.svg";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import editIcon from "../../../assets/images/edit-icon-white.svg";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { setPageTitle } from "utils/pageTitle";

function Redirects() {
	const history = useNavigate();
	const user = useSelector((state) => state.auth.user);
	const permissions = user?.permissions?.map((item) => item.name);

	useEffect(()=>{
		setPageTitle("Redirects - Inspire Uplift")
	},[]);

	const columns = [
		{
			dataField: "id",
			text: "ID",
			formatter: (cell, row) => <div className="font-semi-bold">{row.id}</div>,
		},
		{
			dataField: "request_path",
			text: "Request Path",
		},
		{
			dataField: "target_path",
			text: "Target Path",
		},
		{
			dataField: "action",
			text: "Action",
			formatter: (cell, row) => {
				return (
					<div className="d-flex justify-content-center align-items-center">
						{
							permissions?.includes("redirect-edit") && (
								<div
									className="action-btn edit-icon-bg me-1"
									onClick={() => history(`/redirects/edit/${row.id}`)}
								>
									<img src={editIcon} className="" alt="edit icon" />
								</div>
							)
						}
						{
							permissions?.includes("redirect-delete") && (
								<div className="action-btn delete-btn-bg me-1">
									<img src={deleteIcon} className="" alt="delete icon" />
								</div>
							)

						}

					</div>
				);
			},
		},
	];

	const data = [
		{
			id: 1,
			request_path: "c/table-decor",
			target_path:
				"c/home-garden-tools/home-decor/decorative-objects/table-decor",
		},
		{
			id: 2,
			request_path:
				"c/fashion-accessories/women-clothing/sweatshirts-and-hoodies",
			target_path:
				"c/clothing-and-shoes/women-clothing/sweatshirts-and-hoodies",
		},
		{
			id: 3,
			request_path: "deals/halloween",
			target_path: "c/halloween",
		},
		{
			id: 4,
			request_path: "c/neon-lights",
			target_path:
				"c/home-garden-tools/home-decor/decorative-objects/table-decor",
		},
	];

	return (
		<div className="page-content">
			<div className="mb-3 d-flex justify-content-between align-items-center">
				<TopHeading heading={"Redirects"} />
				{
					permissions?.includes("redirect-create") && (
						<Button
							color="primary"
							onClick={() => history("/redirects/create")}
						>
							<img src={PlusIcon} alt="plus icon" className="me-2" />
							Create New Redirect
						</Button>
					)
				}
			</div>
			<Card className="">
				<CardBody>
					<PrimarySearch />
					<div className="mt-3">
						<BootstrapTable
							columns={columns}
							data={data}
							classes={"table align-middle table-nowrap"}
							headerWrapperClasses={"thead-light"}
							responsive
							bordered={false}
							keyField="id"
						/>
					</div>
				</CardBody>
			</Card>
		</div>
	);
}

export default Redirects;
