import React from "react";

const DeleteModal = ({ title, onConfirm, onCancel }) => {
	return (
		<div className="delete-modal-container">
			<div className="w-[320px] z-[1001] bg-white rounded overflow-hidden delete-modal-container-innerwrapper mt-11 lg:mt-0 text-black">
				<div className="delete-modal-title">{title}</div>
				<div className="delete-modal-body-section">
					<button
						className="delete-modal-btn"
						onClick={onConfirm}
					>
						Yes
					</button>
					<button
						className="delete-modal-btn"
						onClick={onCancel}
					>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};
export default DeleteModal;