import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SpinnerLoader from "components/Common/SpinnerLoader";
import { useDispatch } from "react-redux";
import { loadUser } from "store/actions/auth";
import { userInfo } from "services/AuthServices";
import { deleteSecretKey } from "services/2FAServices";

function AuthWrapper({ children }) {
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !location.pathname.includes("login") &&
      !location.pathname.includes("forgot-password") &&
      !location.pathname.includes("/admin/reset-pass") &&
      !location.pathname.includes("2fa-auth")
    ) {
      if(localStorage?.getItem("adminToken")){
        userInfo()
        .then(res => {
          dispatch(loadUser(res.data));
          if (res?.data["2fa_isEnable"] == 2) {
            deleteSecretKey()
              .then(res => console.log(res))
              .catch(err => console.log(err));
          }
          setAuthenticated(true);
        })
        .catch(err => {
          if (err.response?.data?.message === "2fa is disabled") {
            history("/2fa-auth");
          } else if (err.response?.data?.message === "key not generated") {
            history("/two-factor-authentication");
          }
          if (err.response.status === 401) {
            localStorage.removeItem("adminToken");
          }
        });
      }

      setLoading(true);
      if (!localStorage?.getItem("adminToken")) {
        setLoading(false);
        history("/login");
      } else {
        setLoading(false);
      }
    } else {
      setAuthenticated(true);
      if (
        location.pathname === "/login" &&
        localStorage?.getItem("adminToken")
      ) {
        // check if already authenticated and on login page
        history("/");
      }
    }
  }, [location]);

  if (loading) {
    return (
      <div className="position-fixed top-0 start-0 w-100 h-100">
        <SpinnerLoader />
      </div>
    );
  } else {
    if (authenticated) {
      // render dashboard when authenticated
      return <>{children}</>;
    } else {
      // render loader when authentication is in progress
      return (
        <div className="position-fixed top-0 start-0 w-100 h-100">
          <SpinnerLoader />
        </div>
      );
    }
  }
}

export default AuthWrapper;
