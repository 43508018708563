import React from "react";

const PrimarySelect = ({ label, isRequired, options, name, value, handleChange, isDisabled, path, isShowRussia, radius, classname }) => {

    return (
        <div className={classname}>
            {label && (
                <label className="form-label">
                    {label}
                    {isRequired && <span className="primary-text">*</span>}
                </label>
            )}
            <select name={name} value={value} onChange={handleChange} className={`form-control ${radius} ${isDisabled && "disabled-input"} `}
                disabled={isDisabled}>
                {
                    options?.map((item, index) => {
                        if(isShowRussia){
                            return (<option value={path == "country" ? item?.alpha2Code : path == "state" ? item?.abbreviation  :  item.value} key={index}>{item.name}</option>);
                        }
                        if(item.name !== "Russia") {
                            return (<option value={path == "country" ? item?.alpha2Code : path == "state" ? item?.abbreviation  :  item.value} key={index}>{item.name}</option>);
                        };
                    })
                }
            </select>

        </div>
    )
}

export default PrimarySelect;