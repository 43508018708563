import React from "react";
import PrimaryPopup from "./PrimaryPopup";
import { Button } from "reactstrap";

function WithdrawRejectPopup({ isOpen, handleClose, handleChange, reason, handleRequest, title, loading }) {
  return (
    <PrimaryPopup
      isOpen={isOpen}
      handleClick={handleClose}
      title={title}
      isScrollable={false}
      className="d-flex justify-content-center align-items-center h-100 w-100"
    >
      <div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            Reason
          </label>
          <textarea
            name="reason"
            value={reason}
            className="form-control"
            id="exampleFormControlTextarea1"
            rows={3}
            placeholder={"Enter Reject Reason"}
            onChange={handleChange}
          ></textarea>
        </div>
        {
            <div className="d-flex  justify-content-end">
              <Button color="danger" onClick={handleRequest} disabled={loading}>
                Reject
              </Button>
            </div>
        }
      </div>
    </PrimaryPopup>
  );
}

export default WithdrawRejectPopup;