import React from "react";
import { Row, Col, Card } from "reactstrap";
import { useSelector } from "react-redux";

import profileImg from "assets/images/profile-img.png";

const WelcomeComp = ({ user }) => {
  const userInfo = useSelector(state => state?.auth?.user);

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft" style={{ minHeight: "125px" }}>
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back!</h5>
                <p>{userInfo?.name}</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default WelcomeComp;
