import { React } from "react";
import { useDropzone } from "react-dropzone";
import closeIcon from "assets/images/cross-icon.svg";

const PrimaryImageUploader = ({ label, getFiles, setFiles, isRequired, path }) => {

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        onDropAccepted: files => {
            handleOnDrop(files)
        },
    });

    const handleOnDrop = (files) => {
        let newFile = files.map((file) => {
            return Object.assign(file, { preview: URL.createObjectURL(file) });
        });
        setFiles(files[0]);

    };

    const handleClear = (e,image) => {
        e.stopPropagation()
        setFiles(image);
    };


    return (
        <div className="primary-image-uploader">
            {label && (
                <label className="form-label">
                    {label}
                    {isRequired && <span className="primary-text">*</span>}
                </label>
            )}
            <div
                {...getRootProps()}
                className="d-flex primary-image-uploader-inner-werapper"
            >
                <div className="input-btn-section">Choose file</div>
                <input {...getInputProps()} />
                <div className="file-name-section">{getFiles?.name !== "delete_media_img" ? getFiles?.name : "No file chosen"} {getFiles?.name && <img src={closeIcon} height={13} width={13} className="ps-1 cursor-pointer d-none" onClick={handleClear} alt="close icon" />} </div>
            </div>
            {
                getFiles?.preview && <div className="position-relative">
                    <img height={100} width={100} className="rounded mt-3" src={getFiles?.preview} alt="image preview" />
                    {
                        path == "promotion" && (
                            <div className="position-absolute top-0" style={{left:"100px"}}>
                                <img src={closeIcon} height={13} width={13} className="ps-1 cursor-pointer" onClick={(e)=>handleClear(e,{
                                    name:'delete_media_img',
                                    url:getFiles?.preview
                                })} alt="close icon" />
                            </div>
                        )
                    }

                </div> 
            }
        </div>
    );
};

export default PrimaryImageUploader;
