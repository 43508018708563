import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryTable from "components/Common/PrimaryTable";
import {
  getAllSellerComments,
  createSellerComments,
  deleteSellerComment,
  getSellerTransactionAdjustments,
  getSellerBalanceSummary,
} from "services/SellerStoreServices";
import { getSellerAdjustmentHistory } from "services/SellerStoreServices";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import DeleteModal from "components/Common/Toast/DeleteModal";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import { useSelector } from "react-redux";
import SellerTransactionAdjustments from "components/Seller/Comments/SellerTransactionAdjustments";
import BalanceSummary from "components/Seller/Comments/BalanceSummary";
import deleteIcon from "assets/images/delete-icon-white.svg";
import AdjusmentHistoryTable from "components/Seller/AdjusmentHistoryTable";

function SellerComments() {
  const [commentsData, setCommentsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [selectedId, setSelectedId] = useState("");
  const [transactionData, setTransactionData] = useState([]);
  const [transactionPageNo, setTransactionPageNo] = useState(1);
  const [transactionCount, setTransactionCount] = useState();
  const [balanceSummaryData, setBalanceSummaryData] = useState([]);
  const [balancePageNo, setBalancePageNo] = useState(1);
  const [BalanceCount, setBalanceCount] = useState();
  const [adjustmentData, setAdjustmentData] = useState([]);
  const [adjustmentPageNo, setAdjustmentPageNumber] = useState(1);
  const [adjustmentPageCount, setAdjustmentPageCount] = useState(1);
  const user = useSelector(state => state?.auth?.user);
  const permissions = user?.permissions?.map(item => item.name);
  const queryParams = new URLSearchParams(window?.location?.search);

  const { id } = useParams();

  useEffect(() => {
    setPageTitle("Seller Comments - Inspire Uplift");
  }, []);

  useEffect(() => {
    const pageParam = queryParams?.get("page") || undefined;
    setPageNo(pageParam || 1);
    getCommentsData(pageNo);
  }, [window?.location?.search]);

  useEffect(() => {
    fetchTransactions(transactionPageNo);
  }, [transactionPageNo]);

  useEffect(() => {
    getSellerBalanceSummary(id, balancePageNo)
      .then(res => {
        setBalanceCount(res?.data?.last_page);
        setBalanceSummaryData(res?.data?.data);
      })
      .catch(err => console.log(err));
  }, [balancePageNo]);

  useEffect(() => {
    getSellerAdjustmentHistory(id, adjustmentPageNo)
      .then(res => {
        setAdjustmentPageCount(res?.last_page);
        setAdjustmentData(res?.data);
      })
      .catch(err => console.log(err));
  }, [adjustmentPageNo]);

  const getCommentsData = page => {
    setLoading(true);
    getAllSellerComments(page, id)
      .then(res => {
        if (res?.data?.data?.length == 0 && page > 1) {
          queryParams?.set("page", page - 1);
          const newUrl = `${
            window.location.pathname
          }?${queryParams.toString()}`;
          history(newUrl);
        } else {
          setCommentsData(res?.data?.data);
          setPageCount(res?.data?.last_page);
        }
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const fetchTransactions = page => {
    getSellerTransactionAdjustments(id, page)
      .then(res => {
        setTransactionCount(res.data.last_page);
        setTransactionData(res?.data?.data);
      })
      .catch(err => console.log(err));
  };

  const handleDelete = () => {
    toast.dismiss();
    setLoading(true);
    deleteSellerComment(selectedId)
      .then(res => {
        getCommentsData(pageNo);
        setSelectedId("");
        setShowDeleteModal(false);
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => setLoading(false));
  };

  const history = useNavigate();
  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => <div>{(pageNo - 1) * 10 + row?.index}</div>,
    },
    {
      dataField: "comment",
      text: "Comment",
      formatter: (cell, row) => (
        <div className="product-title-container" style={{ width: "300px" }}>
          {row.comment}
        </div>
      ),
    },
    {
      dataField: "action_by",
      text: "By",
    },
    {
      dataField: "created_at",
      text: "Created At",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) =>
        permissions?.includes("log-comment-delete") && row?.type == "seller" ? (
          <div
            className="action-btn delete-btn-bg cursor-pointer"
            onClick={() => {
              setSelectedId(row?.id);
              setShowDeleteModal(!showDeleteModal);
            }}
          >
            <img src={deleteIcon} className="" alt="delete icon" />
          </div>
        ) : null,
    },
  ];

  const data = commentsData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleCreateComment = e => {
    e.preventDefault();

    setLoading(true);

    createSellerComments(id, comment)
      .then(res => {
        getCommentsData(pageNo);
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => setLoading(false));
  };

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure you want to delete this comment?"}
          onConfirm={handleDelete}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="d-flex justify-content-between mb-3">
        <TopHeading heading={"Comments"} />
        <Button
          color="primary"
          onClick={() => {
            history("/seller/stores");
          }}
        >
          Go Back
        </Button>
      </div>
      <Card>
        <CardBody>
          <div className="d-flex w-100">
            <div className="w-50 me-2">
              <div className="mb-3">
                <label htmlFor="inputEmail4">
                  Comment<span className="primary-text">*</span>
                </label>

                <textarea
                  rows={"4"}
                  type="text"
                  value={comment}
                  onChange={e => setComment(e.target.value)}
                  className="form-control"
                  name="comment"
                  placeholder="Enter Comment"
                />
              </div>

              <div className="d-flex justify-content-end">
                <Button
                  disabled={comment.length == 0}
                  color="primary"
                  type="button"
                  onClick={handleCreateComment}
                >
                  Save
                </Button>
              </div>
            </div>
            <div className="w-50 table-responsive">
              <PrimaryTable
                keyField={"id"}
                responsive
                bordered={false}
                striped={false}
                columns={columns}
                data={data}
              />
              {commentsData?.length > 0 && (
                <PrimaryPagination
                  pageNo={pageNo}
                  setPageNo={setPageNo}
                  pageCount={pageCount}
                />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      {transactionData?.length > 0 && (
        <Card>
          <CardBody>
            <SellerTransactionAdjustments
              transactionData={transactionData}
              pageNo={transactionPageNo}
              pageCount={transactionCount}
              setPageNo={setTransactionPageNo}
            />
          </CardBody>
        </Card>
      )}
      {adjustmentData?.length > 0 && (
        <AdjusmentHistoryTable
          tableData={adjustmentData}
          adjustmentPageCount={adjustmentPageCount}
          adjustmentPageNo={adjustmentPageNo}
          setAdjustmentPageNumber={setAdjustmentPageNumber}
          id={id}
        />
      )}
      {balanceSummaryData?.length > 0 && (
        <Card>
          <CardBody>
            <BalanceSummary
              balanceData={balanceSummaryData}
              pageNo={balancePageNo}
              pageCount={BalanceCount}
              setPageNo={setBalancePageNo}
            />
          </CardBody>
        </Card>
      )}
    </div>
  );
}

export default SellerComments;
