import React from 'react';
import PrimaryTable from 'components/Common/PrimaryTable';
import deleteIcon from "assets/images/delete-icon-white.svg";
import PrimaryPagination from 'components/Common/Pagination/PrimaryPagination';

function ProductIdsTable({
    data,
    pageNo,
    setPageNo,
    handleDelete,
    pageCount,
}) {

    
    const columns = [
        {
            dataField: 'product_id',
            text: 'Product ID',
            formatter: (cell, row) => (
                <div className='font-semi-bold'>
                    #{row.product_id}
                </div>
            )
        },
        {
            dataField: 'product_name',
            text: 'Product Name'
        },
        {
            dataField: 'action',
            text: "Action",
            formatter: (cell, row) => (
                <div className='action-btn delete-btn-bg cursor-pointer' onClick={()=>handleDelete(row.id)}>
                    <img src={deleteIcon} className="" alt="delete icon" />
                </div>
            )
        }
    ];

  return (
    <>
        <h4 className='font-semi-bold mb-3'>Group Products</h4>
        <div className='mt-3'>
            <PrimaryTable
                keyField='id'
                data={data}
                columns={columns}
                responsive
                bordered={false}
            />
            {
                data?.length > 0 && (
                    <PrimaryPagination 
                        pageCount={pageCount}
                        pageNo={pageNo}
                        setPageNo={setPageNo}
                    />
                )
            }
        </div>
    </>
  )
}

export default ProductIdsTable