import { getApi } from "./BaseService";
import  axiosInstance  from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getSellerGroupList = (page,search) => {
    return getApi({
        url:'admin/seller/seller-group',
        data:{
            is_admin:true,
            page:page || undefined,
            search:search || undefined
        }
    }).then(res => (res.data))
}

export const getSellersList = (id,page,search) => {
    return getApi({
        url:`admin/seller/seller-group/${id}/sellers`,
        data:{
            is_admin:true,
            page:page || undefined,
            search:search || undefined
        }
    }).then(res => (res.data))
}

export const deteleSellerGroup  = (id) => {
    return axiosInstance({
		method: "DELETE",
        url:`${siteApiUrl}admin/seller/seller-group/${id}`,
        withCredentials:true,
        data:{
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
}

export const createNewSellerGroup  = (data) => {
    return axiosInstance({
		method: "POST",
        url:`${siteApiUrl}admin/seller/seller-group/create`,
        withCredentials:true,
        
		data: {
            ...data,
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
}


export const getSingleSellerGroup  = (id) => {
    return getApi({
        url:`admin/seller/seller-group/edit/${id}`,
        data:{
            is_admin:true,
        }
    }).then(res => (res.data))
}



export const updateSellerGroup  = (data,id) => {
    return axiosInstance({
		method: "PUT",
        url:`${siteApiUrl}admin/seller/seller-group/update/${id}`,
        data: {
            ...data,
            is_admin:true,
        },
		withCredentials:true,
		headers:getHttpHeaders(),
	});
}