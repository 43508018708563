import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { getSingleAdultKeyword, updateAdultKeyword } from "services/KeywordDetectServices";
import { useParams } from "react-router-dom";
import PrograssPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function EditAdultKeywords() {
	const history = useNavigate();
	const { id } = useParams();
	const [fields, setFields] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(()=>{
		setPageTitle("Edit Adult Keywords - Inspire Uplift")
	},[]);


	useEffect(() => {
		setLoading(true);
		getSingleAdultKeyword(id)
			.then(res => {
				setFields(res.data)
			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false));
	}, []);

	const handleChange = e => {
		const name = e.target.name;
		const value = e.target.value;
		const fieldsObj = { ...fields };

		fieldsObj[name] = value;
		setFields(fieldsObj);

	}

	const handleSubmit = e => {
		e.preventDefault();
		toast.dismiss();
		setLoading(true);
		updateAdultKeyword(fields, id)
			.then(res => {
				history(-1)
				PrimaryToast({type:'success', message:res?.data?.message});
			})
			.catch(err =>{
				console.log(err)
				PrimaryToast({type:'error', message:err?.response?.data?.message[0]});
			})
			.finally(res => setLoading(false));

	}
	return (
		<div className="page-content">
			{
				loading && <PrograssPopup label={"Loading..."} />
			}
			<form onSubmit={handleSubmit}>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<TopHeading heading={"Edit Adult Keywords"} />
					<div className="d-flex align-items-center">
						<Button onClick={() => history(-1)}>Cancel</Button>
						<Button className="ms-3" color="primary">
							Save
						</Button>
					</div>
				</div>
				<Card>
					<CardBody className="w-50">
						<div className="mb-3">
							<label htmlFor="inputEmail4">
								Keywords<span className="primary-text">*</span>
							</label>
							<textarea
								className="form-control"
								id="exampleFormControlTextarea1"
								rows="3"
								name="keywords"
								onChange={handleChange}
								value={fields?.keywords || ""}
								
							></textarea>
						</div>
					</CardBody>
				</Card>
			</form>
		</div>
	);
}

export default EditAdultKeywords;
