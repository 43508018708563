import React from 'react';
import { Button } from 'reactstrap';

const DisputeActions = ({setIsRefund, isRefund, setIsMessage, isMessage, setIsClose, isClose }) => {
  return (
    <div className="action-section mb-4">
    <Button
      size="lg"
      color="primary"
      block
      className="mb-3 "
      onClick={() => setIsRefund(!isRefund)}
    >
      Refund
    </Button>
    <Button
      size="lg"
      color="primary"
      block
      className="mb-3 "
      onClick={() => setIsMessage(!isMessage)}
    >
      Message
    </Button>
    <Button
      size="lg"
      color="primary"
      block
      className=""
      onClick={() => setIsClose(!isClose)}
    >
      Close Dispute
    </Button>
  </div>
  )
}

export default DisputeActions