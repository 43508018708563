import React from "react";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";

const BalanceSummary = ({ balanceData, pageNo, setPageNo, pageCount }) => {

    const columns = [
        {
          dataField: "id",
          text: "#",
          formatter: (cell, row) => (
            <div className="">{(pageNo - 1) * 10 + row?.index}</div>
          ),
        },
        {
          dataField: "message",
          text: "Message",
        },
        {
            dataField: "from",
            text: "From",
        },
        {
            dataField:"to",
            text:"To"
        },
        {
          dataField: "created_at",
          text: "Date",
        },
        {
          dataField: "admin",
          text: "Created By",
        },
      ];
    
      const data = balanceData?.map((item, index) => {
        return {
          ...item,
          index: index + 1,
        };
      });

      
  return (
    <div>
      <h5>Balance Summary</h5>
      <div className="table-responsive mt-4">
        <PrimaryTable
          keyField={"id"}
          responsive
          bordered={false}
          striped={false}
          columns={columns}
          data={data}
        />
      </div>
      {data?.length > 0 && (
        <PrimaryPagination
          pageCount={pageCount}
          pageNo={pageNo}
          skipUrlParam={true}
          setPageNo={setPageNo}
        />
      )}
    </div>
  );
};

export default BalanceSummary;
