import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import TopHeading from "components/Common/TopHeading";
import DateFilter from "components/Common/DateFilter";
import { CardBody, Card, Button } from "reactstrap";
import { exportSellerSales, getSalesBySeller } from "services/ReportsServices";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrograssPopup from "components/Common/Popups/ProgressPopup";
import { setPageTitle } from "utils/pageTitle";
import PrimaryTable from "components/Common/PrimaryTable";
import viewIcon from "assets/images/rectangle-arrow.svg";
import { openLinkInNewTab } from "utils/linkHandling";
import ExportButton from "components/Common/ExportButton";
import DownloadFilesById from "components/Common/Popups/DownloadFilesById";

function SalesBySeller() {
  const [sellerSalesData, setSellerSalesData] = useState([]);
  const [urlParams, setUrlParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [dates, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [isDownloadSellerData, setIsDownloadSellerData] = useState(false);

  useEffect(() => {
    setPageTitle("Sales By Seller - Inspire Uplift");
  }, []);

  useEffect(() => {
    let startParam = urlParams?.get("start") || new Date();
    let endParam = urlParams?.get("end") || new Date();
    const pageParam = urlParams?.get("page") || undefined;
    setPageNo(pageParam || 1);
    const eventStartDate = moment(
      startParam || new Date(),
      "YYYY-MM-DD"
    ).toDate();
    const eventEndDate = moment(endParam || new Date(), "YYYY-MM-DD").toDate();
    setDate({
      startDate: eventStartDate,
      endDate: eventEndDate,
      key: "selection",
    });
    const start = moment(startParam).startOf("day").format("YYYY-MM-DD HH:mm:ss");
    const end = moment(endParam).endOf("day").format("YYYY-MM-DD HH:mm:ss");
    setLoading(true);
    getSalesBySeller(pageParam, start, end)
      .then(res => {
        setSellerSalesData(res.data.data);
        setPageCount(res?.data?.last_page);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, [urlParams]);

  const columns = [
    {
      dataField: "business_name",
      text: "Store name",
    },
    {
      dataField: "quantity",
      text: "Orders",
    },
    {
      dataField: "discount",
      text: "Discount",
      formatter: (cell, row) => <div>{row?.discount?.toFixed(2)}</div>,
    },
    {
      dataField: "subtotal",
      text: "Subtotal",
      formatter: (cell, row) => <div>{row?.subtotal?.toFixed(2)}</div>,
    },
    {
      dataField: "tax",
      text: "Tax",
      formatter: (cell, row) => <div>{row?.tax?.toFixed(2)}</div>,
    },
    {
      dataField: "grand_total",
      text: "Grand Total",
      formatter: (cell, row) => <div>{row?.grand_total?.toFixed(2)}</div>,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div
          className="action-btn edit-icon-bg cursor-pointer"
          onClick={() => handleView(row.seller_id)}
        >
          <img src={viewIcon} height={18} width={18} alt="view icon" />
        </div>
      ),
    },
  ];

  const handleView = id => {
    const url = `${
      window?.location?.origin
    }/reports/sales-by-seller/${id}?start=${moment(dates?.startDate).format(
      "YYYY-MM-DD"
    )}&end=${moment(dates?.endDate).format("YYYY-MM-DD")}`;
    openLinkInNewTab(url, "_blank");
  };

  const data = sellerSalesData?.map(item => {
    return {
      ...item,
    };
  });

  const handleExport = async () => {
    const eventStartDate = moment(dates.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const eventEndDate =moment(dates.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    const response = await exportSellerSales(eventStartDate, eventEndDate);
    return response;
  };

  const handleToggle = () => {
    setIsDownloadSellerData(pre => !pre);
  }


  return (
    <div className="page-content reports-page-container">
      {loading && <PrograssPopup label={"Loading..."} />}
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <TopHeading heading={"Sales By Seller"} />
        <DateFilter dates={dates} setDate={setDate} isAddParam={true} />
      </div>
      <div className="d-flex justify-content-end mb-3">
        <Button className="me-4" color="primary" onClick={handleToggle}>Download Seller Report</Button>
        <ExportButton
          isLoading={loading}
          setIsLoading={setLoading}
          fetchData={handleExport}
        />
      </div>
      <Card>
        <CardBody>
          <div className="">
            <PrimaryTable
              columns={columns}
              data={data}
              classes={"table align-middle table-nowrap"}
              headerWrapperClasses={"thead-light"}
              responsive
              bordered={false}
              keyField="id"
            />
          </div>
        </CardBody>
        {sellerSalesData?.length > 0 && pageCount > 1 && (
          <PrimaryPagination
            pageCount={pageCount}
            pageNo={pageNo}
            setPageNo={setPageNo}
          />
        )}
      </Card>
      {
        isDownloadSellerData && (
            <DownloadFilesById path={"seller-report"} isOpen={isDownloadSellerData} handleClick={handleToggle} />
        )
      }
    </div>
  );
}

export default SalesBySeller;
