import React from "react";
import { Card, CardBody } from "reactstrap";
import editIcon from "../../assets/images/edit-icon.svg";
import addressIcon from "../../assets/images/address-icon.svg";
import contactIcon from "../../assets/images/contact-icon.svg";
import phoneIcon from "../../assets/images/phone-icon.svg";

const AddressComponent = ({
  address,
  city,
  state,
  zip,
  name,
  country,
  heading,
  handleClick,
  phone,
  isEditPermission,
}) => {
  return (
    <div>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <div className="font-semi-bold h5 mb-0">{heading}</div>
            {isEditPermission && (
              <img
                src={editIcon}
                alt="edit icon"
                className="cursor-pointer"
                onClick={handleClick}
              />
            )}
          </div>
          <div className="mt-2 font-semi-bold d-flex align-items-center">
            <img src={contactIcon} alt="contact icon" height={16} width={16} />
            <span className="ps-2">{name}</span>
          </div>
          <p className="mt-2 mb-0  d-flex align-items-start">
            <img
              src={addressIcon}
              alt="address icon"
              className="mt-1"
              height={16}
              width={16}
            />
            <p className="ps-2 mb-0">
              {address}
              <p className="mb-0 break-word">
                {city && `${city},`}
                {state && `${state},`}
                {zip && zip}
              </p>
              <p className="mb-0 ">{country}</p>
            </p>
          </p>
          {phone && (
            <p className="mt-1 mb-0 d-flex">
              <img
                src={phoneIcon}
                alt="phone icon"
                className="pe-2"
                height={22}
                width={22}
              />
              {phone}
            </p>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default AddressComponent;
