import React, { useState } from "react";
import BalanceCard from "components/Common/Cards/BalanceCard";
import BalanceChangePopup from "components/Common/Popups/BalanceChangePopup";
import { updateSellerBalance } from "services/SellerStoreServices";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { getAllSellerBalances } from "services/SellerStoreServices";

function BalanceSection({data, setLoading, setSellerBalances, sellerId, loading}) {
    const [isChanged, setIsChanged] = useState(false);
    const [popupBalance, setBalance] = useState("");
    const [popupTitle, setPopupTitle] = useState("");


    const handleChange=(e)=>{
      setBalance(e.taregt.value);
    };

    const handlePopup = (heading, balance) => {
        setPopupTitle(heading);
        setBalance(balance);
        setIsChanged(!isChanged);
    };

    const handleClose = () => {
        setPopupTitle();
        setBalance();
        setIsChanged(!isChanged);
    };

	const handleUpdateBalance = () => {
  
		let keyName = '';
		if (popupTitle === 'Pending Balance') {
		  keyName = 'pending';
		} else if (popupTitle === 'Available Balance') {
		  keyName = 'available';
		} else {
		  keyName = 'hold';
		}
	  
		const data = {
		  [keyName]:  popupBalance == 0 ? Number(popupBalance)?.toFixed(2) : popupBalance,
		};

    setLoading(true);
    updateSellerBalance(sellerId, data)
      .then((res) => {
        getAllSellerBalances(sellerId)
        .then(res => {
          setSellerBalances(res.data);
        })
        .catch(err => {
          console.log(err)
        });
        PrimaryToast({type:"success",message:res?.data?.message});
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        PrimaryToast({type:"error",message:Array.isArray(err?.response?.data?.message) ? err?.response?.data?.message[0] : err?.response?.data?.message})
      })
      .finally(res => {
        setLoading(false);
      })
	};


  return (
    <div className="mt-4">
      <h4 className="font-semi-bold">Balance Summary</h4>
      <div className="mt-4 d-flex w-100">
        <BalanceCard heading={"Pending Balance"} balance={data?.pending_balance || 0} handlePopup={handlePopup}  />
        <BalanceCard heading={"Available Balance"} balance={data?.available_balance || 0} handlePopup={handlePopup}   />
        <BalanceCard heading={"In-Review Balance"} balance={data?.review_balance || 0}  handlePopup={handlePopup}  />
      </div>
      {
        isChanged && (
          <BalanceChangePopup loading={loading} handleUpdateBalance={handleUpdateBalance} isOpen={isChanged} handleChange={handleChange} handleClose={handleClose} setBalance={setBalance} balance={popupBalance} title={popupTitle}  />
        )
      }
    </div>
  );
}

export default BalanceSection;
