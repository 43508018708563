import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button } from "reactstrap";
import PlusIcon from "assets/images/plus-icon.svg";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import PrimaryTable from "components/Common/PrimaryTable";
import { setPageTitle } from "utils/pageTitle";
import deleteIcon from "assets/images/delete-icon-white.svg";
import editIcon from "assets/images/edit-icon-white.svg";
import { deleteCampaign, getAllCampaigns } from "services/CampaignsServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import DeleteModal from "components/Common/Toast/DeleteModal";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import { useSelector } from "react-redux";
import { openLinkInNewTab } from "utils/linkHandling";

const CampaignsListing = () => {
  const navigate = useNavigate();
  const [campaignsListing, setCampaignsListing] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const params = new URLSearchParams(window?.location?.search);
  const user = useSelector(state => state.auth.user);
  const permissions = user?.permissions?.map(item => item.name);

  useEffect(() => {
    setPageTitle("Campaigns - Inspire Uplift");
  }, []);

  useEffect(() => {
    const pageParams = params?.get("page") || undefined;
    setPageNo(pageParams || 1);
    setLoading(true);
    getData(pageParams);
  }, [window?.location?.search]);

  const getData = page => {
    getAllCampaigns()
      .then(res => {
        if (res?.data?.data?.length == 0 && page > 1) {
          params.set("page", page - 1);
          let newUrl = `${window?.location?.pathname}?${params.toString()}`;
          navigate(newUrl, { replace: true });
        } else {
          setCampaignsListing(res.data.data);
          setPageCount(res?.data?.last_page);
        }
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      formatter: (cell, row) => <div className="">{row.name}</div>,
    },
    {
      dataField: "invited_sellers",
      text: "Invited Sellers",
      formatter: (cell, row) =>
        row?.invited_sellers == null ? "All" : "Selected",
    },
    {
      dataField: "registration_end",
      text: "Registration Close In",
    },
    {
      dataField: "campaign_live_period",
      text: "Campaign Live Period",
      formatter: (cell, row) => {
        return (
          <div>
            <div>
              <span className="font-semi-bold">Start:</span> {row.start}
            </div>
            <div>
              <span className="font-semi-bold">End:</span> {row.end}
            </div>
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => {
        const cStatus = row.status === 1 ? "Active" : "Inactive";
        return (
           <div
             className={`status w-max-content ${cStatus === "Active" ? "success-status" : "danger-status"}`}
           >
             {cStatus}
           </div>
        );
       }
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="action-btn edit-icon-bg me-1"
              onClick={()=> openLinkInNewTab(`/marketing/campaigns/edit/${row.id}`,"")}
            >
              <img src={editIcon} className="" alt="edit icon" />
            </div>
            {permissions?.includes("campaign-delete") ? (
              <div
                className="action-btn delete-btn-bg me-1"
                onClick={() => {
                  setSelectedId(row?.id);
                  setShowDeleteModal(true);
                }}
              >
                <img src={deleteIcon} className="" alt="delete icon" />
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  const data = campaignsListing?.map((item, index) => {
    return {
      ...item,
    };
  });

  const handleDelete = () => {
    setLoading(true);
    deleteCampaign(selectedId)
      .then(res => {
        PrimaryToast({
          type: "success",
          message: res?.data?.message,
        });
        setSelectedId("");
        setShowDeleteModal(false);
        getData(pageNo);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        PrimaryToast({
          type: "error",
          message: err?.response?.data?.message,
        });
      });
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this campaign?"}
          onConfirm={handleDelete}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <TopHeading heading={"Campaigns"} />
        {permissions?.includes("campaign-create") ? (
          <Button
            color="primary"
            onClick={() => navigate("/marketing/campaigns/create")}
          >
            <img src={PlusIcon} alt="create icon" className="pe-2" />
            Create Campaigns
          </Button>
        ) : null}
      </div>
      <Card>
        <CardBody>
          <PrimaryTable
            keyField="id"
            data={data}
            columns={columns}
            bordered={false}
          />
          {campaignsListing?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageNo={pageNo}
              pageCount={pageCount}
              setPageNo={setPageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default CampaignsListing;
