import React, { useState, useEffect } from "react";
import moment from "moment";
import { getSalesByGroup } from "services/ReportsServices";
import TopHeading from "components/Common/TopHeading";
import { useSearchParams } from "react-router-dom";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { Card, CardBody } from "reactstrap";
import DateRangePicker from "components/Common/DateRangePicker";
import PrimaryTable from "components/Common/PrimaryTable";

function SalesByGroup() {
  const [urlParams, setUrlParams] = useSearchParams();
  const [pageNo, setPageNo] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [salesData, setSalesData] = useState([]);
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    const pageParam = urlParams?.get("page") || undefined;
    setPageNo(pageParam || 1);
    let startParam = urlParams?.get("start")
      ? moment(urlParams?.get("start"))
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
    let endParam = urlParams?.get("end")
      ? moment(urlParams?.get("end")).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
    setDates([
      {
        startDate: moment(startParam)?.toDate() || new Date(),
        endDate: moment(endParam).toDate() || new Date(),
        key: "selection",
      },
    ]);

    setLoading(true);
    getSalesByGroup(startParam, endParam, pageParam)
      .then(res => {
        setSalesData(res.data.data);
        setPageCount(res?.data?.last_page);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, [urlParams]);

  const handleFilter = (key, value, key2, value2) => {
    const existingParams = Object.fromEntries(urlParams?.entries());
    if (key && value) {
      existingParams[key] = value;
      if (key2) {
        existingParams[key2] = value2;
      }
    } else {
      delete existingParams[key];
    }
    if (existingParams?.hasOwnProperty("page")) {
      delete existingParams["page"];
    }
    setUrlParams(existingParams);
  };

  const handleDatePicker = item => {
    setDates([
      {
        startDate: moment(item.selection.startDate)
          .local()
          .startOf("day")
          .toDate(),
        endDate: moment(item.selection.endDate).local().endOf("day").toDate(),
        key: "selection",
      },
    ]);
  };

  const salesCol = [
    {
      dataField: "seller_group",
      text: "Seller Group",
      formatter: (cell, row) => <div className="">{row?.seller_group}</div>,
      headerStyle: { width: "35%" },
    },
    {
      dataField: "views",
      text: "Views",
      formatter: (cell, row) => {
        return <div>{row?.views}</div>;
      },
    },
    {
      dataField: "add_to_cart",
      text: "Add To Cart",
      formatter: (cell, row) => {
        return <div>{row?.addToCart}</div>;
      },
    },
    {
      dataField: "purchases",
      text: "Purchases",
      formatter: (cell, row) => {
        return <div>{row?.purchases}</div>;
      },
    },
    {
      dataField: "total_revenue",
      text: "Total Revenue",
      formatter: (cell, row) => <div>${row?.total_revenue.toFixed(2)}</div>,
    },
    {
      dataField: "conversion_rate",
      text: "Conversion Rate",
      formatter: (cell, row) => <div>{row?.conversion_rate}%</div>,
    },
  ];
  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-4">
        <TopHeading heading={"Sales by Group"} />
      </div>
      <Card>
        <CardBody>
          <div className="d-flex">
            <div className="position-relative">
              <div
                className="form-control cursor-pointer rounded-full"
                style={{ color: "#74788D", width: "max-content" }}
                onClick={() => setShowCalendar(true)}
              >
                <span className="product-purchase-start-date">
                  {moment(dates[0].startDate).format("DD MMM YYYY")}
                </span>
                -{" "}
                <span className="product-purchase-end-date">
                  {moment(dates[0].endDate).format("DD MMM YYYY")}
                </span>
              </div>

              {showCalendar && (
                <>
                  <div
                    className="position-fixed top-0 start-0 w-100 h-100"
                    style={{ zIndex: "10" }}
                    onClick={() => setShowCalendar(false)}
                  ></div>
                  <DateRangePicker
                    dates={dates}
                    handleDatePicker={handleDatePicker}
                    handleSubmit={() => {
                      handleFilter(
                        "start",
                        moment(dates[0].startDate).format("YYYY-MM-DD"),
                        "end",
                        moment(dates[0].endDate).format("YYYY-MM-DD")
                      );
                      setShowCalendar(false);
                    }}
                    // minDate={new Date()}
                    maxDate={new Date()}
                  />
                </>
              )}
            </div>
          </div>
          <div className="table-responsive mt-4">
            <PrimaryTable
              columns={salesCol}
              data={salesData}
              bordered={false}
              keyField="order_number"
            />
          </div>
          {salesData?.length > 0 && pageCount > 1 ? (
            <PrimaryPagination
              pageCount={pageCount}
              setPageNo={setPageNo}
              pageNo={pageNo}
            />
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
}

export default SalesByGroup;
