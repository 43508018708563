import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import SwitchButton from "components/Common/SwitchButton";
import PrimaryInput from "components/Common/PrimaryInput";
import { getSellerGroupList } from "services/SellerGroupsServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import SecondarySelect from "components/Common/SecondarySelect";
import { createAnnouncement } from "services/AnnouncementServices";
import moment from "moment";
import CalendarInput from "components/Common/CalenderInput";
import PrimaryImageUploader from "components/Common/PrimaryImageUploder";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

export default function CreateAnnouncement() {
	const [fields, setFields] = useState({
		is_active: true,
	});
	const [options, setOptions] = useState([]);
	const [isDateRangeAdd, setDateRangeAdd] = useState(false);

	const [selectedOptions, setSelectedOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState(undefined);
	const [showStartDateCalender, setShowStartDateCalender] = useState(false);
	const [showEndDateCalendar, setShowEndDateCalender] = useState(false);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");

	const history = useNavigate();
	

	useEffect(()=>{
		setPageTitle("Create New Announcement - Inspire Uplift")
	},[]);

	useEffect(() => {

		setLoading(true);
		getSellerGroupList()
			.then(res => {

				let customArr = res?.data?.data?.map((item, index) => {
					return {
						label: item.name,
						value: item.id
					}
				});
				setOptions([
					{
						label: 'Please Select Seller Group',
						value: undefined
					},
					...customArr
				]);
			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false))
	}, []);

	const handleSelectChange = (selected) => {
		setSelectedOptions(selected);
	};

	const handleDateSelect = (date, name) => {
		if (name === "start_date") {
			setStartDate(date);
			setShowStartDateCalender(false);
		}
		if (name === "end_date") {
			setEndDate(date);
			setShowEndDateCalender(false);
		}
	};

	const maxDate = new Date();
	maxDate.setFullYear(maxDate.getFullYear() + 10);


	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
		const fieldsObj = { ...fields };
		fieldsObj[name] = value;

		if (name === "programId" || name === "programToken") {
			fieldsObj["configuration"][name] = value
		}
		else {
			fieldsObj[name] = value;
		}
		setFields(fieldsObj);
	};

	const handleValidation = () => {
		if (isDateRangeAdd && !startDate) {
			alert("start date is required");
			return false;
		}
		if (isDateRangeAdd && !endDate) {
			alert("end date is required");
			return false;
		}
		return true;
	}

	const handleSubmit = e => {
		e.preventDefault();
		const selectedIds = selectedOptions?.map((item) => item.value);
		const data = {
			...fields,
			seller_groups_ids: selectedIds,
			start_date: isDateRangeAdd && moment(startDate).format("YYYY-MM-DD") || undefined,
			end_date: isDateRangeAdd && moment(endDate).format("YYYY-MM-DD") || undefined,
			image: file
		};
		if (handleValidation()) {
			toast.dismiss();
			setLoading(true);
			createAnnouncement(data)
				.then(res => {
					if (res.status === 200) {
						PrimaryToast({ type: 'success', message: res?.data?.message });
						history(-1);
					}
				})
				.catch(err => {
					PrimaryToast({ type: 'error', message: err?.response?.data?.message[0] });
					console.log(err);
				})
				.finally(res => setLoading(false));
		}
	};

	return (
		<div className="page-content">
			{
				loading && <ProgressPopup label={"Loading..."} />
			}
			<form onSubmit={handleSubmit}>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<TopHeading heading={"Create New Announcement"} />
					<div className="d-flex">
						<Button className="me-2" onClick={() => history(-1)}>
							Go Back
						</Button>
						<Button color="primary" type="submit">
							Create
						</Button>
					</div>
				</div>
				<Card>
					<CardBody>
						<h4 className="font-semi-bold">Announcement Information</h4>
						<div className="w-50">
							<div className="mb-3">
								<PrimaryInput
									name={"title"}
									value={fields?.title || ""}
									label={"Title"}
									placeholder="Enter Title"
									type={"text"}
									isRequired
									handleChange={handleChange}
								/>
							</div>
							<div className="mb-3">
								<label htmlFor="inputEmail4">
									Description <span className="primary-text">*</span>
								</label>
								<textarea
									rows={4}
									type="text"
									className="form-control"
									id="inputEmail4"
									name="description"
									placeholder="Enter Description"
									value={fields?.description || ""}
									onChange={handleChange}
								/>
							</div>
							<div className="mb-3">
								<SecondarySelect
									isRequired={true}
									label={'Select Group ID'}
									options={options}
									isMulti={true}
									hideSelectedOptions={false}
									value={selectedOptions}
									name="seller_groups_ids"
									placeholder={"Enter  Select Group ID"}
									handleSelect={handleSelectChange}
								/>
							</div>
							<div className="mb-3">
								<PrimaryInput
									name={"link"}
									value={fields?.link || ''}
									label={"Link"}
									placeholder="Enter Link"
									type={"text"}
									handleChange={handleChange}
								/>
							</div>
							<div className="mb-3">
								<PrimaryInput
									name={"button"}
									label={"Button Name"}
									placeholder="Enter  Button Name"
									type={"text"}
									value={fields?.button || ""}
									handleChange={handleChange}
								/>
							</div>
							<div className="mb-3">
								<PrimaryImageUploader getFiles={file} setFiles={setFile} label="Image" />
							</div>

							<div className="mb-2">
								<label htmlFor="inputEmail4">Status</label>
								<SwitchButton checked={fields.is_active === true ? true : false} label={fields.is_active === true ? "Active" : "Inactive"} name="is_active" value={fields?.is_active} handleChange={handleChange} />
							</div>
							{
								<div className="form-check mb-3">
									<input
										className="form-check-input"
										type="checkbox"
										value=""
										id="dateRange"
										onChange={(e) => { setDateRangeAdd(e.target.checked) }}
									/>
									<label className="form-check-label" htmlFor="dateRange">
										Click To Add Date Range
									</label>
								</div>
							}
							{
								isDateRangeAdd && (
									<>
										<div className="mb-3">
											<CalendarInput
												label="Start Date"
												handleShowCalender={() => setShowStartDateCalender(!showStartDateCalender)}
												showCalender={showStartDateCalender}
												handleChange={(newDate) =>
													handleDateSelect(
														newDate,
														"start_date"
													)
												}
												minDate={moment().toDate()}
												maxDate={maxDate}
												date={
													startDate
														? new Date(startDate)
														: new Date()
												}
												value={startDate && moment(startDate).format("MM-DD-YYYY")}
												placeholder={"MM-DD-YYYY"}
											/>
										</div>
										<div className="mb-3">
											<CalendarInput
												label="End Date"
												handleShowCalender={() => setShowEndDateCalender(!showEndDateCalendar)}
												showCalender={showEndDateCalendar}
												handleChange={(newDate) =>
													handleDateSelect(
														newDate,
														"end_date"
													)
												}
												minDate={moment().toDate()}
												maxDate={maxDate}
												date={
													endDate
														? new Date(endDate)
														: new Date()
												}
												value={endDate && moment(endDate).format("MM-DD-YYYY")}
												placeholder={"MM-DD-YYYY"}
											/>
										</div>
									</>
								)
							}
						</div>
					</CardBody>
				</Card>
			</form>
		</div>
	);
}
