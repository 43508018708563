import { LOAD_USER } from "../actions/auth";

const initialState  = {
    user:{},
}

export const authReducer = (state=initialState,action) => {
    const {type, payload}  = action;

    switch(type){
        case LOAD_USER : {
            return {
                ...state,
                user:payload
            }
        }
        break;
        default : 
        return  state
    }

};

export default authReducer;

