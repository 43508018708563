import axios from "axios";

const instance = axios.create();

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response && error.response.status === 401) {
			if(window.location.pathname !==  "/login" && window.location.pathname !==  "/forgot-password"){
				window.location.href = "/login";
			}
		
		}
		return Promise.reject(error);
	}
);

export default instance;