import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { checkMediaType } from "utils/checkMediaType";

function AdCreative({ data }) {
  let mediaType;
  if (data?.media) {
    mediaType = checkMediaType(data?.media);
  }
  return (
    <div>
      <Card>
        <CardBody>
          <h4 className="text-15">Ad Creative</h4>
          {data?.media && (
            <Row className="py-2 border-top border-bottom">
              <Col sm="6">
                <div className="d-flex justify-content-between">
                  <div className="font-semi-bold w-50">Media:</div>
                  <div className="w-50">
                    {data?.media && mediaType == "image" && (
                      <a
                        href={data?.media}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={data?.media}
                          height={90}
                          width={90}
                          className="rounded"
                          alt="media image"
                          loading="lazy" 
                        />
                      </a>
                    )}
                    {data?.media && mediaType == "video" && (
                      <a
                        href={data?.media}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <video width="400" height="240" controls>
                          <source src={data?.media} type="video/mp4" />
                          <source src={data?.media} type="video/ogg" />
                        </video>
                      </a>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <Row className="py-2 border-bottom">
            <Col sm="6">
              <div className="d-flex justify-content-between">
                <div className="font-semi-bold w-50">Headline:</div>
                <div className="w-50 font-sem-bold">{data?.headline}</div>
              </div>
            </Col>
            <Col sm="6">
              <div className="d-flex justify-content-between">
                <div className="font-semi-bold w-25">Description:</div>
                <div className="w-75">{data?.description}</div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}

export default AdCreative;
