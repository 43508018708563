import React from 'react';
import TopHeading from 'components/Common/TopHeading';
import { CardBody, Card, Button, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import BootstrapTable from "react-bootstrap-table-next";
import "./dispute.scss";
import rectangeArrow from "../../assets/images/rectangle-arrow.svg";
import optionIcon from "../../assets/images/options-icon.svg";


function BraintreeDispute() {

    const columns = [
        {
            dataField: 'id',
            text: 'Transaction ID',
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (cellContent, row) =><div className='dispute-status'>{row.status}</div>
        },
        {
            dataField: 'reason',
            text: 'Reason',
        },
        {
            dataField: 'source',
            text: 'Source',
        },
        {
            dataField: 'amount',
            text: 'Amount',
        },
        {
            dataField: 'disputed_on',
            text: 'Disputed On',
        },
        {
            dataField: 'respond_by',
            text: 'Respond By',
        },
        {
            dataField: 'action',
            text: 'Action',
            formatter: (cellContent, row) => {
                return (
                    <div className='d-flex align-items-center'>
                        <Button className='d-flex align-items-center dispute-action-btn'>
                            <img src={rectangeArrow} alt="rectangeArrow" />
                            <div className='ms-2'>
                                Dispute
                            </div>
                        </Button>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" to="#" className="card-drop px-3" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={optionIcon} alt="rectangeArrow" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem href="#">View Transaction</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                    </div>
                )
            }
        },
    ]

    const data = [
        {
            id: 'SK2548',
            status: 'Lost',
            reason: 'Fraud',
            source: 'Visa',
            amount: "$100.58",
            disputed_on: '12-10-2022',
            respond_by: '16-10-2022',
        },
        {
            id: 'K2548',
            status: 'Lost',
            reason: 'Fraud',
            source: 'Visa',
            amount: "$100.58",
            disputed_on: '12-10-2022',
            respond_by: '16-10-2022',
        },
        {
            id: 'K2548',
            status: 'Lost',
            reason: 'Fraud',
            source: 'Visa',
            amount: "$100.58",
            disputed_on: '12-10-2022',
            respond_by: '16-10-2022',
        },
        {
            id: 'K2548',
            status: 'Lost',
            reason: 'Fraud',
            source: 'Visa',
            amount: "$100.58",
            disputed_on: '12-10-2022',
            respond_by: '16-10-2022',
        },
        {
            id: 'K2548',
            status: 'Lost',
            reason: 'Fraud',
            source: 'Visa',
            amount: "$100.58",
            disputed_on: '12-10-2022',
            respond_by: '16-10-2022',
        },
        {
            id: 'K2548',
            status: 'Lost',
            reason: 'Fraud',
            source: 'Visa',
            amount: "$100.58",
            disputed_on: '12-10-2022',
            respond_by: '16-10-2022',
        },
        {
            id: 'K2548',
            status: 'Lost',
            reason: 'Fraud',
            source: 'Visa',
            amount: "$100.58",
            disputed_on: '12-10-2022',
            respond_by: '16-10-2022',
        },
        {
            id: 'K2548',
            status: 'Lost',
            reason: 'Fraud',
            source: 'Visa',
            amount: "$100.58",
            disputed_on: '12-10-2022',
            respond_by: '16-10-2022',
        },
        {
            id: 'K2548',
            status: 'Lost',
            reason: 'Fraud',
            source: 'Visa',
            amount: "$100.58",
            disputed_on: '12-10-2022',
            respond_by: '16-10-2022',
        },
        {
            id: 'K2548',
            status: 'Lost',
            reason: 'Fraud',
            source: 'Visa',
            amount: "$100.58",
            disputed_on: '12-10-2022',
            respond_by: '16-10-2022',
        }
    ]

    return (
        <div className='page-content'>
            <div className='mb-4'>
                <TopHeading heading={'Braintree Disputes'} />
            </div>
            <Card>
                <CardBody>
                    <div className='mt-3'>
                        <BootstrapTable
                            columns={columns}
                            data={data}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            responsive
                            bordered={false}
                            keyField="order_number"
                        />
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default BraintreeDispute