import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { siteApiUrl } from "./BaseService";
import { getHttpHeaders } from "./BaseService";

export const getOverTimeData = (start, end) => {
  return getApi({
    url: `admin/reports/overtime`,
    data: {
      is_admin: true,
      startDate: start || undefined,
      endDate: end || undefined,
    },
  }).then(res => res.data);
};

export const getSalesBySeller = (page, start, end) => {
  return getApi({
    url: `admin/reports/seller_sales`,
    data: {
      is_admin: true,
      startDate: start || undefined,
      endDate: end || undefined,
      page: page || undefined,
    },
  }).then(res => res.data);
};

export const getSalesByChannel = (start, end) => {
  return getApi({
    url: `admin/reports/channel_sales`,
    data: {
      is_admin: true,
      startDate: start || undefined,
      endDate: end || undefined,
    },
  }).then(res => res.data);
};

export const getProductPurchases = (
  start,
  end,
  page,
  search,
  sortOf,
  sortBy,
  shopName
) => {
  return getApi({
    url: `admin/reports/product_sale`,
    data: {
      is_admin: true,
      start: start || undefined,
      end: end || undefined,
      page: page || undefined,
      search: search || undefined,
      sort_column: sortOf == "cart" ? "add_to_cart" : sortOf,
      sort_type: sortBy,
      shop_name: shopName || undefined,
    },
  }).then(res => res.data);
};

export const getStoreStats = (
  pageNo,
  count,
  selectedRow,
  countSign,
  sort,
  sort_field
) => {
  return getApi({
    url: `admin/seller/store/statistics`,
    data: {
      is_admin: true,
      page: pageNo || undefined,
      field: selectedRow || undefined,
      value: count ? Number(count) : undefined,
      sort: sort || undefined,
      sort_field: sort_field || undefined,
      sign:
        countSign == "greater"
          ? ">"
          : countSign == "smaller"
          ? "<"
          : countSign == "equal"
          ? "="
          : undefined,
    },
  }).then(res => res.data);
};

export const getSalesByCountry = (start, end, page, search, sortOf, sortBy) => {
  return getApi({
    url: `admin/reports/country`,
    data: {
      is_admin: true,
      start: start || undefined,
      end: end || undefined,
      page: page || undefined,
      search: search || undefined,
      sort_column: sortOf == "cart" ? "addToCart" : sortOf,
      sort_type: sortBy,
    },
  }).then(res => res.data);
};

export const getSalesByReferrals = (
  start,
  end,
  page,
  search,
  sortOf,
  sortBy
) => {
  return getApi({
    url: `admin/reports/referral`,
    data: {
      is_admin: true,
      start: start || undefined,
      end: end || undefined,
      page: page || undefined,
      search: search || undefined,
      sort_column: sortOf == "cart" ? "addToCart" : sortOf,
      sort_type: sortBy,
    },
  }).then(res => res.data);
};

export const getSalesByGroup = (start, end, page) => {
  return getApi({
    url: `admin/reports/group_sale`,
    data: {
      is_admin: true,
      start: start || undefined,
      end: end || undefined,
      page: page || undefined,
    },
  }).then(res => res.data);
};

export const exportProductViewsAndPurchases = (
  start,
  end,
  sort_by,
  sort_order
) => {
  return axiosInstance({
    method: "POST",
    url: `${siteApiUrl}admin/reports/export_product_sale`,
    withCredentials: true,
    data: {
      start,
      end,
      sort_column: sort_order || undefined,
      sort_type: sort_by || undefined,
      is_admin: true,
    },
    headers: getHttpHeaders(),
  });
};

// product views and purchases details
export const getProductPurchasesDetail = (
  start,
  end,
  page,
  product_id,
  sortOf,
  sortBy
) => {
  return getApi({
    url: `admin/reports/product_sale_product`,
    data: {
      is_admin: true,
      start: start || undefined,
      end: end || undefined,
      page: page || undefined,
      product_id,
      sort_column: sortOf == "cart" ? "addToCart" : sortOf,
      sort_type: sortBy,
    },
  }).then(res => res.data);
};

// product sales by seller detail

export const getProductSalesBySeller = (
  start,
  end,
  page,
  seller_id,
  sortOf,
  sortBy
) => {
  return getApi({
    url: `admin/reports/product_sale_seller`,
    data: {
      is_admin: true,
      start: start || undefined,
      end: end || undefined,
      page: page || undefined,
      seller_id,
      sort_column: sortOf == "cart" ? "addToCart" : sortOf,
      sort_type: sortBy,
    },
  }).then(res => res.data);
};

// sales by referrals detail

export const getSalesReferralsProductsDetail = (
  start,
  end,
  page,
  referrer,
  sortOf,
  sortBy,
  filterType,
  filterValue
) => {
  return getApi({
    url: `admin/reports/referral_products`,
    data: {
      is_admin: true,
      start: start || undefined,
      end: end || undefined,
      page: page || undefined,
      referrer,
      sort_column: sortOf == "cart" ? "addToCart" : sortOf,
      sort_type: sortBy,
      filter_type:
        filterType == "greater"
          ? "greater_than"
          : filterType === "smaller"
          ? "less_than"
          : filterType == "equal"
          ? "equal"
          : undefined,
      filter_value: filterValue || undefined,
    },
  }).then(res => res.data);
};

// Export seller sales

export const exportSellerSales = (start, end) => {
  return getApi({
    url: `admin/reports/export/seller_sales`,
    data: {
      startDate: start || undefined,
      endDate: end || undefined,
      is_admin: true,
    },
  });
};

export const exportSellerReportsData = (start, end, sellerId) => {
  return getApi({
    url: `admin/reports/export-sale-seller-products`,
    data: {
      start: start || undefined,
      end: end || undefined,
      seller_id: sellerId || undefined,
      is_admin: true,
    },
  });
};

export const getRefunds = (page, start, end) => {
  return getApi({
    url: `admin/reports/refunds`,
    data: {
      page: page || 1,
      startDate: start || undefined,
      endDate: end || undefined,
      is_admin: true,
    },
  });
};

export const getSalesByCategory = (start, end, page) => {
  return getApi({
    url: `admin/reports/category_sale`,
    data: {
      start: start || undefined,
      end: end || undefined,
      page:page || 1,
      is_admin: true,
    },
  }).then(res => res.data);
};