import React from 'react';
import StatisticsCard from 'components/Common/Cards/StatisticsCard';
import { Card, CardBody, Row, Col } from 'reactstrap';

const StatisticsSection = ({data}) => {
  return (
    <Card>
    <CardBody>
      <h4 className="mb-4 card-title">Statistics</h4>
      <StatisticsCard
        heading={"Sellers Joining"}
        subHeading={"Total  Active Sellers"}
        subTotal={data?.seller_total ? data?.seller_total : 0}
        total={data?.seller_active ? data?.seller_active : 0}
        border={true}
      />
      <StatisticsCard
        heading={"Product Added"}
        subHeading={"Total Active Products"}
        subTotal={data?.product_total ? data?.product_total : 0}
        total={data?.product_approved ? data?.product_approved : 0}
        border={true}
      />
      <StatisticsCard
        heading={"Total Refunds"}
        subHeading={"Total Refunds"}
        subTotal={data?.refunds_total ? Number(data?.refunds_total) : "0"}
        total={`$${
          data?.refunds_amount
            ? Number(data?.refunds_amount)?.toFixed(2)
            : 0
        }`}
        border={true}
      />
      <StatisticsCard
        heading={"Total Commission"}
        subHeading={"Total Commision"}
        total={`$${
          data?.commission ? Number(data?.commission)?.toFixed(2) : 0
        }`}
        border={true}
      />
      <StatisticsCard
        heading={"Total Processing"}
        subHeading={"Total Processing"}
        subTotal={""}
        total={`$${
          data?.processing ? Number(data?.processing)?.toFixed(2) : 0
        }`}
      />
      <Row className="border-top py-4 d-flex justify-content-between align-items-center">
        <Col md="9">
          <h6>Active Subscriptions</h6>
        </Col>
        <Col md="3">
          <p>{data?.active_subscribers}</p>
        </Col>
      </Row>
    </CardBody>
  </Card>
  )
}

export default StatisticsSection;
