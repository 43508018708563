import React, { useState, useEffect } from "react";
import "moment-timezone";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody, Row, Col } from "reactstrap";
import ReviewWithdrawPopup from "components/Common/Popups/ReviewWithdrawPopup";
import { useParams } from "react-router-dom";
import {
  getSingleWithDrawRequest,
  sellerWithdrawHistories,
  sellerWithdrawReview,
  handleWithdrawApprove,
  handleWithdrawReject,
  withdrawRejectByNetwork,
} from "services/WithdrawRequestService";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryTable from "components/Common/PrimaryTable";
import moment from "moment";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import SellerCommentsPopup from "components/Common/Popups/SellerCommentPopup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { setPageTitle } from "utils/pageTitle";
import WithdrawRejectPopup from "components/Common/Popups/WithdrawRejectPopup";
import { getCurrentTimeInEST } from "utils/date";
import BanSellerApprovedPopup from "components/Common/Popups/BanSellerApprovedPopup";

function SingleWithDrawRequest() {
  const params = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reviewWithdraw, setReviewWithdraw] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sellerInfo, setSellerInfo] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [withdrawHistories, setWithdrawHistories] = useState([]);
  const [review, setReview] = useState("");
  const [isPayoneer, setIsPayoneer] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [isReview, setIsReview] = useState(true);
  const [isShowComment, setIsShowComment] = useState(false);
  const [withdrawReason, setWithdrawReason] = useState("");
  const [isWithdrawReject, setIsWithdrawReject] = useState(false);
  const [actionType, setActionType] = useState("");
  const [showTempBanApprovalModal, setShowTempBanApprovalModal] =
    useState(false);
  const [isApprovedBan, setIsApprovedBan] = useState(false);
  const [payoutType, setPayoutType] = useState("");

  const user = useSelector(state => state?.auth?.user);
  const hasWithdrawApprovePer = user.permissions?.some(
    p => p.name === "withdraw-approve"
  );
  const hasWithdrawRejectedPer = user?.permissions?.some(
    p => p.name === "withdraw-reject"
  );
  const hasWithdrawCommentPer = user?.permissions?.some(
    p => p.name === "withdraw-comments"
  );

  const { id } = params;

  useEffect(() => {
    setPageTitle("Seller Withdraw Request - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    getSingleWithDrawRequest(id)
      .then(res => {
        setSellerInfo(res.data);
        let is_review = res?.data?.review_status === 0 ? true : false;
        setIsReview(is_review);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    sellerWithdrawHistories(id, pageNo)
      .then(res => {
        setWithdrawHistories(res?.data?.data);
        setPageCount(res.data.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, [pageNo]);

  const columns = [
    {
      dataField: "withdrawID",
      text: "Withdraw ID",
      formatter: (cell, row) => (
        <div className={`font-semi-bold`}>{row.withdrawID}</div>
      ),
    },
    {
      dataField: "payment_method_name",
      text: "Payout Method",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => (
        <div
          className={`status w-max-content ${
            row.status.toLowerCase() === "completed"
              ? "success-status"
              : row.status.toLowerCase() === "pending"
              ? "warning-status"
              : "danger-status"
          }`}
        >
          {row.status}
        </div>
      ),
    },
    {
      dataField: "createdOn",
      text: "Date",
      formatter: (cell, row) => (
        <div>{moment(row.createdOn).format("DD-MM-YYYY")}</div>
      ),
    },
    {
      dataField: "amount",
      text: "Amount",
    },
  ];

  const data = withdrawHistories?.map(item => {
    return {
      ...item,
    };
  });

  const handleReview = () => {
    setReviewWithdraw(!reviewWithdraw);
    setReview("");
  };

  const handleReviewRequest = status => {
    let data = {
      comment: review,
      seller_id: sellerInfo?.seller?.id,
    };
    toast.dismiss();
    if (!review) {
      PrimaryToast({ type: "error", message: "review field is required" });
      return false;
    } else {
      setLoading(true);
      sellerWithdrawReview(data, id, status)
        .then(res => {
          PrimaryToast({ type: "success", message: res?.data?.message });
          setReviewWithdraw(!reviewWithdraw);
          setSellerInfo({
            ...sellerInfo,
            review_status: status,
          });
          setIsReview(false);
          setReview("");
        })
        .catch(err => {
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        })
        .finally(res => setLoading(false));
    }
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "review") {
      setReview(value);
    }
  };

  const handleApprove = () => {
    const data = {
      seller_withdraw_ids: [Number(id)],
    };
    setLoading(true);
    handleWithdrawApprove(data)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        setActionType("");
        setSellerInfo({
          ...sellerInfo,
          status: 1,
          approved_by: user?.name,
          updated_at: getCurrentTimeInEST(),
        });
        if (showTempBanApprovalModal) {
          handleBanSellerPayoutModal();
        }
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => setLoading(false));
  };

  const handleReject = () => {
    const data = {
      seller_withdraw_ids: [Number(id)],
    };
    setLoading(true);
    handleWithdrawReject(data, withdrawReason)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        setSellerInfo({
          ...sellerInfo,
          status: 2,
          approved_by: user?.name,
          updated_at: getCurrentTimeInEST(),
        });
        setWithdrawReason("");
        setIsWithdrawReject(false);
        setActionType("");
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => setLoading(false));
  };

  const handlePayoneerRequest = () => {
    const data = {
      seller_withdraw_ids: [Number(id)],
      transaction: transactionId,
    };
    setLoading(true);
    handleWithdrawApprove(data)
      .then(res => {
        setSellerInfo({
          ...sellerInfo,
          status: 1,
          approved_by: user?.name,
          updated_at: getCurrentTimeInEST(),
        });
        PrimaryToast({ type: "success", message: res?.data?.message });
        setIsPayoneer(false);
        setTransactionId("");
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      })
      .finally(res => setLoading(false));
  };

  const handleNetWorkReject = () => {
    const withdrawId = Number(id);
    setLoading(true);
    withdrawRejectByNetwork([withdrawId])
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        setSellerInfo({
          ...sellerInfo,
          status: 4,
          approved_by: user?.name,
          updated_at: getCurrentTimeInEST(),
        });
      })
      .catch(err => {
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      })
      .finally(res => setLoading(false));
  };

  const handleBanSellerPayoutModal = () => {
	setShowTempBanApprovalModal(pre => !pre);
	if (showTempBanApprovalModal) {
	  setIsApprovedBan(false);
	  setShowTempBanApprovalModal(false);
	  setPayoutType("");
	}
   };

  const handleBanSellerPayout = e => {
    if (payoutType == "payoneer") {
      setIsPayoneer(true);
      handleBanSellerPayoutModal();
    } else {
      setShowDeleteModal(true);
      setActionType("accept");
    }
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <TopHeading heading={"Withdraw Request"} />
      </div>
      {showDeleteModal && (
        <DeleteModal
          title={
            actionType == "reject" || actionType == "network_reject"
              ? "Are you sure to reject this withdraw?"
              : actionType == "accept"
              ? "Are you sure you want to approve this withdraw?"
              : ""
          }
          onConfirm={() => {
            setShowDeleteModal(!showDeleteModal);
            actionType == "reject"
              ? setIsWithdrawReject(!isWithdrawReject)
              : actionType == "accept"
              ? handleApprove()
              : actionType == "network_reject"
              ? handleNetWorkReject()
              : "";
          }}
          onCancel={() => {
            setShowDeleteModal(!showDeleteModal);
            setActionType("");
            setShowTempBanApprovalModal(false);
            setIsApprovedBan(false);
            setPayoutType("");
          }}
        />
      )}
      <div className="mt-4 d-flex align-items-start">
        <div className="w-75 me-3">
          <Card>
            <CardBody>
              <h4 className="font-semi-bold">Seller Payout Information</h4>
              <Row className="py-2 border-top border-bottom">
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">Amount:</div>
                    <div className="w-50">${sellerInfo?.amount}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">
                      Payout Method Type:
                    </div>
                    <div className="w-50">
                      {sellerInfo?.payment_type?.toUpperCase()}
                    </div>
                  </div>
                </Col>
              </Row>
              {sellerInfo?.payment_type === "usdt" && (
                <Row className="py-2 border-bottom">
                  <Col sm="6">
                    <div className="d-flex justify-content-between">
                      <div className="font-semi-bold w-50">USDT Network:</div>
                      <div className="w-50">
                        {sellerInfo?.payout_method?.usdt_network}
                      </div>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="d-flex justify-content-between">
                      <div className="font-semi-bold w-50">USDT Address:</div>
                      <div className="w-50">
                        {sellerInfo?.payout_method?.usdt_address}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              {sellerInfo?.payment_type === "usdc" &&
                sellerInfo?.payout_method?.usdc_address &&
                sellerInfo?.payout_method?.usdc_network && (
                  <Row className="py-2 border-bottom">
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-50">USDC Network:</div>
                        <div className="w-50">
                          {sellerInfo?.payout_method?.usdc_network}
                        </div>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-50">USDC Address:</div>
                        <div className="w-50">
                          {sellerInfo?.payout_method?.usdc_address}
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              {sellerInfo?.payment_type === "payoneer" && (
                <>
                  <Row className="py-2 border-bottom">
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-50">
                          Payoneer Name:
                        </div>
                        <div className="w-50">
                          {sellerInfo?.payout_method?.payoneer_name}
                        </div>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-50">
                          Payoneer Email:
                        </div>
                        <div className="w-50">
                          {sellerInfo?.payout_method?.payoneer_email}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {sellerInfo?.status !== 2 && (
                    <Row className="py-2 border-bottom">
                      <Col sm="6">
                        <div className="d-flex justify-content-between">
                          <div className="font-semi-bold w-50">
                            Payoneer Transaction ID:
                          </div>
                          <div className="w-50">
                            {sellerInfo?.payoneer_transaction_id}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <h4 className="font-semi-bold">Seller Information</h4>
              <Row className="py-2 border-top border-bottom">
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">Name:</div>
                    <div className="w-50">{sellerInfo?.seller?.name}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">Email:</div>
                    <div className="w-50">{sellerInfo?.seller?.email}</div>
                  </div>
                </Col>
              </Row>

              <Row className="py-2 border-bottom">
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">Store Name:</div>
                    <div className="w-50">
                      {sellerInfo?.seller?.business_name}
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">Seller Group:</div>
                    <div className="w-50">
                      {sellerInfo?.seller?.seller_group}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="py-2 border-bottom">
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">Ratings:</div>
                    {sellerInfo?.seller?.rating && (
                      <div className="w-50">
                        <span
                          className="p-1 text-light rounded"
                          style={{ background: "#34C38F" }}
                        >
                          {sellerInfo?.seller?.rating}
                        </span>{" "}
                        <span className="ps-2">out of 5</span>
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">Seller Status:</div>
                    <div className="w-50">
                      <div
                        className={`status  w-max-content ${
                          sellerInfo?.seller?.is_verify === 0 &&
                          sellerInfo?.seller?.is_active === 1
                            ? "warning-status"
                            : (sellerInfo?.seller?.is_verify === 0 ||
                                sellerInfo?.seller?.is_verify === 1) &&
                              sellerInfo?.seller?.is_active === 0
                            ? "danger-status"
                            : (sellerInfo?.seller?.is_verify === 0 ||
                                sellerInfo?.seller?.is_verify === 1) &&
                              sellerInfo?.seller?.is_active === 2
                            ? "danger-status"
                            : sellerInfo?.seller?.is_verify === 1 &&
                              sellerInfo?.seller?.is_active === 1
                            ? "success-status"
                            : null
                        }`}
                      >
                        {sellerInfo?.seller?.is_verify === 0 &&
                        sellerInfo?.seller?.is_active === 1
                          ? "Not Verified"
                          : (sellerInfo?.seller?.is_verify === 0 ||
                              sellerInfo?.seller?.is_verify === 1) &&
                            sellerInfo?.seller?.is_active === 0
                          ? "Temporary Ban"
                          : (sellerInfo?.seller?.is_verify === 0 ||
                              sellerInfo?.seller?.is_verify === 1) &&
                            sellerInfo?.seller?.is_active === 2
                          ? "Banned"
                          : sellerInfo?.seller?.is_verify === 1 &&
                            sellerInfo?.seller?.is_active === 1
                          ? "verified"
                          : null}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="py-2 border-bottom">
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w50">
                      Payout Review Status:
                    </div>
                    <div className="w-50">
                      <span
                        className={`w-max-content status  ${
                          sellerInfo?.review_status === 0
                            ? "warning-status"
                            : sellerInfo?.review_status === 1
                            ? "success-status"
                            : sellerInfo?.review_status === 2
                            ? "danger-status"
                            : null
                        }`}
                      >
                        {sellerInfo?.review_status === 0
                          ? "Pending"
                          : sellerInfo?.review_status === 1
                          ? "Approved"
                          : sellerInfo?.review_status === 2
                          ? "Rejected"
                          : null}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              {sellerInfo?.approved_by && (
                <Row className="py-2 border-bottom">
                  <Col sm="6">
                    <div className="d-flex justify-content-between">
                      <div className="font-semi-bold w-50">
                        Withdraw{" "}
                        {sellerInfo?.status === 1
                          ? "Approved By"
                          : sellerInfo?.status == 4
                          ? "Cancelled On"
                          : "Rejected By"}{" "}
                        :
                      </div>
                      <div className="w-50">{sellerInfo?.approved_by}</div>
                    </div>
                  </Col>
                  {sellerInfo?.usdt_transaction_id && (
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-50">
                          USDC Transaction Id{" "}
                        </div>
                        <div className="w-50">
                          {sellerInfo?.usdt_transaction_id}
                        </div>
                      </div>
                    </Col>
                  )}
                  {sellerInfo?.updated_at ? (
                    <Col className="" sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-50">
                          Withdraw{" "}
                          {sellerInfo?.status === 1
                            ? "Approved On"
                            : sellerInfo?.status == 4
                            ? "Cancelled On"
                            : "Rejected On"}{" "}
                          :
                        </div>
                        <div className="w-50">{sellerInfo?.updated_at}</div>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              )}
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <h4 className="mb-3 font-semi-bold">Seller Withdraw Histories</h4>
              <div>
                <PrimaryTable
                  columns={columns}
                  data={data}
                  bordered={false}
                  keyField="id"
                />
              </div>
              <div>
                {withdrawHistories?.length > 0 && (
                  <PrimaryPagination
                    setPageNo={setPageNo}
                    pageCount={pageCount}
                    pageNo={pageNo}
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="w-25">
          <div className="action-section">
            {sellerInfo?.status === 0 && (
              <>
                {isReview && (
                  <Button
                    size="lg"
                    block
                    color="primary"
                    className="mb-3"
                    disabled={loading}
                    onClick={handleReview}
                  >
                    Review
                  </Button>
                )}
                {!isReview && (
                  <Button
                    size="lg"
                    block
                    color="primary"
                    className="mb-3"
                    disabled={loading}
                    onClick={handleReview}
                  >
                    Re-Review
                  </Button>
                )}
                {hasWithdrawApprovePer &&
                  (sellerInfo?.payment_type === "payoneer" ? (
                    <Button
                      size="lg"
                      block
                      color="primary"
                      className={`mb-3 ${
                        sellerInfo?.is_payout_enabled == 0 && "d-none"
                      }`}
                      disabled={loading}
                      onClick={() => {
                        if (
                          (sellerInfo?.seller?.is_verify === 0 ||
                            sellerInfo?.seller?.is_verify === 1) &&
                          sellerInfo?.seller?.is_active === 0
                        ) {
                          handleBanSellerPayoutModal();
                        } else {
                          setIsPayoneer(!isPayoneer);
                        }
                      }}
                    >
                      Approve
                    </Button>
                  ) : (
                    <Button
                      size="lg"
                      block
                      color="primary"
                      disabled={loading}
                      className={`mb-3 ${
                        sellerInfo?.is_payout_enabled == 0 && "d-none"
                      }`}
                      onClick={() => {
                        if (
                          (sellerInfo?.seller?.is_verify === 0 ||
                            sellerInfo?.seller?.is_verify === 1) &&
                          sellerInfo?.seller?.is_active === 0
                        ) {
                          handleBanSellerPayoutModal();
                        } else {
                          setShowDeleteModal(true);
                          setActionType("accept");
                        }
                      }}
                    >
                      Approve
                    </Button>
                  ))}
                {hasWithdrawRejectedPer && (
                  <Button
                    size="lg"
                    color="primary"
                    block
                    className={`mb-3 ${
                      sellerInfo?.is_payout_enabled == 0 && "d-none"
                    }`}
                    disabled={loading}
                    onClick={() => {
                      setShowDeleteModal(!showDeleteModal);
                      setActionType("reject");
                    }}
                  >
                    Reject
                  </Button>
                )}
              </>
            )}
            {hasWithdrawCommentPer && (
              <Button
                size="lg"
                color="primary"
                block
                className=""
                onClick={() => {
                  setIsShowComment(!isShowComment);
                }}
                disabled={loading}
              >
                Comments
              </Button>
            )}
            {sellerInfo?.status === 1 && hasWithdrawApprovePer && (
              <Button
                color="primary"
                size="lg"
                block
                className="mt-4"
                onClick={() => {
                  setShowDeleteModal(true);
                  setActionType("network_reject");
                }}
                disabled={loading}
              >
                Reject by Network
              </Button>
            )}
          </div>
        </div>
      </div>
      {isWithdrawReject && (
        <WithdrawRejectPopup
          loading={loading}
          title={"Withdraw Reject"}
          handleChange={e => {
            setWithdrawReason(e.target.value);
          }}
          reason={withdrawReason}
          isOpen={isWithdrawReject}
          handleClose={() => {
            setWithdrawReason("");
            setIsWithdrawReject(!isWithdrawReject);
          }}
          handleRequest={handleReject}
        />
      )}
      {reviewWithdraw && (
        <ReviewWithdrawPopup
          loading={loading}
          title={"Seller Review"}
          handleChange={handleChange}
          handleRequest={handleReviewRequest}
          review={review}
          isOpen={reviewWithdraw}
          handleClose={handleReview}
          reviewStatus={sellerInfo?.review_status}
        />
      )}
      {isPayoneer && (
        <ReviewWithdrawPopup
          loading={loading}
          title={"Payoneer/USDT Information"}
          handleChange={e => setTransactionId(e.target.value)}
          handleRequest={handlePayoneerRequest}
          type="payoneer"
          review={transactionId}
          isOpen={isPayoneer}
          handleClose={() => {
            {
              setTransactionId();
              setIsPayoneer(!isPayoneer);
            }
          }}
        />
      )}
      {isShowComment && (
        <SellerCommentsPopup
          isOpen={isShowComment}
          handleClose={() => {
            setIsShowComment(!isShowComment);
          }}
          sellerId={sellerInfo?.seller?.id}
          withdraw_id={id}
          reviewStatus={sellerInfo?.review_status}
        />
      )}
      {showTempBanApprovalModal && (
        <BanSellerApprovedPopup
          isOpen={showTempBanApprovalModal}
          isApprovedBan={isApprovedBan}
          handleClick={handleBanSellerPayoutModal}
          setIsApprovedBan={setIsApprovedBan}
          handleBanSellerPayout={handleBanSellerPayout}
        />
      )}
    </div>
  );
}

export default SingleWithDrawRequest;
