import React, { useState } from "react";
import PrimaryPopup from "./PrimaryPopup";
import { disable2FA } from "services/2FAServices";
import PrimaryToast from "../Toast/ToastMessage";
import { Button } from "reactstrap";
import { userInfo } from "services/AuthServices";
import { loadUser } from "store/actions/auth";
import { useDispatch } from "react-redux";
import ProgressPopup from "./ProgressPopup";

const Disable2faPopup = ({isOpen, setIsDisabled2fa}) => {
  const [fields, setFields] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const fieldObj = { ...fields };
    fieldObj[name] = value;
    setFields(fieldObj);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    disable2FA(fields)
      .then(res => {
        PrimaryToast({
          type: "success",
          message: "2FA disable successfully",
        });
		userInfo()
        .then(res => {
          dispatch(loadUser(res.data))
		  setIsDisabled2fa(false);
		})
		.catch(err => console.log(err))
		.finally(res => setLoading(false));

      })
      .catch(err => {
        console.log(err);
		setLoading(false);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
  };

  return (
    <PrimaryPopup
      isOpen={isOpen}
      title={"Disable 2FA"}
      className="d-flex justify-content-center align-items-center h-100 w-100"
      handleClick={()=>{
        setIsDisabled2fa(false);
        setFields("");
      }}
    >
		{loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-3">
        <label htmlFor="inputEmail4">Current Password<span className="primary-text">*</span></label>
        <input
          type="password"
          className="form-control"
          id="current_password"
          placeholder="Enter Password"
          value={fields?.current_password}
          name="current_password"
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="inputEmail4">Authenticator Code<span className="primary-text">*</span></label>
        <input
          type="text"
          className="form-control"
          id="otpField"
          placeholder="Enter Code"
          value={fields?.otp}
          onChange={handleChange}
          name="otp"
        />
      </div>
      <div className="order-summary-action-section d-flex justify-content-end">
        <div className="d-flex align-items-center">
          <Button color="secondary" className="me-4" onClick={()=>{
                    setIsDisabled2fa(false);
                    setFields("");
          }}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </PrimaryPopup>
  );
};

export default Disable2faPopup;
