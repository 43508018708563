import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Container, Card, CardBody, Button } from "reactstrap";
import tickIcon from "../../assets/images/tick-icon.svg";
import HoldOrderPopup from "components/Common/Popups/HoldOrderPopup";
import {
  getSellerNotifications,
  createOrderSuspicious,
} from "services/NotificationService";
import PrimaryTable from "components/Common/PrimaryTable";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimarySearch from "components/Common/PrimarySearch";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import { setPageTitle } from "utils/pageTitle";
import { useNavigate } from "react-router-dom";
import DateRangePicker from "components/Common/DateRangePicker";
import moment from "moment";

const SellersNotifications = () => {
  const [isSuspicious, setSuspicious] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [fruadSellersData, setFruadSellersData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [type, setType] = useState("");
  const [searchVal, setSearch] = useState(null);
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const history = useNavigate();

  useEffect(() => {
    setPageTitle("Seller Notifications - Inspire Uplift");
  }, []);

  useEffect(() => {
    const pageParam = queryParams.get("page") || undefined;
    const searchValue = queryParams.get("search") || undefined;
    const typeParam = queryParams.get("type") || undefined;
    const startDateParam = queryParams?.get("start") || undefined;
    const endDateParam = queryParams?.get("end") || undefined;
    setPageNo(pageParam || 1);
    setSearch(searchValue || "");
    setType(typeParam);
    if (startDateParam) {
      setDates([
        {
          key: "selection",
          startDate: new Date(startDateParam),
          endDate: new Date(endDateParam),
        },
      ]);
    }
	let startDate = startDateParam  ? moment(startDateParam).startOf('day').format("YYYY-MM-DD HH:mm:ss") : undefined;
	let endDate = endDateParam  ? moment(endDateParam).endOf('day').format("YYYY-MM-DD HH:mm:ss") : undefined;
    getSellerFruadNotification(
      pageParam,
      searchValue,
      typeParam,
      startDate,
      endDate
    );
  }, [window?.location?.search]);

  const getSellerFruadNotification = (page, search, type, start, end) => {
    setLoading(true);
    getSellerNotifications(page, search, type, start, end)
      .then(res => {
        if (res?.data?.fraudSellers?.data?.length == 0 && pageNo > 1) {
          queryParams?.set("page", pageNo - 1);
          const newUrl = `${
            window.location.pathname
          }?${queryParams.toString()}`;
          history(newUrl);
        } else {
          setFruadSellersData(res?.data?.fraudSellers);
          setPageCount(res?.data?.fraudSellers?.last_page);
        }
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>
      ),
    },
    {
      dataField: "message",
      text: "Notification",
      style: {
        maxWidth: "500px",
        whiteSpace: "break-spaces",
        wordBreak: "break-word",
        overFlow: "hidden",
      },
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell, row) => <div>{row.created_at}</div>,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div>
            {row.is_suspicious === 1 ? (
              <div>Marked Suspicious by {row?.admin}</div>
            ) : (
              <Button
                color="primary"
                onClick={e => handleSuspicious(e, row.id)}
              >
                <img src={tickIcon} alt="tick icon" />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const handleSuspicious = (e, id) => {
    if (e?.target?.id === "close-popup" || e?.target?.id === "cancle-action") {
      setSelectedId();
    } else {
      setSelectedId(id);
    }
    setSuspicious(!isSuspicious);
  };

  const data = fruadSellersData?.data?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleCreateSuspicious = () => {
    setLoading(true);
    createOrderSuspicious(selectedId)
      .then(res => {
        if (res.status) {
          setSelectedId();
          setSuspicious(!isSuspicious);
          const startDate = queryParams?.get("start")
            ? moment(dates[0]?.startDate).format("YYYY-MM-DD")
            : undefined;
          const endDate = queryParams?.get("end")
            ? moment(dates[0]?.endDate).format("YYYY-MM-DD")
            : undefined;
          setLoading(true);

          getSellerNotifications(pageNo, searchVal, type, startDate, endDate)
            .then(res => {
              if (res?.data?.fraudSellers?.data?.length == 0 && pageNo > 1) {
                queryParams?.set("page", pageNo - 1);
                const newUrl = `${
                  window.location.pathname
                }?${queryParams.toString()}`;
                history(newUrl);
              } else {
                setFruadSellersData(res?.data?.fraudSellers);
                setPageCount(res?.data?.fraudSellers?.last_page);
              }
            })
            .catch(err => console.log(err))
            .finally(res => setLoading(false));
        }
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleSearch = e => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (searchVal) {
        queryParams.set("search", searchVal);
      }
      if (!searchVal && queryParams.get("search")) {
        queryParams.delete("search");
      }
      if (queryParams.get("page")) {
        queryParams.delete("page");
      }

      history(`?${queryParams.toString()}`);
    }
  };

  const handleFilter = () => {
    if (searchVal) {
      queryParams.set("search", searchVal);
    }
    if (!searchVal && queryParams.get("search")) {
      queryParams.delete("search");
    }
    if (type) {
      queryParams?.set("type", type);
    } else {
      queryParams?.delete("type");
    }
    if (queryParams.get("page")) {
      queryParams.delete("page");
    }
    queryParams?.set("start", moment(dates[0]?.startDate).format("YYYY-MM-DD"));
    queryParams?.set("end", moment(dates[0]?.endDate).format("YYYY-MM-DD"));
    history(`?${queryParams.toString()}`);
  };

  const handleReset = () => {
    setDates([
      {
        key: "selection",
        startDate: new Date(),
        endDate: new Date(),
      },
    ]);
    history(window.location.pathname);
  };
  const handleDatePicker = item => {
    setDates([item.selection]);
  };

  const handleSubmit = () => {
    setShowCalendar(false);
    if (queryParams?.get("page")) {
      queryParams.delete("page");
    }
    queryParams?.set("start", moment(dates[0]?.startDate).format("YYYY-MM-DD"));
    queryParams?.set("end", moment(dates[0]?.endDate).format("YYYY-MM-DD"));
    history(`?${queryParams?.toString()}`);
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <Container fluid>
        <TopHeading heading={"Seller Notifications"} />
        <div className="mt-4">
          <Card>
            <CardBody>
              <div className="d-flex align-items-center">
                <PrimarySearch
                  placeholder={"Search by Email , Store name"}
                  value={searchVal}
                  handleKeyPress={handleKeyPress}
                  handleChange={handleSearch}
                />
                <select
                  className="primary-select-option"
                  value={type || ""}
                  onChange={e => {
                    setType(e.target.value);
                  }}
                  onKeyPress={handleKeyPress}
                >
                  <option value={""}>Search by</option>
                  <option value={"Fraud Detected. IP"}>
                    Fraud Detected IP
                  </option>
                  <option value={"Suspicious Paypal Seller"}>
                    Suspicious Paypal Seller
                  </option>
                </select>
                <div className="position-relative ms-3">
                  <div
                    className="form-control cursor-pointer rounded-full"
                    style={{ color: "#74788D", width: "max-content" }}
                    onClick={() => setShowCalendar(true)}
                  >
                    {moment(dates[0].startDate).format("DD MMM YYYY")} -{" "}
                    {moment(dates[0].endDate).format("DD MMM YYYY")}
                  </div>

                  {showCalendar && (
                    <>
                      <div
                        className="position-fixed top-0 start-0 w-100 h-100"
                        style={{ zIndex: "10" }}
                        onClick={() => setShowCalendar(false)}
                      ></div>
                      <DateRangePicker
                        dates={dates}
                        handleDatePicker={handleDatePicker}
                        handleSubmit={handleSubmit}
                        maxDate={new Date()}
                      />
                    </>
                  )}
                </div>
                <Button
                  color="primary"
                  className={`btn-rounded  ms-3`}
                  onClick={handleFilter}
                >
                  Apply Filter
                </Button>
                {queryParams?.toString()?.length > 0 && (
                  <Button
                    color="secondary"
                    className={`btn-rounded  ms-3`}
                    onClick={handleReset}
                  >
                    Reset Filter
                  </Button>
                )}
              </div>

              <div className="mt-4">
                <PrimaryTable
                  columns={columns}
                  data={data}
                  bordered={false}
                  keyField="id"
                />
              </div>
              {fruadSellersData?.data?.length > 0 && pageCount > 1 && (
                <PrimaryPagination
                  pageCount={pageCount}
                  pageNo={pageNo}
                  setPageNo={setPageNo}
                />
              )}
            </CardBody>
          </Card>
        </div>
        {isSuspicious && (
          <HoldOrderPopup
            loading={loading}
            isOpen={isSuspicious}
            heading={"Notification"}
            isScrollable={false}
            handleClick={handleSuspicious}
            handleYes={handleCreateSuspicious}
            description={
              "Are you  sure you want to make this notification suspicious?"
            }
          />
        )}
      </Container>
    </div>
  );
};

export default SellersNotifications;
