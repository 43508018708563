import React from "react";
import { Button } from "reactstrap";
import PrimaryPopup from "./PrimaryPopup";

const SellerActionPopup = ({
  handlePopup,
  isRejectBan,
  handleChange,
  fields,
  handleSubmit,
  loading
}) => {
  return (
    <PrimaryPopup
      isOpen={isRejectBan}
      handleClick={handlePopup}
      title={"Reject & Temporary Ban Seller"}
      isScrollable={false}
      className="d-flex justify-content-center align-items-center h-100 w-100"
    >
      <div>
        <div className="mb-3">
          <label htmlFor="inputEmail4">Why are you banning this seller</label>
          <textarea
            rows={3}
            type="text"
            className="form-control"
            id="ban-reason"
            placeholder="Enter Reason of Ban"
            name="reason"
            value={fields?.reason}
            onChange={handleChange}
          />
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            name="ban_status"
            id="flexCheckDefault"
            checked={fields?.ban_status}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Ban Permanently
          </label>
        </div>
        <div className="d-flex  justify-content-end">
          <Button className="me-2"  onClick={handlePopup}>
            Cancel
          </Button>
          <Button color="primary" disabled={loading} onClick={handleSubmit}>
            Confirm
          </Button>
        </div>
      </div>
    </PrimaryPopup>
  );
};

export default SellerActionPopup;
