import React, { useState, useEffect } from "react";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import DeleteModal from "components/Common/Toast/DeleteModal";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import PrimarySearch from "components/Common/PrimarySearch";
import PrimaryTable from "components/Common/PrimaryTable";
import { useNavigate } from "react-router-dom";
import plusIcon from "assets/images/plus-icon.svg";
import deleteIcon from "assets/images/delete-icon-white.svg";
import editIcon from "assets/images/edit-icon-white.svg";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import {
  getAllPlacements,
  deletePlacement,
} from "services/MarketPlaceFeedService";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import useToggle from "hooks/useToggle";

function AdsPlacements() {
  const [loading, setLoading] = useState(false);
  const [placementData, setData] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [selectedId, setSelectedId] = useState("");
  const [searchVal, setSearch] = useState(null);
  const params = new URLSearchParams(window?.location?.search);
  const history = useNavigate();

  useEffect(() => {
    setPageTitle("Ads Placements - Inspire Uplift");
  }, []);

  useEffect(() => {
    const page = params?.get("page") || undefined;
    const search = params?.get("search") || undefined;
    setPageNo(page || 1);
    setSearch(search);
    getPlacements(page, search);
  }, [window?.location?.search]);

  const getPlacements = (page, search) => {
    setLoading(true);
    getAllPlacements(page, search)
      .then(res => {
        setData(res?.data?.data);
        setPageCount(res?.data?.last_page);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => {
        return <div>{(pageNo - 1) * 10 + row.index}</div>;
      },
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "cpc",
      text: "CPC",
    },

    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className="d-flex  align-items-center">
            <div
              className="action-btn edit-icon-bg me-1 cursor-pointer"
              onClick={() =>
                history(`/marketing/ads/placements/edit/${row.id}`)
              }
            >
              <img src={editIcon} className="" alt="edit icon" />
            </div>
            <div
              className="action-btn delete-btn-bg cursor-pointer"
              onClick={() => {
                setSelectedId(row.id);
                setShowDeleteModal(!showDeleteModal);
              }}
            >
              <img src={deleteIcon} className="" alt="delete icon" />
            </div>
          </div>
        );
      },
    },
  ];

  const data = placementData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleDelete = () => {
    const filterData = placementData?.filter(item => item.id !== selectedId);
    toast.dismiss();
    setLoading(true);
    deletePlacement(selectedId)
      .then(res => {
        if (filterData?.length === 0 && pageNo > 1) {
          params.set("page", pageNo - 1);
          let newUrl = `${window?.location?.pathname}?${params.toString()}`;
          history(newUrl, { replace: true });
        } else {
          setData(filterData);
        }
        setSelectedId("");
        PrimaryToast({
          type: "success",
          message: "Platform delete successfully",
        });
        setShowDeleteModal(false);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const handleSearch = e => {
    const val = e.target.value;
    setSearch(val);
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (params?.get("page")) {
        params?.delete("page");
      }
      if (searchVal) {
        params?.set("search", searchVal);
      } else {
        params?.delete("search");
      }
      history(`/marketing/ads/placements?${params?.toString()}`);
    }
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this Placement?"}
          onConfirm={handleDelete}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <TopHeading heading={"Placements"} />
        <Button
          className="rounded-full"
          color="primary"
          onClick={() => history("/marketing/ads/placements/add")}
        >
          <img alt="plus icon" src={plusIcon} className="me-2" />
          Create Placement
        </Button>
      </div>
      <Card>
        <CardBody>
          <div className="mb-3">
            <PrimarySearch
              handleKeyPress={handleKeyPress}
              placeholder={"Search by name"}
              value={searchVal}
              handleChange={handleSearch}
            />
          </div>
          <div>
            <PrimaryTable
              data={data}
              columns={columns}
              bordered={false}
              keyField="id"
            />
            {placementData?.length > 0 && pageCount > 1 && (
              <PrimaryPagination
                pageNo={pageNo}
                pageCount={pageCount}
                setPageNo={setPageNo}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default AdsPlacements;
