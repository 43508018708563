import React from "react";
import { Button } from "reactstrap";

import PrimaryPopup from "./PrimaryPopup";
import PrimaryRadio from "../PrimaryRadio";
import PrimaryInput from "../PrimaryInput";

function ProductGMCActionPopup({
  handleClick,
  isOpen,
  handleFileUpload,
  handleSave,
  loading,
  disabledEnabledBulk,
  handleChange
}) {
  return (
    <PrimaryPopup
      title={"Enable/Disable Products From G.M.C"}
      handleClick={handleClick}
      isScrollable={false}
      className={"d-flex justify-content-center align-items-center h-100 w-100"}
      isOpen={isOpen}
    >
      <form>
        <div>
          <p className="fw-bold mb-2">Enable or Disable</p>
          <div className="mb-2">
            <PrimaryRadio
              label={"Enable"}
              name={"is_enable"}
              value={1}
              id={"enable"}
              className={"mb-2"}
              checked={disabledEnabledBulk?.is_enable == 1}
              handleChange={handleChange}
            />
            <PrimaryRadio
              label={"Disable"}
              name={"is_enable"}
              value={0}
              id={"disable"}
              checked={disabledEnabledBulk?.is_enable == 0}
              handleChange={handleChange}
            />
          </div>
          <p className="fw-bold mb-2">By</p>
          <div className="mb-2">
            <PrimaryRadio
              label={"CSV File"}
              name={"action_by"}
              value={"csv_file"}
              id={"csv-file"}
              className={"mb-2"}
              checked={disabledEnabledBulk?.action_by == "csv_file"}
              handleChange={handleChange}
            />
            <PrimaryRadio
              label={"By Seller ID"}
              name={"action_by"}
              value={"seller_id"}
              id={"by-seller-id"}
              checked={disabledEnabledBulk?.action_by == "seller_id"}
              handleChange={handleChange}
            />
          </div>
          <div className="mb-4">
            {disabledEnabledBulk?.action_by == "csv_file" ? (
              <input
                className="form-control"
                accept="text/csv"
                type="file"
                id="formFile"
                onChange={handleFileUpload}
              />
            ) : disabledEnabledBulk?.action_by == "seller_id" ? (
              <PrimaryInput placeholder={"Enter Seller ID"} name={"seller_id"} handleChange={handleChange} />
            ) : null}
          </div>
        </div>
        <div className="order-summary-action-section d-flex justify-content-end">
          <div className="d-flex align-items-center">
            <Button
              className="me-4"
              color="primary"
              type="button"
              disabled={loading}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button onClick={handleClick} type="button">
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </PrimaryPopup>
  );
}

export default ProductGMCActionPopup;
