import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import PrimaryInput from "components/Common/PrimaryInput";
import SecondaryInput from "components/Common/SecondaryInput";
import PrimaryRadio from "components/Common/PrimaryRadio";

import ProgressPopup from "components/Common/Popups/ProgressPopup";
import SecondarySelect from "components/Common/SecondarySelect";
import { hashGenerater } from "utils/string";
import { createCoupon } from "services/CouponServices";
import CalendarInput from "components/Common/CalenderInput";
import moment from "moment";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function CreateCoupon() {
  const [fields, setFields] = useState({
    code: hashGenerater(),
    type: 1,
    plateform: "0",
    min_amount: "0",
  });
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showStartDateCalender, setShowStartDateCalender] = useState(false);
  const [showEndDateCalendar, setShowEndDateCalender] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(()=>{
		setPageTitle("Create Coupon - Inspire Uplift")
	},[]);

  const history = useNavigate();

  const handleChange = e => {
    const name = e.target.name;
    const value =
      e?.target?.type === "checkbox" ? e.target.checked : e.target.value;
    const fieldsObj = { ...fields };
    fieldsObj[name] = value;
    setFields(fieldsObj);
  };

  const handleSelectChange = selected => {
    setSelectedOptions(selected);
  };

  const handleDateSelect = (date, name) => {
    if (name === "start_date") {
      setStartDate(date);
      setShowStartDateCalender(false);
    }
    if (name === "end_date") {
      setEndDate(date);
      setShowEndDateCalender(false);
    }
  };

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 10);

  const handleValidation = () => {
    if (!fields?.unlimited_expiry && !endDate) {
      PrimaryToast({ type: "error", message: "End Date field is required!" });
      return false;
    }
    return true;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const selectedIds = selectedOptions?.map(item => item.value);
    const data = {
      code: fields?.code || undefined,
      seller_group_ids: selectedIds?.length > 0 ? selectedIds : undefined,
      type: fields?.type || undefined,
      value: fields?.value || undefined,
      platform: fields?.plateform || undefined,
      min_amount: fields?.min_amount || undefined,
      
      amount: fields?.min_amount ? fields?.amount : undefined,
      limit: fields?.limit || 0,
      start_date:
        (startDate && moment(startDate).format("YYYY-MM-DD")) || undefined,
      end_date: !fields?.unlimited_expiry
        ? endDate && moment(endDate).format("YYYY-MM-DD")
        : null,
      unlimited_expiry: fields?.unlimited_expiry || false,
    };
    if (handleValidation()) {
      toast.dismiss();
      setLoading(true);
      createCoupon(data)
        .then(res => {
          PrimaryToast({ type: "success", message: res.data.message });
          history(-1);
        })
        .catch(err => {
          console.log(err); //error
          PrimaryToast({
            type: "error",
            message: Array.isArray(err.response.data.message)
              ? err.response.data.message[0]
              : err.response.data.message,
          });
          
        })
        .finally(res => setLoading(false));
    }
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <TopHeading heading={"Create Coupon"} />
          <div className="d-flex justify-content-between align-items-center">
            <Button
              onClick={() => {
                history(-1);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" className="ms-4" color="primary">
              Create
            </Button>
          </div>
        </div>
        <Card>
          <CardBody className="w-50">
            <h4 className="font-sem-bold">Coupon Information</h4>
            <div className="mb-3">
              <PrimaryInput
                label={"Discount Code"}
                name="code"
                value={fields?.code || ""}
                placeholder="Enter  Discount Code"
                type={"text"}
                isRequired
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <SecondarySelect
                isRequired={true}
                label={"Select Group ID"}
                isMulti={true}
                value={selectedOptions}
                name="seller_groups_ids"
                placeholder={"Enter  Select Group ID"}
                handleSelect={handleSelectChange}
              />
            </div>
            <div className="mb-3">
              <label>
                Type<span className="primary-text">*</span>
              </label>
              <div className="mb-2">
                <PrimaryRadio
                  label={"Percentage"}
                  id="percentage"
                  name={"type"}
                  value={1}
                  checked={fields?.type == 1}
                  handleChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <PrimaryRadio
                  label={"Fixed Amount"}
                  id="fixed_amount"
                  name={"type"}
                  checked={fields?.type == 2}
                  value={2}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-3">
              <SecondaryInput
                name="value"
                placeholder={"Enter Coupon Value"}
                text={fields?.type === 1 ? "%" : fields?.type == 2 ? "$" : "%"}
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label>
                Platform<span className="primary-text">*</span>
              </label>
              <div className="mb-2">
                <PrimaryRadio
                  label={"Both"}
                  id="both"
                  name={"plateform"}
                  value={0}
                  checked={fields?.plateform == 0}
                  handleChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <PrimaryRadio
                  label={"Desktop"}
                  id="desktop"
                  name={"plateform"}
                  value="1"
                  checked={fields?.plateform == 1}
                  handleChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <PrimaryRadio
                  label={"Mobile"}
                  id="mobile"
                  name={"plateform"}
                  value="2"
                  checked={fields?.plateform == 2}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="inputEmail4">
                Minimum Requirements<span className="primary-text">*</span>
              </label>
              <div className="mb-2">
                <PrimaryRadio
                  label={"None"}
                  id="none"
                  name={"min_amount"}
                  value={0}
                  checked={fields?.min_amount == 0}
                  handleChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <PrimaryRadio
                  label={"Minimum Purchase Amount"}
                  id="minimum_purchase"
                  name={"min_amount"}
                  value={1}
                  checked={fields?.min_amount == 1}
                  handleChange={handleChange}
                />
              </div>
              {fields?.min_amount == 1 && (
                <SecondaryInput
                  placeholder={"Enter Minimum Purchase In Amount"}
                  text="$"
                  name={"amount"}
                  handleChange={handleChange}
                />
              )}
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value={fields?.limit || ""}
                id="limitCheck"
                name="limit"
                checked={fields?.limit}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="limitCheck">
                Limit to one use per customer
              </label>
            </div>

            <div className="mb-3">
              <CalendarInput
                label="Start Date"
                handleShowCalender={() =>
                  setShowStartDateCalender(!showStartDateCalender)
                }
                showCalender={showStartDateCalender}
                handleChange={newDate =>
                  handleDateSelect(newDate, "start_date")
                }
                minDate={moment().toDate()}
                maxDate={maxDate}
                date={startDate ? new Date(startDate) : new Date()}
                value={startDate && moment(startDate).format("MM-DD-YYYY")}
                placeholder={"MM-DD-YYYY"}
              />
            </div>
            {!fields?.unlimited_expiry && (
              <div className="mb-3">
                <CalendarInput
                  label="End Date"
                  handleShowCalender={() =>
                    setShowEndDateCalender(!showEndDateCalendar)
                  }
                  showCalender={showEndDateCalendar}
                  handleChange={newDate =>
                    handleDateSelect(newDate, "end_date")
                  }
                  minDate={moment().toDate()}
                  maxDate={maxDate}
                  date={endDate ? new Date(endDate) : new Date()}
                  value={endDate && moment(endDate).format("MM-DD-YYYY")}
                  placeholder={"MM-DD-YYYY"}
                />
              </div>
            )}

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckChecked"
                name="unlimited_expiry"
                onChange={handleChange}
                value={fields?.unlimited_expiry || ""}
                checked={fields?.unlimited_expiry}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Unlimited Expiry for this Coupon
              </label>
            </div>
          </CardBody>
        </Card>
      </form>
    </div>
  );
}

export default CreateCoupon;
