import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleCenter } from "services/MarketPlaceFeedService";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { updateCenter } from "services/MarketPlaceFeedService";
import { toast } from "react-toastify";
import SwitchButton from "components/Common/SwitchButton";
import SecondarySelect from "components/Common/SecondarySelect";
import { setPageTitle } from "utils/pageTitle";

function EditCenter() {
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sellerId, setSellerId] = useState();
  const history = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    setPageTitle("Edit Ad Center - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    getSingleCenter(id)
      .then(res => {
        let customTypes = res.data.allowed_platforms.map(item => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setSelectedOptions(customTypes);
        setSellerId(res?.data?.seller_id);
        setStatus(res?.data?.status === 1 ? true : false);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, []);

  const handleSelect = selected => {
    setSelectedOptions(selected);
  };

  const handleSubmit = e => {
    e.preventDefault();
    toast.dismiss();
    let data = {
      allowed_platforms: selectedOptions?.map(item => item.value) || [],
      seller_id: sellerId,
      status: status === true ? 1 : 0,
    };
    setLoading(true);
    updateCenter(data, id)
      .then(res => {
        PrimaryToast({
          type: "success",
          message: "Platform update successfully",
        });
        history(-1);
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      })
      .finally(res => {
        setLoading(false);
      });
  };
  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <TopHeading heading={"Edit Center"} />
          <div className="d-flex">
            <Button className="me-2" onClick={() => history(-1)}>
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Save
            </Button>
          </div>
        </div>
        <Card>
          <CardBody className="w-50">
            <SecondarySelect
              path={"ads-center"}
              label={"Allowed Platforms"}
              name="allowed_platforms"
              isMulti={true}
              placeholder={"Select Platforms"}
              isRequired={true}
              handleSelect={handleSelect}
              value={selectedOptions}
            />
            <div className="mt-3">
              <label htmlFor="inputEmail4">Status</label>
              <SwitchButton
                checked={status === true ? true : false}
                label={status === true ? "Active" : "Inactive"}
                name="is_active"
                value={status}
                handleChange={e => {
                  setStatus(e.target.checked);
                }}
              />
            </div>
          </CardBody>
        </Card>
      </form>
    </div>
  );
}

export default EditCenter;
