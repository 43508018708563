import React, { useState, useEffect } from "react";
import SwitchButton from "components/Common/SwitchButton";
import { CardBody, Card, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import TopHeading from "components/Common/TopHeading";
import PrimaryInput from "components/Common/PrimaryInput";
import {
  getSingleCommand,
  updateCommand,
} from "services/ScheduleCommandsService";
import PrograssPopup from "components/Common/Popups/ProgressPopup";
import { useParams } from "react-router-dom";
import PrimaryTimePicker from "components/Common/TimePicker";
import moment from "moment";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function EditCommand() {
  const [fields, setFields] = useState({
    schedule: "",
  });
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setPageTitle("Edit Schedule Command - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    getSingleCommand(id)
      .then(res => {
        const customTime = res.data?.processing_time?.toString();
        const customTimeMoment = moment(customTime, "HH:mm:ss");
        setFields({
          ...res.data,
          is_active: res?.data?.is_active === 1 ? true : false,
          processing_time: customTimeMoment,
        });
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, []);

  const handleChange = e => {
    const name = e.target.name;
    const value =
      e.target?.type === "checkbox" ? e.target.checked : e.target.value;
    const fieldsObj = { ...fields };
    fieldsObj[name] = value;
    setFields(fieldsObj);
  };

  const handleTimeChange = val => {
    setFields({
      ...fields,
      processing_time: val,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const data = {
      ...fields,
      processing_time: fields?.processing_time
        ? moment(fields?.processing_time).format("HH:mm")
        : undefined,
    };

    toast.dismiss();
    setLoading(true);
    updateCommand(data, id)
      .then(res => {
        history(-1);
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: err?.response?.data?.message[0],
        });
      })
      .finally(res => {
        setLoading(false);
      });
  };

  return (
    <div className="page-content">
      {loading && <PrograssPopup />}
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <TopHeading heading={"Edit Schedule Command"} />
          <div>
            <Button onClick={() => history(-1)}>Cancel</Button>
            <Button className="ms-3" color="primary">
              Save
            </Button>
          </div>
        </div>
        <Card>
          <CardBody className="w-50">
            <h4 className="font-semi-bold">Schedule Command Information</h4>
            <div className="mb-3">
              <PrimaryInput
                label={"Name"}
                isRequired
                name={"name"}
                placeholder="Enter Name"
                value={fields?.name || ""}
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <PrimaryInput
                label={"Command"}
                isRequired
                name={"command"}
                placeholder="Enter Comand"
                value={fields?.command}
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputEmail4">
                Processing Time<span className="primary-text">*</span>
              </label>
              <PrimaryTimePicker
                value={fields?.processing_time}
                handleTimeChange={handleTimeChange}
                loading={loading}
              />
            </div>
            <div className="mb-3">
              <PrimaryInput
                label={"Schedule"}
                isRequired
                name={"schedule"}
                value={fields?.schedule}
                placeholder="Enter Schedule"
                handleChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="inputEmail4">Status</label>
              <SwitchButton
                name="is_active"
                value={fields?.is_active | ""}
                handleChange={handleChange}
                checked={fields.is_active === true ? true : false}
                label={fields.is_active === true ? "Active" : "Inactive"}
              />
            </div>
          </CardBody>
        </Card>
      </form>
    </div>
  );
}

export default EditCommand;
