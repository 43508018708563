import React, { useState, useEffect } from 'react';
import TopHeading from 'components/Common/TopHeading';
import { CardBody, Card, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import SwitchButton from 'components/Common/SwitchButton';
import PrimaryInput from 'components/Common/PrimaryInput';
import { getSingleCustomer, updateCustomer, subscriptionCancle } from "services/CustomerServices";
import { useParams } from "react-router-dom";
import ProgressPopup from 'components/Common/Popups/ProgressPopup';
import TagsInputFields from 'components/Common/TagsInput';
import CalendarInput from 'components/Common/CalenderInput';
import moment from 'moment';
import PrimaryToast from 'components/Common/Toast/ToastMessage';
import PrimarySelect from 'components/Common/PrimarySelect';
import { toast } from 'react-toastify';
import { setPageTitle } from 'utils/pageTitle';


function EditCustomer() {
    const history = useNavigate();
    const  { id } = useParams();
    const [tags, setTags] = useState([]);
    const [fields, setFields] = useState({
        is_active: true,
    });
    const [loading, setLoading] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [dob, setdob] = useState("");
    const params = new URLSearchParams(window?.location.search);

    useEffect(()=>{
		setPageTitle("Edit Customer - Inspire Uplift")
	},[]);

    useEffect(() => {

        setLoading(true);
        getSingleCustomer(id)
            .then(res => {
                setFields({
                    ...res.data,
                    is_active: res.data?.is_active === 1 ? true : false,
                });
                setdob(res.data?.dob || "");
                const tagsData = JSON.parse(res?.data?.tags);
                setTags(tagsData || []);

            })
            .catch(err => console.log(err))
            .finally(res => setLoading(false));

    }, [])

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        const fieldsObj = { ...fields };
        fieldsObj[name] = value;
        setFields(fieldsObj);
    }

    const handleDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag) => {
        setTags([...tags, tag]);
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
    };

    const handleSubmit = e => {
        e.preventDefault();
        
        const data = {
            ...fields,
            tags: tags,
            dob: dob && moment(dob).format("YYYY-MM-DD") || undefined,
        };
        toast.dismiss();
        setLoading(true);
        updateCustomer(data, id)
            .then(res => {
                if(params?.get('vipCustomer') && !fields?.is_active){
                    subscriptionCancle(id)
                        .then(res => {
                            console.log(res)
                        })
                        .catch(err => {
                            console.log(err);
                        })
                }
                if (res.status === 200) {
                    history(-1)
                }
                PrimaryToast({ type: 'success', message: res?.data?.message });
            })
            .catch(err => {
                console.log(err)
                PrimaryToast({ type: 'error', message: err?.response?.data?.message[0] });
            })
            .finally(res => setLoading(false));
    }

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 10);
    return (
        <div className='page-content'>
            {
                loading && <ProgressPopup label={'Loading...'} />
            }
            <form onSubmit={handleSubmit}>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <TopHeading heading={'Edit customer'} />
                    <div className="d-flex">
                        <Button className="me-2" onClick={() => history(-1)}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit">
                            Save
                        </Button>
                    </div>
                </div>
                <Card>
                    <CardBody>
                        <h4 className='font-semi-bold mb-3'>Customer Information</h4>
                        <div className="mb-3">
                            <PrimaryInput
                                label={'First Name'}
                                name={'first_name'}
                                isRequired
                                type={'text'}
                                placeholder="Enter  First Name"
                                value={fields?.first_name || ''}
                                handleChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
                            <PrimaryInput
                                label={'Last Name'}
                                name={'last_name'}
                                isRequired
                                type={'text'}
                                placeholder="Enter  Last Name"
                                value={fields?.last_name || ''}
                                handleChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
                            <PrimaryInput
                                label={'Email'}
                                name={'email'}
                                isRequired
                                type={'email'}
                                placeholder="Enter  Customer Email"
                                value={fields?.email || ""}
                                handleChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
							<PrimarySelect
								name={"gender"}
								label={"Gender"}
							
								value={fields?.gender || ""}
								handleChange={handleChange}
								options={[
									{
										name: 'Select Gender',
										value: ''
									},
									{
										name: 'Male',
										value: 'male'
									},
									{
										name: 'Female',
										value: 'female'

									}
								]}
							/>
						</div>
            
                        <div className="mb-3">
                            <CalendarInput
                                label="Date of Birth"
                                handleShowCalender={() => setShowCalendar(!showCalendar)}
                                showCalender={showCalendar}
                                handleChange={(newDate) => {
                                    setdob(newDate);
                                    setShowCalendar(!showCalendar);
                                }

                                }
                                minDate={new Date("1920-01-01")}
                                maxDate={maxDate}
                                date={
                                    dob
                                        ? new Date(dob)
                                        : new Date()
                                }
                                value={dob && moment(dob).format("MM-DD-YYYY")}
                                placeholder={"MM-DD-YYYY"}
                            />
                        </div>
                        <div className="mb-3">
                            <PrimaryInput
                                label={'Note'}
                                name={'note'}
                                type={'text'}
                                placeholder="Enter  Customer Note"
                                value={fields?.note || ""}
                                handleChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
                            <PrimaryInput
                                label={'Phone Number'}
                                name={'phone'}
                                type={'text'}
                                placeholder="Enter  Customer Phone Number"
                                value={fields?.phone || ''}
                                handleChange={handleChange}
                            />
                        </div>
                        <div className="mb-3">
                            <TagsInputFields
                                label={"Tags"}
                                handleAddition={handleAddition}
                                handleDelete={handleDelete}
                                tags={tags}
                                placeholder="Enter Tags"
                                handleDrag={handleDrag}
                            />
                        </div>
                        <div className="mb-2">
                            <label htmlFor="inputEmail4">Status</label>
                            <SwitchButton checked={fields.is_active === true ? true : false} label={fields.is_active === true ? "Active" : "Inactive"} name="is_active" value={fields?.is_active} handleChange={handleChange} />
                        </div>

                    </CardBody>
                </Card>

            </form>

        </div>
    )
}

export default EditCustomer