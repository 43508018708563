import React from "react";
import { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import { useLocation } from "react-router-dom";
import PrimaryInput from "components/Common/PrimaryInput";
import PrimarySelect from "components/Common/PrimarySelect";
import {
  getSingleStakeHolder,
  updateStakeHolder,
} from "services/SellerPayoutMethodService";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { useNavigate } from "react-router-dom";
import { setPageTitle } from "utils/pageTitle";
import { useSelector } from "react-redux";
import GlobalService from "services/GlobalService";
import CalendarInput from "components/Common/CalenderInput";
import moment from "moment";

function EditStakeHolders() {
  const [fields, setFields] = useState({});
  const [errors, setErrror] = useState({});
  const [updatedFields, setUpdatedFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [statesData, setStates] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [showCalender, setShowCalender] = useState(false);
  const [isProvinceText, setIsProvinceText] = useState(false);
  const [initalCountryData, setInitialCountryData] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sellerId = searchParams.get("sellerId");
  const stkToken = searchParams.get("t");
  const userToken = searchParams.get("u-t");
  const navigate = useNavigate();
  const globalService = GlobalService();

  const permissions = useSelector(state => state?.auth?.user?.permissions);
  const hasStakeEditPer = permissions?.some(p => p.name === "stakeholder-edit");
  useEffect(() => {
    setPageTitle("Edit Seller Stakeholder - Inspire Uplift");
    getStates();
    getCountries();
  }, []);

  const getStates = () => {
    globalService
      .getStates()
      .then(response => {
        setStates([
          { name: "Please select one...", value: "" },
          ...response?.data?.data,
        ]);
      })
      .catch(error => console.log(error.message));
  };

  const getCountries = () => {
    globalService
      .getCountries()
      .then(response => {
        setCountriesData([
          { name: "Please select one...", value: "" },
          ...response?.data?.data,
        ]);
      })
      .catch(error => console.log(error.message));
  };

  useEffect(() => {
    setLoading(true);
    getSingleStakeHolder(stkToken, userToken)
      .then(res => {
        setFields(res?.data);
        if (res?.data?.country !== "US") {
          setIsProvinceText(true);
        } else {
          setIsProvinceText(false);
        };
		setInitialCountryData(res?.data?.country);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, []);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const updatedFieldsObj = { ...updatedFields };
    setErrror({});

    if (name === "firstName" || name === "lastName") {
      if (value.length > 0 && !value.match(/^[a-zA-Z\s-.,'/]+$/)) {
        const errorsFields = { ...errors };
        errorsFields[name] =
          "Only alphabets and some special characters are allowed.";
        setErrror(errorsFields);
        return false;
      } else {
        updatedFieldsObj[name] = value;
      }
    } else if (name === "phoneNumber") {
      if (!value.match(/^[0-9+()\- ]*$/)) {
        const errorsFields = { ...errors };
        errorsFields[name] =
          "Only numbers and certain special characters are allowed.";
        setErrror(errorsFields);
        return false;
      } else {
        updatedFieldsObj[name] = value;
      }
    } else {
      updatedFieldsObj[name] = value;
    }

    if (name === "country") {
      if (value !== "US") {
        setIsProvinceText(true);
        updatedFieldsObj["stateProvince"] = "";
      } else {
        setIsProvinceText(false);
      }
    }
    setUpdatedFields(updatedFieldsObj);
    if (fields[name] !== value) {
      const fieldsObj = { ...fields };
      fieldsObj[name] = value;
      if (name === "country") {
        if (value !== "US") {
          setIsProvinceText(true);
          fieldsObj["stateProvince"] = "";
        } else {
          setIsProvinceText(false);
        }
      }
      setFields(fieldsObj);
    }
  };

  const handleValidation = () => {
    const fieldsObj = { ...fields };


	if(((fieldsObj.country !== initalCountryData) && !fields?.stateProvince)){
      PrimaryToast({
        type: "error",
		message: "State field cannot be empty",
      });
      return false;
	}

	if (
		fields["dateOfBirth"] &&
		new Date(fields["dateOfBirth"]).getFullYear() >
		  new Date().getFullYear() - 18
	  ) {
		PrimaryToast({
		  type: "error",
		  message: "Date of birth must be a date before 18 years ago.",
		});
		return false;
	  }

	  
    if (
      (fieldsObj?.isDirector == "false" || fieldsObj?.isDirector == false) &&
      (fieldsObj?.isUltimateBeneficialOwner == "false" ||
        fieldsObj?.isUltimateBeneficialOwner == false)
    ) {
      PrimaryToast({
        type: "error",
        message: "Director or beneficially one of them should be yes",
      });
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    const data = {
      seller_id: sellerId || undefined,
      token: stkToken || undefined,
      userToken: userToken || undefined,
      first_name: updatedFields?.firstName || undefined,
      last_name: updatedFields?.lastName || undefined,
      email: updatedFields?.email || fields?.email || undefined,
      address: updatedFields?.addressLine1 || undefined,
      province_code: updatedFields?.stateProvince || undefined,
      city: updatedFields?.city || undefined,
      country_of_birth: updatedFields?.countryOfBirth || undefined,
      country_code: updatedFields?.country || undefined,
      zip_code: updatedFields?.postalCode || undefined,
      dob: updatedFields?.dateOfBirth || undefined,
      country_of_nationality: updatedFields?.countryOfNationality || undefined,
      phone_number: updatedFields?.phoneNumber || undefined,
      is_business_contact: updatedFields?.isBusinessContact
        ? updatedFields?.isBusinessContact === true ||
          updatedFields?.isBusinessContact === "true"
          ? true
          : false
        : undefined,
      is_director: updatedFields?.isDirector
        ? updatedFields?.isDirector == "true" ||
          updatedFields?.isDirector == true
          ? true
          : false
        : undefined,
      is_ultimate_beneficial_owner: updatedFields?.isUltimateBeneficialOwner
        ? updatedFields?.isUltimateBeneficialOwner == "true" ||
          updatedFields?.isUltimateBeneficialOwner == true
          ? true
          : false
        : undefined,
    };
    if (handleValidation()) {
      setLoading(true);
      updateStakeHolder(data)
        .then(res => {
          PrimaryToast({
            type: "success",
            message: "Stakeholder update successfully",
          });
        })
        .catch(err => {
          PrimaryToast({
            type: "error",
            message: Array.isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message,
          });
        })
        .finally(res => {
          setLoading(false);
        });
    }
  };

  

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <TopHeading heading={"Payout Method Stake Holders"} />
            <div
              className={`ms-2 ${
                fields?.status === "DE_ACTIVATED"
                  ? "danger-status"
                  : "success-status"
              } status w-max-content`}
            >
              {fields?.status}
            </div>
          </div>

          <div className="flex align-items-center">
            <Button onClick={() => navigate(`/seller/stores/edit/${sellerId}`)} className="me-2">
              Go Back
            </Button>
            {hasStakeEditPer ? (
              <Button color="primary" type="submit">
                Save
              </Button>
            ) : null}
          </div>
        </div>
        <Card>
          <CardBody className="w-50">
            <h4 className="font-semi-bold mb-3">Personal Information</h4>
            <div className="mb-3">
              <PrimaryInput
                label={"First Name"}
                isRequired
                name="firstName"
                placeholder="Enter First Name"
                value={fields?.firstName || ""}
                handleChange={handleChange}
                maxLength={50}
              />
              {errors["firstName"] && (
                <div className="text-11 text-danger">{errors["firstName"]}</div>
              )}
            </div>
            <div className="mb-3">
              <PrimaryInput
                label={"Last Name"}
                isRequired
                name="lastName"
                placeholder="Enter Last Name"
                value={fields?.lastName || ""}
                handleChange={handleChange}
                maxLength={50}
              />
              {errors["lastName"] && (
                <div className="text-11 text-danger">{errors["lastName"]}</div>
              )}
            </div>
            <div className="mb-3">
              <CalendarInput
			  	isRequired={true}
                label="Date of Birth"
                handleShowCalender={() => setShowCalender(!showCalender)}
                showCalender={showCalender}
                handleChange={newDate => {
                  setFields({
                    ...fields,
                    dateOfBirth: moment(newDate).format("YYYY-MM-DD"),
                  });
				  setUpdatedFields({
                    ...updatedFields,
                    dateOfBirth: moment(newDate).format("YYYY-MM-DD"),
                  });
                  setShowCalender(!showCalender);
                }}
                date={
                  fields?.dateOfBirth
                    ? new Date(fields?.dateOfBirth)
                    : new Date()
                }
                value={
                  fields?.dateOfBirth &&
                  moment(fields?.dateOfBirth).format("YYYY-MM-DD")
                }
                placeholder={"YYYY-MM-DD"}
              />
            </div>
            <div className="mb-3">
              <PrimarySelect
                label={"Country of Birth"}
                name="countryOfBirth"
                isRequired
                handleChange={handleChange}
                value={fields?.countryOfBirth || ""}
                options={countriesData || []}
                path="country"
              />
            </div>
            <div className="mb-3">
              <PrimarySelect
                label={"Country of Nationality"}
                name="countryOfNationality"
                isRequired
                handleChange={handleChange}
                value={fields?.countryOfNationality || ""}
                options={countriesData || []}
                path="country"
              />
            </div>
            <div className="mb-3">
              <PrimarySelect
                label={"Director"}
                name="isDirector"
                isRequired
                handleChange={handleChange}
                value={fields?.isDirector}
                options={[
                  {
                    name: "Yes",
                    value: true,
                  },
                  {
                    name: "No",
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="mb-3">
              <PrimarySelect
                label={"Ultimate Beneficial Owner"}
                name="isUltimateBeneficialOwner"
                isRequired
                handleChange={handleChange}
                value={fields?.isUltimateBeneficialOwner}
                options={[
                  {
                    name: "Yes",
                    value: true,
                  },
                  {
                    name: "No",
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="mb-3">
              <PrimarySelect
                label={"Business Contact"}
                name="isBusinessContact"
                isRequired
                handleChange={handleChange}
                value={fields?.isBusinessContact}
                options={[
                  {
                    name: "Yes",
                    value: true,
                  },
                  {
                    name: "No",
                    value: false,
                  },
                ]}
              />
            </div>
            <h4 className="font-semi-bold mb-3">Contact Information</h4>
            <div className="mb-3">
              <PrimaryInput
                label={"Email"}
                isRequired
                name="email"
                placeholder="Enter email"
                value={fields?.email || ""}
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <PrimaryInput
                label={"Phone Number"}
                isRequired
                name="phoneNumber"
                placeholder="Enter phone number"
                value={fields?.phoneNumber || ""}
                handleChange={handleChange}
                maxLength={15}
              />
              {errors["phoneNumber"] && (
                <div className="text-11 text-danger">
                  {errors["phoneNumber"]}
                </div>
              )}
            </div>
            <h4 className="font-semi-bold mb-3">Home Address</h4>
            <div className="mb-3">
              <PrimaryInput
                name={"addressLine1"}
                label={"Address"}
                placeholder="Enter Address"
                type={"text"}
                isRequired
                value={fields?.addressLine1 || ""}
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <PrimaryInput
                name={"city"}
                label={"City"}
                placeholder="Enter City"
                type={"text"}
                isRequired
                value={fields?.city || ""}
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <PrimarySelect
                label={"Country"}
                name="country"
                isRequired
                handleChange={handleChange}
                value={fields?.country || ""}
                options={countriesData || []}
                path="country"
              />
            </div>
            {!isProvinceText ? (
              <div className="mb-3">
                <PrimarySelect
                  label={"State / Province"}
                  name="stateProvince"
                  isRequired
                  handleChange={handleChange}
                  value={fields?.stateProvince || ""}
                  options={statesData || []}
                  path="state"
                />
              </div>
            ) : (
              <div className="mb-3">
                <PrimaryInput
                  name={"stateProvince"}
                  label={"State / Province"}
                  placeholder="Enter State Code"
                  type={"text"}
                  isRequired
                  value={fields?.stateProvince || ""}
                  handleChange={handleChange}
                />
              </div>
            )}
            <div className="mb-3">
              <PrimaryInput
                name={"postalCode"}
                label={"Zip"}
                type={"text"}
                isRequired
                value={fields?.postalCode || ""}
                placeholder="Enter postal code"
                handleChange={handleChange}
              />
            </div>
          </CardBody>
        </Card>
      </form>
    </div>
  );
}

export default EditStakeHolders;
