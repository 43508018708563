import React from 'react';
import { Row,Col } from 'reactstrap';
import upwardIcon from "assets/images/up-ward.svg";

function StatisticsCard({heading,total,subTotal,subHeading,border}) {
  return (
    <Row className={`py-4 d-flex align-items-center ${border && "border-top border-bottom" }`} >
    <Col md="9">
        <h6>{heading}</h6>
        <p className="mb-0">
            {subHeading}: {subTotal ? subTotal : <img src={upwardIcon} className="upaword icon"/>}
        </p>
    </Col>
    <Col md="3">
        <p>{total}</p>
    </Col>
</Row>
  )
}

export default StatisticsCard;