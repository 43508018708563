import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getAllResponseLogs = (page,type) => {
    return getApi({
        url:`admin/logs`,
        data:{
            is_admin:true,
            page:page || undefined,
            type:(type !== "" && type !== undefined ) ? type : undefined,
        }
    })
    .then(res => res.data);

};

export const deleteResponseLogs = (ids) => {
    return axiosInstance({
        method: "DELETE",
        url: `${siteApiUrl}admin/logs/destroy`,
        withCredentials: true,
        data: {
            log_ids:ids,
            is_admin:true,
            
        },
        headers: getHttpHeaders(),
    });
} 

export const exportResponseLogs = () => {
    return getApi({
        url:`admin/products/clone/errors-export`,
        data:{
            is_admin:true,
        }
    })
    .then(res => res.data);

};