import React, {useState} from "react";
import { Card, CardBody, Col, Container, Modal, Row } from "reactstrap";

const PrimaryPopup = ({
  isOpen,
  handleClick,
  title,
  isScrollable,
  children,
  className,
}) => {
  return (
    <React.Fragment>
        <div className="">
          <Modal
            isOpen={isOpen}
            scrollable={isScrollable}
            className={className}
            toggle={handleClick}
          >
            {title && (
              <div className="modal-header">
                <h5 className="modal-title mt-0">{title}</h5>
                <button
                  type="button"
                  onClick={handleClick}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}
            <div className="modal-body">{children}</div>
          </Modal>
        </div>
    </React.Fragment>
  );
};

export default PrimaryPopup;
