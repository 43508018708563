import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

const ResetButton = () => {
  const navigate = useNavigate();

  const redirectToURL = () => {
    navigate(window.location.pathname);
  };

  return (
    <Button
      color="secondary"
      className={`btn-rounded ms-1`}
      onClick={redirectToURL}
    >
      Reset Filter
    </Button>
  );
};

export default ResetButton;
