import React, { useState, useEffect } from "react";
import PrimaryPopup from "./PrimaryPopup";
import { Row, Col } from "reactstrap";
import PrimaryPagination from "../Pagination/PrimaryPagination";
import { getProductIdsByKeyword } from "services/SEOKeywordsServices";
import ProgressPopup from "./ProgressPopup";

const SeoProductsIdsPopup = ({ isOpen, handleClick, kId }) => {
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData(pageNo);
  }, [pageNo]);

  const getData = async page => {
    try {
      const response = await getProductIdsByKeyword(kId, page);
      setData(response.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handlePopup = () => {
    setData([]);
    handleClick();
  };

  const chunkSize = 10;
  const productIds = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
  ];

  const chunkedData = [];
  for (let i = 0; i < productIds.length; i += chunkSize) {
    chunkedData.push(productIds.slice(i, i + chunkSize));
  }

  return (
    <PrimaryPopup
      title={`Product ID's`}
      handleClick={handlePopup}
      isScrollable={false}
      className={
        "d-flex justify-content-center align-items-center h-100 w-100 product-sku-popup"
      }
      isOpen={isOpen}
    >
      {loading && <ProgressPopup label={"Loading..."} />}
      <Row className="px-4">
        {chunkedData.map((chunk, index) => (
          <Col key={index} className="px-0">
            <h4 className="text-13 pb-3 border-bottom">Col {index + 1}</h4>
            {chunk.map(item => (
              <div key={index} className="border-bottom py-2">
                {item}
              </div>
            ))}
          </Col>
        ))}
        {
          <PrimaryPagination
            pageNo={pageNo}
            pageCount={pageCount}
            setPageNo={setPageNo}
          />
        }
      </Row>
    </PrimaryPopup>
  );
};

export default SeoProductsIdsPopup;
