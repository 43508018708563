import React from "react";
import { Button } from "reactstrap";
import PrimaryPopup from "./PrimaryPopup";
import PrimarySelect from "../PrimarySelect";

const DisputeMessagePopup = ({
    handleClick,
    isOpen,
    isScrollable,
    heading,
    message,
    setMessage,
    handleSave,
    type,
    setType
}) => {
    return (
        <PrimaryPopup isOpen={isOpen} isScrollable={isScrollable} handleClick={handleClick} title={heading}>
            <div className="border-bottom">
                {
                    heading == "Dispute Message" && (

                        <div className="pb-4">
                            <div className="mb-3">
                                <PrimarySelect
                                    label={"Message To"}
                                    isRequired
                                    value={type}
                                    options={[
                                        {
                                            name: "Please Select one",
                                            value: ""
                                        },

                                        {
                                            name: "Seller & Customer",
                                            value: "public"
                                        }
                                        ,
                                        {
                                            name: "Customer",
                                            value: "customer"
                                        },
                                        {
                                            name: "Seller",
                                            value: "seller"
                                        }

                                    ]}
                                    handleChange = {(e)=>{
                                        setType(e.target.value)
                                    }}
                                />

                            </div>
                            <div>
                                <label>Message<span className="primary-text">*</span></label>
                                <textarea rows={"5"} placeholder="Type here" value={message} onChange={(e) => setMessage(e.target.value)} className="form-control" />
                            </div>
                        </div>

                    ) 
     
                }{
                    heading === "Close Dispute" && (
                        <div className="pb-4">
                            <label>Comment</label>
                            <textarea rows={"5"} placeholder="Type here" value={message} onChange={(e) => setMessage(e.target.value)} className="form-control" />
                        </div>
                    )
                }
            </div>
            <div className="d-flex justify-content-end align-items-center mt-3">
                <Button color="secondary" onClick={handleClick} >Cancel</Button>
                {
                    heading == "Dispute Message" && (
                        <Button color="primary" className="ms-2" onClick={()=>handleSave('message')}>Send</Button>
                    )
                }
                {
                    heading === "Close Dispute" && (
                        <Button color="primary" className="ms-2" onClick={()=>handleSave("close")}>Close Dispute</Button>
                    )
                }
               
            </div>

        </PrimaryPopup>
    )
}

export default DisputeMessagePopup