import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import CSVorKeywordSelectorForm from "components/SEO/CSVorKeywordSelectorForm";
import ProccessStatusTable from "components/SEO/ProccessStatusTable";
import KeywordsResultTable from "components/SEO/KeywordsResultTable";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import {
  getAllJobsStatus,
  getAllSeoKeywords,
  removeSeoKeywords,
} from "services/SEOKeywordsServices";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { uploadSeoKeywords } from "services/SEOKeywordsServices";
import { useNavigate } from "react-router-dom";
import { setPageTitle } from "utils/pageTitle";
import { createCollectionByKeyword } from "services/SEOKeywordsServices";

export default function SeoCollections() {
  const [loading, setLoading] = useState(false);
  const [keywordsData, setKeywordsData] = useState([]);
  const [jobsListing, setJobListing] = useState([]);
  const [keywordsPage, setKeywordsPage] = useState(1);
  const [keywordsPageCount, setKeywordsPageCount] = useState(1);
  const [jobPage, setJobPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalResults, setTotalResult] = useState(1);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dates, setDates] = useState([
	  {
		startDate: "",
		endDate: "",
		key: "selection",
	  },
  ]);
  const [filters, setFilters] = useState({
    limit: "",
    search_id: "",
    search_name: "",
    product_count: "",
	count_sign:""
  });
  const [isAppliedFilter , setIsAppliedFilter] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys]= useState([]);
  const [actionType, setActionType] = useState("");

  const params = new URLSearchParams(window?.location?.search);
  const processPage = params.get("p-page");
  const resultsPage = params.get("r-page");
  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle("SEO Collections - Inspire Uplift");
  }, []);

  useEffect(() => {
	const payload  = {
		...filters,
		start_date:dates[0]?.startDate || undefined,
		end_date:dates[0]?.endDate || undefined

	}
	const filteredPayload= removeEmptyValues(payload);
	setKeywordsPage(resultsPage || 1);
    setLoading(true);
	if(isAppliedFilter){
		getKeywords(resultsPage, filteredPayload);
	}
	else {
		getKeywords(resultsPage);
	}
   
  }, [resultsPage]);

  useEffect(() => {
    setJobPage(processPage || 1);
    setLoading(true);
    getJobs(processPage);
  }, [processPage]);

  const getKeywords = async (page, filters) => {
    try {
	const resposne = await getAllSeoKeywords(page, filters);
      setKeywordsData(resposne?.data?.data?.data);
      setKeywordsPageCount(resposne?.data?.data.last_page);
      setTotalResult(resposne?.data?.data?.total);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getJobs = async page => {
    try {
      const response = await getAllJobsStatus(page);
      setJobListing(response.data?.data?.data);
      setPageCount(response?.data?.data?.last_page);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e, data, file, setName, setFile, setKeywords) => {
    e.preventDefault();
    const keywordStringToArr = data?.keywords?.split(", ");
    const formData = {
      name: data.name,
      keywords:
        keywordStringToArr?.length > 0
          ? keywordStringToArr
          : file
          ? file
          : undefined,
    };

    setLoading(true);
    try {
      const response = await uploadSeoKeywords(formData);
	  if(response.status == 200){
		PrimaryToast({ type: "success", message: response?.data?.message});
		setName();
		setFile(null);
		setKeywords();
		document.getElementById("formFile").value = "";
		if(!processPage  || processPage == 1 ){
			getJobs();
		}
		if(!resultsPage || resultsPage ==1){
			getKeywords();
		}
		let newUrl = `${window?.location.pathname}?p-page=1&r-page=1`;
		navigate(newUrl, { replace: true });

	  }
    } catch (err) {
      console.log(err);
      PrimaryToast({ type: "error", message: err?.response?.data?.message });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteKeyWords = async (selectedIds, handleClosePopup) => {
	const payload  = {
		...filters,
		start_date:dates[0]?.startDate || undefined,
		end_date:dates[0]?.endDate || undefined

	}
	const filteredPayload= removeEmptyValues(payload);
	
	try {
		setLoading(true);
		const response = await removeSeoKeywords(selectedIds);
		if(response.status == 200){
			const filterData  = keywordsData?.filter((item) => !selectedIds?.some((keyword) => keyword === item.id));
			if(actionType == "multiple") {
				setSelectedRowKeys([]);
			};
			PrimaryToast({type:"success", message:'Keyword rejected successfully'});
			if(filterData?.length === 0 && keywordsPage  > 1) {
				params.set('r-page',keywordsPage  -1 );
				let newUrl = `${window?.location?.pathname}?${params.toString()}`;
				navigate(newUrl, { replace: true });
			}
			else {
				getKeywords(keywordsPage, filteredPayload);
			}      
			handleClosePopup();
		}
	}
	catch(err){
		setLoading(false);
		console.log(err);
		PrimaryToast({type:"error", message:err?.response?.data?.message});
	
	}

  };


  const handleCreateCollection = async (data, handlePopup) => {
	const payload  = {
		...filters,
		start_date:dates[0]?.startDate || undefined,
		end_date:dates[0]?.endDate || undefined

	}
	const filteredPayload= removeEmptyValues(payload);

	try {
		setLoading(true);
		const response = await createCollectionByKeyword(data);
		if(response.status == 200){
			const filterData  = keywordsData?.filter((item) => !data?.some((keyword) => keyword.keyword_id === item.id));
			if(filterData?.length === 0 && keywordsPage  > 1) {
				params.set('r-page',keywordsPage  -1 );
				let newUrl = `${window?.location?.pathname}?${params.toString()}`;
				navigate(newUrl, { replace: true });
			}
			else {
				getKeywords(keywordsPage, filteredPayload);
			}   
			handlePopup();
		}
	}
	catch(err){
        setLoading(false);
		console.log(err);
		PrimaryToast({type:"error", message:err?.response?.data.message});
	};
  }

  const handleCalenderClick = () => {
	setShowCalendar(!showCalendar);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleApplyFilter = () => {
	setIsAppliedFilter(true);
	const payload  = {
		...filters,
		start_date:dates[0]?.startDate || undefined,
		end_date:dates[0]?.endDate || undefined

	}
	const filteredPayload= removeEmptyValues(payload);
	setLoading(true);
	if(!resultsPage || resultsPage ==1){
		getKeywords(1, filteredPayload);
	}
	else {
		params?.set("r-page",1);
		let newUrl = `${window?.location.pathname}?${params?.toString()}`;
		navigate(newUrl, { replace: true });
	}
  };

  const removeEmptyValues = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (obj[key] !== "" && obj[key] !== undefined && obj[key] !== null) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };

  const handleResetFilter = () => {
	setFilters({
		limit: "",
		search_id: "",
		search_name: "",
		product_count: "",
		count_sign:""
	});
	setDates([
		{
			startDate: "",
			endDate: "",
			key: "selection",
		},
	]);
	setIsAppliedFilter(false);
	if(!resultsPage || resultsPage ==1){
		getKeywords(1);
	}
	else {
		params?.set("r-page",1);
		navigate(`${window?.location.pathname}?${params?.toString()}`);
	}
	
  };

  const handleDatePicker = item => {
    setDates([item.selection]);
  };


  const handleOnSelectAll = (isSelected, rows) => {
	let selectedData = rows.map((row)=> {
		let title = row.keyword.replace(/\b\w/g, (match) => match.toUpperCase());
		return {
			keyword_id:row.id,
			slug: row?.keyword?.trim().replaceAll(" ", "-")?.toLowerCase(),
			title
		}
		
	})
    if (isSelected) {
      setSelectedRowKeys(selectedData);
    } else {
      setSelectedRowKeys([]);
    }
  };


  const selectRow = {
    mode: "checkbox",
    onSelectAll: handleOnSelectAll,
    nonSelectableClasses: "hide-checkbox",
    onSelect: (row, isSelect) => {
		let title = row.keyword.replace(/\b\w/g, (match) => match.toUpperCase());
		const rowData = {
			keyword_id:row.id,
			slug: row?.keyword?.trim().replaceAll(" ", "-")?.toLowerCase(),
			title
		}
      if (isSelect) {
        setSelectedRowKeys([...selectedRowKeys, rowData]);
      } else {
        setSelectedRowKeys(selectedRowKeys.filter(key => key.keyword_id !== row.id));
      }
    },
    selected: selectedRowKeys.map((key) => key.keyword_id),
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <TopHeading heading={"SEO Collection"} />
      <Card className="mt-4">
        <CardBody>
          <Row>
            <Col sm="6">
              <CSVorKeywordSelectorForm
                handleFormSubmit={handleSubmit}
                loading={loading}
              />
            </Col>
            {jobsListing?.length > 0 && (
              <Col sm="6">
                <ProccessStatusTable
                  data={jobsListing}
                  pageNo={jobPage}
                  setPageNo={setJobPage}
                  pageCount={pageCount}
                />
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
      {keywordsData?.length >= 0 && (
		<section>
			<KeywordsResultTable
				loading={loading}
				setDates={setDates}
				data={keywordsData}
				pageNo={keywordsPage}
				setKeywordsPage={setKeywordsPage}
				pageCount={keywordsPageCount}
				handleCreateCollection={handleCreateCollection}
				handleDeleteKeyWords={handleDeleteKeyWords}
				totalResults={totalResults}
				setLoading={setLoading}
				showCalendar={showCalendar}
				dates={dates}
				handleCalenderClick = {handleCalenderClick}
				filters={filters}
				handleFilterChange={handleChange}
				setFilters={setFilters}
				handleApplyFilter={handleApplyFilter}
				handleResetFilter={handleResetFilter}
				handleDatePicker={handleDatePicker}
				isAppliedFilter={isAppliedFilter}
				selectRow={selectRow}
				selectedRowKeys={selectedRowKeys}
				setSelectedRowKeys={setSelectedRowKeys}
				setActionType={setActionType}
				actionType={actionType}
			/>
		</section>
      )}
    </div>
  );
}
