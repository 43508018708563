import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import TopHeading from 'components/Common/TopHeading';
import plusIcon from "../../../assets/images/plus-icon.svg";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import editIcon from "../../../assets/images/edit-icon-white.svg";
import { useNavigate } from 'react-router-dom';
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimarySearch from "components/Common/PrimarySearch";
import PrimaryTable from "components/Common/PrimaryTable";
import { getAllMarketplaceFeedList, deleteMarkeplaceFeed } from "services/MarketPlaceFeedService";
import ProgressPopup from 'components/Common/Popups/ProgressPopup';
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { toast } from 'react-toastify';
import { setPageTitle } from "utils/pageTitle";
import useToggle from 'hooks/useToggle';



function MarketplaceFeed() {
    const [marketplaceFeedData, setMarketplaceFeedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [selectedId, setSelectedId] = useState();
    const [showDeleteModal, setShowDeleteModal] = useToggle(false);
    const [searchVal, setSearch] = useState(null);
    const params = new URLSearchParams(window?.location?.search);

    const history = useNavigate();

    useEffect(() => {
        const page = params?.get("page") || undefined;
        const search = params?.get('search') || undefined;
        setPageNo(page || 1);
        setSearch(search);
        setLoading(true);
        getMarketplaceFeed(page, search);

    }, [window?.location?.search]);

    useEffect(()=>{
		setPageTitle("Marketplace Feeds - Inspire Uplift")
	},[]);

    const getMarketplaceFeed = (page, search) => {
        getAllMarketplaceFeedList(page, search)
            .then(res => {
                setMarketplaceFeedData(res?.data?.data);
                setPageCount(res?.data?.last_page);
            })
            .catch(err => console.log(err))
            .finally(res => setLoading(false));
    }

    const columns = [
        {
            dataField: 'created_at',
            text: 'Created At',
        },
        {
            dataField: 'name',
            text: 'Name',
        },
        {
            dataField: 'group_ids',
            text: 'Group ids',
        },
        {
            dataField: 'is_active',
            text: 'Status',
            formatter: (cell, row) => {
                let status;
                if (row.is_active === 1) {
                    status = "Active";
                }
                if (row.is_active === 0) {
                    status = "Inactive";
                }

                return (
                    <div className={`${row.is_active == 1 ? "success-status" : "danger-status"} w-max-content status`}>{status}</div>
                )
            }
        },
        {
            dataField: "action",
            text: 'Action',
            formatter: (cell, row) => {
                return (
                    <div className="d-flex justify-content-center align-items-center">
                        <div
                            className="action-btn edit-icon-bg me-1 cursor-pointer"
                            onClick={() =>
                                history(`/marketing/marketplace-feed/edit/${row.id}`)
                            }
                        >
                            <img src={editIcon} className="" alt="edit icon" />
                        </div>
                        <div className="action-btn delete-btn-bg cursor-pointer" onClick={() => {
                            setSelectedId(row.id);
                            setShowDeleteModal(true);
                        }}>
                            <img src={deleteIcon} className="" alt="delete icon" />
                        </div>
                    </div>
                )
            }
        }
    ];



    const handleDelete = () => {
        const filterArr = marketplaceFeedData?.filter(item => item?.id !== selectedId);
        toast.dismiss();
        setLoading(true);
        deleteMarkeplaceFeed(selectedId)
            .then(res => {
                setSelectedId('');
                setShowDeleteModal(false);
                if(pageNo > 1 && filterArr.length === 0){
                    params.set('page',pageNo  -1);
                    let newUrl = `${window?.location?.pathname}?${params.toString()}`;
                    history(newUrl, { replace: true });
                }
                else {
                    getMarketplaceFeed(pageNo,searchVal);
                }
                
                PrimaryToast({ type: 'success', message: res?.data?.message });
            })
            .catch(err => {
                setLoading(false);
                PrimaryToast({ type: 'error', message: response?.err?.data?.message });
                console.log(err);
            })
       
    }

    const handleSearch = (e) => {
        const val = e.target.value;
        setSearch(val);
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            if (params?.get("page")) {
              params?.delete("page");
            }
            if (searchVal) {
              params?.set("search", searchVal);
            } else {
              params?.delete("search");
            }
            history(`/marketing/marketplace-feed?${params?.toString()}`);
        }
    };

    return (
        <div className='page-content'>
            {
                showDeleteModal && <DeleteModal
                    title={"Are you sure to delete this feed?"}
                    onConfirm={handleDelete}
                    onCancel={() => {
                        setSelectedId('');
                        setShowDeleteModal(!showDeleteModal);
                    }}

                />
            }
            {
                loading && <ProgressPopup label={"Loading..."} />
            }
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <TopHeading heading={"Marketplace Feeds"} />
                <Button
                    className="rounded-full"
                    color="primary"
                    onClick={(() => history('/marketing/marketplace-feed/create'))}
                >
                    <img alt="plus icon" src={plusIcon} className="me-2" />
                    Create New Feed
                </Button>
            </div>
            <Card>
                <CardBody>
                    <div className="me-2 mb-3 d-flex align-items-center">
                        <PrimarySearch handleKeyPress={handleKeyPress} placeholder={"Search by name"} value={searchVal} handleChange={handleSearch} />
                    </div>
                    <div className="mt-3 table-responsive">
                        <PrimaryTable
                            columns={columns}
                            data={marketplaceFeedData}
                            bordered={false}
                            keyField="order_number"
                        />
                    </div>
                    {
                        (marketplaceFeedData?.length > 0  && pageCount > 1)&& (
                            <PrimaryPagination
                                pageNo={pageNo}
                                pageCount={pageCount}
                                setPageNo={setPageNo}
                            />
                        )
                    }
                </CardBody>
            </Card>
        </div>
    )
}

export default MarketplaceFeed;