import React from "react";

const ProgressPopup = ({label}) => {
    return (
        <div className='position-fixed progress-popup-wrapper top-0 start-0 w-100 h-100 bg-white bg-opacity-10 d-flex justify-content-center align-items-center'>
            <div className="w-80 sm:w-96 h-48 bg-opacity-90  d-flex align-items-center justify-content-center flex-column progress-popup-container">
                <svg className="mb-1 animate-spin -ml-1 mr-3 h-11 w-11 text-white" style={{width:'2.75rem',height:"2.75rem",color:'white',marginLeft:"-0.25rem"}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                {label}
            </div>
        </div>
    )

}
export default ProgressPopup;