import { getApi } from "./BaseService";

export const getAllBugsList = (page) => {
    return getApi({
        url:`admin/bugs`,
        data: {
            is_admin: true,
            page:page || undefined,
        }
    })
    .then(res => res.data)

}