import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getAllFilterRelatedGroups } from "services/SEOKeywordsServices";

const RelatedGroupsField = ({ label, fields, setFields, isRequired }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getAllFilterRelatedGroups()
      .then(res => {
        const categoriesList = [
          ...res.map(item => {
            return {
              label: item.name,
              value: item.id,
            };
          }),
        ];
        setOptions(categoriesList);
      })
      .catch(err => console.log(err));
  }, []);

  let relatedGroupValue = options?.find(item => {
	
    const relatedGroupId = fields?.related_group_id;
    return relatedGroupId === item.value;
  });

  return (
    <div>
      {label && (
        <label>
          {label}
          {isRequired && <span className="primary-text">*</span>}
        </label>
      )}
      <Select
        isMulti={false}
        options={options}
        onChange={selectedItem => {
          setFields({
            ...fields,
            related_group_id: selectedItem.value,
          });
        }}
        className="react-select-container"
        placeholder={"Enter  Related Groups"}
        hideSelectedOptions={false}
        value={relatedGroupValue}
        name="related_group_id"
      />
    </div>
  );
};

export default RelatedGroupsField;
