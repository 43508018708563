import React, { Fragment } from "react";
import { Card, CardBody } from "reactstrap";
import { useState, useEffect } from "react";

import {  useNavigate } from "react-router-dom";

import PrimaryInput from "components/Common/PrimaryInput";
import { checkResetPassword, resetPassword } from "services/AuthServices";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import PrograssPopup from "components/Common/Popups/ProgressPopup";
import ExpiredIcon from "assets/images/expired-icon.svg";
import SpinnerLoader from "components/Common/SpinnerLoader";
import { forgotPassword } from "services/AuthServices";

const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [token, setToken] = useState("");
  const [requestSent, setRequestSent] = useState(true);
  const history = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    const email = searchParams.get("email");
    setToken(token);
    setEmail(email);
    if (token && email) {
      let data = {
        token,
        email,
      };
      setRequestSent(true);
      checkResetPassword(data)
        .then(res => {
          setIsVerify(true);
        })
        .catch(err => {
          setIsVerify(false);
          setEmail("");
          console.log(err);
        })
        .finally(res => {
          setRequestSent(false);
        });
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    resetPassword(email, password, confirmPassword, token)
      .then(res => {
        PrimaryToast({ type: "success", message: "login successfull" });
        localStorage.setItem("adminToken", res.data.token);
        history("/dashboard");
        setEmail("");
        setPassword("");
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const handleEmailSubmit = e => {
    e.preventDefault();
    setLoading(true);
    forgotPassword(email)
      .then(res => {
        PrimaryToast({ type: "success", message: "Email sent successfully" });
        setEmail("");
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      })
      .finally(res => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {loading && <PrograssPopup label={"Loading..."} />}
      {requestSent ? (
        <div className="position-fixed top-0 start-0 w-100 h-100">
          <SpinnerLoader />
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center h-screen w-100">
          <div>
            {isVerify ? (
              <Card className="overflow-hidden forgot-password-wrapper">
                <h4 className="text-center">Create New Password</h4>
                <p className="text-center mb-0 text-13 text-gray-700 ">
                  Enter new password for your account.
                </p>
                <CardBody className="">
                  <div className="">
                    <form className="form-horizontal" onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <PrimaryInput
                          name={"password"}
                          type="password"
                          placeholder="Password"
                          value={password}
                          handleChange={e => {
                            setPassword(e.target.value);
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <PrimaryInput
                          name={"confirm_password"}
                          type="password"
                          value={confirmPassword}
                          placeholder="Confirm Password"
                          handleChange={e => {
                            setConfirmPassword(e.target.value);
                          }}
                        />
                      </div>
                      <button
                        className="w-100 bg-primary text-white outline-none border-0 p-2 rounded"
                        type="submit"
                      >
                        Reset Password
                      </button>
                    </form>
                  </div>
                </CardBody>
              </Card>
            ) : (
              <Card className="forgot-password-wrapper d-flex justify-content-center align-items-center flex-column ">
                <img
                  src={ExpiredIcon}
                  height={77}
                  width={77}
                  className="text-center"
                  alt="success-icon"
                />
                <h4 className="mt-3 text-center">Password Link Has Expired</h4>
                <p className="text-center text-13 text-gray-700 ">
                  Please enter your email address below to receive a password
                  link again
                </p>
                <div className="mb-3 w-100">
                  <PrimaryInput
                    name={"email"}
                    type="email"
                    placeholder={"Enter email"}
                    value={email}
                    handleChange={e => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <button
                  className="w-100 bg-primary text-white outline-none border-0 p-2 rounded"
                  type="button"
                  onClick={handleEmailSubmit}
                >
                  Send Reset Email
                </button>
              </Card>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ResetPasswordPage;
