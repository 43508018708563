import moment from "moment";
import React from "react";
import { Card, CardBody } from "reactstrap";



const TaskInformation = ({data}) => {
 
    return (
        <Card>
            <CardBody>
                <div className="font-semi-bold text-15">Task Detail</div>
                <div className="text-13 d-flex justify-content-between align-items-center mt-3">
                    <div className="font-semi-bold">Assigned By:</div>
                    <div className="">{data?.assigned_by}</div>
                </div>
                <div className="text-13 d-flex justify-content-between align-items-center mt-3">
                    <div className="font-semi-bold">Assigned On:</div>
                    <div className="">{(moment(data?.assigned_on).format("DD-MM-YYYY hh:mm:ss a")) }</div>
                </div>  
                <div className="text-13 d-flex align-items-start justify-content-between mt-3">
                    <div className="font-semi-bold w-max-content">Assigned To:</div>
                    <div className="d-flex flex-wrap justify-content-end" style={{width:'60%'}}>
                        {
                            data?.assigned_to?.length > 0 && data?.assigned_to.map((item,index)=>{
                                return (
                                    <div className="p-1 primary-status status me-1 mb-1" key={index}>{item?.name}</div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="text-13 d-flex justify-content-between align-items-center mt-3">
                    <div className="font-semi-bold">Priority:</div>
                    <div className={`status ${data?.priority === "low" ? "success-status" : data?.priority === "medium" ? "warning-status" : data?.priority === "high" ? "danger-status" : ""} w-max-content`}>{data?.priority}</div>
                </div>
                <div className="text-13 d-flex justify-content-between align-items-center mt-3">
                    <div className="font-semi-bold">Status:</div>
                    <div className={`status ${data?.status === "completed" ? "success-status" : data?.status === "pending" ? "warning-status" : ''}  w-max-content`}>{data?.status}</div>
                </div>
            </CardBody>
        </Card>
    )
}
export default TaskInformation;