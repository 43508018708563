import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ChartApex from "./ChartApex";
import StatisticsSection from "./StatisticsSection";

const DashboardBelowSection = ({ data }) => {
  return (
    <Row>
      <Col md="8">
        <Card>
          <CardBody>
            <ChartApex
              dataColors='["--bs-primary", "--bs-success"]'
              data={data?.graphData?.graph}
              amountsData={data?.graphData?.graph?.map(item => item?.count)}
              monthsData={data?.graphData?.graph?.map(item => item?.time)}
            />
          </CardBody>
        </Card>
      </Col>
      <Col md="4">
        <StatisticsSection data={data} />
      </Col>

    </Row>
  );
};
export default DashboardBelowSection;
