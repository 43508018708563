import { ORDERS_HISTORIES } from "store/actions/orders";
import { ADD_HISTORY } from "store/actions/orders";
import { GET_TRACKING_NUMBER } from "store/actions/orders";

const initialState = {
    orderHistories: [],
    tracking_numbers:[],
}

const ordersReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ORDERS_HISTORIES:
            return {
                ...state,
                orderHistories: payload,
            }
            break
        case ADD_HISTORY:
            return {
                ...state,
                orderHistories: [payload,...state.orderHistories]
            }
            break;
        case GET_TRACKING_NUMBER : 
            return {
                ...state,
                tracking_numbers: payload !== "" ? [payload] : [],
            }
        default:
            return state;
    }

}

export default ordersReducer;