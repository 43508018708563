import React from "react";
import { Card, CardBody } from "reactstrap";
import moment from "moment";

const EmployeeDetailSection = ({ employeeData }) => {
  return (
    <div>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center">
            {employeeData?.image ? (
              <img
                src={employeeData?.image}
                className="employee-profile-image"
                alt="employee image"
              />
            ) : (
              <div className="name-avatar  text-light text-uppercase">
                {employeeData?.first_name && employeeData?.first_name[0]}
              </div>
            )}

            <h4 className="font-medium ms-3">
              {employeeData?.first_name} {employeeData?.last_name}
            </h4>
          </div>
          {employeeData?.email && (
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div className="font-semi-bold text-secondary">Email:</div>
              <p className="text-secondary mb-0">{employeeData?.email}</p>
            </div>
          )}

          {employeeData?.gender && (
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div className="font-semi-bold text-secondary">Gender:</div>
              <p className="text-secondary mb-0">{employeeData?.gender}</p>
            </div>
          )}

          {employeeData?.date_of_birth && (
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div className="font-semi-bold text-secondary">
                Date of Birth:
              </div>
              <p className="text-secondary mb-0">
                {employeeData?.date_of_birth &&
                  moment(employeeData?.date_of_birth).format("DD-MM-YYYY")}
              </p>
            </div>
          )}

          {employeeData?.date_of_joining && (
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div className="font-semi-bold text-secondary">
                Date of Joining:
              </div>
              <p className="text-secondary mb-0">
                {employeeData?.date_of_joining &&
                  moment(employeeData?.date_of_joining).format("DD-MM-YYYY")}
              </p>
            </div>
          )}
          {employeeData?.department && (
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div className="font-semi-bold text-secondary">Department:</div>
              <p className="text-secondary mb-0">{employeeData?.department}</p>
            </div>
          )}
          {employeeData?.phone && (
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div className="font-semi-bold text-secondary">Phone Number:</div>
              <p className="text-secondary mb-0">{employeeData?.phone}</p>
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div className="font-semi-bold text-secondary">Status:</div>
            <p
              className={`mb-0 status w-max-content ${
                employeeData?.is_active == 1
                  ? "success-status"
                  : "danger-status"
              }`}
            >
              {employeeData?.is_active == 1 ? "Active" : "Inactive"}
            </p>
          </div>
          {employeeData?.roles?.length > 0 && (
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div className="font-semi-bold text-secondary">Role:</div>
              <p className="mb-0 status w-max-content success-status">
                {employeeData?.roles?.length > 0 &&
                  employeeData?.roles[0]?.name}
              </p>
            </div>
          )}
        </CardBody>
      </Card>
      {employeeData?.bank_account_detail && (
        <Card className="mt-4">
          <CardBody>
            <h5>Bank Account Details</h5>
            <p className="mb-0">{employeeData?.bank_account_detail}</p>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default EmployeeDetailSection;
