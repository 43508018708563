import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { getSellerGroups } from "services/CouponServices";
import { getAllEmployees } from "services/TaskServices";
import { getFeedTypes } from "services/MarketPlaceFeedService";
import { getProductGroups } from "services/ProductsServices";
import { getAllProductsGroups } from "services/OrderManagementService";
import { getAllAdsPlatform } from "services/MarketPlaceFeedService";


let timmer;

function SecondarySelect({
    label,
    isRequired,
    handleSelect,
    value,
    isMulti,
    path,
    placeholder,
    customStyles
}) {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getSearch();

    }, []);


    const getSearch = (search) => {
        if (path === "emplooye") {
            getAllEmployees(search)
                .then(res => {
                    let results = res.data.map((result) => {
                        return {
                            label: result.name,
                            value: result.id,
                        }
                    });
                    setOptions(results);
                })
                .catch(err => console.log(err));
        }
        else if (path === "orders-managment") {
            getAllProductsGroups(search)
                .then(res => {
        
                    let customTypes = res?.data?.map((item) => {
                        return {
                            label: item.name,
                            value: item.id
                        }
                    })
                    setOptions(customTypes)
                })
                .catch(err => {
                    console.log(err);
                })
        }
        else if (path === "marketplace-feed") {

            getFeedTypes(search)
                .then(res => {
                    let customTypes = res?.data?.map((item) => {
                        return {
                            label: item.name,
                            value: item.id
                        }
                    })
                    setOptions(customTypes)
                })
                .catch(err => console.log(err))

        }
        else if (path == "product-group") {
            getProductGroups(search)
                .then(res => {
                    let customTypes = res?.data?.data.map((item) => {
                        return {
                            label: item.name,
                            value: item.id
                        }
                    })
                    setOptions(customTypes)
                })
                .catch(err => {
                    console.log(err);
                })
        }
        else if(path == "ads-center"){
            getAllAdsPlatform(search)
                .then( res => {
                    let customTypes = res?.data?.map((item) => {
                        return {
                            label: item.name,
                            value: item.id
                        }
                    })
                    setOptions(customTypes)
                })
                .catch(err => {
                    console.log(err);
                })
        }
        else {
            getSellerGroups(search)
                .then(res => {
                    let results = res.data.map((result) => {
                        return {
                            label: result.name,
                            value: result.id,
                        }
                    });
                    setOptions(results);
                })
                .catch(err => console.log(err));
        }
    };

    const loadOptions = (inputValue) => {
    
        if(path !== "emplooye"){
            clearTimeout(timmer);
            timmer = setTimeout(() => {
                getSearch(inputValue);
            }, 500)
        }

    };

    const handleChange = (selectedOptions) => {
        handleSelect(selectedOptions);
    };

    // 
    return (
        <div>
            {
                label && (
                    <label className="form-label">
                        {label}
                        {isRequired && <span className="primary-text">*</span>}
                    </label>
                )
            }
            <Select
                isMulti={isMulti}
                options={options}
                onInputChange={(inputValue) => loadOptions(inputValue)}
                onChange={handleChange}
                value={value}
                name={name}
                placeholder={placeholder}
                styles={customStyles}

            />

        </div>

    );
}

export default SecondarySelect;