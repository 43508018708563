import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";


export const createRestrictSeller = (seller_ids) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seller/restricted/update`,
        withCredentials: true,
        data: {
            is_admin: true,
            seller_ids,
        },
        headers: getHttpHeaders(),
    });
};

export const getAllRestrictedSeller = () => {
    return getApi({
        url: 'admin/seller/restricted',
        data: {
            is_admin: true,
        }
    }).then(res => res.data)
};