import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { createNewKeyword } from "services/KeywordDetectServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function KeywordCreate() {
	const [keywords, setKeywords] = useState("");
	const [loading, setLoading] = useState(false);

	const history = useNavigate();

	useEffect(()=>{
		setPageTitle("Create Keywords - Inspire Uplift")
	},[]);

	const handleSubmit = e => {
		e.preventDefault();
		toast.dismiss();
		setLoading(true);
		createNewKeyword(keywords)
			.then(res => {
				history(-1)
				PrimaryToast({type:'success', message:res?.data?.message});
			})
			.catch(err =>{
				console.log(err)
				PrimaryToast({type:'error', message:err?.response?.data?.message[0]});
			})
			.finally(res => setLoading(false));
	}

	return (
		<div className="page-content">
			{
				loading && <ProgressPopup label={"Loading..."} />
			}
			<form onSubmit={handleSubmit}>
				<div className="mb-3 d-flex justify-content-between align-items-center">
					<TopHeading heading={"Create Keywords"} />
					<div className="d-flex align-items-center">
						<Button onClick={() => history(-1)}>Cancel</Button>
						<Button color="primary" className="ms-4">
							Create
						</Button>
					</div>
				</div>
				<Card>
					<CardBody>
						<div className="w-50">
							<div className="mb-3">
								<label htmlFor="inputEmail4">
									Keywords<span className="primary-text">*</span>
								</label>
								<textarea
									className="form-control"
									id="exampleFormControlTextarea1"
									rows="3"
									placeholder="(e.g: keyword1, keyword2, keyword3, keyword4, ...)"
									onChange={(e)=>setKeywords(e.target.value)}
								></textarea>
							</div>
						</div>
					</CardBody>
				</Card>
			</form>

		</div>
	);
}

export default KeywordCreate;
