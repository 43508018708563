import React from 'react';
import TimePicker from 'rc-time-picker';

const PrimaryTimePicker = ({
    name,
    value,
    handleTimeChange,
    loading
}) => {
        return (
            <div className='form-control'>
                {
                    loading ? <div>loading...</div>: (
                        <TimePicker
                        name={name}
                        defaultValue={value}
                        showSecond={false}
                        onChange={handleTimeChange}
                        
                    
                    />
                    )
                }
            </div>

        )
    


}

export default PrimaryTimePicker;