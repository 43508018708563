import React, { useEffect, useState } from 'react';
import TopHeading from 'components/Common/TopHeading';
import { Button, Card, CardBody } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
    getSingleProductGroup,
    updateProudctGroup,
    getAllProductGroupIds,
    deleteGroupProduct
}
    from "services/ProductsServices";
import PrograssPopup from 'components/Common/Popups/ProgressPopup';
import ProductGroupIdsForm from 'components/Products/ProductGroups/ProductGroupIdsForm';
import ProductIdsTable from 'components/Products/ProductGroups/ProductIdsTable';
import PrimaryToast from 'components/Common/Toast/ToastMessage';
import DeleteModal from 'components/Common/Toast/DeleteModal';
import { toast } from 'react-toastify';
import  { setPageTitle } from "utils/pageTitle";


function EditGroup() {
    const [fields, setFields] = useState({});
    const [loading, setLoading] = useState(false);
    const [productIdsData, setProductIdsData] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState();

    const { id } = useParams();
    const history = useNavigate();

    useEffect(()=>{
		setPageTitle("Edit Product Group - Inspire Uplift")
	},[]);


    useEffect(() => {
        setLoading(true);
        getSingleProductGroup(id)
            .then(res => {
                setFields({
                    group: res?.data?.name,
                    product_ids: res?.data?.product_ids,
                })
            })
            .catch(err => console.log(err))
            .finally(res => setLoading(false));

    }, []);

    useEffect(() => {
        setLoading(true);
        getAllProductGroupIds(id, pageNo)
            .then(res => {
                setProductIdsData(res.data.data);
                setPageCount(res.data.last_page);
            })
            .catch(err => console.log(err))
            .finally(res => setLoading(false))

    }, [pageNo])


    const data = productIdsData?.map((item) => {
        return {
            ...item,
        }
    })

    const handleChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        const fieldsObj = { ...fields };
        fieldsObj[name] = value;
        setFields(fieldsObj);
    };

    const isDelete = (id) => {
        setShowDeleteModal(true);
        setSelectedId(id);
    }

    const handleDelete = () => {
        let filterData = productIdsData?.filter((item) => item.id !== selectedId);
        toast.dismiss();
        setLoading(true);
        deleteGroupProduct(selectedId)
            .then(res => {
                setProductIdsData(filterData);
                setFields({
                    ...fields,
                    product_ids: filterData.map((item) => item.product_id),

                })
                PrimaryToast({ type: 'success', message: res?.data?.message });
                setSelectedId();
                setShowDeleteModal(false);
            })
            .catch(err => {
                console.log(err)
                PrimaryToast({ type: 'error', message: err?.response?.data?.message[0] });
            })
            .finally(res => setLoading(false));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        updateProudctGroup(fields, id)
            .then(res => {
                getAllProductGroupIds(id, pageNo)
                    .then(res => {
                        setProductIdsData(res.data.data);
                        setPageCount(res.data.last_page);
                    })
                    .catch(err => console.log(err))
                    .finally(res => setLoading(false))
                PrimaryToast({ type: 'success', message: res?.data?.message });
            })
            .catch(err => {
                console.log(err)
                PrimaryToast({type:'error', message:Array.isArray(err?.response?.data?.message) ? err?.response?.data?.message[0] : err?.response?.data?.message});
            })
            .finally(res => setLoading(false));

    };

    return (
        <div className='page-content'>
            {
                loading && <PrograssPopup label={'Loading...'} />
            }
            {
                showDeleteModal && <DeleteModal
                    title={"Are you sure to delete this product from the group?"}
                    onConfirm={handleDelete}
                    onCancel={() => {
                        setSelectedId('');
                        setShowDeleteModal(!showDeleteModal);
                    }}

                />
            }
            <form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <TopHeading heading={"Edit Product Group"} />
                    <div className='d-flex align-items-center'>
                        <Button onClick={() => history(-1)}>
                            Cancel
                        </Button>
                        <Button className='ms-2' color='primary'>
                            Save
                        </Button>
                    </div>
                </div>
                <Card>
                    <CardBody>
                        <ProductGroupIdsForm fields={fields} handleChange={handleChange} />
                        <div className='mt-4'>
                            <ProductIdsTable
                                data={data}
                                pageNo={pageNo}
                                setPageNo={setPageNo}
                                handleDelete={isDelete}
                                pageCount={pageCount}
                            />
                        </div>
                    </CardBody>
                </Card>
            </form>


        </div>
    )
}

export default EditGroup