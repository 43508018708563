import React, { useEffect, useState } from "react";
import { dmcaForm } from "services/DMCAEmailsServices";
import TopHeading from "components/Common/TopHeading";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { setPageTitle } from "utils/pageTitle";
import PrimarySelect from "components/Common/PrimarySelect";
import deleteIcon from "assets/images/delete-icon-white.svg";
import plusIcon from "assets/images/plus-icon.svg";
import PrimaryInput from "components/Common/PrimaryInput";

function DMCAForm() {
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    copyright: "",
    firstName: "",
    lastName: "",
    address: "",
    company: "",
    email: "",
    telephone: "",
    originalUrls: ["", "", "", ""],
    originalDescription: "",
    reportUrls: ["", "", "", ""],
    reportDescription: "",
    signature: "",
    acknowledgement: false,
    infoStatus: false,
  });

  useEffect(() => {
    setPageTitle("DMCA - Inspire Uplift");
  }, []);

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    setInputValues(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleArrayChange = (e, index, arrayName) => {
    const { value } = e.target;
    setInputValues(prevState => {
      const updatedArray = [...prevState[arrayName]];
      updatedArray[index] = value;
      return {
        ...prevState,
        [arrayName]: updatedArray,
      };
    });
  };

  const handleAddUrl = arrayName => {
    setInputValues(prevState => ({
      ...prevState,
      [arrayName]: [...prevState[arrayName], ""], // Add an empty string to the array
    }));
  };

  const handleDeleteUrl = (index, key) => {
    setInputValues(prevValues => {
      const updatedArray = [...prevValues[key]];
      updatedArray.splice(index, 1); // Remove the URL at the given index
      return { ...prevValues, [key]: updatedArray }; // Update the state
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (
      !inputValues.copyrightStatus ||
      !inputValues.infoStatus ||
      !inputValues.acknowledgement
    ) {
      PrimaryToast({
        type: "error",
        message: "Please agree to all terms before submitting the form.",
      });
      return;
    }

    try {
      setLoading(true);
      const response = await dmcaForm(inputValues);
      if (response.status === 200) {
        PrimaryToast({
          type: "success",
          message: response?.data?.message,
        });
        // Clear the form
        setInputValues({
          copyright: "",
          firstName: "",
          lastName: "",
          address: "",
          company: "",
          email: "",
          telephone: "",
          originalUrls: ["", "", "", ""],
          originalDescription: "",
          reportUrls: ["", "", "", ""],
          reportDescription: "",
          signature: "",
          acknowledgement: false,
          infoStatus: false,
        });
      }
    } catch (err) {
      PrimaryToast({
        type: "error",
        message: err?.response?.data?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <TopHeading heading={"Create DMCA"} />
      <Card className="mt-4">
        <CardBody>
          <form onSubmit={handleSubmit}>
            <div className="personal-detail-section">
              <h4 className="fw-bold">
                Inspire Uplift Notice And Takedown Procedure
              </h4>
              <Row className="text-13 py-3">
                <div style={{ width: "50%" }}>
                  <PrimarySelect
                    label={"Who owns the copyright?"}
                    name="copyright"
                    isRequired
                    handleChange={handleChange}
                    value={inputValues.copyright}
                    options={[
                      { name: "Select", value: "" },
                      {
                        name: "I am the copyright owner",
                        value: "I am the copyright owner",
                      },
                      {
                        name: "I am an authorized representative of the copyright owner",
                        value:
                          "I am an authorized representative of the copyright owner",
                      },
                      { name: "None of the above", value: "other" },
                    ]}
                  />
                </div>

                <h4 className="fw-bold py-3">Contact Information</h4>

                <Col sm="6" className="d-flex flex-column align-items-start">
                  <PrimaryInput
                    label={"First Name"}
                    isRequired
                    name={"firstName"}
                    value={inputValues.firstName}
                    handleChange={handleChange}
                  />
                </Col>

                <Col sm="6" className="d-flex flex-column align-items-start">
                  <PrimaryInput
                    label={"Last Name"}
                    isRequired
                    name={"lastName"}
                    value={inputValues.lastName}
                    handleChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="text-13">
                <Col sm="6" className="d-flex flex-column align-items-start">
                  <PrimaryInput
                    label={"Address"}
                    isRequired
                    name="address"
                    value={inputValues.address}
                    handleChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="text-13 py-3">
                <Col sm="6" className="d-flex flex-column align-items-start">
                  <PrimaryInput
                    label={"Company"}
                    isRequired
                    name="company"
                    value={inputValues.company}
                    handleChange={handleChange}
                  />
                </Col>
                <Col sm="6" className="d-flex flex-column align-items-start">
                  <PrimaryInput
                    label={"Email"}
                    isRequired
                    name="email"
                    value={inputValues.email}
                    handleChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="text-13 pb-3">
                <Col sm="6" className="d-flex flex-column align-items-start">
                  <PrimaryInput
                    label={"Telephone No"}
                    isRequired
                    name="telephone"
                    value={inputValues.telephone}
                    handleChange={handleChange}
                  />
                </Col>
              </Row>
            </div>
            <h4 className="fw-bold">Content you want to report</h4>
            
            <Row className="text-13 py-3">
              <Col sm="6">
                {inputValues.originalUrls.map((url, index) => (
                  <div key={index} className="d-flex align-items-center mb-2">
                    <PrimaryInput
                      placeholder="Link to original work"
                      value={url}
                      handleChange={e =>
                        handleArrayChange(e, index, "originalUrls")
                      }
                    />
                    <Button
                      type="button"
                      color="danger"
                      className="ms-2"
                      onClick={() => handleDeleteUrl(index, "originalUrls")}
                      style={{ padding: "0.375rem 0.75rem" }}
                    >
                      <img src={deleteIcon} alt="delete icon" />
                    </Button>
                  </div>
                ))}
                <Button
                  type="button"
                  onClick={() => handleAddUrl("originalUrls")}
                  className="mt-3"
                >
                  <img src={plusIcon} className="me-1" alt="plus icon" /> Add
                  Another Link
                </Button>
              </Col>
            </Row>

            <Row className="text-13 py-3">
              <Col sm="6" className="d-flex flex-column align-items-start">
                <div className="font-semi-bold mb-1" style={{ width: "100%" }}>
                  Description<span className="primary-text">*</span>
                </div>
                <textarea
                  className="form-control"
                  name="originalDescription"
                  value={inputValues.originalDescription}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row className="text-13 py-3">
              <Col sm="6">
                {inputValues.reportUrls.map((url, index) => (
                  <div key={index} className="d-flex align-items-center mb-2">
                    <PrimaryInput
                      placeholder="Link to allegedly infringing work"
                      value={url}
                      handleChange={e =>
                        handleArrayChange(e, index, "reportUrls")
                      }
                    />
                    <Button
                      type="button"
                      color="danger"
                      className="ms-2"
                      onClick={() => handleDeleteUrl(index, "reportUrls")}
                      style={{ padding: "0.375rem 0.75rem" }}
                    >
                      <img src={deleteIcon} className="" alt="delete icon" />
                    </Button>
                  </div>
                ))}
                <Button
                  type="button"
                  onClick={() => handleAddUrl("reportUrls")}
                  className="mt-3"
                >
                  <img src={plusIcon} className="me-1" alt="plus icon" /> Add
                  Another Link
                </Button>
              </Col>
            </Row>

            <Row className="text-13 py-3">
              <Col sm="6" className="d-flex flex-column align-items-start">
                <div className="font-semi-bold mb-1" style={{ width: "100%" }}>
                  Description<span className="primary-text">*</span>
                </div>
                <textarea
                  className="form-control"
                  name="reportDescription"
                  value={inputValues.reportDescription}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <div className="py-3">
              <h4 className="fw-bold">Declaration</h4>
            </div>
            <div className="mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="copyrightStatus"
                id="copyrightStatus"
                checked={inputValues.copyrightStatus}
                onChange={handleChange}
              />
              <label
                className="form-check-label ms-2"
                htmlFor="copyrightStatus"
              >
                I have a good faith belief that use of the material in the
                manner complained of is not authorized by the copyright owner,
                its agent, or the law.
              </label>
            </div>
            <div className="mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="infoStatus"
                id="infoStatus"
                checked={inputValues.infoStatus}
                onChange={handleChange}
              />
              <label className="form-check-label ms-2" htmlFor="infoStatus">
                I swear, under penalty of perjury, that the information in the
                notification is accurate, and that I am the copyright owner or
                am authorized to act on behalf of the owner of an exclusive
                right that is allegedly infringed.
              </label>
            </div>
            <div className="mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="acknowledgement"
                id="acknowledgement"
                checked={inputValues.acknowledgement}
                onChange={handleChange}
              />
              <label
                className="form-check-label ms-2"
                htmlFor="acknowledgement"
              >
                I acknowledge that a copy of this infringement notice, including
                any contact information provided above, may be provided to the
                person that posted the content being reported.
              </label>
            </div>

            <div className="py-3">
              <h4 className="fw-bold">Electronic Signature</h4>
            </div>

            <Row className="text-13">
              <Col sm="6" className="d-flex flex-column align-items-start">
                <PrimaryInput
                  label={"Requester Signature"}
                  isRequired
                  name="signature"
                  value={inputValues.signature}
                  handleChange={handleChange}
                />
              </Col>
            </Row>

            <div className="pt-3">
              <Button
                type="submit"
                color="primary"
                className="btn-submit py-2"
                disabled={loading}
              >
                Create DMCA
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
}

export default DMCAForm;
