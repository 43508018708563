import React from "react";
import { CardBody, Card } from "reactstrap";

export default function PaymentDetailComponent({ data }) {
    return (
        <Card>
            <CardBody>
                <div className="mb-0 font-weight-bold h5">Payment Detail</div>
                {
                    data?.total_line_items_price > 0 && (
                        <div className="d-flex justify-content-between my-4">
                            <div className="">Total Line Item Price:</div>
                            <div className="">${data?.total_line_items_price?.toFixed(2)}</div>
                        </div>
                    )
                }
                {
                    data?.shipping > 0 && (

                        <div className="d-flex justify-content-between my-4">
                            <div className="">Shipping:</div>
                            <div className="">${data?.shipping?.toFixed(2)}</div>
                        </div>)
                }
                {
                    data?.discount > 0 && (
                        <div className="d-flex justify-content-between my-4">
                            <div className="">Discount:</div>
                            <div className="">-${data?.discount?.toFixed(2)}</div>
                        </div>
                    )
                }
                {
                    data?.promotion > 0 && (
                        <div className="d-flex justify-content-between my-4">
                            <div className="">Promotion:</div>
                            <div className="">-${data?.promotion?.toFixed(2)}</div>
                        </div>
                    )
                }
                {
                    data?.subtotal_price > 0 && (
                        <div className="d-flex justify-content-between my-4">
                            <div className="">Subtotal:</div>
                            <div className="">${data?.subtotal_price?.toFixed(2)}</div>
                        </div>
                    )
                }
                {
                    data?.total_tax > 0 && (
                        <div className="d-flex justify-content-between my-4">
                            <div className="">Total Tax:</div>
                            <div className="">${parseFloat(data?.total_tax).toFixed(2)}</div>
                        </div>
                    )
                }
                {
                    data?.grand_total > 0 && (
                        <div className="d-flex justify-content-between my-4">
                            <div className="">Grand Total:</div>
                            <div className="">${data?.grand_total?.toFixed(2)}</div>
                        </div>
                    )
                }
                {
                    data?.wallet_amount > 0 && (
                        <div className="d-flex justify-content-between my-4">
                            <div className="">Wallet Amount:</div>
                            <div className="">-${data?.wallet_amount?.toFixed(2)}</div>
                        </div>
                    )
                }
                <div className="d-flex justify-content-between mt-4">
                    <div className="font-semi-bold">Paid By Customer:</div>
                    <div className="">${data?.paid_by_customer?.toFixed(2)}</div>
                </div>
                {
                    data?.wallet_refund_amount > 0 && (
                        <div className="d-flex justify-content-between my-4">
                            <div className="">Wallet Refund Amount:</div>
                            <div className="">-${data?.wallet_refund_amount?.toFixed(2)}</div>
                        </div>
                    )
                }
                {
                    data?.refund_amount > 0 && (
                        <div className="d-flex justify-content-between my-4">
                            <div className="">Refund Amount:</div>
                            <div className="">-${data?.refund_amount?.toFixed(2)}</div>
                        </div>
                    )
                }
                {
                    data?.tax_refund > 0 && (
                        <div className="d-flex justify-content-between my-4">
                            <div className="">Tax Refund:</div>
                            <div className="">-${data?.tax_refund}</div>
                        </div>
                    )
                }

            </CardBody>
        </Card>
    )
}
