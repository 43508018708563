import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getAllCoupons = (page,search) => {
    return getApi({
        url:`admin/coupons`,
        data: {
            is_admin: true,
            page:page || undefined,
            search: search || undefined
        }
    })
    .then(res => res.data)

}

export const deleteCoupon = (id) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/coupons/${id}`,
        method: "DELETE",
        data: {
            is_admin:true,
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
}

export const createCoupon = (coupon) =>{
    return axiosInstance({
        url: `${siteApiUrl}admin/coupons/create`,
        method: "POST",
        data: {
            ...coupon,
            is_admin:true,
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
}

export const getSingleCoupon = (id) => {
    return getApi({
        url:`admin/coupons/edit/${id}`,
        data: {
            is_admin: true,
        }
    })
    .then(res => res.data)
}

export const updateCoupon = (coupon,id) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/coupons/update/${id}`,
        method: "PUT",
        data: {
            ...coupon,
            is_admin:true,
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
}


export const getSellerGroups = (search) => {
    return getApi({
        url:`admin/coupons/getSellerGroups`,
        data: {
            is_admin: true,
            search:search || undefined
        }
    })
    .then(res => res.data)
}