import React from "react";
import PrimaryPopup from "./PrimaryPopup";
import PrimaryInput from "../PrimaryInput";
import { Button } from "reactstrap";

const ApprovePaymentPopup = ({
  isOpen,
  handleClick,
  handleChange,
  handleSubmit,
  uniqueInfo
}) => {
  return (
    <PrimaryPopup
      handleClick={handleClick}
      isScrollable={false}
      title={"Payout Method Approve"}
      isOpen={isOpen}
    >
      <PrimaryInput label={"Unique Info"} handleChange={handleChange} value={uniqueInfo} name={"unique_info"} />
      <div className="d-flex align-items-center justify-content-end mt-3">
        <Button
          color="primary"
          className="me-3"
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button onClick={handleClick}>
            Cancel
        </Button>
      </div>
    </PrimaryPopup>
  );
};

export default ApprovePaymentPopup;
