//  seo keywords paginations limit options

export const limitOptions = [
  {
    name: "Please select Limit",
    value: "",
  },
  {
    name: "50",
    value: 50,
  },
  {
    name: "100",
    value: 100,
  },
  {
    name: "500",
    value: 500,
  },
];

//   product count dropdown options

export const countOptions = [
  {
    name: "Please select product count",
    value: "",
  },
  {
    name: "=",
    value: "equal",
  },
  {
    name: "<",
    value: "smaller",
  },
  {
    name: ">",
    value: "greater",
  },
];
