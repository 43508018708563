import { WithContext as ReactTags } from 'react-tag-input';
import React from 'react';

const KeyCodes = {
	comma: 188,
	enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const TagsInputFields = ({
    label,
    isRequired,
    tags,
    handleDelete,
    autocomplete,
    handleAddition,
    placeholder,
    handleDrag
}) => {
	const myRef = React.createRef();
    return (
        <div>
            {
                label && (
                    <label className="form-label">
                        {label}
                        {isRequired && <span className="primary-text">*</span>}
                    </label>
                )
            }
            <div className='tags-form-group  p-2 rounded option-values' ref={myRef}>
                <ReactTags 
                    delimiters={delimiters}
                    tags={tags}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    autocomplete={autocomplete}
                    placeholder={placeholder}
                    handleDrag={handleDrag}
                />
            </div>


        </div>
    )
}

export default TagsInputFields;