import moment from "moment";
import { getApi } from "./BaseService";
export const getOrderNotifications = (page, start, end, search, type) => {
  return getApi({
    url: "admin/notifications/orders",
    data: {
      is_admin: true,
      search: search && search !== "" ? search : undefined,
      page: page || undefined,
      type: type || undefined,
      start_date: moment(start).isValid() ? start : undefined,
      end_date: moment(end).isValid() ? end : undefined,
    },
  }).then(res => res.data);
};

export const createOrderSuspicious = id => {
  return getApi({
    url: `admin/notifications/suspicious/${id}`,
    data: {
      is_admin: true,
    },
  }).then(res => res.data);
};

export const getSellerNotifications = (page, search, type, start, end) => {
  return getApi({
    url: "admin/notifications/sellers",
    data: {
      is_admin: true,
      page: page || undefined,
      search: search || undefined,
      type: type || undefined,
      start_date: moment(start).isValid() ? start : undefined,
      end_date: moment(end).isValid() ? end : undefined,
    },
  }).then(res => res.data);
};
