import React from 'react';
import PrimaryInput from 'components/Common/PrimaryInput';

function ProductGroupIdsForm({
    fields,
    handleChange
}) {
    return (
        <>
            <h4 className='font-semi-bold mb-3'>Group Information</h4>
            <div className='w-50'>
                <div className="mb-3">
                    <PrimaryInput
                        label={'Group Name'}
                        name="group"
                        type={"text"}
                        value={fields?.group || ""}
                        placeholder="Enter Group Name"
                        isRequired
                        handleChange={handleChange}
                    />
                </div>
                <div className="d-flex align-items-end w-100">
                    <PrimaryInput
                        label={"Product Ids"}
                        isRequired
                        name={"product_ids"}
                        type="text"
                        placeholder="Enter Product Ids"
                        value={fields?.product_ids || ""}
                        handleChange={handleChange}

                    />
                </div>
            </div>
        </>
    )
}

export default ProductGroupIdsForm;