export const ENABLE_BUTTON = 'ENABLE_BUTTON';
export const DISABLE_BUTTON = 'DISABLE_BUTTON';

export const handleDisableButton = (payload) => {
    return {
        type:DISABLE_BUTTON,
        payload
    }
};

export const handleEnableButton = (payload) => {
    return {
        type:ENABLE_BUTTON,
        payload
    }
};

