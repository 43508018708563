import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CardBody, Card, Button, Row, Col } from "reactstrap";
import TopHeading from "components/Common/TopHeading";
import PrimaryInput from "components/Common/PrimaryInput";
import { useParams } from "react-router-dom";
import { getSingleRole, updateRole, getAllPermissions } from "services/SettingsServices";
import PrograssPopup from "components/Common/Popups/ProgressPopup";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import PrimaryToast from "components/Common/Toast/ToastMessage";

function EditRole() {
	const [fields, setFields] = useState({});
	const [loading, setLoading] = useState(false);
	const [permissions, setPermisions] = useState([]);
	const [isChecked, setIsChecked] = useState(false);
	const [selectedIds, setSelectedIds] = useState([]);

	const { id } = useParams();
	const history = useNavigate();

	useEffect(()=>{
		setPageTitle("Edit Role - Inspire Uplift")
	},[]);

	useEffect(() => {
		setLoading(true);
		
		getSingleRole(id)
			.then(res => {
				setFields({
					guard_name: res?.data?.guard_name,
					role: res?.data?.name,

				});
				let ids = res?.data?.permissions?.map((item) => item.id);
				setSelectedIds(ids);
			})
			.catch(err => console.log(err))
			.finally(res => {

				getAllPermissions()
				.then(res => {
					setPermisions(res?.data)
				})
				.catch(err => console.log(err))
				.finally(res => {
					setLoading(false);
				})
			})
	}, [])



	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		const fieldsObj = { ...fields };
		fieldsObj[name] = value;
		setFields(fieldsObj);
	};

	const handleCheckboxChange = (event) => {
		const id = Number(event.target.value);
		const isChecked = event.target.checked;
	  
		if (isChecked) {
		  setSelectedIds([...selectedIds, id]);
		} else {
		  setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = {
			...fields,
			permission:[...selectedIds],
		};
		toast.dismiss();
		setLoading(true);
		updateRole(data, id)
			.then(res => {
				PrimaryToast({
					type:"success",
                    message:res?.data?.message,
				})
				history(-1);
			})
			.catch(err => {
				PrimaryToast({
					type:"error",
					message:Array.isArray(err?.response?.data?.message) ? err?.response?.data?.message[0] : err?.response?.data?.message,
				});
				console.log(err);
			})
			.finally(res => setLoading(false));
	};

	const handleSelectAll = () => {
		setIsChecked(!isChecked);
		if (!isChecked) {
		  const allIds = permissions.map((permission) => permission.id);
		  setSelectedIds(allIds);
		} else {
		  setSelectedIds([]);
		}
	};

	return (
		<div className="page-content">
			{
				loading && <PrograssPopup label={"Loading..."} />
			}
			<form onSubmit={handleSubmit}>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<TopHeading heading={"Edit Role"} />
					<div className="d-flex align-items-center">
						<Button onClick={() => history(-1)}>Cancel</Button>
						<Button color="primary" className="ms-4">
							Save
						</Button>
					</div>
				</div>
				<Card>
					<CardBody>
						<h4 className="font-semi-bold mb-3">Role Information</h4>
						<div className="d-flex mb-4">
							<div className="w-50">
								<PrimaryInput
									name="role"
									label={"Role Name"}
									value={fields?.role || ""}
									isRequired
									type={"text"}
									placeholder={"Enter Name"}
									handleChange={handleChange}
								/>
							</div>
						</div>

						<h4 className="font-semi-bold">Permissions</h4>
						<div>
							<div className="form-check mb-2">
								<input
									className="form-check-input"
									type="checkbox"
									id={`selectedAll`}
									checked={isChecked}
									onChange={handleSelectAll}
								/>
								<label
									className="form-check-label"
									htmlFor={"selectedAll"}
								>
									Select All
								</label>
							</div>
							<Row className="py-2">
								{permissions?.map((permission, index) => {

									return (
										<Col sm="3" key={index} className="mb-2">
											<div className="form-check">
												<input
													className="form-check-input"
													type="checkbox"
													value={permission.id}
													checked={selectedIds.includes(permission.id)}
													id={`${permission.id}`}
													onChange={handleCheckboxChange}
												/>
												<label
													className="form-check-label"
													htmlFor={permission.id}
												>
													{permission.name}
												</label>
											</div>
										</Col>
									);
								})}
							</Row>
						</div>
					</CardBody>
				</Card>
			</form>
		</div>
	);
}

export default EditRole;
