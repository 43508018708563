import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import PrimaryInput from "components/Common/PrimaryInput";
import SecondaryInput from "components/Common/SecondaryInput";
import { createGiftCard } from "services/GiftCardsService";
import PrograssPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function CreateGiftCards() {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({});


  useEffect(()=>{
		setPageTitle("Create Gift Card - Inspire Uplift")
	},[]);
  
  const history = useNavigate();

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const fieldsObj = { ...fields };
    fieldsObj[name] = value;
    setFields(fieldsObj);
  }

  const handleSubmit = e => {
    e.preventDefault();
    toast.dismiss();
    setLoading(true);
    createGiftCard(fields)
      .then(res => {
        if (res.status === 200) {
          history(-1)
        }
        PrimaryToast({ type: 'success', message: res?.data?.message });
      })
      .catch(err => {
        console.log(err)
        PrimaryToast({ type: 'error', message: Array.isArray(err?.response?.data?.message) ?   err?.response?.data?.message[0] : err?.response?.data?.message });
      })
      .finally(res => setLoading(false));
  }

  return (
    <div className="page-content">
      {
        loading && <PrograssPopup label={"Loading..."} />
      }
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center mb-3">

          <TopHeading heading={"Create Gift Card"} />

          <div className="d-flex align-items-center">
            <Button onClick={() => history(-1)}>Cancel</Button>
            <Button className="ms-4" color="primary">
              Create
            </Button>
          </div>
        </div>
        <Card>
          <CardBody>
            <div className="w-50">
              <h4 className="font-semi-bold">Gift Card Information</h4>
              <div className="mb-3">
                <PrimaryInput
                  label={'Recipient Name'}
                  name="recipient_name"
                  handleChange={handleChange}
                  value={fields?.recipient_name || ""}
                  type="text"
                  isRequired
                  placeholder="Enter Recipient name"
                />
              </div>
              <div className="mb-3">
                <PrimaryInput
                  label={'Recipient Email'}
                  name="recipient_email"
                  value={fields?.recipient_email || ''}
                  handleChange={handleChange}
                  type="email"
                  isRequired
                  placeholder="Enter Recipient Email"
                />
              </div>

              <div className="mb-3">
                <SecondaryInput
                  label={'Amount'}
                  name="amount"
                  value={fields?.amount || ""}
                  handleChange={handleChange}
                  type="text"
                  isRequired
                  placeholder="Enter Amount"
                  text={"$"}
                />

              </div>
              <div className="mb-3">
                <label
                  className="form-label"
                >
                  Reason / Additional Information
                </label>
                <textarea
                  className="form-control"
                  name="description"
                  value={fields?.description || ""}
                  onChange={handleChange}
                  rows="3"
                  placeholder="Enter Reason / Additional Information"
                ></textarea>
              </div>
            </div>
          </CardBody>
        </Card>
      </form>
    </div>
  );
}

export default CreateGiftCards;
