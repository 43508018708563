import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import PrimaryTable from "components/Common/PrimaryTable";
import { useSelector } from "react-redux";
import {
  deleteCampaignParticipants,
} from "services/CampaignsServices";
import deleteIcon from "assets/images/delete-icon-white.svg";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import PrimaryInput from "components/Common/PrimaryInput";
import PrimarySearch from "components/Common/PrimarySearch";


const CampaignParticipantsSection = ({ cId, setLoading, participantsData, pageCount, pageNo, setPageNo, getParticipants, storeIds, setStoreIds, handleStoreSearch, handleKeyPress, searchValue }) => {

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const user = useSelector(state => state.auth.user);

  const permissions = user?.permissions?.map(item => item.name);


  const columns = [
    {
      dataField: "id",
      text: "Seller ID",
      formatter: (cell, row) => <div className="">{row.id}</div>,
      width: "200px",
    },
    {
      dataField: "shop_name",
      text: "Store Name",
      formatter: (cell, row) => <div>{row.shop_name}</div>,
    },
    {
        dataField:"is_joined",
        text:"Status",
        formatter:(cell,row) => {
            let status;
            switch(row?.is_joined) {
                case 0 :
                    status = "Pending";
                    break;
                case 1 :
                    status = "Joined"   ;
                    break;
                default : 
                    status = "Pending"     
            };
            return (
                <div className={`status status w-max-content ${status == "Pending" ? "warning-status" : status == "Joined" ? "success-status":""}`}>{status}</div>
            )

        }
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return permissions?.includes("campaign-store-delete") ? (
          <div
            className="action-btn delete-btn-bg"
            onClick={() => {
              setSelectedId(row?.id);
              setShowDeleteModal(true);
            }}
          >
            <img src={deleteIcon} className="" alt="delete icon" />
          </div>
        ) : null;
      },
    },
  ];

  const data = participantsData?.map((item, index) => {
    return {
      ...item,
    };
  });

  const handleDelete = () => {
    let storesFilter = storeIds?.split(",").filter((storeId) => Number(storeId) != selectedId).join(",");
    setLoading(true);
    deleteCampaignParticipants(cId, selectedId)
      .then(res => {
        setSelectedId();
        setLoading(false);
        setShowDeleteModal(false);
        getParticipants(pageNo, searchValue);
        setStoreIds(storesFilter);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      });
  };

  return (
    <div>
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this store?"}
          onCancel={() => {
            setSelectedId();
            setShowDeleteModal(false);
          }}
          onConfirm={handleDelete}
        />
      )}
      <Card>
        <CardBody>
			<div className="d-flex justify-content-between align-items-center mb-4">
				<h2 className="h4">Campaign Participants</h2>
				<PrimarySearch  placeholder={"Search By Store Name"} value={searchValue} handleKeyPress={handleKeyPress} handleChange={handleStoreSearch} />
			</div>
          <PrimaryTable
            keyField="id"
            data={data}
            columns={columns}
            bordered={false}
          />
          {pageCount > 1 && (
            <PrimaryPagination
              pageNo={pageNo}
              pageCount={pageCount}
              setPageNo={setPageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default CampaignParticipantsSection;
