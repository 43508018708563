import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import ClearCacheComponent from "components/ClearCache/ClearCacheComponent";
import { getCachesData } from "services/CacheServices";
import PrograssPopup from "components/Common/Popups/ProgressPopup";
import { clearCustomCache } from "services/CacheServices";
import PrimaryToast from "components/Common/Toast/ToastMessage";

function Cache() {
  const [cacheData, setCacheData] = useState({});
  const [loading, setLoading] = useState(false);

  const [selectedCache, setSelectedCache] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedIndex, setSelectedIndex] = useState();

  useEffect(() => {
    setLoading(true);
    getCachesData()
      .then(res => {
        const groupedData = {};
        res?.data?.forEach(obj => {
          obj.type.forEach(type => {
            if (groupedData[type]) {
              groupedData[type].push(obj);
            } else {
              groupedData[type] = [obj];
            }
          });
        });
        setCacheData(res?.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(re => {
        setLoading(false);
      });
  }, []);

  const handleChange = (e, index) => {
    const value = e.target.value;
    const isChecked = e.target.checked;
    setInputValue("");

    if (isChecked) {
      setSelectedCache(value);
      setSelectedIndex(index);
    } else {
      setSelectedCache("");
      setSelectedIndex("");
    }
  };

  const handleInputChange = e => {
    const value = e.target.value;
    setInputValue(value);
  };

  const handleClearCache = () => {
    const mergedValue = selectedCache + inputValue;
    setLoading(true);
    clearCustomCache(mergedValue)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        setSelectedIndex("");
        setSelectedCache("");
        setInputValue("");
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => {
        setLoading(false);
      });
  };

  return (
    <div className="page-content">
      {loading && <PrograssPopup label={"Loading..."} />}
      <TopHeading heading={"Cache"} />
      <Card className="mt-4">
        <CardBody>
          <div className="d-flex justify-content-between align-items-start flex-wrap">
            <div className="w-50">
              {cacheData &&
                cacheData?.length > 0 &&
                cacheData?.map((cache, index) => {
                  if (cache?.property?.length > 0) {
                    return (
                      <div className="" key={index}>
                        <ClearCacheComponent
                          handleChange={e => handleChange(e, index)}
                          handleInputChange={handleInputChange}
                          key={index}
                          label={cache?.title}
                          value={cache?.key}
                          id={`${cache?.key}-${index}`}
                          tooltipContent={cache?.property || false}
                          selectedCache={selectedCache}
                          inputValue={inputValue}
                          pIndex={index}
                          selectedIndex={selectedIndex}
                        />
                      </div>
                    );
                  }
                })}
            </div>
            <div className="w-50">
              {cacheData &&
                cacheData?.length > 0 &&
                cacheData?.map((cache, index) => {
                  if (cache?.property?.length == 0) {
                    return (
                      <div className="" key={index}>
                        <ClearCacheComponent
                          handleChange={e => handleChange(e, index)}
                          handleInputChange={handleInputChange}
                          key={index}
                          label={cache?.title}
                          value={cache?.key}
                          id={`${cache?.key}-${index}`}
                          tooltipContent={cache?.property || false}
                          selectedCache={selectedCache}
                          inputValue={inputValue}
                          pIndex={index}
                          selectedIndex={selectedIndex}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button color="primary" onClick={handleClearCache}>
              Clear Cache
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default Cache;
