import React from 'react';
import PrimaryTable from 'components/Common/PrimaryTable';

function OrderDetailTable({ columns, data, heading, status,refundTotal, isPartial, refundReason }) {

    return (
        <div className='table-responsive'>
            <div className='d-flex align-items-center mb-4'>
                <div className="h5 mb-0 font-semi-bold">{heading}</div>
                {
                    status && (
                        <div className={`${status === "pending" ? "warning-status":status === "rejected" ? "danger-status" : status === "approved" ? "success-status" :""} ms-2 status`}>{status}</div>
                    )
                }
                {
                    isPartial && (
                        <div className={`${`danger-status`} ms-2 status`}>{isPartial}</div>
                    )
                }
            </div>
            {
                (refundReason && refundReason?.trim() !== "" ) ? (
                    <div className='p-3 mb-4 rounded' style={{backgroundColor:'#eff2f7'}}>
                        <span className='fw-bold'>Reason:</span> {refundReason}
                    </div>
                ) : null
            }
            <PrimaryTable
                keyField={"id"}
                columns={columns}
                data={data}
                responsive
                bordered={false}
            />
            {
                heading.includes("Refund")  && (
                    <div className='d-flex justify-content-end pb-3 border-bottom font-semi-bold'>
                        <div className=''>
                            Refund Amount:
                        </div>
                        <div className='ms-4'>
                            ${refundTotal?.toFixed(2)}
                        </div>
                    </div>
                )
            }


        </div>
    )
}

export default OrderDetailTable