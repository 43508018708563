import React, { useState, useEffect } from "react";
import SwitchButton from "components/Common/SwitchButton";
import { CardBody, Card, Button } from "reactstrap";
import TopHeading from "components/Common/TopHeading";
import { useNavigate } from "react-router-dom";
import { getSingleAppWellcome, updateAppWellcome } from "services/MobileAppServices";
import { useParams } from "react-router-dom";
import PrograssPopup from "components/Common/Popups/ProgressPopup";
import PrimaryInput from "components/Common/PrimaryInput";
import PrimaryImageUploader from "components/Common/PrimaryImageUploder";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function EditWelcome() {
	const history = useNavigate();
	const { id } = useParams();
	const [fields, setFields] = useState({
		is_hidden: true,
	});
	const [media, setMedia] = useState();
	const [loading, setLoading] = useState(false);

	useEffect(()=>{
		setPageTitle("Edit App Welcome - Inspire Uplift")
	},[]);


	useEffect(() => {
		setLoading(true);
		getSingleAppWellcome(id)
			.then(res => {
				setFields({
					...res.data,
					is_hidden: res.data.is_hidden === 0 ? true : false
				});
				setMedia({
					name:"image",
					preview:res.data.media,
				});
			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false));
	}, [])


	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.type == "checkbox" ? e.target.checked : e.target?.value;
		const fieldsObj = { ...fields };
		fieldsObj[name] = value;
		setFields(fieldsObj);
	};

	const handleSubmit = e => {
		e.preventDefault();
		let data = {
			...fields,
			media: media?.name !== "image" ? media : undefined,
		}
		toast.dismiss();
		setLoading(true);
		updateAppWellcome(data, id)
			.then(res => {
				history(-1);
				PrimaryToast({type:'success', message:res?.data?.message});
			})
			.catch(err =>{
				console.log(err)
				PrimaryToast({type:'error', message:err?.response?.data?.message[0]});
			})
			.finally(res => setLoading(false))
	}


	return (
		<div className="page-content">
			{
				loading && <PrograssPopup label={"Loading..."} />
			}
			<form onSubmit={handleSubmit}>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<TopHeading heading={"Edit App Welcome"} />
					<div className="d-flex align-items-center">
						<Button onClick={() => history(-1)}>Cancel</Button>
						<Button className="ms-2" color="primary">
							Save
						</Button>
					</div>
				</div>
				<Card>
					<CardBody className="w-50">
						<div className="mb-3">
							<PrimaryInput
								label={'Description'}
								isRequired
								handleChange={handleChange}
								name={'description'}
								value={fields?.description || ''}
								placeholder="Enter App Welcome Description"
							/>
						</div>
						<div className="mb-3">
							<div className="mb-3">
								<PrimaryImageUploader label={"Media Image"} isRequired setFiles={setMedia} getFiles={media} />
							</div>
						</div>
						<div>
							<label htmlFor="inputEmail4">Visibilty</label>
							<SwitchButton checked={fields.is_hidden === true ? true : false} label={fields.is_hidden === true ? "Show" : "Hide"} name="is_hidden" value={fields?.is_hidden} handleChange={handleChange} />
						</div>
					</CardBody>
				</Card>

			</form>
		</div>
	);
}

export default EditWelcome;
