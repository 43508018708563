import React, { useEffect, useState } from "react";
import TopHeading from "components/Common/TopHeading";
import {
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { downloadKeywordsCsv } from "services/SEOKeywordsServices";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { downloadFile } from "utils/downloadFile";
import { setPageTitle } from "utils/pageTitle";

export const SeoKeywords = () => {
  const [keywords, setKeywords] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    setPageTitle("SEO Keywords - Inspire Uplift");
  },[])

  const handleSubmit = async e => {
    e.preventDefault();
    const convertKeywordsToArr  = keywords.split(", ");
    setLoading(true);
    try {
        const response = await downloadKeywordsCsv(convertKeywordsToArr);
        const { headers, data } = response;
        setKeywords("");
		downloadFile(data, headers);
    }
    catch(err){
        console.log(err);
        PrimaryToast({type:"error", message:err.response.data.message});
    }
    finally{
      setLoading(false)
    }
    
  };

  return (
    <div className="page-content">
        {
            loading && <ProgressPopup label={"Loading..."} />
        }
      <TopHeading heading={"SEO Keywords"} />
      <Card className="mt-4">
        <CardBody>
          <Row>
            <Col xs="4">
              <Form onSubmit={handleSubmit}>
                <FormGroup className="d-flex">
                  <Input
                    type="text"
                    placeholder="Enter keyword"
                    value={keywords}
                    onChange={e => setKeywords(e.target.value)}
                  />
                  <Button color="primary" type="submit" disabled={loading || keywords.length == 0} className="ms-2">
                    Submit
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
