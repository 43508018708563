import React from "react";
import PrimaryPopup from "./PrimaryPopup";
import { Button } from "reactstrap";

const BanSellerApprovedPopup = ({ isOpen, handleClick, isApprovedBan, setIsApprovedBan, handleBanSellerPayout }) => {

  return (
    <PrimaryPopup isOpen={isOpen} title="Ban Seller Payout" handleClick={handleClick}>
      <p>
        This seller is temporarily banned. Are you sure you want to approve this
        withdraw?
      </p>
      <div className="form-check border-bottom pb-2">
        <input
          type="checkbox"
          className="form-check-input"
          id="approvePayoutCheckbox"
          checked={isApprovedBan}
          onChange={(e) => setIsApprovedBan(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="approvePayoutCheckbox">
          {" "}
          Is Approve
        </label>
      </div>
      <div className="d-flex justify-content-end mt-3" onClick={handleClick}>
        <Button>
            Cancel
        </Button>
        <Button className="ms-2" color="primary" disabled={!isApprovedBan} onClick={handleBanSellerPayout}>
            Save
        </Button>
      </div>
    </PrimaryPopup>
  );
};

export default BanSellerApprovedPopup;
