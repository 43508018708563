import React from "react";
import { Button } from "reactstrap";

import PrimaryPopup from "./PrimaryPopup";
import PrimaryInput from "../PrimaryInput";

export const ProductTagsAddPopup = (
    {
        isOpen,
        handleClose,
        handleAddTag,
        handleChange,
        tagFields
    }
) => {
  return (
    <PrimaryPopup
      isOpen={isOpen}
      isScrollable={false}
      title={"Add Tag"}
      handleClick={handleClose}
      className="d-flex justify-content-center align-items-center h-100 w-100"
    >
      <form onSubmit={handleAddTag}>
        <PrimaryInput 
            label={"Tag"}
            name={"tag"}
            type={"text"}
            handleChange={handleChange}
            placeholder={"Enter Tag"}
            value={tagFields?.tag}
        />
        <div className="mb-3 mt-3">
          <label htmlFor="product_ids" className="form-label">
            Product ID&lsquo;s
          </label>
          <textarea
            name="product_ids"
            className="form-control"
            id="product_ids"
            rows={3}
            placeholder={"Enter  Product Id's"}
            onChange={handleChange}
            value={tagFields?.product_ids}
          ></textarea>
        </div>
        {
          <div className="d-flex  justify-content-end">
            <Button onClick={handleClose}>
              Cancel
            </Button>
            <Button color="primary" className="ms-3" type="submit">
              Add
            </Button>
          </div>
        }
      </form>
    </PrimaryPopup>
  );
};
