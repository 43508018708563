import React from "react";
import PrimaryPopup from "./PrimaryPopup";
import { Button } from "reactstrap";

function ReviewWithdrawPopup({ isOpen, handleClose, handleChange, review, handleRequest, type, title, reviewStatus, loading }) {
  return (
    <PrimaryPopup
      isOpen={isOpen}
      handleClick={handleClose}
      title={title}
      isScrollable={false}
      className="d-flex justify-content-center align-items-center h-100 w-100"
    >
      <div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            {
              type === "payoneer" ? "Transaction ID" : "Review"
            }
          </label>
          <textarea
            name="review"
            value={review}
            className="form-control"
            id="exampleFormControlTextarea1"
            rows={type === "payoneer" ? 1 : 3}
            placeholder={type === "payoneer" ?  "Enter Transaction ID"  : "Write Seller Review"}
            onChange={handleChange}
          ></textarea>
        </div>
        {
          type === "payoneer" ? (
            <div className="d-flex justify-content-end">
              <Button className="me-2" color="primary" onClick={handleRequest} disabled={loading}>
                Update
              </Button>
            </div>
          ) : (
            <div className="d-flex  justify-content-end">
				{
					reviewStatus == 1 && (
						<Button className="me-2" color="danger" onClick={() => handleRequest(2)} disabled={loading}>
							Reject
					  	</Button>
					)
				}
				{
					reviewStatus == 2 && (
						<Button color="primary" onClick={() => handleRequest(1)}  disabled={loading}>Approve</Button>
					)
				}
				{
					reviewStatus == 0 && (
						<div className="d-flex">
							<Button className="me-2" color="danger" disabled={loading} onClick={() => handleRequest(2)}>
								Reject
					  		</Button>
							<Button color="primary" onClick={() => handleRequest(1)} disabled={loading}>Approve</Button>
						</div>
					)
				}
            </div>
          )
        }
      </div>
    </PrimaryPopup>
  );
}

export default ReviewWithdrawPopup;
