export const LOAD_USER = "LOAD_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const loadUser = (payload) =>{
    return {
        type:LOAD_USER,
        payload
    }
};

export const loginReducer = (payload) => {
    return {
        type:LOGIN_SUCCESS,
        payload
    }
}

