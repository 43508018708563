import React, { useState, useEffect, useMemo } from "react";
import TopHeading from "components/Common/TopHeading";
import { Card, CardBody, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import PrimaryTable from "components/Common/PrimaryTable";
import tickIcon from "assets/images/tick-icon.svg";
import { getMyTasks, comoeleteTask } from "services/TaskServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PlusIcon from "../../../assets/images/plus-icon.svg";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import editIcon from "../../../assets/images/edit-icon-white.svg";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { deleteTask } from "services/TaskServices";
import { useSelector } from "react-redux";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";
import PrimarySearch from "components/Common/PrimarySearch";
import { getAllEmployees } from "services/TaskServices";
import Select from "react-select";
import ResetButton from "components/Common/ResetButton";
import moment from "moment";

const customStyles = {
  control: provided => ({
    ...provided,
    borderRadius: "999px",
  }),
};

const MyTasks = () => {
  const history = useNavigate();
  const [myTaskList, setMyTaskList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [selectedId, setSelectedId] = useState("");
  const [popupType, setPopupType] = useState("");
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [options, setOptions] = useState();
  const [assignedEmployee, setAssignedEmployee] = useState([]);
  const params = new URLSearchParams(window?.location?.search);
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    setPageTitle("My Tasks - Inspire Uplift");
  }, []);

  const getEmployees = async () => {
    try {
      const res = await getAllEmployees();
      const customTypes = res?.data?.map(item => ({
        label: item.name,
        value: item.id,
      }));
      setOptions(customTypes);
      return customTypes; // Return the fetched options
    } catch (error) {
      console.log(error);
    }
  };

  const getOptions = useMemo(() => {
    return getEmployees();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const employees = await getOptions;
      const page = params?.get("page") || undefined;
      setPageNo(page || 1);
      const searchParam = params?.get("search") || undefined;
      setSearch(searchParam || '');
      const statusParam = params?.get("status") || undefined;
      setStatus(statusParam || " ");
      const assignedParam = params?.get("assigned_by") || undefined;
      let findEmployee;
      if (assignedParam) {
        findEmployee = employees?.find(item => item.label == assignedParam);
        setAssignedEmployee(findEmployee);
      }
      else {
        setAssignedEmployee([]);
      }
      setLoading(true);
      getMyTasks(page, searchParam, statusParam, findEmployee?.value)
        .then(res => {
          setMyTaskList(res?.data?.data);
          setPageCount(res?.data?.last_page);
        })
        .catch(err => console.log(err))
        .finally(res => setLoading(false));
    };
    fetchData();
  }, [window?.location?.search]);

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>
      ),
    },
    {
      dataField: "title",
      text: "Title",
      formatter: (cell, row) => (
        <div className="font-semi-bold">
          {row.title?.length >= 50
            ? `${row?.title?.slice(0, 50)}...`
            : row.title}
        </div>
      ),
    },
    {
      dataField: "assigned_by",
      text: "Assigned By",
      formatter: (cell, row) => {
        return <div>{row?.assigned_by?.name}</div>;
      },
    },
    {
      dataField: "assigned_to",
      text: "Assigned To",
      formatter: (cell, row) => {
        let assignedTask = row?.assigned_to?.map((item, index) => (
          <div key={index}>
            {item.name} {index === row?.assigned_to?.length - 1 ? "" : ", "}
          </div>
        ));
        return <div>{assignedTask}</div>;
      },
    },
    {
      dataField: "priority",
      text: "Priority",
      formatter: (cell, row) => (
        <div
          className={`status ${
            row.priority === "low"
              ? "success-status"
              : row.priority === "medium"
              ? "warning-status"
              : row.priority === "high"
              ? "danger-status"
              : ""
          } w-max-content`}
        >
          {row.priority}
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => (
        <div
          className={`status ${
            row.status == "completed"
              ? "success-status"
              : row.status === "pending"
              ? "warning-status"
              : ""
          }  w-max-content`}
        >
          {row.status}
        </div>
      ),
    },
    {
      dataField: "created_on",
      text: "Created On",
      formatter: (cell, row) => (
        <div>{(moment(row?.created_on).format("DD-MM-YYYY hh:mm:ss a"))}</div>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex  align-items-center">
          {user?.name === row?.assigned_by?.name &&
            row.status !== "completed" && (
              <div
                className="action-btn approve-btn-bg me-1"
                onClick={e => {
                  e.stopPropagation();
                  setShowDeleteModal(true);
                  setPopupType("complete");
                  setSelectedId(row.id);
                }}
              >
                <img src={tickIcon} className="" alt="tick icon" />
              </div>
            )}
          {user?.name === row?.assigned_by?.name &&
            row.status !== "completed" && (
              <div
                className="action-btn edit-icon-bg me-1 cursor-pointer"
                onClick={e => {
                  e.stopPropagation();
                  history(`/employees/my-task/edit/${row.id}`);
                }}
              >
                <img src={editIcon} className="" alt="edit icon" />
              </div>
            )}
          {user?.name === row?.assigned_by?.name && (
            <div
              className="action-btn delete-btn-bg cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                setShowDeleteModal(true);
                setPopupType("delete");
                setSelectedId(row.id);
              }}
            >
              <img src={deleteIcon} className="" alt="delete icon" />
            </div>
          )}
        </div>
      ),
    },
  ];

  const data = myTaskList?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleDelete = () => {
    let ids = selectedId ? [selectedId] : selectedRowKeys;
    let filterArr;
    if (selectedId) {
      filterArr = myTaskList?.filter(item => item.id !== selectedId);
    } else {
      filterArr = myTaskList?.filter(
        item => !selectedRowKeys.includes(item.id)
      );
    }
    setLoading(true);
    deleteTask(ids)
      .then(res => {
        if (pageNo > 1 && filterArr.length === 0) {
          params.set("page", pageNo - 1);
          let newUrl = `${window?.location?.pathname}?${params.toString()}`;
          history(newUrl, { replace: true });
        } else {
          setMyTaskList(filterArr);
        }
        setSelectedRowKeys([]);
        setSelectedId("");
        PrimaryToast({ type: "success", message: res?.data?.message });
        setShowDeleteModal(false);
        setPopupType("");
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => setLoading(false));
  };

  const handleOnSelectAll = (isSelected, rows) => {
    if (isSelected) {
      setSelectedRowKeys(rows.map(row => row.id));
    } else {
      setSelectedRowKeys([]);
    }
  };

  const nonSelected = myTaskList?.map(item => {
    if (
      item.status === "completed" ||
      item.assigned_to?.some(item => item.name == user?.name)
    ) {
      return item.id;
    }
  });

  const selectRow = {
    mode: "checkbox",
    onSelectAll: handleOnSelectAll,
    nonSelectable: nonSelected,
    nonSelectableClasses: "hide-checkbox",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedRowKeys([...selectedRowKeys, row.id]);
      } else {
        setSelectedRowKeys(selectedRowKeys.filter(key => key !== row.id));
      }
    },
    selected: selectedRowKeys,
  };

  const handleComplete = () => {
    let ids = selectedId ? [selectedId] : selectedRowKeys;
    let filterArr;
    if (selectedId) {
      filterArr = myTaskList?.filter(item => item.id !== selectedId);
    } else {
      filterArr = myTaskList?.filter(
        item => !selectedRowKeys.includes(item.id)
      );
    }
    setLoading(true);
    comoeleteTask(ids)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.messgae });
        setSelectedRowKeys([]);
        setSelectedId("");
        setPopupType("");
        setShowDeleteModal(false);
        getMyTasks(pageNo, search, status, assignedEmployee)
          .then(res => {
            if (pageNo > 1 && filterArr.length === 0) {
                params.set("page", pageNo - 1);
                let newUrl = `${window?.location?.pathname}?${params.toString()}`;
                navigate(newUrl, { replace: true });
              } else {
                setMyTaskList(res?.data?.data);
                setPageCount(res?.data?.last_page);
            }
        
          })
          .catch(err => console.log(err))
		  .finally(res =>    setLoading(false));
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.messgae)
            ? err?.response?.data?.messgae[0]
            : err?.response?.data?.messgae,
        });
      })
  };

  const handleFilter = () => {
    if (search) {
      params.set("search", search);
    } else {
      params.delete("search");
    }

    if (status) {
      params.set("status", status);
    } else {
      params.delete("status");
    }

    if (assignedEmployee?.value) {
      params.set("assigned_by", assignedEmployee?.label);
    } else {
      params.delete("assigned_by");
    }
    params.delete("page");
    history(`?${params.toString()}`);
  };

  const handleSelectChange = item => setAssignedEmployee(item);

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      {showDeleteModal && (
        <DeleteModal
          title={
            popupType === "delete"
              ? "Are you sure you want to delete this task?"
              : "Are you sure you want to complete this task?"
          }
          onConfirm={
            popupType === "delete"
              ? () => handleDelete()
              : () => handleComplete()
          }
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
            setPopupType("");
          }}
        />
      )}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <TopHeading heading={"My Tasks"} />
        <Button
          color="primary"
          className="d-flex align-items-center"
          onClick={() => history("/employees/my-task/create")}
        >
          <img src={PlusIcon} className="me-2" alt="cretae employee" />
          Create new Task
        </Button>
      </div>
      <Card className="mt-3">
        <CardBody>
          <div className="mb-4 d-flex">
            <div className="">
              <PrimarySearch
                placeholder={"search by title"}
                value={search || ""}
                handleChange={e => setSearch(e.target.value)}
                handleKeyPress={e => {
                  if (e.key == "Enter") {
                    handleFilter();
                  }
                }}
              />
            </div>
            <div style={{ minWidth: "250px", marginLeft: "12px" }}>
              <Select
                isMulti={false}
                options={options}
                onChange={handleSelectChange}
                value={assignedEmployee}
                name={"payout_method"}
                className="react-select-container"
                styles={customStyles}
                placeholder={"Search by assigned by"}
              />
            </div>

            <div className="">
              <select
                className="primary-select-option ms-2"
                name="status"
                value={status || ""}
                onKeyPress={e => {
                  if (e.key == "Enter") {
                    handleFilter();
                  }
                }}
                onChange={e => {
                  setStatus(e.target.value);
                }}
              >
                <option value={""}>Search By Status</option>
                <option value="pending"> Pending </option>
                <option value="complete"> Complete </option>
              </select>
            </div>
            <Button
              className="rounded-full me-2 ms-2"
              color="primary"
              onClick={handleFilter}
            >
              Apply Filter
            </Button>
            {params?.toString()?.length > 0 && (
              <ResetButton />
            )}
          </div>
          {selectedRowKeys?.length > 0 && (
            <div className="d-flex align-items-center justify-content-end ">
              <Button
                className="rounded-full me-2 cursor-pointer"
                color="primary"
                onClick={() => {
                  setShowDeleteModal(true);
                  setPopupType("complete");
                }}
              >
                <img src={tickIcon} alt="tick icon" className="pe-2" />
                Mark as Complete
              </Button>
            </div>
          )}
          <div className="mt-3">
            <PrimaryTable
              columns={columns}
              data={data}
              responsive
              bordered={false}
              scrollable
              keyField="id"
              wrapperClasses="table-responsive"
              selectRow={selectRow}
              isPermission={true}
              rowUrl={"/employees/my-task"}
            />
            {myTaskList?.length > 0 && pageCount > 1 && (
              <PrimaryPagination
                pageCount={pageCount}
                pageNo={pageNo}
                setPageNo={setPageNo}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
export default MyTasks;
