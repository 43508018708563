import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TopHeading from "components/Common/TopHeading";
import { CardBody, Card, Button } from "reactstrap";
import SwitchButton from "components/Common/SwitchButton";
import SecondaryInput from "components/Common/SecondaryInput";
import PrimaryInput from "components/Common/PrimaryInput";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { getSingleGiftCard, updateGiftCard } from "services/GiftCardsService";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function EditGiftCard() {
	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState({});

	const { id } = useParams();
	const history = useNavigate();

	useEffect(()=>{
		setPageTitle("Edit Gift Card - Inspire Uplift")
	},[]);

	useEffect(() => {
		setLoading(true);
		getSingleGiftCard(id)
			.then(res => {
				console.log(res);
				setFields({
					...res.data[0],
					is_active: res.data[0]?.is_active === 1 ? true : false,
					inital_amount:res?.data[0]?.amount,
				});
			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false));


	}, []);

	const handleChange = e => {
		const name = e.target.name;
		const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
		const fieldsObj = { ...fields };
		fieldsObj[name] = value;
		setFields(fieldsObj);
	};

	const handleSubmit = e => {
		e.preventDefault();
		toast.dismiss();
		setLoading(true)
		updateGiftCard(fields,id)
			.then(res => {
				history(-1);
				PrimaryToast({type:'success', message:res?.data?.message});
			})
			.catch(err =>{
				console.log(err)
				PrimaryToast({type:'error', message:Array.isArray(err?.response?.data?.message) ?   err?.response?.data?.message[0] : err?.response?.data?.message });
			})
			.finally(res => setLoading(false));
	};

	return (
		<div className="page-content">
			{
				loading && <ProgressPopup label={"Loading..."} />
			}
			<form onSubmit={handleSubmit}>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<TopHeading heading={"Edit Gift Card"} />
					<div className="d-flex align-items-center">
						<Button onClick={() => history(-1)}>Cancel</Button>
						<Button className="ms-4" color="primary">
							Save
						</Button>
					</div>
				</div>
				<Card>
					<CardBody>
						<div className="w-50">
							<h4 className="font-semi-bold">Gift Card Information</h4>
							<div className="mb-3">
								<PrimaryInput
									name="recipient_name"
									label={'Recipient Name'}
									placeholder="Enter Recipient name"
									value={fields?.recipient_name || ''}
									handleChange={handleChange}
									type="text"
									isRequired
								/>
							</div>
							<div className="mb-3">
								<PrimaryInput
									name="recipient_email"
									label={'Recipient Email'}
									handleChange={handleChange}
									placeholder="Enter Recipient Email"
									value={fields?.recipient_email || ""}
									type="email"
									isRequired
								/>
							</div>
							<div className="mb-3">
								<SecondaryInput
									name="inital_amount"
									label={'Initial Amount'}
									placeholder="Enter Amount"
									value={fields?.inital_amount?.toString() || ""}
									type="text"
									isDisabled
									text="$"
								/>
							</div>
							<div className="mb-3">
								<SecondaryInput
									name="balance"
									label={'Balance'}
									placeholder="Enter Balance"
									type="text"
									value={fields?.balance?.toString() || ""}
									text="$"
									isDisabled
								/>
							</div>
							<div className="mb-2">
								<label htmlFor="inputEmail4">Availability</label>
								<SwitchButton checked={fields.is_active === true ? true : false} label={fields.is_active === true ? "Active" : "Inactive"} name="is_active" value={fields?.is_active} handleChange={handleChange} />
							</div>

							<div className="mb-3">
								<label

									className="form-label"
								>
									Reason / Additional Information
								</label>
								<textarea
									className="form-control"
									name="description"
									onChange={handleChange}
									value={fields?.description || ""}
									rows="3"
									placeholder="Enter Reason / Additional Information"
								></textarea>
							</div>
						</div>
					</CardBody>
				</Card>
			</form>
		</div>
	);
}

export default EditGiftCard;
