import React, { useState, useEffect } from 'react';
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import PrimaryInput from "components/Common/PrimaryInput";
import SwitchButton from "components/Common/SwitchButton";
import { useNavigate } from "react-router-dom";
import PrograssPopup from "components/Common/Popups/ProgressPopup";
import { createMarkeplaceFeed } from 'services/MarketPlaceFeedService';
import SecondarySelect from 'components/Common/SecondarySelect';
import PrimaryToast from 'components/Common/Toast/ToastMessage';
import { toast } from 'react-toastify';
import { setPageTitle } from 'utils/pageTitle';

function CreateMarketplaceFeed() {
    const [selelctedType, setSelectedType] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useNavigate();

    const [fields, setFields] = useState({
        is_active: true,
    });

    useEffect(()=>{
		setPageTitle("Create Marketplace Feed - Inspire Uplift")
	},[]);





    const handleChange = e => {
        const name = e.target.name;
        const value = e.target?.type === "checkbox" ? e.target.checked : e.target.value;
        const fieldsObj = { ...fields };
        fieldsObj[name] = value;
        setFields(fieldsObj);
    };

    const handleSelect = (selected, name) => {
        if (name == "type") {
            setSelectedType(selected);
        }
        if (name === "seller_group") {
            setSelectedGroups(selected);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let types = selelctedType?.map(item => item.value);
        let selectedSellerGroups = selectedGroups?.map(item => item.value);

        let data = {
            ...fields,
            group_ids: selectedSellerGroups,
            type: types,
        };
        toast.dismiss();
        setLoading(true);
        createMarkeplaceFeed(data)
            .then(res => {
                history(-1)
                PrimaryToast({ type: 'success', message: res?.data?.message });
            })
            .catch(err => {
                console.log(err)
                PrimaryToast({ type: 'error', message: err?.response?.data?.message[0] });
            })
            .finally(res => {
                setLoading(false);
            })
    }

    return (
        <div className='page-content'>
            {
                loading && <PrograssPopup label={"Loading..."} />
            }
            <form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <TopHeading heading={"Create Marketplace Feed"} />
                    <div className="d-flex">
                        <Button className="me-2" onClick={() => history(-1)}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit">
                            Create
                        </Button>
                    </div>
                </div>
                <Card className='w-50'>
                    <CardBody>
                        <div className="mb-3">
                            <PrimaryInput
                                label={"Name"}
                                isRequired
                                name="name"
                                placeholder={'Enter Feed Name'}
                                value={fields?.name || ""}
                                handleChange={handleChange}

                            />
                        </div>
                        <div className='mb-3'>
                            <SecondarySelect
                                label={"Type"}
                                name={"type"}
                                isRequired
                                path="marketplace-feed"
                                handleSelect={(option) => handleSelect(option, "type")}
                                value={selelctedType}
                                isMulti={true}
                                hideSelectedOptions={false}

                            />

                        </div>
                        <div className='mb-3'>
                            <SecondarySelect
                                label={"Seller Groups"}
                                name={"seller_group"}
                                isRequired
                                options={options}
                                handleSelect={(option) => handleSelect(option, "seller_group")}
                                value={selectedGroups}
                                isMulti={true}
                                hideSelectedOptions={false}

                            />

                        </div>
                        <div className="">
                            <label htmlFor="inputEmail4">Status<span className="primary-text">*</span></label>
                            <SwitchButton checked={fields.is_active === true ? true : false} label={fields.is_active === true ? "Active" : "Inactive"} name="is_active" value={fields?.is_active} handleChange={handleChange} />
                        </div>
                    </CardBody>
                </Card>

            </form>

        </div>
    )
}

export default CreateMarketplaceFeed;