import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getSecretKey = () => {
    return getApi({
        url: 'admin/2fa',
        data: {
            is_admin: true,
        }
    }
    ).then(res => res.data)
};

export const generateSecretKey = () => {
    return axiosInstance({
        method: "POST",
        data: {
            is_admin:true,
        },
		withCredentials:true,
        url: `${siteApiUrl}admin/2fa/generateSecret`,
        headers: getHttpHeaders(),
    });
};

export const enable2FA = (secret) => {
    return axiosInstance({
        method: "POST",
        data: {
            is_admin:true,
            secret,
        },
		withCredentials:true,
        url: `${siteApiUrl}admin/2fa/enable2fa`,
        headers: getHttpHeaders(),
    });
};


export const disable2FA = (fields) => {
    return axiosInstance({
        method: "POST",
        data: {
            is_admin:true,
            ...fields,
        },
		withCredentials:true,
        url: `${siteApiUrl}admin/2fa/disable2fa`,
        headers: getHttpHeaders(),
    });
};

export const deleteSecretKey = () => {
    return axiosInstance({
        method: "DELETE",
        data: {
            is_admin:true,
        },
		withCredentials:true,
        url: `${siteApiUrl}admin/2fa/delete-key`,
        headers: getHttpHeaders(),
    });
};