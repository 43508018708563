import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getAllSellersKYCRecords = (page, seller_name, statusValue) => {
    let status;
    switch (statusValue) {
        case "Approved":
            status = 1;
            break;
        case "Rejected":
            status = 2;
            break;
        case "Submitted":
            status = 3;
            break;
        default:
            status = undefined;
    }

    return getApi({
        url: 'admin/seller/kyc',
        data: {
            is_admin: true,
            page: page || 1,
            seller_name: seller_name || undefined,
            status: status || undefined,
        }
    }).then(res => res.data);
};


export const getSingleSellerKYCRecord = (id) => {
    return getApi({
        url: `admin/seller/kyc/${id}`,
        data: {
            is_admin: true,
        }
    }).then(res => res.data)
};



export const setKYCStatus = (id, actionType, reason) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seller/kyc/${actionType}/${id}`,
        withCredentials: true,
        data: {
            is_admin: true,
            reason: reason || undefined,
        },
        headers: getHttpHeaders(),
    });
};
