import React from "react";
import PrimaryInput from "components/Common/PrimaryInput";
import { Button, Row, Col } from "reactstrap";
import PrimarySelect from "components/Common/PrimarySelect";

export default function HyperwalletUser({
  hyperwalletFields,
  handleUpdateHyperwalletUser,
  handleHyperwalletChange,
  isProvinceText,
  sellerId,
  countries,
  states,
  loading
}) {

  return (
    <div className="mt-4 w-50">
      <h4 className="font-semi-bold mb-3">HyperWallet User</h4>
      {hyperwalletFields?.profileType?.toLowerCase() !== "business" && (
        <Row className="mb-3">
          <Col className="form-group col-md-6">
            <PrimaryInput
              name={"firstName"}
              label={"First Name"}
              placeholder="Enter First Name"
              type={"text"}
              isRequired
              value={hyperwalletFields?.firstName || ""}
              handleChange={handleHyperwalletChange}
              maxLength={50}
            />
          </Col>
          <Col className="form-group col-md-6">
            <PrimaryInput
              name={"lastName"}
              label={"Last Name"}
              placeholder="Enter First Name"
              type={"text"}
              value={hyperwalletFields?.lastName || ""}
              isRequired
              handleChange={handleHyperwalletChange}
              maxLength={50}
            />
          </Col>
        </Row>
      )}
      <div className="mb-3">
        <PrimaryInput
          name={"email"}
          label={"Email"}
          placeholder="Enter Email"
          type={"email"}
          isRequired
          value={hyperwalletFields?.email || ""}
          handleChange={handleHyperwalletChange}
        />
      </div>
      {hyperwalletFields?.businessName ? (
        <div className="mb-3">
          <PrimaryInput
            name={"businessName"}
            label={"Business Name"}
            placeholder="Enter Business Name"
            type={"text"}
            isRequired
            value={hyperwalletFields?.businessName || ""}
            handleChange={handleHyperwalletChange}
          />
        </div>
      ) : null}

      <div className="mb-3">
        <PrimaryInput
          name={"dateOfBirth"}
          label={"Date of Birth"}
          placeholder="Enter Date of birth(yyyy-mm-dd)"
          type={"text"}
          isRequired
          value={hyperwalletFields?.dateOfBirth || ""}
          handleChange={handleHyperwalletChange}
        />
      </div>
      <div className="mb-3">
        <PrimaryInput
          name={"addressLine1"}
          label={"Address"}
          placeholder="Enter Address"
          type={"text"}
          isRequired
          value={hyperwalletFields?.addressLine1 || ""}
          handleChange={handleHyperwalletChange}
        />
      </div>
      <div className="mb-3">
        <PrimaryInput
          name={"city"}
          label={"City"}
          placeholder="Enter City"
          type={"text"}
          isRequired
          value={hyperwalletFields?.city || ""}
          handleChange={handleHyperwalletChange}
        />
      </div>
      <div className="mb-3">
        <PrimarySelect
          label={"Country"}
          name="country"
          isRequired
          handleChange={handleHyperwalletChange}
          value={hyperwalletFields?.country || ""}
          options={countries || []}
          path="country"
        />
      </div>
      {!isProvinceText ? (
        <div className="mb-3">
          <PrimarySelect
            label={"State / Province"}
            name="stateProvince"
            isRequired
            handleChange={handleHyperwalletChange}
            value={hyperwalletFields?.stateProvince || ""}
            options={states || []}
            path="state"
          />
        </div>
      ) : (
        <div className="mb-3">
          <PrimaryInput
            name={"stateProvince"}
            label={"State / Province"}
            placeholder="Enter State Code"
            type={"text"}
            isRequired
            value={hyperwalletFields?.stateProvince || ""}
            handleChange={handleHyperwalletChange}
          />
        </div>
      )}
      <div className="mb-3">
        <PrimaryInput
          name={"postalCode"}
          label={"Zip"}
          type={"text"}
          isRequired
          value={hyperwalletFields?.postalCode || ""}
          placeholder="Enter postal code"
          handleChange={handleHyperwalletChange}
        />
      </div>
      {hyperwalletFields?.profileType?.toLowerCase() === "individual" && (
        <div className="mb-3">
          <PrimaryInput
            label={"Nationality"}
            isRequired
            name="countryOfNationality"
            placeholder="Enter Nationality"
            value={hyperwalletFields?.countryOfNationality || ""}
            handleChange={handleHyperwalletChange}
          />
        </div>
      )}

      <div className="mb-3">
        <PrimarySelect
          label={"Business Entity"}
          name="profileType"
          isRequired
          handleChange={handleHyperwalletChange}
          value={hyperwalletFields?.profileType?.toLowerCase()}
        //   isDisabled={true}
          options={[
            {
              name: "Individual",
              value: "individual",
            },
            {
              name: "Business",
              value: "business",
            },
          ]}
        />
      </div>
      <div className="d-flex justify-content-end">
        <Button
          className="me-4"
          onClick={() => {
            window?.open(`/seller/stores/${sellerId}/hyperwallet-info`);
          }}
        >
          More Info
        </Button>
        <Button disabled={loading} color="primary" onClick={handleUpdateHyperwalletUser}>
          Save
        </Button>
      </div>
    </div>
  );
}
