import React from "react";
import { Col, Row } from "reactstrap";
import DashboardCountCard from "components/Common/Cards/DashboardCountCard";
import WelcomeComp from "components/Common/Cards/WelcomeComp";
import conversionIcon from "assets/images/conversion-rate.svg";
import avgOrderIcon from "assets/images/avg-order.svg";
import avgRevenueIcon from "assets/images/avg-revenue.svg";

const DashBoardCardsSection = ({ data }) => {
    let conversionRate = (data?.orders_total / data?.sessions) * 100 || 0;
    let averageOrderRate = ( (data?.sales_total ? parseFloat(data?.sales_total) : 0 )/  data?.orders_total ) || 0;
    let averageRevenuePerPaying = ((data?.sales_total ? parseFloat(data?.sales_total) : 0 ) /  data?.sessions ) || 0;
  return (
    <div>
      <Row>
        <Col md="3">
          <WelcomeComp />
        </Col>
        <DashboardCountCard
          title="Sessions"
          amount={Number(data?.sessions) || 0}
        />
        <DashboardCountCard
          title="Orders"
          amount={Number(data?.orders_total) || 0}
        />
        <DashboardCountCard
          title="Revenue"
          amount={`$${
            data?.sales_total ? Number(data?.sales_total)?.toFixed(2) : 0
          }`}
        />
      </Row>
      <Row className="mt-3">
        <DashboardCountCard
          title="Conversion Rate"
          amount={conversionRate?.toFixed(2)}
          iconPath={conversionIcon}
        />
        <DashboardCountCard
          title="Avg Order Value"
          amount={`$${averageOrderRate?.toFixed(2)}`}
          iconPath={avgOrderIcon}
        />
        <DashboardCountCard
          title="ARPPU"
          amount={`$${averageRevenuePerPaying?.toFixed(2)}`}
          iconPath={avgRevenueIcon}
        />
      </Row>
    </div>
  );
};

export default DashBoardCardsSection;
