import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";

import TopHeading from "components/Common/TopHeading";
import PrimaryInput from "components/Common/PrimaryInput";
import { getAllFiltersCategories } from "services/SEOKeywordsServices";
import PrimarySelect from "components/Common/PrimarySelect";
import SwitchButton from "components/Common/SwitchButton";

const CreatedGroupForm = ({
  fields,
  setFields,
  handleChange,
  handleSubmit,
  loading,
  path,
}) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getAllFiltersCategories()
      .then(res => {
        const categoriesList = [
          ...res.map(item => {
            return {
              label: item.name,
              value: item.id,
            };
          }),
        ];
        setCategories(categoriesList);
      })
      .catch(err => console.log(err));
  }, []);

  let categoriesValue = categories?.filter(item =>
    fields?.category_ids?.includes(item.value)
  );

  return (
    <form onSubmit={handleSubmit}>
      <section className="d-flex justify-content-between align-items-center mb-4">
        <TopHeading
          heading={`${path == "create" ? "Create" : "Edit"}  Related Group`}
        />
        <div>
          <Button onClick={() => navigate("/seo-keywords/related-groups")}>
            Cancel
          </Button>
          <Button
            className="ms-3"
            color="primary"
            disabled={loading || Object.keys(fields)?.length == 0}
          >
            {path == "create" ? "Create" : "Save"}
          </Button>
        </div>
      </section>
      <section>
        <Card>
          <CardBody className="w-50">
            <div className="mb-4">
              <PrimaryInput
                name={"name"}
                label={"Title"}
                isRequired={true}
                value={fields?.name || ""}
                handleChange={handleChange}
                placeholder={"Enter Related Group Title"}
              />
            </div>
            <div className="mb-3">
              <PrimarySelect
                label={"Type"}
                name={"type"}
                isRequired={true}
                value={fields?.type || ""}
                handleChange={handleChange}
                options={[
                  { name: "Please select one", value: "" },
                  { name: "Category", value: "category" },
                  { name: "Collection", value: "collection" },
                ]}
              />
            </div>
            {fields?.type == "category" || fields?.type == "collection" ? (
              <div className="mb-3">
                <PrimaryInput
                  label={`${fields?.type} ID`}
                  labelClassName={"text-capitalize"}
                  name={"_id"}
                  isRequired={true}
                  value={fields?._id}
                  handleChange={handleChange}
                  placeholder={`Enter ${fields?.type} id`}
                />
              </div>
            ) : null}

            <div className="mb-3" style={{ minWidth: "200px" }}>
              <label htmlFor="inputEmail4">
                Product Categories<span className="primary-text">*</span>
              </label>
              <Select
                isMulti={true}
                options={categories}
                onChange={selectedItems => {
                  const selectedIds = selectedItems.map(item => item.value);
                  setFields({
                    ...fields,
                    category_ids: selectedIds,
                  });
                }}
                value={categoriesValue}
                name={"parent_id"}
                className="react-select-container"
                placeholder={"Please select categories"}
              />
            </div>
            <div className="mb-3" style={{ minWidth: "200px" }}>
              <PrimaryInput
                name={"collection_ids"}
                label={"Product Collections"}
                isRequired={true}
                value={fields?.collection_ids || ""}
                handleChange={handleChange}
                placeholder={"Please select collections"}
              />
            </div>
          </CardBody>
        </Card>
      </section>
    </form>
  );
};

export default CreatedGroupForm;
