import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Card, CardBody, Button } from "reactstrap";
import PreviewIcon from "../../assets/images/preview-icon.svg";
import ImagePreviewPopup from "components/Common/Popups/ImagePreviewPopup";
import { getAllBugsList } from "services/BugReportService";
import moment from "moment";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { setPageTitle } from "utils/pageTitle";

function BugsReport() {
  const [isPreview, setIsPreview] = useState(false);
  const [bugsReportData, setBugsReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [imageUrl, setImageUrl] = useState('');

  useEffect(()=>{
		setPageTitle("Bugs Report - Inspire Uplift")

	  },[]);

  useEffect(() => {
    const params = new URLSearchParams(window?.location?.search);
    const page = params?.get('page') || undefined;
    setPageNo(page || 1);
    setLoading(true);
    getAllBugsList(page)
      .then(res => {
        setPageCount(res.data.last_page);
        setBugsReportData(res.data.data);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));

  }, [window?.location?.search])


  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => <div className="font-semi-bold">{((pageNo - 1) * 10) + row.index}</div>,
    },
    {
      dataField: "description",
      text: "Description",
      style: {
        maxWidth: '400px',
        whiteSpace: "break-spaces",
        wordBreak: "break-word",
      }
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell, row) => (
        <div>{moment(row.created_at).format('MM-DD-YYYY')}</div>
      )
    },
    {
      dataField: "media",
      text: "Media",
      formatter: (cell, row) => (
        row.mediaUrl && (
          <Button className="d-flex align-items-center" color="primary" onClick={() => handlePreview(row.mediaUrl)}>
            <img src={PreviewIcon} alt="preview icon" className="me-1" />
            Preview
          </Button>)
      )
      ,
    },
  ];

  const handlePreview = (imgUrl) => {
    setImageUrl(imgUrl);
    setIsPreview(true);
  }

  const handlePreviewClose = () => {
    setImageUrl('');
    setIsPreview(!isPreview);
  };

  const data = bugsReportData?.map((item,index) => {
    return {
      ...item,
      index:index + 1,
    }
  })
  return (
    <div className="page-content">
      {
        loading && <ProgressPopup label={"Loading..."} />
      }
      <TopHeading heading={"Bugs Report"} />
      <Card className="mt-3">
        <CardBody>
          <PrimaryTable
            columns={columns}
            data={data}
            bordered={false}
            keyField="id"
          />
          {
            (bugsReportData?.length > 0 && pageCount > 1 ) && (
              <PrimaryPagination
                pageNo={pageNo}
                pageCount={pageCount}
                setPageNo={setPageNo}
              />
            )
          }
        </CardBody>
      </Card>
      {
        isPreview && <ImagePreviewPopup handleClick={handlePreviewClose} isOpen={isPreview} url={imageUrl} />
      }
    </div>
  );
}

export default BugsReport;
