import React, { useState } from 'react';
import downWardArrow from "../../assets/images/downwrad-arrow-black.svg";
import upWardArrow from "../../assets/images/upward-arrow-black.svg";


function QuantityInput({ totalQuantity, handleChange, item,sellerName,path,isShippingCheck }) {
  const [quantity, setQuantity] = useState( path=== "tracking" ? totalQuantity : 0);

  const handleIncrease = () => {
    if (quantity < totalQuantity) {
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);
      handleChange(item, newQuantity,sellerName,isShippingCheck);
    }
  }

  const handleDescrease = () => {
    if (quantity > 0) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      handleChange(item, newQuantity,sellerName,isShippingCheck);
    }
  }
  return (
    <div
      className="d-flex quantity-input justify-content-between align-items-center"
      style={{
        width: "80px",
        height: "40px",
        border: "1px solid #D7DADE",
        padding: " 0px 0px 0px 11px",
        borderRadius: "4px",
      }}
    >
      <div>
        {quantity} <span>of {totalQuantity}</span>
      </div>
      <div className="d-flex flex-column justify-content-between h-100 ">
        <div className="d-flex justify-content-center align-items-center  quantity-arrow-icon cursor-pointer" onClick={handleIncrease}>
          <img src={upWardArrow} alt="upward Arrow" />
        </div>
        <div className="d-flex justify-content-center align-items-center  quantity-arrow-icon cursor-pointer" onClick={handleDescrease}>
          <img src={downWardArrow} alt="downward Arrow" />
        </div>
      </div>
    </div>
  )
}

export default QuantityInput