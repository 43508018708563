export const userAgentLog = async () => {
  try {
    const response = await fetch("https://www.cloudflare.com/cdn-cgi/trace");
    const data = await response.text();
    const ipMatch = data.match(/ip=(\d+\.\d+\.\d+\.\d+)/);
    const countryMatch = data.match(/loc=([A-Z]{2})/);
    const userObj = {
      user_agent: navigator.userAgent,
      ip: ipMatch ? ipMatch[1] : "N/A",
      country_code: countryMatch ? countryMatch[1] : "N/A",
    };
    return userObj;
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
};
