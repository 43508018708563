import React, { useState, useEffect } from 'react';
import PrimaryInput from 'components/Common/PrimaryInput';
import TopHeading from 'components/Common/TopHeading';
import { Button, Card, CardBody } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { createPlatform } from 'services/MarketPlaceFeedService';
import PrimaryToast from 'components/Common/Toast/ToastMessage';
import PrograssPopup from 'components/Common/Popups/ProgressPopup';
import { toast } from 'react-toastify';
import { setPageTitle } from 'utils/pageTitle';

function AddPlatform() {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useNavigate();

    useEffect(()=>{
		setPageTitle("Create Ad Platform - Inspire Uplift")
	},[]);

    const handleSubmit = (e) => {
        e.preventDefault();
        toast.dismiss();
        setLoading(true);
        createPlatform(name)
            .then(res => {
                setName("");
                PrimaryToast({type:"success",message:"Platform create successfully"});
                history(-1);
            })
            .catch(err => {
                console.log(err);
                PrimaryToast({type:"error",message:err?.response?.data?.message});
            })
            .finally(res =>{
                setLoading(false);
            })

    }
    return (
        <div className='page-content'>
            {
                loading && <PrograssPopup label={"Loading..."} />
            }
            <form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <TopHeading heading={"Create Platform"} />
                    <div className="d-flex">
                        <Button className="me-2" onClick={() => history(-1)}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit">
                            Create
                        </Button>
                    </div>
                </div>
                <Card>
                    <CardBody className='w-50'>
                        <PrimaryInput 
                            isRequired={true}
                            name="name"
                            label={"Name"}
                            placeholder={"Enter Platform Name"}
                            handleChange={(e)=>{
                                setName(e.target.value)
                            }}
                        />
                    </CardBody>
                </Card>
            </form>
        </div>
    )
}

export default AddPlatform