import React, { useEffect, useState } from "react";
import { setPageTitle } from "utils/pageTitle";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import plusIcon from "assets/images/plus-icon.svg";
import { useNavigate } from "react-router-dom";
import RelatedGroupTable from "components/SEO/RelatedGroupTable";
import {
  getRelatedGroups,
  deleteRelatedGroups,
  exportRelatedGroups,
} from "services/SEOKeywordsServices";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { toast } from "react-toastify";
import PrimarySearch from "components/Common/PrimarySearch";
import ExportButton from "components/Common/ExportButton";

function RelatedGroups() {
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState();
  const [isShowDeleteModal, setShowDeleteModal] = useState(false);
  const [exportBy, setExportBy] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const params = new URLSearchParams(window.location.search);

  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle("Related Groups - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    const pageParam = params.get("page") | undefined;
    const searchParam = params.get("q") || undefined;
    setSearchValue(searchParam || "");
    setPageNo(pageParam || 1);
    getData(pageParam, searchParam);
  }, [window.location.search]);

  const getData = async (page, search) => {
    try {
      const response = await getRelatedGroups(page, search);
      setData(response?.data?.data?.data);
      setPageCount(response?.data?.data?.last_page);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDelete = () => {
    const filterData = data?.filter(item => item.id !== selectedId);
    toast.dismiss();
    setLoading(true);
    deleteRelatedGroups(selectedId)
      .then(res => {
        if (res.status === 200) {
          handleDeleteModal("");
          if (filterData?.length === 0 && pageNo > 1) {
            params.set("page", pageNo - 1);
            let newUrl = `${window?.location?.pathname}?${params.toString()}`;
            navigate(newUrl, { replace: true });
          } else {
            getData(pageNo, searchValue);
          }
        }
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      });
  };

  const handleDeleteModal = id => {
    setSelectedId(id);
    setShowDeleteModal(!isShowDeleteModal);
  };

  const handleKeyPress = e => {
    if (e.key == "Enter") {
      if (searchValue) {
        params.set("q", searchValue);
      } else {
        params.delete("q");
      }
      params.delete("page");
      let newUrl = `${window?.location?.pathname}?${params.toString()}`;
      navigate(newUrl, { replace: true });
    }
  };

  const downloadCsv = async () => {
    const response = await exportRelatedGroups(exportBy);
    return response;
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      {isShowDeleteModal && (
        <DeleteModal
          title={"Are you sure you want to delete this group?"}
          onCancel={() => handleDeleteModal("")}
          onConfirm={handleDelete}
        />
      )}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <TopHeading heading={"Related Groups"} />
        <div className="d-flex align-items-center">
          <Button
            color="primary"
            className="me-2"
            onClick={() => navigate("/seo-keywords/related-groups/create")}
          >
            <img src={plusIcon} alt="plus icon" className="pe-2" />
            Create Related Group
          </Button>
        </div>
      </div>
      <Card>
        <CardBody>
          <div className="mb-4 d-flex justify-content-between align-items-center">
            <PrimarySearch
              placeholder={"Search by name"}
              value={searchValue}
              handleChange={e => setSearchValue(e.target.value)}
              handleKeyPress={handleKeyPress}
            />
            <div className="d-flex">
              <ExportButton
                isLoading={loading}
                setIsLoading={setLoading}
                fetchData={downloadCsv}
              />
              <select
                className="primary-select-option ms-2 w-max-content"
                value={exportBy || "all"}
                onChange={e => {
                  setExportBy(e.target.value);
                }}
                //   onKeyPress={handleFilter}
              >
                <option value={"all"}>All</option>
                <option value={"category"}>Category</option>
                <option value={"collection"}>Collection</option>
              </select>
            </div>
          </div>
          <RelatedGroupTable
            data={data}
            pageCount={pageCount}
            pageNo={pageNo}
            setPageNo={setPageNo}
            handleDelete={handleDelete}
            handleDeleteModal={handleDeleteModal}
          />
        </CardBody>
      </Card>
    </div>
  );
}

export default RelatedGroups;
