import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getAllMarketplaceFeedList = (page, search) => {
    return getApi({
        url: 'admin/marketing/marketplace-feed',
        data: {
            is_admin: true,
            page: page || undefined,
            search: search || undefined
        }
    }).then(res => (res.data))
};


export const createMarkeplaceFeed  = (data) => {
    return axiosInstance({
		method: "POST",
        url:`${siteApiUrl}admin/marketing/marketplace-feed/create`,
        withCredentials:true,
		data: {
            ...data,
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
};

export const getSignleMarketplaceFeed = (id) => {
    return getApi({
        url: `admin/marketing/marketplace-feed/edit/${id}`,
        data: {
            is_admin: true,
        }
    }).then(res => (res.data))
};

export const updateMarkeplaceFeed  = (data,id) => {
    return axiosInstance({
		method: "POST",
        url:`${siteApiUrl}admin/marketing/marketplace-feed/update/${id}`,
        withCredentials:true,
		data: {
            ...data,
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
};

export const getFeedTypes = (search) => {
    return getApi({
        url: `admin/marketing/marketplace-feed/feed-types`,
        data: {
            is_admin: true,
            search:search || undefined,
        }
    }).then(res => (res.data))
};

export const deleteMarkeplaceFeed  = (id) => {
    return axiosInstance({
		method: "DELETE",
        url:`${siteApiUrl}admin/marketing/marketplace-feed/${id}`,
        withCredentials:true,
		data: {
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
};

// Ads Plateform

export const getAllPlatfrom = (page,search) => {
    return getApi({
        url: `admin/seller-ad-platforms`,
        data: {
            is_admin: true,
            search:search || undefined,
            page:page || undefined
        }
    }).then(res => (res.data))
};

export  const deletePlatform = (id) => {
    return axiosInstance({
		method: "DELETE",
        url:`${siteApiUrl}admin/seller-ad-platforms/${id}/delete`,
        withCredentials:true,
		data: {
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
};

export  const  createPlatform = (name) => {
    return axiosInstance({
		method: "POST",
        url:`${siteApiUrl}admin/seller-ad-platforms/store`,
        withCredentials:true,
		data: {
            is_admin:true,
            name
        },
		headers:getHttpHeaders(),
	});
};

export const getSinglePlatfrom = (id) => {
    return getApi({
        url: `admin/seller-ad-platforms/${id}/edit`,
        data: {
            is_admin: true,
        }
    }).then(res => (res.data))
};

export  const  updatePlatform = (name,id) => {
    return axiosInstance({
		method: "POST",
        url:`${siteApiUrl}admin/seller-ad-platforms/${id}/update`,
        withCredentials:true,
		data: {
            is_admin:true,
            name
        },
		headers:getHttpHeaders(),
	});
}

// ads placements

export const getAllPlacements = (page,search) => {
    return getApi({
        url: `admin/seller-ad-placements`,
        data: {
            is_admin: true,
            search:search || undefined,
            page:page || undefined
        }
    }).then(res => (res.data))
};

export  const  createPlacement = (name,cpc) => {
    return axiosInstance({
		method: "POST",
        url:`${siteApiUrl}admin/seller-ad-placements`,
        withCredentials:true,
		data: {
            is_admin:true,
            name,
            cpc
        },
		headers:getHttpHeaders(),
	});
};

export const getSinglePlacement = (id) => {
    return getApi({
        url: `admin/seller-ad-placements/${id}/edit`,
        data: {
            is_admin: true,
        }
    }).then(res => (res.data))
};

export  const  updatePlacement = (cpc,name,id) => {
    return axiosInstance({
		method: "POST",
        url:`${siteApiUrl}admin/seller-ad-placements/${id}/update`,
        withCredentials:true,
		data: {
            is_admin:true,
            name,
            cpc
        },
		headers:getHttpHeaders(),
	});
};

export  const deletePlacement = (id) => {
    return axiosInstance({
		method: "DELETE",
        url:`${siteApiUrl}admin/seller-ad-placements/${id}/delete`,
        withCredentials:true,
		data: {
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
};

// Ads center

export const getAllCenters = (page,search) => {
    return getApi({
        url: `admin/seller-ad-centers`,
        data: {
            is_admin: true,
            search:search || undefined,
            page:page || undefined
        }
    }).then(res => (res.data))
};


export const getSingleCenter = (id) => {
    return getApi({
        url: `admin/seller-ad-centers/${id}/edit`,
        data: {
            is_admin: true,
        }
    }).then(res => (res.data))
};

export  const  updateCenter = (data,id )=> {
    return axiosInstance({
		method: "POST",
        url:`${siteApiUrl}admin/seller-ad-centers/${id}/update`,
        withCredentials:true,
		data: {
            is_admin:true,
            ...data
        },
		headers:getHttpHeaders(),
	});
};

export  const deleteCenter = (id) => {
    return axiosInstance({
		method: "DELETE",
        url:`${siteApiUrl}admin/seller-ad-centers/${id}/delete`,
        withCredentials:true,
		data: {
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
};

// get All ads 

export const getAllProductAds = (page,search,platform, status) => {
    return getApi({
        url: `admin/products-ad`,
        data: {
            is_admin: true,
            page:page || undefined,
            search:search || undefined,
            platform:platform || undefined,
            status: status || undefined,
        }
    }).then(res => (res.data))
};

export const getSingleProductAd = (id) => {
    return getApi({
        url: `admin/products-ad/${id}/detail`,
        data: {
            is_admin: true,
        }
    }).then(res => (res.data))
};

export  const  changeProductStatus = (data,id )=> {
    return axiosInstance({
		method: "POST",
        url:`${siteApiUrl}admin/products-ad/${id}/change-status`,
        withCredentials:true,
		data: {
            is_admin:true,
            ...data
        },
		headers:getHttpHeaders(),
	});
};

export const getAllAdsPlatform = () => {
    return getApi({
        url: `admin/seller-ad-platforms/list`,
        data: {
            is_admin: true,
        }
    }).then(res => (res.data))
}


