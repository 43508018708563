import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";


export const getAnnouncementsList = (page,search) => {
    return getApi({
        url: 'admin/seller/announcements',
        data: {
            is_admin: true,
            page:page || undefined,
            search: search || undefined
        }
    }
    ).then(res => res.data)

}

export const deleteAnnouncement = (id) => {
    return axiosInstance({
        method: "DELETE",
        data: {
            is_admin:true,
        },
		withCredentials:true,
        url: `${siteApiUrl}admin/seller/announcements/${id}`,
        headers: getHttpHeaders(),
    });
}

export const createAnnouncement = (data) => {
    const formData = new FormData();
    if (data?.title) {
        formData.append("title", data.title);
    }
    if (data?.description) {
        formData?.append("description", data.description);
    }
    if (data?.seller_groups_ids) {
        for (let i = 0; i < data.seller_groups_ids.length; i++) {
            formData.append('seller_groups_ids[]', data.seller_groups_ids[i]);
        }
    }
    if (data.is_active) {
        formData?.append("is_active", data.is_active === true ? 1 : 0);
    }
    if (data?.start_date) {
        formData?.append("start_date", data.start_date);
    }
    if (data?.end_date) {
        formData?.append("end_date", data.end_date);
    }
    if (data?.link) {
        formData?.append("link", data.link);
    }
    if (data?.button) {
        formData?.append("button", data.button);
    }
    if (data?.image) {
        formData?.append("image", data.image);
    }
    formData.append("is_admin",true);


    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seller/announcements/create`,
        withCredentials:true,
        data: formData,
        headers: getHttpHeaders(),
    });
};



export const getSingleAnnouncement = (id) => {
    return getApi({
        url: `/admin/seller/announcements/edit/${id}`,
        data:{
            is_admin:true,
            
        }
    }
    ).then(res => res.data)

};


export const updateAnnouncement = (data, id) => {
    const formData = new FormData();
    if (data?.title) {
        formData.append("title", data.title);
    }
    if (data?.description) {
        formData?.append("description", data.description);
    }
    if (data?.seller_groups_ids) {
        for (let i = 0; i < data.seller_groups_ids.length; i++) {
            formData.append('seller_groups_ids[]', data.seller_groups_ids[i]);
        }
    }
    
    formData?.append("is_active", data.is_active === true ? 1 : 0);
    if (data?.start_date) {
        formData?.append("start_date", data.start_date);
    }
    if (data?.end_date) {
        formData?.append("end_date", data.end_date);
    }
    if (data?.link) {
        formData?.append("link", data.link);
    }
    if (data?.button) {
        formData?.append("button", data.button || "");
    }
    if (data?.image) {
        formData?.append("image", data.image);
    }
    formData.append("is_admin",true);

    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seller/announcements/update/${id}`,
        data: formData,
        withCredentials:true,
        headers: getHttpHeaders(),
    });

}