import React, { useState, useEffect } from 'react';

import { Button } from "reactstrap";
import QuantityInput from '../QuantityInput';
import { getTrackProductDetail } from 'services/OrderManagementService';
import { substractVariantTitle } from 'utils/string';

export default function AddTrackingPopup({ handleClick,
    id,
    handleCreateTracking,
    loading
}) {
    const [trackingData, setTrackingData] = useState();
    const [quantityData, setQuantityData] = useState({
        variant_id: [],
        product_id: [],
        lineItemQty: []
    });
    const [trackingNumber, setTrackingNumber] = useState();
    const [aliExpressNumbers, setAliExpressNumber] = useState();

    useEffect(() => {
        getTrackProductDetail(id)
          .then(res => {
            const data = res?.data;
            setTrackingData(data);
    
            if (data && Object.keys(data.line_items).length > 0) {
              const { line_items } = data;
    
              const variant_ids = [];
              const product_ids = [];
              const lineItemQty = [];
    
              Object.keys(line_items).forEach(key => {
                line_items[key].forEach(item => {
                  variant_ids.push(item.variant_id);
                  product_ids.push(item.product_id);
                  lineItemQty.push(item.quantity);
                });
              });
    
              setQuantityData({
                variant_id: variant_ids,
                product_id: product_ids,
                lineItemQty: lineItemQty
              });
            }
          })
          .catch(err => {
            console.log(err);
          });
      }, []);

    const handleChange = (item, quantity) => {
        const { variant_id, product_id, lineItemQty } = quantityData;
        const index = variant_id.indexOf(item?.variant_id);

        if (quantity === 0 && index > -1) {
            // If the quantity becomes zero, remove the corresponding data from the arrays
            variant_id.splice(index, 1);
            product_id.splice(index, 1);
            lineItemQty.splice(index, 1);
        } else if (index > -1) {
            // If the item already exists, update the quantity
            lineItemQty[index] = quantity;
        } else {
            // If the item doesn't exist, add a new entry
            variant_id.push(item.variant_id);
            product_id.push(item?.product_id);
            lineItemQty.push(quantity);
        };

        setQuantityData({
            variant_id,
            product_id,
            lineItemQty
        });
    };


    return (
        <div>
            <div className="order-refund-popup-table-header d-flex  align-items-center mb-4">
                <div className='me-4'>
                    #
                </div>
                <div className='d-flex justify-content-between align-items-center w-100'>
                    <div style={{width:"300px"}}>
                        Product
                    </div>
                    <div className='product-title-container'>
                        Variant
                    </div>
                    <div>
                        Quantity
                    </div>
                </div>

            </div>
            {
                trackingData && Object.keys(trackingData).length > 0 ? (
                    Object.keys(trackingData.line_items).map((item, index) => {
                        const lineItem = trackingData.line_items[item];
                        return (
                            <div className="order-refund-popup-table-body mb-4" key={index}>
                                <div className="font-semi-bold h5 mb-0 order-refund-seller-name">
                                    {item}
                                </div>
                                {
                                    lineItem?.map((item1, index1) => (
                                        <div className="d-flex align-items-center  py-3 border-bottom" key={index1}>
                                            <div className='me-4 fw-bold'>
                                                {index1 + 1}
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between w-100'>
                                                <div className='product-title-container' style={{width:"350px"}}>
                                                    {item1?.title}
                                                </div>
                                                {
                                                    <div className='product-title-container'>
                                                        {item1?.type == "configurable" ? substractVariantTitle(item1?.title, item1?.variant_title)  : null }
                                                    </div>
                                                }
                                                <QuantityInput
                                                    totalQuantity={item1?.quantity}
                                                    handleChange={handleChange}
                                                    item={item1}
                                                    path="tracking"
                                                />
                                                    
                                                
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        );
                    })
                ) : null
            }
            <div className="refund-order-summary-section-container my-4 d-flex justify-content-end border-bottom">
                <div className="refund-order-summary-section-inner-wrapper">
                    <div className="font-semi-bold h5 mb-0 py-3 border-bottom">
                        Add Tracking
                    </div>
                    <div className=" mb-3">
                        <label htmlFor="inputEmail4" className="form-label">Tracking Number</label>
                        <div className='position-relative'>
                            <input type="text" name='tracking' value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} className={`form-control w-100`} id="inputEmail4" placeholder='e.g: RB029673326SG' />
                            <div className='tracking-copy-btn position-absolute'>
                 
                            </div>

                        </div>
                    </div>
                    <div className=" mb-3">
                        <label htmlFor="inputEmail4" className="form-label">Ali Express Number</label>
                        <div className='position-relative'>
                            <input type="text" name="aliExpressNumbers" value={aliExpressNumbers} onChange={(e) => setAliExpressNumber(e.target?.value)} className={`form-control w-100`} id="inputEmail4" placeholder='e.g: RB029673326SG' />
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="order-summary-action-section d-flex justify-content-end">
                <div className="d-flex align-items-center">
                    <Button color="secondary" className="me-4" onClick={handleClick}>
                        Cancel
                    </Button>

                    <Button disabled={loading} color="primary" onClick={() => handleCreateTracking(quantityData, trackingNumber, aliExpressNumbers)} >
                        Add Tracking
                    </Button>
                </div>
            </div>
        </div>
    )
};
