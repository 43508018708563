import React from 'react'
import { Button } from 'reactstrap';
import importIcon from "assets/images/upward-arrow.svg";
import { downloadFile } from 'utils/downloadFile';
import PrimaryToast from './Toast/ToastMessage';

const ExportButton = ({
    fetchData, 
    isLoading,
    setIsLoading,
    buttonText = 'Export CSV',
}) => {

    const handleDownload = async () => {
        setIsLoading(true);
        try {
            const response = await fetchData();
            const { data = [], headers = {} } = response; 
            downloadFile(data, headers);
        }
        catch(err) {
            console.log(err);
            PrimaryToast({
                type:"error",
                message:Array.isArray(err?.response?.data?.message) ? err?.response?.data?.message[0] : err?.response?.data?.message,
            })
        }
        finally {
            setIsLoading(false)
        }
    }

  return (
    <Button type="button" color="primary" onClick={handleDownload} disabled={isLoading}>
      <img src={importIcon} alt="import icon" className="pe-2" />
      {isLoading ? "Exporting..." : buttonText}
    </Button>
  )
}

export default ExportButton
