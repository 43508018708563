import AxiosInstance from './axiosInstance';
 

const GlobalService = () => {
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  };

  const siteApiUrl = `${process.env.REACT_APP_APIKEY}`;

  const getStates = () => {
    const url = siteApiUrl+ 'states';
    const headersCopy = { ...headers };
    return AxiosInstance.get(url, { headers: headersCopy });
  };

  const getCountries = () => {
    const url = siteApiUrl + 'countries';
    const headersCopy = { ...headers };
    return AxiosInstance.get(url, { headers: headersCopy });
  };

  return {
    getStates,
    getCountries
  };
};

export default GlobalService;
