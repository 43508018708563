import React from "react";
import PrimaryPopup from "./PrimaryPopup";

function ImagePreviewPopup({ handleClick, isOpen, url }) {
  return (
    <PrimaryPopup
      title={"Image Preview"}
      handleClick={handleClick}
      isScrollable={false}
      className={
        "d-flex justify-content-center align-items-center h-100 w-100 primary-popup-container image-preview-container"
      }
      isOpen={isOpen}
    >
      <img
        src={url}
        className="object-contain"
        style={{ minWidth: "50%", maxWidth: "100%", height: "100%" }}
        alt="preview"
      />
    </PrimaryPopup>
  );
}

export default ImagePreviewPopup;
