import React from "react";
import PrimaryTable from "components/Common/PrimaryTable";
import deleteIcon from "../../assets/images/delete-icon-white.svg";
import editIcon from "../../assets/images/edit-icon-white.svg";
import { useNavigate } from "react-router-dom";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import { useSelector } from "react-redux";
function PayoutMethodSection({
  payoutMethodsList,
  payoutPage,
  setPayoutPage,
  setSelectedId,
  setShowDeleteModal,
  setPopupTitle
}) {
  const history = useNavigate();
  const user = useSelector(state => state?.auth?.user);
  const hasPayoutEditPer = user?.permissions?.some(
    p => p.name === "payout-edit"
  );
  const hasPayoutDeletePer = user?.permissions?.some(
    p => p.name === "payout-delete"
  );

  const payoutCol = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(payoutPage - 1) * 10 + row.index}</div>
      ),

    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "slug",
      text: "Payout Method",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => {
        let status;
        switch (row.status) {
          case 1:
            status = "Approved";
            break;
          case 2:
            status = "Rejected";
            break;
            case 5:
                status = "Disabled";
            break;
          default:
            status = "Pending";
        }
        return (
          <div
            className={`status ${
              status === "Pending"
                ? "warning-status"
                : status === "Approved"
                ? "success-status"
                : status === "Rejected" ||  status === "Disabled"
                ? "danger-status"
                : ""
            }  w-max-content`}
          >
            {status}
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row) => (
        <div className="d-flex  align-items-center">
          {hasPayoutEditPer && (
            <div
              className="action-btn edit-icon-bg me-1 cursor-pointer"
              onClick={() => history(`/seller/stores/edit-payout/${row.id}`)}
            >
              <img src={editIcon} className="" alt="edit icon" />
            </div>
          )}
          {hasPayoutDeletePer && (
            <div
              className="action-btn delete-btn-bg me-1 cursor-pointer"
              onClick={() => {
                setShowDeleteModal(true);
                setSelectedId(row?.id);
                setPopupTitle('Are you sure you want to delete this payout method?');
              }}
            >
              <img src={deleteIcon} className="" alt="delete icon" />
            </div>
          )}
        </div>
      ),
    },
  ];

  const data = payoutMethodsList?.data?.map((item,index) => {
    return {
      ...item,
      index:index + 1

    };
  });
  
  return (
    <div>
      <h4 className="font-semi-bold mb-3">Payout Methods</h4>
      <PrimaryTable
        keyField={"id"}
        responsive
        bordered={false}
        striped={false}
        columns={payoutCol}
        data={data}
        classes={"table align-middle table-nowrap"}
        headerWrapperClasses={"thead-light"}
      />
      {payoutMethodsList?.data?.length > 0 && (
        <div className="d-flex justify-content-center mt-4">
          <PrimaryPagination
            pageCount={payoutMethodsList?.last_page}
            pageNo={payoutPage}
            setPageNo={setPayoutPage}
          />
        </div>
      )}
    </div>
  );
}

export default PayoutMethodSection;
