import React from "react";
import { Card, CardBody } from "reactstrap";

function DisputeSummary({ disputeDetail, customerName }) {
  return (
    <div className="">
      <Card>
        <CardBody>
          <h5 className="mb-0">Dispute Details</h5>
          <div className="flex text-13">
            <div className="d-flex justify-content-between  mt-3">
              <div className="dispute-summary-right-side">Order ID:</div>
              <div className="primary-text font-semi-bold">
                #{disputeDetail?.dispute_order_id}
              </div>
            </div>
            <div className="d-flex justify-content-between  mt-3">
              <div className="dispute-summary-right-side">Store Name:</div>
              <div className="dispute-summary-right-side">{disputeDetail?.seller_store_name}</div>
            </div>
            <div className="d-flex justify-content-between  mt-3">
              <div className="">Item Name:</div>
              <div className="dispute-summary-right-side">{disputeDetail?.seller_product_title}</div>
            </div>
            <div className="d-flex justify-content-between  mt-3">
              <div className="">Dispute Amount:</div>
              <div className="dispute-summary-right-side">${disputeDetail?.dispute_amount?.toFixed(2)}</div>
            </div>
            <div className="d-flex justify-content-between  mt-3">
              <div className="">Type:</div>
              <div className="dispute-summary-right-side">{disputeDetail?.dispute_type}</div>
            </div>
            <div className="d-flex justify-content-between  mt-3">
              <div className="">Request:</div>
              <div className="dispute-summary-right-side">{disputeDetail?.dispute_issue}</div>
            </div>
            <div className="d-flex justify-content-between  mt-3">
              <div className="">Status:</div>
              <div
                className={`status w-max-content ${
                 (disputeDetail?.dispute_status == "In Process" && disputeDetail?.dispute_iu_action != 1)
                    ? "warning-status"
                    : disputeDetail?.dispute_status == "Close" ||
                    disputeDetail?.dispute_iu_action == 1
                    ? "danger-status"
                    : disputeDetail?.dispute_status == "Complete"
                    ? "success-status"
                    : ""
                }`}
              >
                {disputeDetail?.dispute_iu_action == 1
                  ? "Response Required"
                  : disputeDetail?.dispute_status}
              </div>
            </div>
            <div className="d-flex justify-content-between  mt-3">
              <div className="">Customer:</div>
              <div className="dispute-summary-right-side">{customerName}</div>
            </div>
            <div className="d-flex justify-content-between  mt-3">
              <div className="">Dispute Date:</div>
              <div className="dispute-summary-right-side">{disputeDetail?.dispute_date}</div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default DisputeSummary;
