import React, { useState, useEffect, useRef } from "react";
import TopHeading from "components/Common/TopHeading";
import { Row, Col, Button } from "reactstrap";
import TaskInformation from "components/Tasks/TaskDetail";
import TaskTimeline from "components/Tasks/TaskTimeLine";
import tickIcon from "assets/images/tick-icon.svg";
import { useParams } from "react-router-dom";
import {
  getMyTaskDetail,
  getTaskTimeline,
  createComment,
  comoeleteTask,
} from "services/TaskServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { uploadTaskImage } from "services/TaskServices";
import { useSelector } from "react-redux";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

const TaskDetails = () => {
  const { id } = useParams();
  const [taskDetail, setTaskDetail] = useState();
  const [TaskTimeLineData, setTaskTimelineData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [mediaId, setMediaId] = useState("");
  const [mediaPreview, setMediaPreview] = useState();
  const fileInputRef = useRef(null);
  const user = useSelector(state => state.auth.user);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);


  useEffect(()=>{
		setPageTitle("Task Detail - Inspire Uplift")
	},[]);

  useEffect(() => {
    setLoading(true);
    getMyTaskDetail(id)
      .then(res => {
        setTaskDetail(res.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getTimeLine()
  }, []);

  const getTimeLine = () => {
    getTaskTimeline(id,page)
      .then(res => {
        const newData  = res.data.data
        setTaskTimelineData((prevData) => [...prevData, ...newData]);
        setPage(prevPage => prevPage + 1); 
        if(newData.length === 0 || newData.length < 15) {
          setHasMore(false);
        }
      })
      .catch(err => console.log(err));
  }

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const createTimelineComment = () => {
    const data = {
      comment: comment || undefined,
      task_id: id,
      media_id: mediaId || undefined,
    };
    setLoading(true);
    createComment(data)
      .then(res => {
        let newComment = {
          admin: user?.name,
          comment: comment,
          created: "just now",
          media_url: mediaPreview,
          admin_media:user?.image,

        };
        setTaskTimelineData([...TaskTimeLineData, newComment]);
        setComment("");
        setMediaId("");
        setMediaPreview("");
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const handleComplete = () => {
    setLoading(true);
    comoeleteTask([id])
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        setShowDeleteModal(false);
        setTaskDetail({
          ...taskDetail,
          status: "completed",
        });
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const handleClear = () => {
    setMediaId("");
    setMediaPreview("");
  };

  const handleImageChange = event => {
    const file = event.target.files[0];
    toast.dismiss();
    setLoading(true);
    uploadTaskImage(file)
      .then(res => {
        setMediaId(res.data.media?.id);
        setMediaPreview(res?.data?.media?.url);
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({type:"error",message:err?.response?.data?.message});
      })
      .finally(res => {
        setLoading(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      });
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      createTimelineComment();
    }
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <div>
        {showDeleteModal && (
          <DeleteModal
            title={"Are you sure you want to complete this task?"}
            onConfirm={handleComplete}
            onCancel={() => {
              setShowDeleteModal(!showDeleteModal);
            }}
          />
        )}
        <div className="d-flex justify-content-between align-items-center">
          <TopHeading heading={`Task`} />
          <div className="d-flex">
            {taskDetail?.status !== "completed" &&
              (user?.name === taskDetail?.assigned_by || (user?.role?.[0]?.toLowerCase() ?? "") === "admin") && (
                <Button
                  className="me-2 cursor-pointer"
                  color="primary"
                  onClick={() => {
                    setShowDeleteModal(!showDeleteModal);
                  }}
                >
                  <img src={tickIcon} alt="tick icon" className="pe-2" />
                  Mark as Complete
                </Button>
              )}
          </div>
        </div>
        <div className="mt-4">
          <Row>
            <Col sm="4">
              <TaskInformation data={taskDetail} />
            </Col>
            <Col sm="8">
              <TaskTimeline
                loading = {loading}
                taskDetail={taskDetail}
                data={TaskTimeLineData}
                pageNo={pageNo}
                setPageNo={setPageNo}
                createTimelineComment={createTimelineComment}
                comment={comment}
                setComment={setComment}
                fileInputRef={fileInputRef}
                handleImageClick={handleImageClick}
                handleImageChange={handleImageChange}
                mediaPreview={mediaPreview}
                handleClear={handleClear}
                getTimeLine={getTimeLine}
                handleKeyPress={handleKeyPress}
                hasMore={hasMore}
                page={page}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default TaskDetails;
