import React, { useState } from "react";
import moment from "moment";

import PrimaryPopup from "./PrimaryPopup";
import PrimaryInput from "../PrimaryInput";
import DateRangePicker from "components/Common/DateRangePicker";
import { Button } from "reactstrap";
import ExportButton from "../ExportButton";
import ProgressPopup from "./ProgressPopup";
import { exportSellerReportsData } from "services/ReportsServices";
import PrimaryToast from "../Toast/ToastMessage";
import { downloadDigitalFile } from "services/ProductsServices";
import downLoadIcon from "assets/images/download-icon.svg";

const DownloadFilesById = ({ isOpen, handleClick, path }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [dates, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [sellerId, setSellerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDownloadFile, setIsDownloadFile] = useState(false);
  const [files, setFiles] = useState([]);

  const handleDatePicker = item => {
    setDate([item.selection]);
  };

  const handleExport = async () => {
    const start = moment(dates[0]?.startDate).startOf('day').format("YYYY-MM-DD HH:mm:ss");
    const end = moment(dates[0]?.endDate).endOf('day').format("YYYY-MM-DD HH:mm:ss");
    setLoading(true);
    try {
      const response = await exportSellerReportsData(
        start,
        end,
        sellerId
      );
      PrimaryToast({
        type: "success",
        message: "Report downloaded successfully",
      });
      setSellerId("");
      return response;
    } catch (error) {
      console.log(error);
      PrimaryToast({
        type: "error",
        message: error?.response?.data?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadFiles = async () => {
    setLoading(true);
    try {
      const response = await downloadDigitalFile(sellerId);
      setFiles(response.data.data);
      setIsDownloadFile(true);
    } catch (error) {
      console.log(error);
      PrimaryToast({
        type: "error",
        message: error?.response?.data?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = () => {
    if (path == "seller-report") {
      setDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }

    setSellerId(pre => "");
    handleClick();
    if (path == "digital-product-download") {
      setIsDownloadFile(false);
      setFiles([]);
    }
  };

  return (
    <>
      <PrimaryPopup
        isOpen={isOpen}
        title={`Download ${
          path === "seller-report"
            ? "Seller Report"
            : path === "digital-product-download"
            ? "Digital Product"
            : ""
        } `}
        handleClick={handleToggle}
      >
        {loading && <ProgressPopup label={"Loading..."} />}
        {!isDownloadFile ? (
          <div>
            {path === "seller-report" && (
              <div className="position-relative mb-2">
                <label className="form-label">Date Range</label>
                <div
                  className="form-control cursor-pointer rounded-full"
                  style={{ color: "#74788D", width: "max-content" }}
                  onClick={() => setShowCalendar(true)}
                >
                  {moment(dates[0].startDate).format("DD MMM YYYY")} -{" "}
                  {moment(dates[0].endDate).format("DD MMM YYYY")}
                </div>

                {showCalendar && (
                  <>
                    <div
                      className="position-fixed top-0 start-0 w-100 h-100"
                      style={{ zIndex: "10" }}
                      onClick={() => setShowCalendar(false)}
                    ></div>
                    <DateRangePicker
                      dates={dates}
                      handleDatePicker={handleDatePicker}
                      handleSubmit={() => {
                        setShowCalendar(false);
                      }}
                    />
                  </>
                )}
              </div>
            )}

            <PrimaryInput
              label={`${
                path === "seller-report"
                  ? "Seller"
                  : path === "digital-product-download"
                  ? "Product"
                  : ""
              } ID`}
              name={"seller_id"}
              placeholder={`Enter ${
                path === "seller-report"
                  ? "Seller"
                  : path === "digital-product-download"
                  ? "Product"
                  : ""
              }  Id`}
              value={sellerId}
              handleChange={e => setSellerId(e.target.value)}
            />
            <div
              className="d-flex justify-content-end mt-3"
              style={{
                gap: "1rem",
              }}
            >
              <Button onClick={handleToggle}>Cancel</Button>
              {path === "seller-report" ? (
                <ExportButton
                  setIsLoading={setLoading}
                  fetchData={handleExport}
                  buttonText="Export"
                />
              ) : (
                <Button color="primary" onClick={handleDownloadFiles}>
                  Download
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div>
            {files && files?.length > 0
              ? files?.map((file, index) => {
                  return (
                    <div
                      className="d-flex align-items-center fw-semibold text-13 mb-4"
                      key={index + file.name}
                    >
                      <div className="me-4">{index + 1}.</div>
                      <a
                        className="d-flex align-items-center"
                        rel="noreferrer"
                        target={"_blank"}
                        href={file?.url}
                        download
                      >
                        <img
                          src={downLoadIcon}
                          alt="download icon"
                          className="me-3"
                          height={16}
                          width={16}
                        />
                        <div
                          className="text-truncate-2"
                          style={{ width: "400px" }}
                        >
                          {file.name}
                        </div>
                      </a>
                    </div>
                  );
                })
              : null}
          </div>
        )}
      </PrimaryPopup>
    </>
  );
};

export default DownloadFilesById;
