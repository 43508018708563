import React from 'react';
import PrimaryPopup from './PrimaryPopup';
import PrimaryInput from '../PrimaryInput';
import { Button } from 'reactstrap';
import SwitchButton from '../SwitchButton';

const CreateCollectionPopup = ({ title, handleClick, isOpen, handleCreate, fields, handleChange  }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        handleCreate();
    }
  return (
    <PrimaryPopup 
        title={title}
        isOpen={isOpen}
        handleClick={handleClick}
        className={"d-flex justify-content-center align-items-center h-100 w-100"}
    >
        <form onSubmit={handleSubmit}>
            <div className='mb-3'>
                <PrimaryInput 
                    label={"Title"}
                    name={"title"}
                    isRequired={true}
                    type={"text"}
                    placeholder={"Enter Product Collection Title"}
                    handleChange={handleChange}
                    value={fields?.title || ""}
                />
            </div>
            <div className='mb-3'>
                <PrimaryInput 
                    label={"Slug"}
                    name={"slug"}
                    isRequired={true}
                    type={"text"}
                    placeholder={"Enter Product Collection Slug"}
                    handleChange={handleChange}
                    value={fields?.slug || ""}
                />
            </div>
            {/* <div className="mb-3">
                    <label>Status</label>
                    <SwitchButton checked={fields.is_hidden === true ? true : false} label={fields.is_hidden === true ? "Activate" : "Deactivate"} name="is_hidden" value={fields?.is_hidden} handleChange={handleChange} />
                </div> */}
            <div className='d-flex justify-content-end mt-3'>
                <Button className='me-3' onClick={handleClick}>Cancel</Button>
                <Button color='primary' type='submit'>Create</Button>
            </div>

        </form>
      
    </PrimaryPopup>
  )
}

export default CreateCollectionPopup
