import React from "react";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";

const ProccessStatusTable = ({ data, pageNo, setPageNo, pageCount }) => {

  const columns = [
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => {
        let jobStatus;
        if (row.status == 0) {
          jobStatus = "In Process";
        }
        if (row.status == 1) {
          jobStatus = "Running";
        }
        if (row.status == 2) {
          jobStatus = "Completed";
        }
        return (
          <div
            className={`status w-max-content ${
              row.status == 2 ? "success-status" : "warning-status"
            }`}
          >
            {jobStatus}
          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Submission Date",
    },
  ];
  const jobsData = data?.map(item => {
    return {
      ...item,
    };
  });

  return (
    <div>
      <h2 className="font-semi-bold text-19 mb-4">Processing Status</h2>
      <PrimaryTable
        columns={columns}
        data={jobsData}
        responsive
        bordered={false}
        keyField="id"
      />
      {pageCount > 1 && (
        <div className="">
          <PrimaryPagination
            pageNo={pageNo}
            setPageNo={setPageNo}
            pageCount={pageCount}
            paramName="p-page"
          />
        </div>
      )}
    </div>
  );
};
export default ProccessStatusTable;
