import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getALlRefundApproval = (page, search) => {
    return getApi({
        url:`admin/orders/refund`,
        data:{
            is_admin:true,
            page:page || undefined,
            search:search || undefined,
        },

    }).then(res => (res.data))
};

export const deleteRefunds = (refund_ids) => {
    return axiosInstance({
        method: "DELETE",
        url: `${siteApiUrl}admin/orders/refund/delete`,
        withCredentials: true,
        data: {
            is_admin: true,
            refund_ids,
        },
        headers: getHttpHeaders(),
    });
}

export const refundApproval = (selectedRows) => {
    return axiosInstance(
        {
            url: `${siteApiUrl}admin/orders/refund/approve`,
            method:'POST',
            data:{
                is_admin:true,
                refund:selectedRows,
            },
            withCredentials:true,
            responseType:'text',
            headers: getHttpHeaders(),
        }

    );
};