import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getAllCommandSchedules = (page, search) => {
    return getApi({
        url: 'admin/schedule/command',
        data: {
            is_admin: true,
            page: page || undefined,
            search: search || undefined
        }
    }).then(res => (res.data))
}

export const createNewCommand = (command) => {
    return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/schedule/command/create`,
        withCredentials:true,
		data: {
            ...command,
            is_admin:true,
        },
		headers:getHttpHeaders(),
    })

}


export const getSingleCommand = (id) => {
    return getApi({
        url: `admin/schedule/command/edit/${id}`,
        data: {
            is_admin: true,

        }
    }).then(res => (res.data))
}

export const updateCommand = (command,id) => {
    return axiosInstance({
		method: "PUT",
		url: `${siteApiUrl}admin/schedule/command/update/${id}`,
        withCredentials:true,
		data: {
            ...command,
            is_admin:true,
        },
		headers:getHttpHeaders(),
    })
}

export const deleteScheduleCommand = (id) => {
    return axiosInstance({
        method: "DELETE",
        data: {
            is_admin:true,
        },
		withCredentials:true,
        url: `${siteApiUrl}admin/schedule/command/${id}`,
        headers: getHttpHeaders(),
    });
}
