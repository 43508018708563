import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

function SwitchButton({label, handleChange, name,checked}) {
  return (
    <FormGroup switch className="d-flex align-items-center">
      <Input type="switch" name={name} checked={checked} className="swtich-btn" role="switch" onChange={handleChange} />
      <Label check className="ms-2 mt-1 font-semi-bold">{label}</Label>
    </FormGroup>
  );
}

export default SwitchButton;
