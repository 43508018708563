import React from "react";
import Logo from "assets/images/Inspire-Uplift.png";
import { Row, Col } from "reactstrap";
import PlaceholderImage from "assets/images/placeholder.png";
import moment from "moment";

const InvoicePrint = ({ data }) => {
  const {
    id,
    order_detail,
    shipping_address,
    billing_address,
    orderLineItems,
    payment_detail,
  } = data;
  const str = payment_detail?.total_tax;
  const num = parseFloat(str);
  const convertTax = num?.toFixed(2);

  const dateStr = order_detail?.order_date;
  const date = moment(dateStr, "DD-MM-YYYY HH:mm:ss");
  const formattedDate = date.format("DD MMM, YYYY");

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center">
        <img src={Logo} height={150} width={150} alt="inpire uplift logo" />
        <div>
          <div className="text-lg font-semi-bold">Order # {id}</div>
          <div className="text-lg font-semi-bold">{formattedDate}</div>
        </div>
      </div>
      <div className="mt-3 d-flex align-items-center">
        <div className="w-300 me-4">
          <h3 className="text-lg font-bold">SHIP TO</h3>
          <p className="mb-0 mt-2">
            <p className="text-capitalize mb-1">
              {shipping_address?.name ? shipping_address?.name : "John"}
            </p>
            <p className="mb-0">
              {shipping_address?.address
                ? shipping_address?.address +
                  " " +
                  (shipping_address?.address1 ?? "")
                : "5335 NW 87th Ave C109 Ste"}
            </p>
            <p className="mb-0">
              {(shipping_address?.city ? shipping_address?.city : "Miami") +
                ", " +
                (shipping_address?.province
                  ? shipping_address?.province + ", "
                  : "") +
                (shipping_address?.zip ? shipping_address?.zip : "33178")}
              <p className="mb-0">
                {shipping_address?.country ? shipping_address?.country : "US"}
              </p>
            </p>
          </p>
        </div>
        <div className="w-300 ">
          <h3 className="text-lg font-bold">BILL TO</h3>
          <p className="mt-2 mb-0">
            <p className="text-capitalize mb-1">
              {billing_address?.name ? billing_address?.name : "John"}
            </p>
            <p className="mb-0">
              <p className="mb-0">
                {billing_address?.address
                  ? billing_address?.address +
                    " " +
                    (billing_address?.address1 ?? "")
                  : "5335 NW 87th Ave C109 Ste"}
              </p>
              <p className="mb-0">
                {(billing_address?.city ? billing_address?.city : "Miami") +
                  ", " +
                  (billing_address?.province
                    ? billing_address?.province + ", "
                    : "") +
                  (billing_address?.zip ? billing_address?.zip : "33178")}
                <p>
                  {billing_address?.country ? billing_address?.country : "US"}
                </p>
              </p>
            </p>
          </p>
        </div>
      </div>
      <div className="my-3 border-t-2 border-b-2 border-black py-4">
        <Row className="border-top border-bottom py-2">
          <Col sm="3"></Col>
          <Col sm="3" className="font-bold">
            Product
          </Col>
          <Col sm="2" className="font-bold">
            Variant
          </Col>
          <Col sm="1" className="font-bold">
            Price
          </Col>
          <Col sm="3" className="font-bold text-center">
            Quantity
          </Col>
        </Row>
        {orderLineItems
          ? Object.keys(orderLineItems)?.map((seller, index) => {
              return (
                <div key={index}>
                  <div className="font-bold mt-3">{seller}</div>
                  {orderLineItems[seller]?.lineItems?.map((item, index1) => {
                    return (
                      <Row
                        className="border-bottom p-3 align-items-center"
                        key={index1}
                      >
                        <Col sm="3" className="font-semi-bold">
                          <img
                            height={"50"}
                            width={50}
                            src={item?.file ? item.file : PlaceholderImage}
                          />
                        </Col>
                        <Col sm="3">{item?.product}</Col>
                        <Col sm="2">
                          {item?.product_type == "configurable" &&
                            item?.variant}
                        </Col>
                        <Col sm="1">${item?.price?.toFixed(2)}</Col>
                        <Col sm="3" className="text-center">
                          {item?.quantity}
                        </Col>
                      </Row>
                    );
                  })}
                  <div className="my-2"></div>
                </div>
              );
            })
          : null}
        <div className="d-flex justify-content-end">
          <div className="my-3 border-bottom border-black">
            <div className="d-flex justify-content-between mb-1 me-3">
              <div className="font-bold slip-summary-item">
                Total Line Items Price :
              </div>
              <div>${payment_detail?.total_line_items_price?.toFixed(2)}</div>
            </div>
            {payment_detail?.shipping > 0 && (
              <div className="d-flex justify-content-between mb-1 me-3">
                <div className="font-bold slip-summary-item">Shipping :</div>
                <div>${payment_detail?.shipping?.toFixed(2)}</div>
              </div>
            )}
            {payment_detail?.discount > 0 && (
              <div className="d-flex justify-content-between mb-1 me-3">
                <div className="font-bold slip-summary-item">
                  Total Discounts :
                </div>
                <div>${payment_detail?.discount?.toFixed(2)}</div>
              </div>
            )}
            {payment_detail?.promotion > 0 && (
              <div className="d-flex justify-content-between mb-1 me-3">
                <div className="font-bold slip-summary-item">Promotion :</div>
                <div>-${payment_detail?.promotion?.toFixed(2)}</div>
              </div>
            )}
            <div className="d-flex justify-content-between mb-1 me-3">
              <div className="font-bold slip-summary-item">
                Subtotal Price :
              </div>
              <div>${payment_detail?.subtotal_price?.toFixed(2)}</div>
            </div>
            {payment_detail?.wallet_amount > 0 && (
              <div className="d-flex justify-content-between my-4 mb-1 me-3">
                <div className="font-bold slip-summary-item">
                  Wallet Amount:
                </div>
                <div className="">
                  -${payment_detail?.wallet_amount?.toFixed(2)}
                </div>
              </div>
            )}
            <div className="d-flex justify-content-between mb-1 me-3">
              <div className="font-bold slip-summary-item">Total Tax :</div>
              <div className="border-bottom pb-2">${convertTax}</div>
            </div>
            <div className="d-flex justify-content-between mb-3 me-3">
              <div className="font-bold slip-summary-item">Grand Total :</div>
              <div className="">${payment_detail?.grand_total?.toFixed(2)}</div>
            </div>
            {payment_detail?.wallet_refund_amount > 0 && (
              <div className="d-flex justify-content-between my-4">
                <div className="font-bold slip-summary-item">
                  Wallet Refund Amount:
                </div>
                <div className="">
                  ${payment_detail?.wallet_refund_amount?.toFixed(2)}
                </div>
              </div>
            )}
            {payment_detail?.refund_amount > 0 && (
              <div className="d-flex justify-content-between mb-3 me-3">
                <div className="font-bold slip-summary-item">
                  Refund Amount :
                </div>
                <div className="">
                  -${payment_detail?.refund_amount?.toFixed(2)}
                </div>
              </div>
            )}
            {payment_detail?.tax_refund > 0 && (
              <div className="d-flex justify-content-between mb-3 me-3">
                <div className="font-bold slip-summary-item">Tax Refund :</div>
                <div className="">-${payment_detail?.tax_refund}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column align-items-center mt-">
        <p className="mb-0">Thank you for shopping with us!</p>
        <h3 className="font-bold text-xl">Inspire Uplift</h3>
        <div className="mt-2 text-center">
          <p className="mb-0">contact@inspireuplift.com</p>
          <p>www.inspireuplift.com</p>
        </div>
      </div>
    </div>
  );
};

export default InvoicePrint;
