import { DISABLE_BUTTON, ENABLE_BUTTON } from "store/actions/buttonState";

const initialState = {
    isButtonDisabled: false,
  };
  
  const buttonStateReducer = (state = initialState, action) => {
    switch (action.type) {
      case DISABLE_BUTTON:
        return {
          ...state,
          isButtonDisabled: true,
        };
      case ENABLE_BUTTON:
        return {
          ...state,
          isButtonDisabled: false,
        };
      // handle other actions here
      default:
        return state;
    }
  };
  export default buttonStateReducer;