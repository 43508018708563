import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import {
  getShippingAddress,
  getBillingAddress,
} from "services/OrderManagementService";
import GlobalService from "services/GlobalService";
import PrimarySelect from "../PrimarySelect";
import { useDispatch } from "react-redux";
import { loadStates } from "store/actions/states";
import { loadCountries } from "store/actions/countries";

const EditAddressPopup = ({
  handleClick,
  id,
  type,
  handleChange,
  fields,
  setFields,
  handleSubmit,
  isProvinceText,
  setIsProvinceText,
  loading
}) => {
  const globalService = GlobalService();

  const [statesData, setStates] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getCountries();
    getStates();
  }, []);

  const getStates = () => {
    globalService
      .getStates()
      .then(response => {
        setStates([
          { name: "Please select one...", value: "" },
          ...response?.data?.data,
        ]);
        dispatch(loadStates(response?.data?.data));
      })
      .catch(error => console.log(error.message));
  };

  const getCountries = () => {
    globalService
      .getCountries()
      .then(response => {
        setCountriesData([
          { name: "Please select one...", value: "" },
          ...response?.data?.data,
        ]);
        dispatch(loadCountries(response.data?.data));
      })
      .catch(error => console.log(error.message));
  };

  useEffect(() => {
    if (type === "shipping") {
      getShippingAddress(id)
        .then(res => {
          setFields({
            first_name: res?.data?.shipping_first_name,
            last_name: res?.data?.shipping_last_name,
            phone: res?.data?.phone,
            address1: res?.data?.shipping_address1,
            address2: res?.data?.shipping_address2,
            country: res?.data?.shipping_country,
            city: res?.data?.shipping_city,
            province: res?.data?.shipping_province,
            country_code: res?.data?.shipping_country_code,
            province_code: res?.data?.shipping_province_code,
            zip: res?.data?.shipping_zip,
          });
          if (res?.data?.shipping_country_code == "US") {
            setIsProvinceText(false);
          } else {
            setIsProvinceText(true);
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else if (type === "billing") {
      getBillingAddress(id)
        .then(res => {
          setFields({
            first_name: res?.data?.billing_first_name,
            last_name: res?.data?.billing_last_name,
            phone: res?.data?.billing_phone,
            address1: res?.data?.billing_address1,
            address2: res?.data?.billing_address2,
            country: res?.data?.billing_country,
            city: res?.data?.billing_city,
            province: res?.data?.billing_province,
            country_code: res?.data?.billing_country_code,
            province_code: res?.data?.billing_province_code,
            zip: res?.data?.billing_zip,
          });
          if (res?.data?.billing_country_code == "US") {
            setIsProvinceText(false);
          } else {
            setIsProvinceText(true);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, []);


  return (
    <div>
      <form onSubmit={e => handleSubmit(e, type)}>
        <Row className="mb-3">
          <Col className="form-group col-md-6">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              onChange={handleChange}
              name="first_name"
              value={fields?.first_name}
              placeholder="Enter First Name"
            />
          </Col>
          <Col className="form-group col-md-6">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              onChange={handleChange}
              name="last_name"
              value={fields?.last_name}
              placeholder="Enter Last Name"
            />
          </Col>
        </Row>
        <div className="form-group mb-3">
          <label htmlFor="inputAddress1">Address1</label>
          <input
            type="text"
            className="form-control"
            id="inputAddress1"
            onChange={handleChange}
            name="address1"
            value={fields?.address1}
            placeholder="Enter Address1"
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="address2">Address2</label>
          <input
            type="text"
            className="form-control"
            id="address2"
            onChange={handleChange}
            name="address2"
            value={fields?.address2}
            placeholder="Enter Address 2"
          />
        </div>
        <Row className="mb-3">
          <Col className="form-group col-md-6">
            <div className="mb-3">
              <PrimarySelect
                label={"Country"}
                name="country_code"
                isRequired
                handleChange={handleChange}
                value={fields?.country_code || ""}
                options={countriesData || []}
                path="country"
                isShowRussia={true}
              />
            </div>
          </Col>
          <Col className="form-group col-md-6">
            {!isProvinceText ? (
              <div className="mb-3">
                <PrimarySelect
                  label={"State"}
                  name="province_code"
                  isRequired
                  handleChange={handleChange}
                  value={fields?.province_code || ""}
                  options={statesData || []}
                  path="state"
                />
              </div>
            ) : (
              <div className="">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  value={fields?.province || ''}
                  name="province"
                  placeholder="Enter State"
                />
              </div>
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="form-group col-md-6">
            <label htmlFor="city">City</label>
            <input
              type="text"
              className="form-control"
              id="city"
              onChange={handleChange}
              value={fields?.city}
              name="city"
              placeholder="Enter City"
            />
          </Col>
          <Col className="form-group col-md-6">
            <label htmlFor="zip">Zip</label>
            <input
              type="text"
              className="form-control"
              id="zip"
              onChange={handleChange}
              value={fields?.zip}
              name="zip"
              placeholder="Enter Zip"
            />
          </Col>
        </Row>
        <Row className="mb-3 align-items-center">
          <Col className="form-group col-md-6">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              className="form-control"
              id="phone"
              onChange={handleChange}
              value={fields?.phone}
              name="phone"
              placeholder="Enter Phone"
            />
          </Col>
          <Col className="col-md-6 mt-4">
            <input
              className="form-check-input me-2"
              type="checkbox"
              onChange={handleChange}
              name={type === "shipping" ? "same_billing" : "same_shipping"}
              id="gridCheck"
            />
            <label className="form-check-label" htmlFor="gridCheck">
              {type === "shipping"
                ? "Billing address same"
                : "Shipping address same"}
            </label>
          </Col>
        </Row>
        <div className="d-flex  justify-content-end pt-3 border-top">
          <Button
            className="me-2"
            color="secondary"
            type="button"
            onClick={handleClick}
          >
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={loading}>
            Save Changes
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditAddressPopup;
