import React from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import PrimaryInput from "components/Common/PrimaryInput";

function CreateRedirect() {
  const history = useNavigate();
  return (
    <div className="page-content">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <TopHeading heading={"Create Redirect"} />
        <div className="d-flex align-items-center">
          <Button onClick={() => history(-1)}>Cancel</Button>
          <Button color="primary" className="ms-3">
            Create
          </Button>
        </div>
      </div>
      <Card>
        <CardBody className="w-50">
          <h4 className="font-semi-bold">Redirect Information</h4>
          <div className="mb-3">
            <PrimaryInput
              label={"Request Path"}
              type="text"
              isRequired
              placeholder="Enter Request Path"
              name={"request_path"}
            />
          </div>
          <div className="">
            <PrimaryInput
              label={"Target Path"}
              type="text"
              isRequired
              placeholder="Enter Target Path"
              name={"target_path"}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateRedirect;
