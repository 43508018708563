import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getAllGiftCards = (page,search) => {
    return getApi({
        url:`admin/gift-cards`,
        data:{
            is_admin:true,
            page:page || undefined,
            search:search || undefined,
        }
    }).then(res => res.data);
};



export const createGiftCard = (data) => {
    return axiosInstance({
        method:"POST",
        url:`${siteApiUrl}admin/gift-cards/create`,
        withCredentials:true,
        data:{
            ...data,
            is_admin:true
        },
        headers:getHttpHeaders()
    })
}

export const getSingleGiftCard = (id) =>{
    return getApi({
        url:`admin/gift-cards/edit/${id}`,
        data:{
            is_admin:true,
        }
    }).then(res => res.data);
}

export const updateGiftCard = (data,id) => {
    return axiosInstance({
        method:"PUT",
        url:`${siteApiUrl}admin/gift-cards/update/${id}`,
        withCredentials:true,
        data:{
            ...data,
            is_admin:true
        },
        headers:getHttpHeaders()
    })
}

export const deleteGiftCard = (id) => {
    return axiosInstance({
        method:"DELETE",
        url:`${siteApiUrl}admin/gift-cards/${id}`,
        withCredentials:true,
        data:{
            is_admin:true
        },
        headers:getHttpHeaders()
    })
};


export const getGiftCardApprovalList = (page) => {
    return getApi({
        url:`admin/gift-cards/approvals`,
        data:{
            is_admin:true,
        }
    }).then(res => res.data);

}

export const giftCardAction = (ids,type) => {
    return getApi({
        url:`admin/gift-cards/action/${type}`,
        data:{
            is_admin:true,
            gift_card_ids:ids,
        }
    }).then(res => res.data);

}