import React from "react";
import { Button } from "reactstrap";

import PrimaryInput from "components/Common/PrimaryInput";
import SwitchButton from "components/Common/SwitchButton";
import RelatedGroupsField from "../RelatedGroupsField";
import PrimaryRadio from "components/Common/PrimaryRadio";
import plusIcon from "assets/images/plus-icon.svg";
import deleteIcon from "assets/images/delete-icon.svg";
import RichTextEditor from "components/Common/TextEditor/RichTextEditor";
import TagsInputFields from "components/Common/TagsInput";

function CollectionForm({
  handleChange,
  fields,
  description,
  handleEditor,
  setFields,
  faq,
  handleAddQuestion,
  handleDelete,
  handleFaq,
  path,
  handleDeleteTags,
  handleAddition,
  tags
}) {
  return (
    <div>
      <h4 className="font-semi-bold">Product Collection Information</h4>
      <div className="w-50">
        <div className="mb-3">
          <PrimaryInput
            label={"Title"}
            name="title"
            type={"text"}
            isRequired
            placeholder="Enter Product Collection Title"
            handleChange={handleChange}
            value={fields?.title || ""}
          />
        </div>
        <div className="mb-3">
          <PrimaryInput
            label={"Slug"}
            name="slug"
            type={"text"}
            isRequired
            placeholder="Enter Product Collection Slug"
            handleChange={handleChange}
            value={fields?.slug || ""}
          />
        </div>
        <div className="mb-3">
          <RelatedGroupsField
            label={"Related Groups"}
            fields={fields}
            setFields={setFields}
          />
        </div>
        <div className="mb-3">
            <TagsInputFields isRequired={true} label={"Tags"} tags={tags} handleAddition={handleAddition} placeholder={"Enter Tags"} handleDelete={handleDeleteTags} />
        </div>
        <div className="mb-3">
          <label>Status</label>
          <SwitchButton
            checked={fields.is_hidden === true ? true : false}
            label={fields.is_hidden === true ? "Activate" : "Deactivate"}
            name="is_hidden"
            value={fields?.is_hidden}
            handleChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <PrimaryInput
            label={"SEO Title"}
            name="seo_title"
            isRequired
            type={"text"}
            placeholder="Enter SEO Title"
            value={fields?.seo_title || ""}
            handleChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="inputEmail4">
            SEO Description<span className="primary-text">*</span>
          </label>
          <textarea
            rows={4}
            type="text"
            className="form-control"
            id="inputEmail4"
            name="seo_description"
            placeholder="Enter SEO Description"
            value={fields?.seo_description || ""}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="inputEmail4">Description</label>
          <RichTextEditor
            name="description"
            maxChar={4000}
            value={description}
            disablePlugins={
              "about,paste-storage,add-new-line,ai-assistant,color,copyformat,drag-and-drop,drag-and-drop-element,dtd,file,fullsize,hr,iframe,indent,key-arrow-outside,line-height,media,mobile,placeholder,powered-by-jodit,preview,print,resize-cells,resize-handler,search,select-cells,source,speech-recognize,spellcheck,sticky,symbols,table-keyboard-navigation,video,wrap-nodes,xpath"
            }
            buttons={
              "bold,underline,italic,strikethrough,ul,ol,paragraph,table,image,link,left, right, center"
            }
            handleChange={e => handleEditor(e)}
            uploader={true}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">Filters</label>
          <div className="mb-2">
            <PrimaryRadio
              label={"Physical"}
              name={"product_type"}
              id="physical-1"
              value={"physical"}
              checked={fields?.product_type == "physical"}
              handleChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <PrimaryRadio
              label={"Digital"}
              name={"product_type"}
              id="digital-1"
              value={"digital"}
              checked={fields?.product_type == "digital"}
              handleChange={handleChange}
            />
          </div>
          <PrimaryRadio
            label={"All"}
            name={"product_type"}
            id="all-1"
            value={"all"}
            checked={fields?.product_type == "all"}
            handleChange={handleChange}
          />
        </div>
        {path == "edit" ? (
          <div className="mb-3">
            <label>Auto Update Related Collections</label>
            <SwitchButton
              checked={fields?.non_updatable === true ? true : false}
              name="non_updatable"
              value={fields?.non_updatable}
              handleChange={handleChange}
            />
          </div>
        ) : null}
        <div className="">
          <h4>Category FAQ&apos;s</h4>
          {faq?.map((item, index) => {
            const label1 = `FAQ ${index + 1} `;
            return (
              <div className="mb-3" key={index}>
                <div className="">
                  <div className="d-flex justify-content-between mb-0">
                    <div className="mb-0 font-medium faq-label-section">
                      {label1}
                    </div>
                    <div
                      onClick={() => handleDelete(index)}
                      className="cursor-pointer text-danger d-flex align-items-center font-medium"
                    >
                      <img
                        src={deleteIcon}
                        alt="delete icon"
                        height={12}
                        width={13}
                        className="pe-1"
                      />
                      Remove this FAQ
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-text">Question</span>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e => handleFaq(e, index)}
                      name="question"
                      value={item.question}
                      placeholder="Type here"
                    />
                  </div>
                </div>
                <div className="">
                  <div className="mb-3">
                    <label htmlFor="answer">Answer</label>
                    <div>
                      <RichTextEditor
                        maxChar={4000}
                        value={item?.answer}
                        disablePlugins={
                          "about,paste-storage,add-new-line,ai-assistant,color,copyformat,drag-and-drop,drag-and-drop-element,dtd,file,fullsize,hr,iframe,indent,key-arrow-outside,line-height,media,mobile,placeholder,powered-by-jodit,preview,print,resize-cells,resize-handler,search,select-cells,source,speech-recognize,spellcheck,sticky,symbols,table-keyboard-navigation,video,wrap-nodes,xpath"
                        }
                        buttons={
                          "bold,underline,italic,strikethrough,ul,ol,paragraph,link,left, right, center"
                        }
                        name={"answer"}
                        handleChange={e => handleFaq(e, index)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <Button className="" color="primary" onClick={handleAddQuestion}>
            <img src={plusIcon} className="mdi mdi-plus-circle-outline me-1" />
            Add FAQ
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CollectionForm;
