import { getApi } from "./BaseService";
import  axiosInstance  from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getALlPayoutsApproval = (page,search, status, type) => {
    return getApi({
        url:`admin/seller/payout_methods`,
        data:{
            is_admin:true,
            page:page || undefined,
            search:search || undefined,
            status:status ||undefined,
            type:type || undefined
        }

    }).then(res => res.data)
}

export const getSinglePayoutMethod = (id) => {
    return getApi({
        url:`admin/seller/payout_methods/${id}`,
        data:{
            is_admin:true
        }
    }).then(res => res.data)
}

export const payoutMethodApprove = (payout_method_ids) => {
    return axiosInstance({
		method: "POST",
        url:`${siteApiUrl}admin/seller/payout_methods/approve`,
        withCredentials:true,
        data:{
            ...payout_method_ids,
            is_admin:true,
            
        },
		headers:getHttpHeaders(),
	});
}

export const payoutMethodReject = (payout_method_ids) => {
    return axiosInstance({
		method: "POST",
        url:`${siteApiUrl}admin/seller/payout_methods/reject`,
        withCredentials:true,
        data:{
            ...payout_method_ids,
            is_admin:true,
            
        },
		headers:getHttpHeaders(),
	});
};

export const actionSeller = (data) => {
    return axiosInstance({
		method: "POST",
        url:`${siteApiUrl}admin/seller/payout_methods/rejectnban`,
        withCredentials:true,
        data:{
            ...data,
            is_admin:true,
            
        },
		headers:getHttpHeaders(),
	});
};

export const getAllPayoutMethodList = () => {
    return getApi({
        url:`admin/seller/payouts/method-list`,
        data:{
            is_admin:true
        }
    }).then(res => res.data)
};