import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { CardBody, Card, Button } from "reactstrap";
import PrimarySearch from "components/Common/PrimarySearch";
import plusIcon from "../../../assets/images/plus-icon.svg";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import editIcon from "../../../assets/images/edit-icon-white.svg";
import rectangeArrow from "../../../assets/images/rectangle-arrow.svg";
import { useNavigate } from "react-router-dom";
import {
  getSellerGroupList,
  deteleSellerGroup,
} from "services/SellerGroupsServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";

function SellerGroup() {
  const [sellerGroupList, setSellerGroupList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [selectedId, setSelectedId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [searchVal, setSearch] = useState(null);
  const user = useSelector(state => state.auth?.user);
  const permissions = user?.permissions?.map(item => item.name);
  const params = new URLSearchParams(window?.location?.search);

  const history = useNavigate();

  useEffect(() => {
    setPageTitle("Seller Groups - Inspire Uplift");
  }, []);

  useEffect(() => {
    const page = params.get("page") || undefined;
    const search = params.get("search") || undefined;
    setSearch(search);
    setPageNo(page || 1);
    getAllSellerList(page, search);
  }, [window?.location?.search]);

  const getAllSellerList = (page, search) => {
    setLoading(true);
    getSellerGroupList(page, search)
      .then(res => {
        setSellerGroupList(res?.data?.data);
        setPageCount(res.data?.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const handleDeleteGroup = () => {
    let filterArr = sellerGroupList?.filter(item => item.id !== selectedId);
    toast.dismiss();
    setLoading(true);
    deteleSellerGroup(selectedId)
      .then(res => {
        if (res.status === 200) {
          setSelectedId("");
          setShowDeleteModal(false);
          if (filterArr?.length == 0 && pageNo > 1) {
            params.set("page", pageNo - 1);
            let newUrl = `${window?.location?.pathname}?${params.toString()}`;
            history(newUrl, { replace: true });
          } else {
            setSellerGroupList(filterArr);
          }

          PrimaryToast({ type: "success", message: res?.data?.message });
        }
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message:
            err?.response?.data?.message !== ""
              ? err?.response?.data?.message
              : "something went wrong",
        });
      })
      .finally(res => setLoading(false));
  };
  const columns = [
    {
      dataField: "#",
      text: "ID",
      formatter: (cell, row) => <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>,
    },
    {
      dataField: "name",
      text: "Title",
      style: {
        width: "70%",
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex align-items-center">
            <Button
              color="primary"
              className="d-flex align-items-center me-1"
              onClick={() => history(`/seller/groups/${row.id}/sellers-list`)}
            >
              <img src={rectangeArrow} />
              <div className="ms-2">Seller List</div>
            </Button>
            {permissions?.includes("store-edit") ? (
              <div
                className="action-btn edit-icon-bg me-1 cursor-pointer"
                onClick={() => history(`/seller/groups/edit/${row.id}`)}
              >
                <img src={editIcon} className="" alt="edit icon" />
              </div>
            ) : null}
            {permissions?.includes("store-delete") ? (
              <div
                className="action-btn delete-btn-bg cursor-pointer"
                onClick={() => {
                  setSelectedId(row.id);
                  setShowDeleteModal(true);
                }}
              >
                <img src={deleteIcon} className="" alt="delete icon" />
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  const data = sellerGroupList?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleSearch = e => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
        if(params?.get('page')){
            params?.delete('page');
        };
        if(searchVal){
            params?.set('search', searchVal);
        }
        else{
            params?.delete('search');
        }
        history(`/seller/groups?${params?.toString()}`)
    }
  };

  return (
    <div className="page-content order-payment-approval-container">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this group?"}
          onConfirm={handleDeleteGroup}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <TopHeading heading={"Seller Groups"} />
        {permissions?.includes("store-create") && (
          <Button
            className="rounded"
            color="primary"
            onClick={() => {
              history("/seller/groups/create");
            }}
          >
            <img alt="plus icon" src={plusIcon} className="me-2" />
            Create New Group
          </Button>
        )}
      </div>
      <Card>
        <CardBody>
          <PrimarySearch
            placeholder={"Search by name"}
            handleChange={handleSearch}
            handleKeyPress={handleKeyPress}
            value={searchVal}
          />
          <div className="mt-3">
            <PrimaryTable
              columns={columns}
              data={data}
              keyField="id"
              bordered={false}
            />
          </div>
          {sellerGroupList?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default SellerGroup;
