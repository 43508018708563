import React, { useEffect, useState } from "react";
import TopHeading from "components/Common/TopHeading";
import { Card, CardBody } from "reactstrap";
import { getHyperwalletUser } from "services/SellerStoreServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { useParams } from "react-router-dom";

function HyperWalletInfo() {
  const [fields, setFields] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    getHyperwalletUser(id)
      .then(res => {
        setFields(res.data);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const getStatusColor = (key, value) => {
    let status = "";
    if (key?.toLowerCase().includes("status")) {
      if (value == "REQUIRED") {
        status = `status w-max-content ${status} warning-status`;
      } else {
        status = `status w-max-content ${status} success-status`;
      }
    }
    return status;
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <TopHeading heading={"HyperWallet User More Info"} />
      <Card className="mt-4">
        <CardBody>
          <div className="border-bottom pb-2 text-15 font-semi-bold">
            User Info
          </div>
          <div className="d-flex flex-wrap">
            {Object.entries(fields).map(([key, value]) => (
              <div
                key={key}
                className={`w-50 border-bottom text-13 py-3 d-flex align-items-center`}
              >
                <div className="font-semi-bold" style={{ width: "30%" }}>
                  {key}:
                </div>
                {Array.isArray(value) ? (
                  <div className="ms-4">{JSON.stringify(value, null, 2)}</div>
                ) : (
                  <div className={`ms-4 ${getStatusColor(key, value)}`}>
                    {value?.replace("_", " ")}
                  </div>
                )}
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default HyperWalletInfo;
