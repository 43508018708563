import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { CardBody, Card, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import PrimarySearch from "components/Common/PrimarySearch";
import plusIcon from "../../../assets/images/plus-icon.svg";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import editIcon from "../../../assets/images/edit-icon-white.svg";
import {
  getAllRoles,
  deleteRole,
  importFixedTags,
} from "services/SettingsServices";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import DeleteModal from "components/Common/Toast/DeleteModal";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";
import ImportCSVFilePopup from "components/Common/Popups/ImportCSVFilePopup";
import { importReviews } from "services/SettingsServices";
import { downloadFile } from "utils/downloadFile";

function Roles() {
  const [importType, setImportType] = useState("");
  const [roles, setRoles] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [searchVal, setSearch] = useState(null);
  const [importFile, setImportFile] = useState();
  const [isImportCsv, setIsImportCsv] = useState(false);
  const user = useSelector(state => state?.auth?.user);
  const permissions = user?.permissions?.map(item => item.name);
  const history = useNavigate();
  const params = new URLSearchParams(window?.location?.search);

  useEffect(() => {
    const page = params?.get("page") || undefined;
    const search = params?.get("search") || undefined;
    setPageNo(page || 1);
    setSearch(search || "");
    getRoles(page, search);
  }, [window?.location?.search]);

  useEffect(() => {
    setPageTitle("Roles - Inspire Uplift");
  }, []);

  const getRoles = (page, search) => {
    setLoading(true);
    getAllRoles(page, search)
      .then(res => {
        setRoles(res?.data?.data);
        setPageCount(res?.data?.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const handleSearch = e => {
    const val = e.target.value;
    setSearch(val);
  };
  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(pageNo - 1) * 10 + row?.index}</div>
      ),
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex align-items-center">
          {permissions?.includes("role-edit") && (
            <div
              className="action-btn edit-icon-bg me-1 cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                history(`/settings/roles/edit/${row.id}`);
              }}
            >
              <img src={editIcon} className="" alt="edit icon" />
            </div>
          )}
          {permissions?.includes("role-delete") && (
            <div
              className="action-btn delete-btn-bg cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                setSelectedId(row.id);
                setShowDeleteModal(true);
              }}
            >
              <img src={deleteIcon} className="" alt="delete icon" />
            </div>
          )}
        </div>
      ),
    },
  ];

  const data = roles?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleDelete = () => {
    let filteredData = roles?.filter(item => item.id !== selectedId);
    toast.dismiss();
    setLoading(true);
    deleteRole(selectedId)
      .then(res => {
        PrimaryToast({ type: "success", message: "Role delete successfully" });
        if (filteredData?.length === 0 && pageNo > 1) {
          params?.set("page", pageNo - 1);
          let newUrl = `${window?.location?.pathname}?${params.toString()}`;
          history(newUrl, { replace: true });
        } else {
          setRoles(filteredData);
        }
        setSelectedId();
        setShowDeleteModal(false);
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(re => setLoading(false));
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (params?.get("page")) {
        params?.delete("page");
      }
      if (searchVal) {
        params?.set("search", searchVal);
      } else {
        params?.delete("search");
      }

      history(`/settings/roles?${params?.toString()}`);
    }
  };

  const handleImport = () => {
    setLoading(true);
    if (importType == "reviews") {
      importReviews(importFile)
        .then(res => {
          const data = res.data;
          const headers = res.headers;
          downloadFile(data, headers);
        })
        .catch(err => {
          console.log(err);
          PrimaryToast({
            type: "error",
            message: Array.isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message,
          });
        })
        .finally(res => {
          setLoading(false);
        });
    } else {
      importFixedTags(importFile)
        .then(res => {
          const data = res.data;
          const headers = res.headers;
          downloadFile(data, headers);
        })
        .catch(err => {
          console.log(err);
          PrimaryToast({
            type: "error",
            message: Array.isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message,
          });
        })
        .finally(res => {
          setLoading(false);
        });
    }
  };

  const handlePopup = () => {
    setIsImportCsv(!isImportCsv);
    setImportFile();
    setImportType("");
  };

  const handleFileUpload = event => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      setImportFile(file);
    } else {
      alert("Please select a CSV file.");
    }
  };

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this role?"}
          onConfirm={handleDelete}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <TopHeading heading={"Roles Management"} />
        <div className="d-flex">
          {permissions?.includes("main-dashboard") ? (
            <Button
              className="me-2"
              color="primary"
              onClick={() => {
                setIsImportCsv(true);
                setImportType("tags");
              }}
            >
              <img alt="plus icon" src={plusIcon} className="me-2" />
              Fixed Tags
            </Button>
          ) : null}
          {permissions?.includes("role-create") ? (
            <Button
              color="primary"
              onClick={() => {
                setIsImportCsv(true);
                setImportType("reviews");
              }}
            >
              <img alt="plus icon" src={plusIcon} className="me-2" />
              Import Reviews
            </Button>
          ) : null}

          {permissions?.includes("role-create") && (
            <Button
              className="rounded-full ms-2"
              color="primary"
              onClick={() => {
                history("/settings/roles/create");
              }}
            >
              <img alt="plus icon" src={plusIcon} className="me-2" />
              Create New Role
            </Button>
          )}
        </div>
      </div>
      <Card>
        <CardBody>
          <PrimarySearch
            value={searchVal}
            handleKeyPress={handleKeyPress}
            placeholder={"Search by name"}
            handleChange={handleSearch}
          />
          <div className="mt-3">
            <PrimaryTable
              columns={columns}
              data={data}
              bordered={false}
              keyField="id"
              isPermission={true}
              rowUrl={"/settings/roles"}
            />
          </div>
          {roles?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              setPageNo={setPageNo}
              pageNo={pageNo}
            />
          )}
        </CardBody>
      </Card>
      {isImportCsv && (
        <ImportCSVFilePopup
          title={importType === "reviews" ? "Reviews" : "Tags"}
          isOpen={isImportCsv}
          handleClick={handlePopup}
          handleFileUpload={handleFileUpload}
          handleSave={handleImport}
          loading={loading}
        />
      )}
    </div>
  );
}

export default Roles;
