import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import PrimaryInput from "components/Common/PrimaryInput";
import { useNavigate } from "react-router-dom";
import { createTask } from "services/TaskServices";
import SecondarySelect from "components/Common/SecondarySelect";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimarySelect from "components/Common/PrimarySelect";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { setPageTitle } from "utils/pageTitle";
import RichTextEditor from "components/Common/TextEditor/RichTextEditor";

const CreateTask = () => {
  const navigate = useNavigate();
  const [fields, setFields] = useState({});
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setPageTitle("Create New Task - Inspire Uplift");
  }, []);

  const handleSelectChange = selected => {
    setSelectedOptions(selected);
  };

  const handleEditor = (event) => {
    const { name, value } = event;
    if (name === "description") {
      setDetails(value);
    }
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const fieldsObj = { ...fields };
    fieldsObj[name] = value;
    setFields(fieldsObj);
  };

  const handleCreateTask = e => {
    e.preventDefault();
    const selectedIds = selectedOptions?.map(item => item.value);
    const data = {
      ...fields,
      employees_id: selectedIds,
      details,
    };
    setLoading(true);
    createTask(data)
      .then(res => {
        PrimaryToast({ type: "success", message: "Task create successfully" });
        navigate(-1);
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => setLoading(false));
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <form onSubmit={handleCreateTask}>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <TopHeading heading={"Create Task"} />
          <div>
            <Button onClick={() => navigate(-1)}>Cancel</Button>
            <Button className="ms-2" color="primary">
              Create
            </Button>
          </div>
        </div>
        <Card>
          <CardBody className="w-50">
            <div className="mb-3">
              <PrimaryInput
                label={"Title"}
                name="title"
                type="text"
                isRequired={true}
                placeholder={"Enter Task Title"}
                value={fields?.title || ""}
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label>
                Details<span className="primary-text">*</span>
              </label>
              <RichTextEditor
                value={details}
                disablePlugins={
                  "about,paste-storage,add-new-line,ai-assistant,color,copyformat,drag-and-drop,drag-and-drop-element,dtd,file,fullsize,hr,iframe,indent,key-arrow-outside,line-height,media,mobile,placeholder,powered-by-jodit,preview,print,resize-cells,resize-handler,search,select-cells,source,speech-recognize,spellcheck,sticky,symbols,table-keyboard-navigation,video,wrap-nodes,xpath"
                }
                buttons={
                  "bold,underline,italic,strikethrough,ul,ol,paragraph,image,link,left, right, center"
                }
                name={"description"}
                handleChange={e => handleEditor(e)}
                uploader={true}
              />
            </div>
            <div className="mb-3">
              <SecondarySelect
                isRequired={true}
                label={"Assigned To"}
                isMulti={true}
                hideSelectedOptions={false}
                value={selectedOptions}
                name="admin_ids"
                placeholder={"Assigned To"}
                handleSelect={handleSelectChange}
                path="emplooye"
              />
            </div>
            <div className="mb-3">
              <PrimarySelect
                label={"Priority"}
                value={fields?.priority || ""}
                name="priority"
                isRequired
                handleChange={handleChange}
                options={[
                  {
                    name: "Select Task Priority",
                    value: "",
                  },
                  {
                    name: "High",
                    value: "high",
                  },
                  {
                    name: "Medium",
                    value: "medium",
                  },
                  {
                    name: "Low",
                    value: "low",
                  },
                ]}
              />
            </div>
          </CardBody>
        </Card>
      </form>
    </div>
  );
};

export default CreateTask;
