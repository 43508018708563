import React, { useState, useEffect } from "react";
import { CardBody, Card, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import TopHeading from "components/Common/TopHeading";
import SwitchButton from "components/Common/SwitchButton";
import PlusIcon from "../../../assets/images/plus-icon.svg";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import PrimaryInput from "components/Common/PrimaryInput";
import { useParams } from "react-router-dom";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { getSingleWidget, updateWidget } from "services/WidgetsServices";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function EditWidget() {
  const history = useNavigate();
  const [collections, setCollections] = useState([
    {
      slug: "",
      product_ids: "",
    },
  ]);
  const [fields, setFields] = useState({
    status: true,
  });
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setPageTitle("Edit Widget - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    getSingleWidget(id)
      .then(res => {
        setFields({
          widget_name: res?.data?.name || undefined,
          status: res?.data?.status == 1 ? true : false,
          extra_data: res?.data?.extra_data == 1 ? true : false,
          widget_slug: res?.data?.slug || undefined,
          length:
            res?.data?.product_length == 0
              ? res?.data?.product_length.toString()
              : res?.data?.product_length || undefined,
        });
        let collection = res?.data?.collection;
        collection = collection?.map(item => {
          if (Array.isArray(item)) {
            return {
              slug: item[0],
              product_ids: item[1].join(","),
            };
          }
          return {
            slug: item,
          };
        });
        setCollections(collection);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, []);

  const handleAdd = () => {
    const newCollection = {
      slug: "",
    };
    setCollections([...collections, newCollection]);
  };

  const handleDelete = itemIndex => {
    if (collections.length > 1) {
      const filteredCollection = collections.filter(
        (item, index) => index !== itemIndex
      );
      setCollections(filteredCollection);
    }
  };

  const handleChange = e => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const fieldsObj = { ...fields };
    fieldsObj[name] = value;
    setFields(fieldsObj);
  };

  const handleCollections = (e, index) => {
    let collectionsObj = [...collections];
    collectionsObj[index][e.target.name] = e.target.value;
    setCollections(collectionsObj);
  };

  const handleSubmit = e => {
    e.preventDefault();

    let collectionsData = collections?.map(item => {
      if (item?.product_ids?.trim?.()?.length > 0) {
        return [
          item.slug,
          item.product_ids.split(",").filter(ids => ids !== ""),
        ];
      } else {
        return item.slug;
      }
    });

    let data = {
      ...fields,
      status: fields?.status === true ? 1 : 0,
      extra_data: fields?.extra_data === true ? 1 : 0,
      collection: collectionsData?.length > 0 ? collectionsData : undefined,
    };
    toast.dismiss();
    setLoading(true);
    updateWidget(data, id)
      .then(res => {
        history(-1);
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => setLoading(false));
  };
  const handleCollectionProducts = (e, index, slug) => {
    const collection_arr = [...collections];
    const value = e.target.value;
    collection_arr[index].product_ids = value;
    setCollections(collection_arr);
  };
  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <TopHeading heading={"Edit Widget"} />
          <div className="d-flex align-items-center">
            <Button onClick={() => history(-1)}>Cancel</Button>
            <Button className="ms-4" color="primary">
              Save
            </Button>
          </div>
        </div>
        <Card>
          <CardBody className="w-50">
            <div className="mb-3">
              <PrimaryInput
                label={"Name"}
                name="widget_name"
                type={"text"}
                isRequired
                value={fields?.widget_name || ""}
                handleChange={handleChange}
                placeholder="Enter Widget Name"
              />
            </div>
            <div className="mb-3">
              <PrimaryInput
                label={"Slug"}
                name="widget_slug"
                type={"text"}
                isRequired
                handleChange={handleChange}
                value={fields?.widget_slug || ""}
                placeholder="Enter Widget Slug"
              />
            </div>
            <div className="">
              <label htmlFor="inputEmail4">
                Collections<span className="primary-text">*</span>
              </label>
              {collections?.map((item, index) => {
                return (
                  <div className="d-flex align-items-center mb-3" key={index}>
                    <PrimaryInput
                      name={"slug"}
                      type="text"
                      value={item.slug || ""}
                      placeholder="Enter Collection, Category or Shop Slug"
                      handleChange={e => handleCollections(e, index)}
                    />
                    {item?.slug.trim() ? (
                      <div className="ms-2">
                        <PrimaryInput
                          name={"product-ids"}
                          type="text"
                          value={item?.product_ids || ""}
                          placeholder="Enter collection product ids"
                          handleChange={e =>
                            handleCollectionProducts(e, index, item?.slug)
                          }
                        />
                      </div>
                    ) : null}
                    <div
                      className="action-btn delete-btn-bg  cursor-pointer ms-2"
                      onClick={() => handleDelete(index)}
                      style={{ minWidth: "35px" }}
                    >
                      <img src={deleteIcon} className="" alt="delete icon" />
                    </div>
                  </div>
                );
              })}
            </div>
            <Button
              className="d-flex align-items-center mb-3"
              color="primary"
              onClick={handleAdd}
            >
              <img src={PlusIcon} alt="plus icon" className="me-2" />
              Add Slug
            </Button>
            <div className="mb-3">
              <PrimaryInput
                label={"Product Length"}
                name="length"
                type={"text"}
                isRequired
                value={fields?.length || ""}
                placeholder="Enter Product Length"
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputEmail4">Status</label>
              <SwitchButton
                checked={fields.status === true ? true : false}
                label={fields.status === true ? "Active" : "Inactive"}
                name="status"
                value={fields?.status}
                handleChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="inputEmail4">Extra Data</label>
              <SwitchButton
                checked={fields.extra_data === true ? true : false}
                label={fields.extra_data === true ? "Yes" : "No"}
                name="extra_data"
                value={fields?.status}
                handleChange={handleChange}
              />
            </div>
          </CardBody>
        </Card>
      </form>
    </div>
  );
}

export default EditWidget;
