import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import plusIcon from "../../../assets/images/plus-icon.svg";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import {
  getAnnouncementsList,
  deleteAnnouncement,
} from "services/AnnouncementServices";

import editIcon from "../../../assets/images/edit-icon-white.svg";
import { useNavigate } from "react-router-dom";
import PrimarySearch from "components/Common/PrimarySearch";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";

function AnnouncementsComponent() {
  const [announcementsData, getAnnouncementsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [selectedId, setSelectedId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [searchVal, setSearch] = useState(null);
  const params = new URLSearchParams(window?.location?.search);

  useEffect(() => {
    setPageTitle("Seller Announcements - Inspire Uplift");
  }, []);

  useEffect(() => {
   
    const page = params?.get("page") || undefined;
    const search = params?.get("search") || undefined;
    setPageNo(page || 1);
    setSearch(search);
    getAnnouncements(page, search);
  }, [window?.location?.search]);

  const getAnnouncements = (page, search) => {
    setLoading(true);
    getAnnouncementsList(page, search)
      .then(res => {
        getAnnouncementsData(res.data.announcements?.data);
        setPageCount(res?.data?.announcements?.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const history = useNavigate();

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>
      ),
    },
    {
      dataField: "title",
      text: "Title",
      style: {
        maxWidth: "200px",
        whiteSpace: "break-spaces",
        wordBreak: "break-word",
        overFlow: "hidden",
      },
    },
    {
      dataField: "description",
      text: "Description",
      style: {
        maxWidth: "500px",
        whiteSpace: "break-spaces",
        wordBreak: "break-word",
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row) => (
        <div className="d-flex align-items-center">
          <div
            className="action-btn edit-icon-bg me-1"
            onClick={() => history(`/seller/announcements/edit/${row.id}`)}
          >
            <img src={editIcon} className="" alt="edit icon" />
          </div>
          <div
            className="action-btn delete-btn-bg cursor-pointer"
            onClick={() => {
              setSelectedId(row?.id);
              setShowDeleteModal(!showDeleteModal);
            }}
          >
            <img src={deleteIcon} className="" alt="delete icon" />
          </div>
        </div>
      ),
    },
  ];

  const data = announcementsData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleDeleteAnnouncement = () => {
    const filterArr = announcementsData?.filter(item => item.id !== selectedId);
    toast.dismiss();
    setLoading(true);
    deleteAnnouncement(selectedId)
      .then(res => {
        getAnnouncementsData(filterArr);
        setShowDeleteModal(!showDeleteModal);
        setSelectedId("");
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        PrimaryToast({
          type: "error",
          message: err?.response?.data?.message[0],
        });
        console.log(err);
      })
      .finally(res => setLoading(false));
  };

  const handleSearch = e => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
        if(params?.get('page')){
            params?.delete('page');
        };
        if(searchVal){
            params?.set('search', searchVal);
        }
        else{
            params?.delete('search');
        }
        history(`/seller/announcements?${params?.toString()}`)
    }
  };

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this Announcement?"}
          onConfirm={handleDeleteAnnouncement}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <TopHeading heading={"Announcements"} />
        <Button
          className="rounded"
          color="primary"
          onClick={() => {
            history("/seller/announcements/create");
          }}
        >
          <img alt="plus icon" src={plusIcon} className="me-2" />
          Create New Announcement
        </Button>
      </div>
      <Card>
        <CardBody>
          <PrimarySearch
            value={searchVal}
            handleKeyPress={handleKeyPress}
            placeholder={"Search by title"}
            handleChange={handleSearch}
          />
          <div className="mt-3">
            <BootstrapTable
              columns={columns}
              data={data}
              classes={"table align-middle table-nowrap"}
              headerWrapperClasses={"thead-light"}
              responsive
              bordered={false}
              keyField="order_number"
            />
          </div>
          {announcementsData?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              setPageNo={setPageNo}
              pageNo={pageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default AnnouncementsComponent;
