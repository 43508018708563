import React from "react";
import editIcon from "../../assets/images/edit-icon.svg";
import { CardBody, Card } from "reactstrap";
import { useSelector } from "react-redux";
import vipIcon from "assets/images/VIP.png";

const OrderDetailsComponent = ({ data, handleClick }) => {
  const userInfo = useSelector(state => state.auth.user);
  const hasEmailPermission = userInfo?.permissions?.some(
    item => item.name === "email-visibility"
  );
  const hasEmailUpdate = userInfo?.permissions?.some(
    item => item.name === "order-email-update"
  );
  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center">
          <div className="mb-0 font-weight-bold h5">Order Details</div>
          {hasEmailUpdate && hasEmailPermission && (
            <img
              src={editIcon}
              alt="edit icon"
              className="cursor-pointer"
              onClick={handleClick}
            />
          )}
        </div>
        {hasEmailPermission && (
          <div className="d-flex justify-content-between my-4">
            <div>Customer Email:</div>
            <div>{data?.customer_email}</div>
          </div>
        )}
        {data?.customer_subscription == 1 && (
          <div className="d-flex justify-content-between my-4">
            <div>Customer Status:</div>
            <div className="">
              <img src={vipIcon} height={24} width={38} alt="vip icon" />
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between my-4">
          <div>Ordered By:</div>
          <div className="success-status status">{data?.customer_badge}</div>
        </div>
        <div className="d-flex justify-content-between my-4">
          <div>Platform:</div>
          <div>{data?.platform}</div>
        </div>
        <div className="d-flex justify-content-between my-4">
          <div>Processing Method:</div>
          <div>{data?.processing_method}</div>
        </div>
        <div className="d-flex justify-content-between my-4">
          <div>Financial Status:</div>
          <div
            className={` ${
              data?.financial_status === "Refunded" ||
              data?.financial_status === "Partially Refunded"
                ? "danger-status"
                : "success-status"
            } status`}
          >
            {data?.financial_status}
          </div>
        </div>
        <div className="d-flex justify-content-between my-4">
          <div className="">Fulfillment Status:</div>
          <span className="fulfilment_status">
            {data?.fulfillment_status == 0
              ? "Unfulfilled"
              : data?.fulfillment_status == 1
              ? "Fulfilled"
              : data?.fulfillment_status == 2
              ? "Partial Fulfilled"
              : data?.fulfillment_status == 3
              ? "Canceled"
              : data?.fulfillment_status == 4
              ? "Ordered"
              : ""}
          </span>
        </div>
        <div className="d-flex justify-content-between my-4">
          <div>Order Status:</div>
          <div className="success-status status">{data?.order_status}</div>
        </div>
        <div className="d-flex justify-content-between mt-4">
          <div>Order Date:</div>
          <div className="">
            <span>{data?.order_date}</span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default OrderDetailsComponent;
