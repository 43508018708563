import TopHeading from "components/Common/TopHeading";
import React, { useState, useEffect } from "react";
import { Button, Card, CardBody } from "reactstrap";
import SwitchButton from "components/Common/SwitchButton";
import { useNavigate } from "react-router-dom";
import PrimaryInput from "components/Common/PrimaryInput";
import PrimarySelect from "components/Common/PrimarySelect";
import PrimaryImageUploader from "components/Common/PrimaryImageUploder";
import { createNewUser, getAllEmployeeRoles } from "services/SettingsServices";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import moment from "moment";
import CalendarInput from "components/Common/CalenderInput";
import { toast } from "react-toastify";
import Select from "react-select";

let timmer;
function CreateEmployee() {
	const history = useNavigate();
	const [fields, setFields] = useState({
		is_active: true,
	});
	const [roles, setRoles] = useState([]);
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState(undefined);
	const [cnic, setCnic] = useState(undefined);
	const [showStartDateCalender, setShowStartDateCalender] = useState(false);
	const [showEndDateCalendar, setShowEndDateCalender] = useState(false);
	const [selectedRole, setSelected] = useState([]);
	const [dob, setDob] = useState("");
	const [doj, setDoj] = useState("");


	useEffect(() => {
		setLoading(true);
		getAllEmployeeRoles()
			.then(res => {
				let options = res?.data?.map((item) => {
					return {
						label: item.name,
						value: item.name
					}
				});
				setRoles(options);
			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false));
	}, []);

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target?.type === "checkbox" ? e.target.checked : e.target.value;
		const fieldsObj = { ...fields };
		fieldsObj[name] = value;
		setFields(fieldsObj);

	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const data = {
			...fields,
			roles:selectedRole[0]?.value || undefined,
			cnic: cnic,
			media: file,
			date_of_birth: dob && moment(dob).format("YYYY-MM-DD") || undefined,
			date_of_joining: doj && moment(doj).format("YYYY-MM-DD") || undefined,
		};
	
		toast.dismiss();
		setLoading(true);
		createNewUser(data)
			.then(res => {
				PrimaryToast({ type: 'success', message: res?.data?.message });
				history(-1);
			})
			.catch(err => {
				PrimaryToast({ type: 'error', message: Array.isArray(err?.response?.data?.message) ? err?.response?.data?.message[0] : err?.response?.data?.message });
				console.log(err);
			})
			.finally(res => {
				setLoading(false);
			})

	};


	const handleDateSelect = (date, name) => {
		if (name === "dob") {
			setDob(date);
			setShowStartDateCalender(false);
		}
		if (name === "doj") {
			setDoj(date);
			setShowEndDateCalender(false);
		}
	};

	const maxDate = new Date();
	maxDate.setFullYear(maxDate.getFullYear() + 10);

	const handleSelectChange = (item) => {
		
		setSelected([item]);
	}

	const loadOptions = (search) => {
		clearTimeout(timmer);
		timmer = setTimeout(()=>{
			getAllEmployeeRoles(search)
			.then(res => {
				let options = res?.data?.map((item) => {
					return {
						label: item.name,
						value: item.name
					}
				});
				setRoles(options);
			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false));
		},500)
	};

	return (
		<div className="page-content">
			{
				loading && <ProgressPopup label={"Loading..."} />
			}
			<form onSubmit={handleSubmit}>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<TopHeading heading={"Register Employee"} />
					<div>
						<Button onClick={() => history(-1)}>Cancel</Button>
						<Button className="ms-2" color="primary">
							Register
						</Button>
					</div>
				</div>
				<Card>
					<CardBody className="w-50">
						<div className="mb-3">
							<PrimaryInput
								label={'First Name'}
								name="first_name"
								isRequired
								type={'text'}
								handleChange={handleChange}
								placeholder="Enter First Name"
							/>
						</div>
						<div className="mb-3">
							<PrimaryInput
								label={'Last Name'}
								name="last_name"
								isRequired
								type={'text'}
								value={fields?.last_name}
								handleChange={handleChange}
								placeholder="Enter Last Name"
							/>
						</div>
						<div className="mb-3">
							<PrimaryInput
								label={'Email'}
								name="email"
								isRequired
								type={'text'}
								handleChange={handleChange}
								placeholder="Enter Employee Email"
							/>
						</div>
						<div className="mb-3">
							<PrimarySelect
								name={"gender"}
								label={"Gender"}
								isRequired
								value={fields?.gender || ""}
								handleChange={handleChange}
								options={[
									{
										name: 'Select Gender',
										value: ''
									},
									{
										name: 'Male',
										value: 'male'
									},
									{
										name: 'Female',
										value: 'female'

									}
								]}
							/>
						</div>
						<div className="mb-3">
							<CalendarInput
								label="Date of Birth"
								handleShowCalender={() => setShowStartDateCalender(!showStartDateCalender)}
								showCalender={showStartDateCalender}
								handleChange={(newDate) =>
									handleDateSelect(
										newDate,
										"dob"
									)
								}
			
								date={
									dob
										? new Date(dob)
										: new Date()
								}
								
								value={dob && moment(dob).format("MM-DD-YYYY")}
								placeholder={"MM-DD-YYYY"}
							/>
						</div>
						<div className="mb-3">
							<CalendarInput
								label="Date of Joining "
								handleShowCalender={() => setShowEndDateCalender(!showEndDateCalendar)}
								showCalender={showEndDateCalendar}
								handleChange={(newDate) =>
									handleDateSelect(
										newDate,
										"doj"
									)
								}
						
								date={
									doj
										? new Date(doj)
										: new Date()
								}
								value={doj && moment(doj).format("MM-DD-YYYY")}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="">Department</label>
							<input
								type="text"
								className="form-control"
								name="department"
								value={fields?.department}
								onChange={handleChange}
								placeholder="Enter Department"
							/>
						</div>
						<div className="mb-3">
							<PrimaryImageUploader getFiles={cnic} setFiles={setCnic} label="CNIC" />
						</div>
						<div className="mb-3">
							<PrimaryInput
								label={'Phone'}
								name="phone"
							
								type={'text'}
								handleChange={handleChange}
								placeholder="Enter Phone Number"
							/>
						</div>
						<div className="mb-3">
							<PrimaryInput
								label={'Salary'}
								name="salary"
								
								value={fields?.salary}
								type={'text'}
								handleChange={handleChange}
								placeholder="Enter Salary"
								maxLength={10}
							/>
						</div>
						<div className="mb-3">
							<PrimaryInput
								label={'Leaves Allowed'}
								name="allowed_leaves"
								
								type={'text'}
								value={fields?.allowed_leaves}
								handleChange={handleChange}
								placeholder="Enter Allowed Leaves"
							/>
						</div>
						<div className="mb-3">
							<PrimarySelect
								name={"schedule"}
								label={"Schedule"}
						
								value={fields?.schedule || ""}
								handleChange={handleChange}
								options={[
									{
										name: 'Select Employee Schedule',
										value: ''
									},
									{
										name: 'Morning',
										value: 'morning'
									},
									{
										name: 'Evening',
										value: 'evening'

									}
								]}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="exampleFormControlTextarea1" className="form-label">
								Bank Account Details
							</label>
							<textarea
								name="bank_account_detail"
								value={fields?.bank_account_detail}
								onChange={handleChange}
								className="form-control"
								id="exampleFormControlTextarea1"
								rows="3"
								placeholder="Enter Employee Bank Account"
							></textarea>
						</div>
						<div className="mb-2">
							<label htmlFor="inputEmail4">Status</label>
							<SwitchButton checked={fields.is_active === true ? true : false} label={fields.is_active === true ? "Active" : "Inactive"} name="is_active" value={fields?.is_active} handleChange={handleChange} />
						</div>
						<div className="mb-3 d-flex align-items-center">
							<div className="w-50">
								<PrimaryInput
									label={'Password'}
									name="user_password"
									isRequired
									type={'password'}
									handleChange={handleChange}
									placeholder="Enter Password"
								/>
							</div>
							<div className="w-50 ms-4">
								<PrimaryInput
									label={'Confirm Password'}
									name="confirm_password"
									isRequired
									type={'password'}
									handleChange={handleChange}
									placeholder="Enter Confirm Password"
								/>
							</div>
						</div>
						<div className="mb-3">
							<label className="form-label">Role<span className="primary-text">*</span></label>
							<Select 
								isMulti={false}
								options={roles}
								onChange={handleSelectChange}
								value={selectedRole}
								name={"roles"}
								placeholder={"Please Select Role"}
							
								onInputChange={inputValue => loadOptions(inputValue)}
							/>
						</div>
						<div className="mb-3">
							<PrimaryImageUploader getFiles={file} setFiles={setFile} label="Profile Picture" />
						</div>
					</CardBody>
				</Card>
			</form>
		</div>
	);
}

export default CreateEmployee;
