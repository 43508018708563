import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getAllDisputes = (page, search,type, status, issue, start_date, end_date) => {
    return getApi({
        url:`admin/disputes`,
        data:{
            is_admin:true,
            page,
            search:search || undefined,
            type:type || undefined,
            status: status || undefined,
            issue: issue || undefined,
            start_date:start_date || undefined,
            end_date:end_date || undefined,
        }
    }).then(res => res.data);
};

export const getDisputeDetail = (id) => {
    return getApi({
        url:`admin/disputes/${id}/detail`,
        data:{
            is_admin:true,
        }
    }).then(res => res.data);
};

export const closeDispute = (id,data) => {
    return axiosInstance({
        method:"POST",
        url:`${siteApiUrl}admin/disputes/${id}/close`,
        withCredentials:true,
        data:{
            ...data,
            is_admin:true
        },
        headers:getHttpHeaders()
    })
};

export const sentMessage = (id,data) => {
    return axiosInstance({
        method:"POST",
        url:`${siteApiUrl}admin/disputes/${id}/message`,
        withCredentials:true,
        data:{
            ...data,
            is_admin:true
        },
        headers:getHttpHeaders()
    })
};

export const disputeResources = () => {
    return getApi({
        url:`dispute/resources`,
        data:{
            is_admin:true,
        }
    }).then(res => res.data);
}