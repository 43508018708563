import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "services/AuthServices";
// users

const ProfileMenu = ({ user, setIsDisabled2fa }) => {
  const [menu, setMenu] = useState(false);
  const userInfo = useSelector(state => state?.auth?.user);

  const navigate = useNavigate();

  const toggle = () => {
    setMenu(!menu);
  };

  const handleLogout = () => {
    logoutUser()
      .then(res => {
        localStorage.removeItem("adminToken");
        navigate(0);
      })
      .catch(err => console.log(err));
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle
          className={`btn header-item ${
            !user?.image && "d-flex justify-content-center align-items-center"
          }`}
          id="page-header-user-dropdown"
          tag="button"
        >
          {userInfo?.image ? (
            <img
              className="rounded-circle header-profile-user"
              src={userInfo?.image}
              alt="Header Avatar"
            />
          ) : (
            <div className="rounded-circle header-profile-user text-uppercase d-flex justify-content-center align-items-center h5">
              {userInfo?.name ? userInfo?.name[0] : null}
            </div>
          )}{" "}
          <span className="d-none d-xl-inline-block ms-1 ">
            {userInfo?.name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {!window?.location?.pathname.includes("two-factor-authentication") ? (
            <>
              {userInfo.hasOwnProperty("2fa_isEnable") &&
              userInfo["2fa_isEnable"] === 1 ? (
                <div
                  className="dropdown-item cursor-pointer"
                  onClick={() => {
                    setMenu(!menu);
                    setIsDisabled2fa(true);
                  }}
                >
                  Disable 2FA
                </div>
              ) : (
                <div
                  className="dropdown-item cursor-pointer"
                  onClick={() => {
                    setMenu(!menu);
                    navigate("/two-factor-authentication");
                  }}
                >
                  Enable 2FA
                </div>
              )}
            </>
          ) : null}

          <div className="dropdown-item cursor-pointer" onClick={handleLogout}>
            Logout
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
