import React from "react";
import { Button } from "reactstrap";
import PrimaryPopup from "./PrimaryPopup";

const DisableStorePopup = ({
    handleClick,
    isOpen,
    isScrollable,
    heading,
    setIsTemporaryBan,
    setReason,
    handleDisable,
    banType,
    loading,
    storeNames,
    setStoreNames,
    actionType
}) => {
    return (
        <PrimaryPopup isOpen={isOpen} isScrollable={isScrollable} handleClick={handleClick} title={heading}>
            <div className="border-bottom">
                {
                    actionType == "banByStoreName" && (
                        <div className="pb-4">
                            <label>Seller Store Name</label>
                            <input type="text" className="form-control" name="store_name" value={storeNames} onChange={(e)=>{setStoreNames(e.target.value)}}  placeholder="Enter Store Names(separate by,)"  />
                        </div>
                    )
                }
                <div className="pb-4">
                    <label>{banType == "temporary" ? "Why you permanently ban this Seller?" : `${actionType == "banByStoreName" ? "Why are you banning these Sellers?" : "Why you ban this Seller?"}`}</label>
                    <textarea rows={"5"} placeholder="Enter Reason Of Ban" onChange={(e)=>setReason(e.target.value)} className="form-control" />
                </div>
                {
                    banType !== "temporary" && (
                        <div className="form-check mb-2">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={e => setIsTemporaryBan(e.target.checked)} />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Permanently Ban
                        </label>
                    </div>
                    )
                }
            </div>
            <div className="d-flex justify-content-end align-items-center mt-3">
                <Button color="secondary" onClick={handleClick}>Cancel</Button>
                <Button color="primary" className="ms-2" disabled={loading} onClick={handleDisable}>Ban</Button>
            </div>

        </PrimaryPopup>
    )
}
export default DisableStorePopup;