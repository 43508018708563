import React, { useEffect, useState } from "react";
import TopHeading from "components/Common/TopHeading";
import PrimarySearch from "components/Common/PrimarySearch";
import { Button, Card, CardBody } from "reactstrap";
import PlusIcon from "../../../assets/images/plus-icon.svg";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import editIcon from "../../../assets/images/edit-icon-white.svg";
import { useNavigate } from "react-router-dom";
import {
  getAppWellcomeData,
  deleteAppWellcome,
} from "services/MobileAppServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";

function MobileWelcomeListing() {
  const history = useNavigate();
  const [mobileAppData, setMobileAppData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [selectedId, setSelectedId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [searchVal, setSearchVal] = useState(null);
  const params = new URLSearchParams(window?.location?.search);

  useEffect(()=>{
		setPageTitle("App Welcome Information - Inspire Uplift")
	  },[]);

  useEffect(() => {

    const page = params?.get('page') || undefined;
    const searchParam = params.get("search") || undefined;
    setPageNo(page || 1);
    setSearchVal(searchParam);
    getMobileAppDataList(page, searchParam);
  }, [window?.location?.search]);

  const getMobileAppDataList = (page, search) => {
    setLoading(true);
    getAppWellcomeData(page, search)
      .then(res => {
        setMobileAppData(res.data.data);
        setPageCount(res?.data?.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const handleSearch = e => {
    const val = e.target.value;
    setSearchVal(val);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (params?.get("page")) {
        params?.delete("page");
      }
      if (searchVal) {
        params?.set("search", searchVal);
      } else {
        params?.delete("search");
      }
      history(`/theme/mobile-app?${params?.toString()}`);
  }
};

  const columns = [
    {
      dataField: "id",
      text: "ID",
      formatter: (cell, row) => {
        return <div className="font-semi-bold">{((pageNo - 1) * 10) + row.index}</div>;
      },
    },
    {
      dataField: "description",
      text: "Description",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => {
        return (
          <div
            className={`status w-max-content ${row.is_hidden === 0
                ? "success-status"
                : row.is_hidden === 1
                  ? "danger-status"
                  : ""
              }`}
          >
            {row.is_hidden === 0 ? "Show" : "Hide"}
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex align-items-center">
          <div
            className="action-btn edit-icon-bg me-1"
            onClick={() => history(`/theme/mobile-app/edit/${row.id}`)}
          >
            <img src={editIcon} className="" alt="edit icon" />
          </div>
          <div
            className="action-btn delete-btn-bg me-1 cursor-pointer"
            onClick={() => {
              setSelectedId(row.id);
              setShowDeleteModal(true);
            }}
          >
            <img src={deleteIcon} className="" alt="delete icon" />
          </div>
        </div>
      ),
    },
  ];

  const handleDelete = () => {
    const filter = mobileAppData?.filter(item => item.id !== selectedId);
    toast.dismiss();
    setLoading(true);
    deleteAppWellcome(selectedId)
      .then(res => {
		if(filter.length === 0 && pageNo > 1) {
			params?.set("page", pageNo - 1);
			let newUrl = `${window?.location?.pathname}?${params.toString()}`;
			history(newUrl, { replace: true });
		}
		else {
			setMobileAppData(filter);
		};
        setShowDeleteModal(false);
        setSelectedId();
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      })
      .finally(res => setLoading(false));
  };

  const data = mobileAppData?.map((item,index) => {
    return {
      ...item,
      index:index + 1
    };
  });

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure you want to delete app welcome information?"}
          onConfirm={handleDelete}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"loading..."} />}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <TopHeading heading={"App Welcome Information"} />
        <Button
          className="d-flex align-items-center"
          color="primary"
          onClick={() => history("/theme/mobile-app/create")}
        >
          <img src={PlusIcon} alt="plus icon" className="me-2" />
          Add App Welcome
        </Button>
      </div>
      <Card>
        <CardBody>
          <PrimarySearch value={searchVal} handleKeyPress={handleKeyPress} handleChange={handleSearch} />
          <div className="mt-3">
            <PrimaryTable
              columns={columns}
              data={data}
              bordered={false}
              keyField="id"
            />
          </div>
          {(mobileAppData?.length > 0 && pageCount > 1 )&& (
            <PrimaryPagination
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default MobileWelcomeListing;
