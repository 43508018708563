import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import editIcon from "../../../assets/images/edit-icon-white.svg";
import plusIcon from "../../../assets/images/plus-icon.svg";
import { useNavigate } from "react-router-dom";
import {
  getAdultKeywords,
  deleteAdultKeyword,
} from "services/KeywordDetectServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";
import PrimarySearch from "components/Common/PrimarySearch";

function AdultKeywords() {
  const history = useNavigate();
  const [adultKeywords, setAdultKeywords] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [searchValue, setSearchValue] = useState();
  const params = new URLSearchParams(window?.location?.search);

  useEffect(() => {
    setPageTitle("Adult Keywords - Inspire Uplift");
  }, []);

  useEffect(() => {
    const page = params.get("page") || undefined;
    const searchParams = params?.get("search") || undefined;
    setSearchValue(searchParams || "");
    setPageNo(page || 1);
    adultsKeywords(page, searchParams);
  }, [window?.location?.search]);

  const adultsKeywords = (page, search) => {
    setLoading(true);
    getAdultKeywords(page, search)
      .then(res => {
        setPageCount(res.data.last_page);
        setAdultKeywords(res.data.data);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      formatter: (cell, row) => {
        return (
          <div className="font-semi-bold">{(pageNo - 1) * 15 + row.index}</div>
        );
      },
    },
    {
      dataField: "type",
      text: "Type",
    },
    {
      dataField: "keywords",
      text: "Keyword",
      style: {
        width: "70%",
      },
    },
    {
      dataField: "created_at",
      text: "Created At",
    },

    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex align-items-center">
          <div
            className="action-btn edit-icon-bg me-1 cursor-pointer"
            onClick={() => history(`/flags/adult-keywords/edit/${row.id}`)}
          >
            <img src={editIcon} className="" alt="edit icon" />
          </div>
          <div
            className="action-btn delete-btn-bg cursor-pointer"
            onClick={() => {
              setSelectedId(row.id);
              setShowDeleteModal(true);
            }}
          >
            <img src={deleteIcon} className="" alt="delete icon" />
          </div>
        </div>
      ),
    },
  ];

  const data = adultKeywords?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleDelete = () => {
    let filterArr = adultKeywords?.filter(item => item.id !== selectedId);
    toast.dismiss();
    setLoading(true);
    deleteAdultKeyword(selectedId)
      .then(res => {
        if (filterArr?.length == 0 && pageNo > 1) {
          params?.set("page", pageNo - 1);
          let newUrl = `${window?.location?.pathname}?${params.toString()}`;
          history(newUrl, { replace: true });
        } else {
          setAdultKeywords(filterArr);
        }

        setSelectedId();
        setShowDeleteModal(false);
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      })
      .finally(res => setLoading(false));
  };

  const handleSearch = e => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (params?.get("page")) {
        params?.delete("page");
      }
      if (searchValue) {
        params?.set("search", searchValue);
      } else {
        params?.delete("search");
      }
      history(`/flags/adult-keywords?${params?.toString()}`);
    }
  };
  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this keyword?"}
          onConfirm={handleDelete}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading...."} />}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <TopHeading heading={"Adult Keywords"} />

        <Button
          color="primary"
          className="rounded"
          onClick={() => history("/flags/adult-keywords/create")}
        >
          <img src={plusIcon} className="mdi mdi-plus-circle-outline me-1" />
          Create New Ketwords
        </Button>
      </div>
      <Card>
        <CardBody>
          <PrimarySearch
            value={searchValue || ""}
            handleChange={handleSearch}
            handleKeyPress={handleKeyPress}
          />
          <div className="mt-3">
            <PrimaryTable
              keyField="id"
              data={data}
              columns={columns}
              bordered={false}
            />
          </div>
          {adultKeywords?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default AdultKeywords;
