import React from 'react';
import PrimaryTable from 'components/Common/PrimaryTable';
import { Card, CardBody } from 'reactstrap';
import deleteIcon from "assets/images/delete-icon-white.svg";
import PrimaryPagination from 'components/Common/Pagination/PrimaryPagination';


const RelatedGroupsTable = ({
    handleDeleteModal,
    pageNo,
    setPageNo,
    pageCount,
    data,
}) => {
    const columns = [
        {
            dataField:'name',
            text:"Name"
        },
        {
            dataField:'type',
            text:"Type"
        },
        {
            dataField:"action",
            text:"Action",
            formatter:(cell, row)=>{
                return (
                    <div
                    className="action-btn delete-btn-bg cursor-pointer"
                    onClick={e => {
                      e.stopPropagation();
                      handleDeleteModal({
						id:row.id,
						type:row.type,
					  });
                    }}
                  >
                    <img src={deleteIcon} className="" alt="delete icon" />
                  </div>
                )
            }
        }
    ];
    

  return (
    <Card>
        <CardBody>
            <h2 className='text-19 font-semi-bold mb-4'>Related Group Categories & Collections</h2>
            <PrimaryTable 
                data={data}
                columns={columns}
                keyField={"id"}
                bordered={false}
            />
            {
              pageCount > 1 && (
                <PrimaryPagination 
                  pageNo={pageNo}
                  setPageNo={setPageNo}
                  pageCount={pageCount}
                />
              )
            }
        </CardBody>
      
    </Card>
  )
}

export default RelatedGroupsTable
