import React, { useState } from "react";
import PrimaryInput from "components/Common/PrimaryInput";
import Divider from "components/Common/Divider";
import { Button } from "reactstrap";
import ProgressPopup from "components/Common/Popups/ProgressPopup";

const CSVorKeywordSelectorForm = ( { handleFormSubmit, loading } ) => {
  const [name, setName] = useState();
  const [file, setFile] = useState();
  const [keywords, setKeywords] = useState();
  const [isChanged, setIsChanged] = useState(false) ;


  const handleFileUpload = e => {
    const file = e.target.files[0];
    setFile(file);
	setIsChanged(true);
  };

  const handleSubmit = async e => {
    e.preventDefault();
	const data = {
		name,
		keywords
	}
	handleFormSubmit(e, data, file, setName, setFile, setKeywords );
  };

  

  return (
    <>
      <form onSubmit={handleSubmit}>
        <h2 className="text-19 font-semi-bold mb-4">
          Select CSV or Enter Keywords
        </h2>
        <div className="mb-3">
          <PrimaryInput
            label={"Job Name"}
            name={"name"}
            handleChange={e => {
				setName(e.target.value);
				setIsChanged(true);
			}}
            value={name || ""}
            placeholder={"Enter job name"}
            type={"text"}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="formFile" className="form-label">
            CSV
          </label>
          <input
            className="form-control"
            accept="text/csv"
            type="file"
            id="formFile"
            onChange={handleFileUpload}
			// value={file || ""} 
          />
        </div>
        <Divider text={"OR"} />
        <div className="mb-3">
          <label className="form-label">Keywords</label>
          <textarea
            placeholder="Enter Keywords(Seperate With,)"
            rows={3}
            className="form-control"
			value={keywords || ''}
            name="keywords"
            onChange={e => {
				setKeywords(e.target.value);
				setIsChanged(true);
			}}
          />
        </div>
        <Button color="primary" type="submit" disabled={loading || !isChanged}>
          Submit
        </Button>
      </form>
    </>
  );
};

export default CSVorKeywordSelectorForm;
