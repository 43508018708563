import { getApi } from "./BaseService";
import  axiosInstance  from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getPayoutMethodsList = (pageNo,search) => {
    return getApi({
        url:`admin/seller/payouts`,

        data:{
            is_admin:true,
            page:pageNo,
            search:search || undefined
        }
    }).then(res => res.data)
}

export const createPayoutMethod  = (data) => {
    return axiosInstance({
		method: "POST",
        url:`${siteApiUrl}admin/seller/payouts/create`,
        withCredentials:true,
		data: {
            ...data,
            is_admin:true
        },
		headers:getHttpHeaders(),
	});
}

export const detelePayoutMethod  = (id) => {
    return axiosInstance({
		method: "DELETE",
        url:`${siteApiUrl}admin/seller/payouts/${id}`,
        withCredentials:true,
        data:{
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
}

export const getSinglePayoutMethodData  = (id) => {
    return getApi({
        url:`admin/seller/payouts/edit/${id}`,
        data:{
            is_admin:true,
        }
    }).then(res => res.data)
}

export const updatePayoutMethodData  = (data,id) => {
    return axiosInstance({
		method: "PUT",
        url:`${siteApiUrl}admin/seller/payouts/update/${id}`,
        withCredentials:true,
		data: {
            is_admin:true,
            ...data
        },
		headers:getHttpHeaders(),
	});
};

export const getStakeholderList = (id) => {
    return getApi({
        url:`admin/seller/store/hyperwallet-stakeholder/${id}`,
        data:{
            is_admin:true,
        }
    }).then(res => res.data)
};

export const getSingleStakeHolder = (stkToken,userToken) => {
    return getApi({
        url:`admin/seller/store/hyperwallet-edit-stakeholder`,
        data:{
            is_admin:true,
            userToken,
            stkToken

        }
    }).then(res => res.data)
};

export const updateStakeHolder  = (data) => {
    return axiosInstance({
		method: "POST",
        url:`${siteApiUrl}admin/seller/store/hyperwallet-stakeholder/update`,
        withCredentials:true,
		data: {
            is_admin:true,
            ...data
        },
		headers:getHttpHeaders(),
	});
};

export const createStakeHolder = (data,id) => {
    return axiosInstance({
		method: "POST",
        url:`${siteApiUrl}admin/seller/store/hyperwallet-stakeholder/${id}`,
        withCredentials:true,
		data: {
            is_admin:true,
            ...data
        },
		headers:getHttpHeaders(),
	});
}