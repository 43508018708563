import React from "react";
import PrimaryTable from "components/Common/PrimaryTable";
import editIcon from "assets/images/edit-icon-white.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import plusIcon from "assets/images/plus-icon.svg";

function StakeHolderTable({
  stakeHoldersData,
  sellerId,
  hasStakeholderEditPer,
  isHyperwalletUser
}) {
  const navigate = useNavigate();
  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{row.index}</div>
      ),
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (cell, row) => (
        <div>
          {row?.firstName} {row?.lastName}
        </div>
      ),
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "isDirector",
      text: "Director",
      headerStyle: {
        textAlign: "center",
      },
      formatter: (cell, row) => (
        <div className="text-center">{row?.isDirector ? "Yes" : "No"}</div>
      ),
    },

    {
      dataField: "isUltimateBeneficialOwner",
      text: "Ultimate Benefical Owner",
      formatter: (cell, row) => (
        <div className="text-center">
          {row?.isUltimateBeneficialOwner ? "Yes" : "No"}
        </div>
      ),
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      dataField: "isBusinessContact",
      text: "Business Contact",
      formatter: (cell, row) => (
        <div className="text-center">
          {row?.isBusinessContact ? "Yes" : "No"}
        </div>
      ),
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      dataField: "createdOn",
      text: "Created On",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row) => (
        <div className="d-flex  align-items-center">
          <div
            className="action-btn edit-icon-bg me-1 cursor-pointer"
            onClick={() =>
              navigate(
                `/seller/stores/stakeholders/edit?t=${row.token}&u-t=${row?.userToken}&sellerId=${sellerId}`
              )
            }
          >
            <img src={editIcon} className="" alt="edit icon" />
          </div>
        </div>
      ),
    },
  ];

  const data = stakeHoldersData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h4>Payout Method Stakeholders</h4>
        {(hasStakeholderEditPer && isHyperwalletUser) ? (
          <Button
            color="primary"
            onClick={() =>
              navigate(`/seller/stores/stakeholders/create?sellerId=${sellerId}`)
            }
          >
            <img alt="plus icon" src={plusIcon} className="me-2" />
            Create Stake Holder
          </Button>
        ) : null}
      </div>
      {stakeHoldersData?.length > 0 && (
        <div className="table-responsive mt-3">
          <PrimaryTable
            bordered={false}
            columns={columns}
            data={data}
            keyField="id"
          />
        </div>
      )}
    </div>
  );
}

export default StakeHolderTable;
