import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getWidgetsList = (page,search) => {
    return getApi({
        url: 'admin/widgets',
        data: {
            is_admin: true,
            page:page || undefined,
            search:search ||undefined,
        }
    }
    ).then(res => res.data)
};

export const createWidget = (widget) => {
    return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/widgets/create`,
        withCredentials:true,
        data:{
            ...widget,
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
};


export const getSingleWidget = (id) => {
    return getApi({
        url: `admin/widgets/edit/${id}`,
        data: {
            is_admin: true,
        }
    }
    ).then(res => res.data)
};

export const updateWidget = (widget,id) => {
    return axiosInstance({
		method: "PUT",
		url: `${siteApiUrl}admin/widgets/update/${id}`,
        withCredentials:true,
        data:{
            ...widget,
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
};



export const deleteWidget = (id) => {
    return axiosInstance({
		method: "DELETE",
		url: `${siteApiUrl}admin/widgets/${id}`,
        withCredentials:true,
        data:{
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
};
