import React from 'react'

const Divider = ({ text }) => {
  return (
    <div className='line'>
        <span className="centered-text">{text}</span>
    </div>
  )
};

export default Divider
