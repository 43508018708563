import React, { useState, useEffect } from 'react';
import TopHeading from 'components/Common/TopHeading';
import { Button, Card, CardBody } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { createProudctGroup } from 'services/ProductsServices';
import PrograssPopup from 'components/Common/Popups/ProgressPopup';
import ProductGroupIdsForm from "components/Products/ProductGroups/ProductGroupIdsForm";
import PrimaryToast from 'components/Common/Toast/ToastMessage';
import { toast } from 'react-toastify';
import { setPageTitle } from 'utils/pageTitle';

function CreateGroup() {
    const history = useNavigate();
    const [fields, setFields] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(()=>{
		setPageTitle("Create Product Group - Inspire Uplift")
	},[]);

    const handleChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        const fieldsObj = { ...fields };
        fieldsObj[name] = value;
        setFields(fieldsObj);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        toast.dismiss();
        setLoading(true);
        createProudctGroup(fields)
            .then(res => {
                history(-1)
				PrimaryToast({type:'success', message:res?.data?.message});
			})
			.catch(err =>{
				console.log(err)
				PrimaryToast({type:'error', message:Array.isArray(err?.response?.data?.message) ? err?.response?.data?.message[0] : err?.response?.data?.message});
			})
            .finally(res => setLoading(false));

    };

    return (
        <div className='page-content'>
            {
                loading && <PrograssPopup label={"Loading..."} />
            }
            <form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <TopHeading heading={"Create Product Group"} />
                    <div className='d-flex align-items-center'>
                        <Button onClick={() => history(-1)}>
                            Cancel
                        </Button>
                        <Button className='ms-2' color='primary'>
                            create
                        </Button>
                    </div>
                </div>
                <Card>
                    <CardBody>
                        <ProductGroupIdsForm fields={fields} handleChange={handleChange} />
                    </CardBody>

                </Card>
            </form>
        </div>
    )
}

export default CreateGroup