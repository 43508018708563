import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

// users apis

export const getAllUsers = (page, search) => {
    return getApi({
        url: 'admin/users',
        data: {
            is_admin: true,
            page: page || undefined,
            search: search || undefined,
        }
    }).then(res => res.data)
};

export const createNewUser = (data) => {
    const formData = new FormData();
    if(data?.first_name) {
        formData?.append("first_name",data?.first_name)
    };
    if(data?.last_name) {
        formData?.append("last_name",data?.last_name)
    };
    if(data?.email) {
        formData?.append("email",data?.email)
    };
    if(data?.gender) {
        formData?.append('gender',data?.gender)
    };
    if(data?.date_of_birth){
        formData?.append('date_of_birth',data?.date_of_birth)
    }
    if(data?.date_of_joining){
        formData?.append('date_of_joining',data?.date_of_joining)
    }
    if(data?.department){
        formData?.append("department",data?.department);
    };
    if(data?.cnic){
        formData?.append("cnic",data?.cnic);
    }
    if(data?.phone){{
        formData?.append("phone",data?.phone);
    }};
    if(data?.allowed_leaves){{
        formData?.append("allowed_leaves",data?.allowed_leaves);
    }};
    if(data?.schedule){{
        formData?.append("schedule",data?.schedule);
    }};
    if(data?.bank_account_detail){{
        formData?.append("bank_account_detail",data?.bank_account_detail);
    }};
    if(data?.salary){{
        formData?.append("salary",data?.salary);
    }};

    formData?.append("is_active",data?.is_active === true ? 1 : "0");


    if(data?.media){
        formData.append("media",data?.media);
    }
    if(data?.user_password){
        formData.append("password", data?.user_password);
    }
    if(data?.confirm_password){
        formData.append("confirm-password", data?.confirm_password);
    }
    if(data?.roles){
        formData.append("roles",data?.roles)
    }
    formData.append("is_admin",true);

    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/users/create`,
        withCredentials: true,
        data:formData,
        headers: getHttpHeaders(),
    });
};

export const deleteStore = (id) => {

    return axiosInstance({
        method: "DELETE",
        url: `${siteApiUrl}admin/users/${id}`,
        withCredentials: true,
        data: {
            is_admin: true,
        },
        headers: getHttpHeaders(),
    });
};

export const getSingleUser = (id) => {
    return getApi({
        url: `admin/users/edit/${id}`,
        data: {
            is_admin: true,
        }
    }).then(res => res.data)
};

export const userDashboard = (id) => {
    return getApi({
        url: `admin/users/dashboard/${id}`,
        data: {
            is_admin: true,
        }
    }).then(res => res.data)
}


export const updateUser = (data,id) => {
    const formData = new FormData();
    if(data?.first_name) {
        formData?.append("first_name",data?.first_name)
    };
    if(data?.last_name) {
        formData?.append("last_name",data?.last_name)
    };
    if(data?.email) {
        formData?.append("email",data?.email)
    };
    if(data?.gender) {
        formData?.append('gender',data?.gender)
    };
    if(data?.date_of_birth){
        formData?.append('date_of_birth',data?.date_of_birth)
    }
    if(data?.date_of_joining){
        formData?.append('date_of_joining',data?.date_of_joining)
    }
    if(data?.department){
        formData?.append("department",data?.department);
    };
    if(data?.cnic){
        formData?.append("cnic",data?.cnic);
    }
    if(data?.phone){{
        formData?.append("phone",data?.phone);
    }};
    if(data?.allowed_leaves){{
        formData?.append("allowed_leaves",data?.allowed_leaves);
    }};
    if(data?.schedule){{
        formData?.append("schedule",data?.schedule);
    }};
    if(data?.bank_account_detail){{
        formData?.append("bank_account_detail",data?.bank_account_detail);
    }};
    if(data?.salary){{
        formData?.append("salary",data?.salary);
    }};

    formData?.append("is_active",data?.is_active === true ? 1 : "0");

    if(data?.media){
        formData.append("media",data?.media);
    }
    if(data?.roles){
        formData.append("roles",data?.roles)
    }
    formData.append("is_admin",true);

    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/users/update/${id}`,
        withCredentials: true,
        data:formData,
        headers: getHttpHeaders(),
    });
};


export const userResetPasswordLink = (id) => {
    return getApi({
        url: `admin/users/reset-password/${id}`,
        data: {
            is_admin: true,
        }
    }).then(res => res.data)
};

export const handleEmployeeAction = (type,id) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/users/action/${type}/${id}`,
        withCredentials: true,
        data: {
            is_admin: true,
        },
        headers: getHttpHeaders(),
    });
}




// roles 

export const getAllRoles = (page, search) => {
    return getApi({
        url: 'admin/roles',
        data: {
            is_admin: true,
            page: page || undefined,
            search: search || undefined,
        }
    }).then(res => res.data)
};

export const createNewRole = (data) => {

    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/roles/create`,
        withCredentials: true,
        data: {
            is_admin: true,
            ...data,
        },
        headers: getHttpHeaders(),
    });
};


export const getSingleRole = (id) => {
    return getApi({
        url: `admin/roles/edit/${id}`,
        data: {
            is_admin: true,
        }
    }).then(res => res.data)
};

export const updateRole = (data,id) => {

    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/roles/update/${id}`,
        withCredentials: true,
        data: {
            is_admin: true,
            ...data,
        },
        headers: getHttpHeaders(),
    });
};

export const deleteRole = (id) => {

    return axiosInstance({
        method: "DELETE",
        url: `${siteApiUrl}admin/roles/${id}`,
        withCredentials: true,
        data: {
            is_admin: true,
        },
        headers: getHttpHeaders(),
    });
};

export const getAllPermissions = () => {
    return getApi({
        url: `admin/roles/getPermissions`,
        data: {
            is_admin: true,
        }
    }).then(res => res.data)
};

export const getAllEmployeeRoles = (search) => {
    return getApi({
        url: `/admin/users/roles`,
        data: {
            is_admin: true,
            search:search || undefined,
        }
    }).then(res => res.data)
};

// import reviews

export const importReviews = (file) => {

    const formData = new FormData();
    if(file){
        formData?.append("import",file)
    };
    formData.append("is_admin",true);
    return axiosInstance(
        {
            url: `${siteApiUrl}admin/orders/import-csv-reviewits`,
            method:'POST',
            data:formData,
            withCredentials:true,
            responseType:'text',
            headers: getHttpHeaders(),
        }

    );
};

export const importFixedTags = (file) => {

    const formData = new FormData();
    if(file){
        formData?.append("import",file)
    };
    formData.append("is_admin",true);
    return axiosInstance(
        {
            url: `${siteApiUrl}admin/tags_import?is_admin=true`,
            method:'POST',
            data:formData,
            withCredentials:true,
            responseType:'text',
            headers: getHttpHeaders(),
        }

    );
};