import React from "react";
import PrimaryPopup from "./PrimaryPopup";
import { Row, Button } from "reactstrap";
import PrimarySelect from "../PrimarySelect";

function AdsRejectPopup({
  title,
  isOpen,
  handleClose,
  handleSave,
  reason,
  setReason,
  loading,
}) {
  const options = [
    {
      name: "Select Option to Reject this Ad",
      value: "",
    },
    {
      name: "Potentially violates Facebook / Instagram Community Standards",
      value: "Potentially violates Facebook / Instagram Community Standards",
    },
    {
      name: "Drugs and drug-related products",
      value: "Drugs and drug-related products",
    },
    {
      name: "Potentially unsafe supplements",
      value: "Potentially unsafe supplements",
    },
    {
      name: "Adult products or services or content",
      value: "Adult products or services or content",
    },
    {
      name: "Potentially third-party infringement",
      value: "Potentially third-party infringement",
    },
    {
      name: "Potentially sensational content",
      value: "Potentially sensational content",
    },
    {
      name: "Misinformation or Misleading claims",
      value: "Misinformation or Misleading claims",
    },
    {
      name: "Potentially controversial content",
      value: "Potentially controversial content",
    },
    {
      name: "Grammar and profanity",
      value: "Grammar and profanity",
    },
	{
		name:'Violation of Inspire Uplift Policy',
		value:'Violation of Inspire Uplift Policy'
	},
	{
		name:'Low balance',
		value:'Low balance'
	}
  ];
  return (
    <PrimaryPopup
      title={"Select Reject Reason"}
      isOpen={isOpen}
      className={"d-flex justify-content-center align-items-center"}
      isScrollable={true}
      handleClick={handleClose}
    >
      <Row className="mb-3 align-items-center">
        <div className="h4 mb-3">{title}</div>
        <PrimarySelect
          handleChange={e => setReason(e.target.value)}
          name="comment"
          label={"Reason"}
          isRequired={true}
          value={reason}
          options={options}
        />
      </Row>
      <div className="d-flex  justify-content-end">
        <Button
          color="primary"
          type="button"
          disabled={loading}
          onClick={handleSave}
        >
          Submit
        </Button>
      </div>
    </PrimaryPopup>
  );
}

export default AdsRejectPopup;
