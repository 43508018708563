import React, { useState, useEffect } from "react";
import moment from "moment";
import { getRefunds } from "services/ReportsServices";
import TopHeading from "components/Common/TopHeading";
import { useSearchParams } from "react-router-dom";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { Card, CardBody } from "reactstrap";
import DateRangePicker from "components/Common/DateRangePicker";
import PrimaryTable from "components/Common/PrimaryTable";

function Refunds() {
  const [urlParams, setUrlParams] = useSearchParams();
  const initialPageNo = urlParams.get("page") || 1;

  const [pageNo, setPageNo] = useState(initialPageNo);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [refundsData, setRefundsData] = useState([]);

  const defaultDates = [
    {
      startDate: urlParams.get("start")
        ? new Date(urlParams.get("start"))
        : new Date(),
      endDate: urlParams.get("end")
        ? new Date(urlParams.get("end"))
        : new Date(),
      key: "selection",
    },
  ];

  const [dates, setDates] = useState(defaultDates);
  const [appliedDates, setAppliedDates] = useState(defaultDates);
  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    const start = moment(appliedDates[0]?.startDate)
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    const end = moment(appliedDates[0]?.endDate)
      .endOf("day")
      .format("YYYY-MM-DD HH:mm:ss");

    setLoading(true);
    getRefunds(pageNo, start, end)
      .then((response) => {
        setRefundsData(response?.data?.data?.data || []);
        setPageCount(response?.data?.data?.last_page || 1);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [pageNo, appliedDates]);

  useEffect(() => {
    const currentPage = urlParams.get("page") || 1;
    if (pageNo !== currentPage) setPageNo(currentPage);
  }, [urlParams, pageNo]);

  const handleDatePicker = (item) => {
    setDates([
      {
        startDate: moment(item.selection.startDate).startOf("day").toDate(),
        endDate: moment(item.selection.endDate).endOf("day").toDate(),
        key: "selection",
      },
    ]);
  };

  const handleSubmit = () => {
    setAppliedDates([...dates]);

    const start = moment(dates[0].startDate).format("YYYY-MM-DD");
    const end = moment(dates[0].endDate).format("YYYY-MM-DD");

    setUrlParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("start", start);
      params.set("end", end);
      params.delete("page");
      return params;
    });

    setShowCalendar(false);
  };

  const handlePageChange = (newPageNo) => {
    setPageNo(newPageNo);
    setUrlParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("page", newPageNo);
      return params;
    });
  };

  const refundsColumns = [
    {
      dataField: "date",
      text: "Date",
      formatter: (_, row) => <div>{row?.date}</div>,
    },
    {
      dataField: "totalCounts",
      text: "Total Counts",
      formatter: (_, row) => <div>{row?.totalCounts}</div>,
    },
    {
      dataField: "totalAmount",
      text: "Total Amount",
      formatter: (_, row) => <div>{parseFloat(row?.totalAmount || 0).toFixed(2)}</div>,
    },
  ];

  const tableData = refundsData.map((item) => ({
    ...item,
    date: item?.Date || "-",
    totalCounts: item?.["Total Counts"] || 0,
    totalAmount: item?.["Total Amount"] || "0.00",
  }));

  return (
    <div className="page-content">
      {loading && <ProgressPopup label="Loading..." />}
      <div className="mb-4">
        <TopHeading heading="Refunds" />
      </div>
      <Card>
        <CardBody>
          <div className="d-flex">
            <div className="position-relative">
              <div
                className="form-control cursor-pointer rounded-full"
                style={{ color: "#74788D", width: "max-content" }}
                onClick={() => setShowCalendar(true)}
              >
                <span>
                  {moment(appliedDates[0].startDate).format("DD MMM YYYY")}
                </span>{" "}
                -{" "}
                <span>
                  {moment(appliedDates[0].endDate).format("DD MMM YYYY")}
                </span>
              </div>

              {showCalendar && (
                <>
                  <div
                    className="position-fixed top-0 start-0 w-100 h-100"
                    style={{ zIndex: "10" }}
                    onClick={() => setShowCalendar(false)}
                  ></div>
                  <DateRangePicker
                    dates={dates}
                    handleDatePicker={handleDatePicker}
                    handleSubmit={handleSubmit}
                    maxDate={new Date()}
                  />
                </>
              )}
            </div>
          </div>
          <div className="table-responsive mt-4">
            <PrimaryTable
              columns={refundsColumns}
              data={tableData}
              bordered={false}
              keyField="id"
            />
          </div>
          {tableData.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              setPageNo={handlePageChange}
              pageNo={pageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default Refunds;
