import React from "react";
import { Card, CardBody } from "reactstrap";

const DisputeHistorySection = ({ disputeData, handleShowGallery }) => {
  return (
    <div>
      <Card>
        <CardBody>
          <div className="font-semi-bold">History</div>
          <ul className="mt-4 dispute-history-container">
            <li className="dispute-history-item">
              <div className="d-flex justify-content-between align-items-center">
                <div className="font-semi-bold fs-6">
                  {disputeData?.dispute_details?.dispute_type}
                </div>
                <div className="fs-6">
                  {
                    disputeData?.dispute_discussions?.data[
                      disputeData?.dispute_discussions?.data?.length - 1
                    ].created_on
                  }
                </div>
              </div>
              <div className="font-semi-bold fs-6">
                {disputeData?.dispute_details?.dispute_issue}
              </div>
              <div className="d-flex align-items-center mt-3 flex-wrap">
                {disputeData?.dispute_discussions?.data &&
                  disputeData?.dispute_discussions?.data[
                    disputeData?.dispute_discussions?.data?.length - 1
                  ]?.attachment?.map((imgSrc, index) => {
                    let images =
                      disputeData?.dispute_discussions?.data[
                        disputeData?.dispute_discussions?.data?.length - 1
                      ]?.attachment;
                    return (
                      <img
                        src={imgSrc}
                        width={160}
                        height={150}
                        alt="dispute image"
                        className="me-2 mb-3"
                        key={index}
                        onClick={() => handleShowGallery(index, images)}
                      />
                    );
                  })}
              </div>
              <div className="">
                {disputeData?.dispute_discussions?.data[
                  disputeData?.dispute_discussions?.data?.length - 1
                ].message != "undefined" ? (
                  <p className="fs-6">
                    {
                      disputeData?.dispute_discussions?.data[
                        disputeData?.dispute_discussions?.data?.length - 1
                      ].message
                    }
                    .
                  </p>
                ) : null}
              </div>
            </li>
            {disputeData?.dispute_discussions?.data?.map((dispute, index) => {
              {
                return (
                  index !==
                  disputeData?.dispute_discussions?.data?.length - 1 && (
                    <li className="dispute-history-item" key={index}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="font-semi-bold fs-6">
                          <span className="text-capitalize">
                            {dispute?.sender !== "admin" && dispute?.sender}
                            {dispute?.admin_name ? `${dispute?.admin_name}` : ""}
                          </span>{" "}
                          Sent a message{" "}
                          {dispute?.sender == "admin" &&
                            (dispute?.customer_name && !dispute?.seller_name
                              ? "to the  Customer"
                              : !dispute?.customer_name && dispute?.seller_name
                                ? "to the  Seller"
                                : dispute?.customer_name && dispute?.seller_name
                                  ? "to the Seller and Customer"
                                  : "")}
                        </div>
                        <div>{dispute?.created_on}</div>
                      </div>
                      <div className="d-flex align-items-center mt-3 flex-wrap">
                        {dispute?.attachment?.map((imgSrc, index) => {
                          return (
                            <img
                              src={imgSrc}
                              width={160}
                              height={150}
                              alt="dispute image"
                              className="me-2 mb-3"
                              key={index}
                              onClick={() =>
                                handleShowGallery(index, dispute?.attachment)
                              }
                            />
                          );
                        })}
                      </div>
                      <div className="d-flex justify-content-between items-start">
                        {dispute?.message && dispute?.message != "undefined" ? (
                          <p className="fs-6">{dispute?.message}.</p>
                        ) : null}
                      </div>
                    </li>
                  )
                );
              }
            })}
          </ul>
        </CardBody>
      </Card>
    </div>
  );
};

export default DisputeHistorySection;
