import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

function AudienceDetails({ data }) {
  return (
    <div>
      <Card>
        <CardBody>
          <h4 className="text-15">Audience Details</h4>
          <Row className="py-2 border-top border-bottom">
            {data?.gender && (
              <Col sm="6">
                <div className="d-flex justify-content-between">
                  <div className="font-semi-bold w-50">Gender:</div>
                  <div className="w-50">{data?.gender}</div>
                </div>
              </Col>
            )}
            {data?.age && (
              <Col sm="6">
                <div className="d-flex justify-content-between">
                  <div className="font-semi-bold w-25">Age:</div>
                  <div className="w-75">{data?.age}</div>
                </div>
              </Col>
            )}
          </Row>
          {data?.location && (
            <Row className="py-2 border-bottom">
              <Col sm="12">
                <div className="d-flex justify-content-between">
                  <div className="font-semi-bold w-25">Location:</div>
                  <div
                    className="w-75 font-sem-bold"
                    style={{ marginRight: "24px" }}
                  >
                    {data?.location}
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default AudienceDetails;
