import React, { useEffect, useState } from 'react';

import { Button, Card, CardBody } from 'reactstrap';
import PrimaryTable from 'components/Common/PrimaryTable';
import { getAllComments, cretaeNewComment } from 'services/SellerStoreServices';
import ProgressPopup from 'components/Common/Popups/ProgressPopup';
import PrimaryToast from 'components/Common/Toast/ToastMessage';
import PrimaryPagination from 'components/Common/Pagination/PrimaryPagination';



function CommentSection({ isLabel, id }) {
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);
    const [commentsList, setCommentsList] = useState();
    const [pageCount, setPageCount] =  useState();
    const [pageNo, setPageNo] = useState(1);


    useEffect(() => {
        setLoading(true);
        getAllComments(id,pageNo)
            .then(res => {
                setCommentsList(res.data.data);
            })
            .catch(err => console.log(err))
            .finally(res => setLoading(false));
    }, []);


    const columns = [
        {
            dataField: 'id',
            text: '#',
            formatter:(cell,row)=>{
                return (
                    <div>{(pageNo  -  1) * 10 + row?.index}</div>
                )
            }
        }, {
            dataField: 'comment',
            text: 'Comment'
        },
        {
            dataField: 'verified_by',
            text: 'Verified By',
            formatter: (cell, row) => {
                return (
                    <div>
                        {row.admin?.name}
                    </div>
                )
            }
        },
        {
            dataField: 'created_at',
            text: 'Date',

        },
    ];

    const handleCreateComment = () => {
        setLoading(true)
        cretaeNewComment(comment, id)
            .then(res => {
                PrimaryToast({type:'success', message:res?.data?.message});
                getAllComments(id,pageNo)
                    .then(res => {
                        setCommentsList(res.data.data);

                    })
                    .catch(err => console.log(err))
            })
            .catch(err => {
                console.log(err);
                PrimaryToast({type:'error', message:Array.isArray(err?.response?.data?.message) ? err?.response?.data?.message[0] : err?.response?.data?.message});
            })
            .finally(res => setLoading(false))
    }

    const data = commentsList?.map((item,index) => {
        return {
            ...item,
            index:index + 1,
        }
    });

    return (
        <div>
            {loading && <ProgressPopup label={"Loading ..."} />}
            <Card>
                <CardBody>
                    {
                        !isLabel && (
                            <div className='font-semi-bold mb-3'>
                                Comments
                            </div>
                        )
                    }
                    <div className="d-flex w-100">
                        {
                            commentsList?.length === 0 && (
                                <div className="w-50 me-2">
                                    <div className="mb-3">
                                        {
                                            isLabel && (
                                                <label htmlFor="inputEmail4">Comment<span className='primary-text'>*</span></label>
                                            )
                                        }

                                        <textarea rows={"4"} type="text" value={comment} onChange={(e) => setComment(e.target.value)} className="form-control" name="comment" placeholder="Enter Comment" />
                                    </div>



                                    <div className="d-flex justify-content-end">
                                        <Button disabled={loading} color="primary" type='button' onClick={handleCreateComment}>
                                            Verify
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                        <div className={`${commentsList?.length === 0 ? "w-50" : "w-100"}`}>
                            <PrimaryTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                columns={columns}
                                data={data}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                            />
                            			{
							(commentsList?.length > 0 && pageCount > 1 ) && (
								<PrimaryPagination
									pageCount={pageCount}
									setPageNo={setPageNo}
									pageNo={pageNo}
								/>
							)
						}
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default CommentSection