import React, { useEffect, useState } from "react";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import { Button, Nav, NavItem, NavLink } from "reactstrap";
import { useSearchParams } from "react-router-dom";

const DateFilter = ({
  width,
  setDate,
  defaultActiveTab = "4",
  isAddParam = false,
}) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const [urlParams, setUrlParams] = useSearchParams();
  const [rangeDates, setRangeDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showDateRange, setShowDateRange] = useState(false);

  useEffect(() => {
    if (isAddParam) {
      const startDateParam = urlParams.get("start");
      const endDateParam = urlParams.get("end");

      if (startDateParam && endDateParam) {
        const startDate = moment(startDateParam, "YYYY-MM-DD").toDate();
        const endDate = moment(endDateParam, "YYYY-MM-DD").toDate();

        // Determine which tab to set active based on the date range
        if (
          moment(startDate).isSame(moment(), "day") &&
          moment(endDate).isSame(moment(), "day")
        ) {
          setActiveTab("4"); // Today
        } else if (
          moment(startDate).isSame(moment().startOf("week"), "day") &&
          moment(endDate).isSame(moment().endOf("week"), "day")
        ) {
          setActiveTab("2"); // This Week
        } else if (
          moment(startDate).isSame(moment().subtract(1, "day"), "day") &&
          moment(endDate).isSame(moment().subtract(1, "day"), "day")
        ) {
          setActiveTab("3"); // Yesterday
        } else {
          setActiveTab("1"); // Custom
          setRangeDates([{ startDate, endDate, key: "selection" }]);
          setShowDateRange(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    setShowDateRange(false);
  }, [urlParams]);

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }

    if (tab === "2") {
      const startOfWeek = moment().startOf("week").format("LL");
      const endOfWeek = moment().endOf("week").format("LL");
      setDate({
        startDate: new Date(startOfWeek),
        endDate: new Date(endOfWeek),
      });
      if (isAddParam) {
        const startDateParam = moment(startOfWeek).format("YYYY-MM-DD");
        const endDateParam = moment(endOfWeek).format("YYYY-MM-DD");
        handleFilter("start", startDateParam, "end", endDateParam);
      }
    } else if (tab === "3") {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      setDate({
        startDate: yesterday,
        endDate: yesterday,
      });
      if (isAddParam) {
        const startDateParam = moment(yesterday).format("YYYY-MM-DD");
        const endDateParam = moment(yesterday).format("YYYY-MM-DD");
        handleFilter("start", startDateParam, "end", endDateParam);
      }
    } else if (tab === "4") {
      setDate({
        startDate: new Date(),
        endDate: new Date(),
      });
      if (isAddParam) {
        const startDateParam = moment(new Date()).format("YYYY-MM-DD");
        const endDateParam = moment(new Date()).format("YYYY-MM-DD");
        handleFilter("start", startDateParam, "end", endDateParam);
      }
    }
  };

  const handleFilter = (key, value, key2, value2) => {
    const existingParams = Object.fromEntries(urlParams?.entries());
    if (key && value) {
      existingParams[key] = value;
      if (key2) {
        existingParams[key2] = value2;
      }
    } else {
      delete existingParams[key];
    }
    if (existingParams?.hasOwnProperty("page")) {
      delete existingParams["page"];
    }
    setUrlParams(existingParams);
  };

  const handleSelect = ranges => {
    setRangeDates([ranges.selection]);
  };

  return (
    <Nav pills justified className="date-filter-container position-relative">
      <NavItem>
        <NavLink
          className={activeTab === "1" ? "active" : ""}
          onClick={() => {
            toggleTab("1");
            setShowDateRange(true);
          }}
        >
          <i className="bx bx-chat font-size-20 d-sm-none" />
          <span className="d-none d-sm-block">Custom</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={activeTab === "2" ? "active" : ""}
          onClick={() => {
            toggleTab("2");
          }}
        >
          <i className="bx bx-group font-size-20 d-sm-none" />
          <span className="d-none d-sm-block">This Week</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={activeTab === "3" ? "active" : ""}
          onClick={() => {
            toggleTab("3");
          }}
        >
          <i className="bx bx-book-content font-size-20 d-sm-none" />
          <span className="d-none d-sm-block">Yesterday</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={activeTab === "4" ? "active" : ""}
          onClick={() => {
            toggleTab("4");
          }}
        >
          <i className="bx bx-book-content font-size-20 d-sm-none" />
          <span className="d-none d-sm-block">Today</span>
        </NavLink>
      </NavItem>
      {showDateRange && activeTab === "1" && (
        <div
          style={{ left: `-125px` }}
          className={`position-absolute top-[60px] z-20  ${
            width ? width : ""
          } date-range-container right-0 sm:overflow-visible overflow-auto`}
        >
          <DateRangePicker
            onChange={handleSelect}
            ranges={rangeDates}
            direction="horizontal"
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            inputRanges={[]}
            maxDate={new Date()}
          />
          <Button
            onClick={() => {
              setDate(rangeDates[0]);
              setShowDateRange(false);
              if (isAddParam) {
                handleFilter(
                  "start",
                  moment(rangeDates[0].startDate).format("YYYY-MM-DD"),
                  "end",
                  moment(rangeDates[0].endDate).format("YYYY-MM-DD")
                );
              }
            }}
            style={{
              position: "absolute",
              bottom: "10px",
              left: "20px",
            }}
            color="primary"
            className="nav-bg text-white min-w-[100px] max-w-[170px] text-base py-1 px-4 rounded absolute top-[30%] right-4 xl:bottom-4 xl:left-8 xl:right-[unset] xl:top-[unset] break-words"
          >
            Submit
          </Button>
        </div>
      )}
    </Nav>
  );
};

export default DateFilter;
