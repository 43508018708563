import React, { useState, useEffect } from "react";
import { CardBody, Card, Button } from "reactstrap";
import TopHeading from "components/Common/TopHeading";
import { useNavigate } from "react-router-dom";
import SwitchButton from "components/Common/SwitchButton";
import PrimaryInput from "components/Common/PrimaryInput";
import SecondaryInput from "components/Common/SecondaryInput";
import PrimaryRadio from "components/Common/PrimaryRadio";
import { useParams } from "react-router-dom";

import { getSingleCoupon, updateCoupon } from "services/CouponServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import moment from "moment";
import CalendarInput from "components/Common/CalenderInput";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function EditCoupon() {
  const [fields, setFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [showStartDateCalender, setShowStartDateCalender] = useState(false);
  const [showEndDateCalendar, setShowEndDateCalender] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { id } = useParams();

  useEffect(() => {
    setPageTitle("Edit Coupon - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    getSingleCoupon(id)
      .then(res => {
        setFields({
          is_active: res?.data?.is_active === 1 ? true : false,
          type: res?.data?.type_id || undefined,
          code: res?.data?.code || undefined,
          value: res?.data?.value > 0 ? res?.data?.value : "0" || undefined,
          plateform:
            res?.data?.platform > 0 ? res?.data?.platform : "0" || undefined,
          min_amount:
            res?.data?.minimum_purchase_amount.toString() || undefined,
          amount: res?.data?.minimum_purchase_amount || undefined,
          limit: res?.data?.redemption_time === 1 ? true : undefined,
          unlimited_expiry: res?.data?.end_date ? false : true,
          shop_name: res?.data?.seller_id || undefined,
        });
        setStartDate(
          res?.data?.start_date
            ? moment(res?.data?.start_date).format("YYYY-MM-DD")
            : undefined
        );
        setEndDate(
          res?.data?.end_date
            ? moment(res?.data?.end_date).format("YYYY-MM-DD")
            : undefined
        );
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, []);

  const history = useNavigate();

  const handleDateSelect = (date, name) => {
    if (name === "start_date") {
      setStartDate(date);
      setShowStartDateCalender(false);
    }
    if (name === "end_date") {
      setEndDate(date);
      setShowEndDateCalender(false);
    }
  };

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 10);

  const handleChange = e => {
    const name = e.target.name;
    const value =
      e?.target?.type === "checkbox" ? e.target.checked : e.target.value;
    const fieldsObj = { ...fields };
    fieldsObj[name] = value;
    setFields(fieldsObj);
  };

  const handleValidation = () => {
    if (!fields?.unlimited_expiry && !endDate) {
      PrimaryToast({ type: "error", message: "End Date field is required!" });
      return false;
    }
    return true;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const data = {
      code: fields?.code || undefined,
      type: fields?.type || undefined,
      value: fields?.value?.toString() || undefined,
      platform: fields?.plateform || undefined,
      min_amount: fields?.min_amount || undefined,
      amount:
        fields?.min_amount > 0
          ? fields?.min_amount
            ? fields?.amount
            : undefined
          : undefined,
      limit: fields?.limit || 0,
      start_date:
        (startDate && moment(startDate).format("YYYY-MM-DD")) || undefined,
      end_date: !fields?.unlimited_expiry
        ? endDate && moment(endDate).format("YYYY-MM-DD")
        : null,
      unlimited_expiry: fields?.unlimited_expiry || false,
      is_active: !fields?.is_active ? 0 : 1,
    };
    if (handleValidation()) {
      toast.dismiss();
      setLoading(true);
      updateCoupon(data, id)
        .then(res => {
          PrimaryToast({ type: "success", message: res.data.message });
          history(-1);
        })
        .catch(err => {
          PrimaryToast({
            type: "error",
            message: err.response.data.message[0],
          });
          console.log(err);
        })
        .finally(res => setLoading(false));
    }
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"loading"} />}
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <TopHeading heading={"Edit Coupon"} />
          <div className="d-flex justify-content-between align-items-center">
            <Button
              onClick={() => {
                history(-1);
              }}
            >
              Cancel
            </Button>
            <Button className="ms-4" color="primary">
              Save
            </Button>
          </div>
        </div>
        <Card>
          <CardBody className="w-50">
            <h4 className="font-sem-bold">Coupon Information</h4>
            <div className="mb-3">
              <PrimaryInput
                label={"Discount Code"}
                name="code"
                value={fields?.code || ""}
                placeholder="Enter  Discount Code"
                type={"text"}
                isRequired
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <PrimaryInput
                label={"Store name"}
                name="code"
                value={fields?.shop_name || ""}
                placeholder="Enter shop name"
                type={"text"}
                isRequired
                isDisabled
              />
            </div>
            <div className="mb-3">
              <label>
                Type<span className="primary-text">*</span>
              </label>
              <div className="mb-2">
                <PrimaryRadio
                  label={"Percentage"}
                  id="percentage"
                  name={"type"}
                  value="1"
                  checked={fields?.type == 1}
                  handleChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <PrimaryRadio
                  label={"Fixed Amount"}
                  id="fixed_amount"
                  name={"type"}
                  value="2"
                  checked={fields?.type == 2}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-3">
              <SecondaryInput
                name="value"
                placeholder={"Enter Coupon Value"}
                text={fields?.type === 1 ? "%" : fields?.type == 2 ? "$" : "%"}
                value={fields?.value || ""}
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label>
                Platform<span className="primary-text">*</span>
              </label>
              <div className="mb-2">
                <PrimaryRadio
                  label={"Both"}
                  id="both"
                  name={"plateform"}
                  value="0"
                  checked={fields?.plateform == 0}
                  handleChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <PrimaryRadio
                  label={"Desktop"}
                  id="desktop"
                  name={"plateform"}
                  value="1"
                  checked={fields?.plateform == 1}
                  handleChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <PrimaryRadio
                  label={"Mobile"}
                  id="mobile"
                  name={"plateform"}
                  value="2"
                  checked={fields?.plateform == 2}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="inputEmail4">
                Minimum Requirements<span className="primary-text">*</span>
              </label>
              <div className="mb-2">
                <PrimaryRadio
                  label={"None"}
                  id="none"
                  name={"min_amount"}
                  value="0"
                  checked={fields?.min_amount == 0}
                  handleChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <PrimaryRadio
                  label={"Minimum Purchase Amount"}
                  id="minimum_purchase"
                  name={"min_amount"}
                  value="1"
                  checked={fields?.min_amount > 0}
                  handleChange={handleChange}
                />
              </div>
              {fields?.min_amount > 0 && (
                <SecondaryInput
                  placeholder={"Enter Minimum Purchase In Amount"}
                  text="$"
                  name={"amount"}
                  value={fields?.amount || ""}
                  handleChange={handleChange}
                />
              )}
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                name="limit"
                id="flexCheckChecked"
                checked={fields?.limit || ""}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Limit to one use per customer
              </label>
            </div>
            <div className="mb-3">
              <CalendarInput
                label="Start Date"
                handleShowCalender={() =>
                  setShowStartDateCalender(!showStartDateCalender)
                }
                showCalender={showStartDateCalender}
                handleChange={newDate =>
                  handleDateSelect(newDate, "start_date")
                }
                minDate={moment().toDate()}
                maxDate={maxDate}
                date={startDate ? new Date(startDate) : new Date()}
                value={startDate && moment(startDate).format("MM-DD-YYYY")}
                placeholder={"MM-DD-YYYY"}
              />
            </div>
            {!fields?.unlimited_expiry && (
              <div className="mb-3">
                <CalendarInput
                  label="End Date"
                  handleShowCalender={() =>
                    setShowEndDateCalender(!showEndDateCalendar)
                  }
                  showCalender={showEndDateCalendar}
                  handleChange={newDate =>
                    handleDateSelect(newDate, "end_date")
                  }
                  minDate={moment().toDate()}
                  maxDate={maxDate}
                  date={endDate ? new Date(endDate) : new Date()}
                  value={endDate && moment(endDate).format("MM-DD-YYYY")}
                  placeholder={"MM-DD-YYYY"}
                />
              </div>
            )}

            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="unlimited_expiry"
                name="unlimited_expiry"
                checked={fields?.unlimited_expiry}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="unlimited_expiry">
                Unlimited Expiry for this Coupon
              </label>
            </div>
            <div>
              <label htmlFor="inputEmail4">Status</label>
              <SwitchButton
                checked={fields.is_active === true ? true : false}
                label={fields.is_active === true ? "Active" : "Inactive"}
                name="is_active"
                value={fields?.is_active}
                handleChange={handleChange}
              />
            </div>
            <div></div>
          </CardBody>
        </Card>
      </form>
    </div>
  );
}

export default EditCoupon;
