import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import moment from "moment";
import { openLinkInNewTab } from "utils/linkHandling";

function AdInformation({ data }) {
  return (
    <div>
      <Card>
        <CardBody>
          <h4 className="text-15">Ad Information</h4>
          <Row className="py-2 border-top border-bottom">
            <Col sm="6">
              <div className="d-flex justify-content-between">
                <div className="font-semi-bold w-50">Platform:</div>
                <div className="w-50">{data?.platform?.toLowerCase() == 'facebook' ? "Facebook & Instagram" : data?.platform}</div>
              </div>
            </Col>
            <Col sm="6">
              <div className="d-flex justify-content-between">
                <div className="font-semi-bold w-25">Status:</div>

                <div className="w-75">
                  <span
                    className={`success-status ${
                      data?.status == 0
                        ? "warning-status"
                        : data?.status == 1 || data?.status == 2
                        ? "success-status"
                        : data?.status == 3 || data?.status == 5
                        ? "danger-status"
                        : data?.status == 4
                        ? "bg-secondary text-white"
                        : ""
                    } w-max-content status`}
                  >
                    {data?.status == 0
                      ? "Pending"
                      : data?.status == 1
                      ? "Running"
                      : data?.status == 2
                      ? "Complete"
                      : data?.status == 3
                      ? "Rejected"
                      : data?.status == 4
                      ? "Pause"
                      : data?.status == 5
                      ? "Expired"
                      : ""}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="py-2 border-bottom">
            <Col sm="6">
              <div className="d-flex justify-content-between">
                <div className="font-semi-bold w-50">Store Name:</div>
                <div className="w-50 font-sem-bold">{data["seller_name"]}</div>
              </div>
            </Col>
            <Col sm="6">
              <div className="d-flex justify-content-between">
                <div className="font-semi-bold w-25">Product Name:</div>
                <div
                  className={`w-75 text-primary  ${
                    data?.product_url ? "cursor-pointer" : ""
                  }`}
                  onClick={() => {
                    if (data?.product_url) {
                      openLinkInNewTab(`${data?.product_url}`);
                    } else {
                      return;
                    }
                  }}
                >
                  {data?.product_name}
                </div>
              </div>
            </Col>
          </Row>
          {data?.days ? (
            <Row className="py-2 border-bottom">
              <Col sm="6">
                <div className="d-flex justify-content-between">
                  <div className="font-semi-bold w-50">Days:</div>
                  <div className="w-50 font-sem-bold">
                    {data?.days}
                  </div>
                </div>
              </Col>
            </Row>
          ) : null}
          {data?.start_date || data?.end_date ? (
            <Row className="py-2 border-bottom">
              {data?.start_date ? (
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">Start Date:</div>
                    <div className="w-50 font-sem-bold">
                      {data?.start_date &&
                        moment(data?.start_date).format("DD-MM-YYYY")}
                    </div>
                  </div>
                </Col>
              ) : null}
              {data?.end_date ? (
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-25">End Date:</div>
                    <div className="w-75">
                      {data?.end_date &&
                        moment(data?.end_date).format("DD-MM-YYYY")}
                    </div>
                  </div>
                </Col>
              ) : null}
            </Row>
          ) : null}

          <Row className="py-2 border-bottom">
            <Col sm="6">
              <div className="d-flex justify-content-between">
                <div className="font-semi-bold w-50">Daily Budget:</div>
                <div className="w-50 font-sem-bold">${data?.per_day_spent}</div>
              </div>
            </Col>
            <Col sm="6">
              <div className="d-flex justify-content-between">
                <div className="font-semi-bold w-25">Total Budget:</div>
                <div className="w-75">${data?.total_budget}</div>
              </div>
            </Col>
          </Row>
          {data?.placement && (
            <Row className="py-2 border-bottom">
              <Col>
                <div className="d-flex justify-content-between">
                  <div className="font-semi-bold w-25">Placement:</div>
                  <div className="w-75">{data?.placement}</div>
                </div>
              </Col>
            </Row>
          )}

          {data?.platform?.toLowerCase() === "facebook" && (
            <>
              {data?.fb_campaign_id || data?.ad_set_id ? (
                <Row className="py-2 border-bottom">
                  {data?.fb_campaign_id ? (
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-50">Campaign Id:</div>
                        <div className="w-50 font-sem-bold">
                          {data?.fb_campaign_id}
                        </div>
                      </div>
                    </Col>
                  ) : null}
                  {data?.ad_set_id ? (
                    <Col sm="6">
                      <div className="d-flex justify-content-between">
                        <div className="font-semi-bold w-25">Ad Set Id:</div>
                        <div className="w-75">{data?.ad_set_id}</div>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              ) : null}
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default AdInformation;
