
import React, { useState } from "react";



import { Link } from "react-router-dom";


// Import menuDropdown

import ProfileMenu from "./ProfileMenu";

import logoLightPng from "assets/images/inspireuplift-logo.svg";
import logoLightSvg from "assets/images/logo-light.svg";
import CacheClearIcon from "assets/images/arrow-circle.svg";
import { clearCache } from "services/AuthServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { useSelector } from "react-redux";
import Disable2faPopup from "../Popups/Disable2faPopup";


const Header = () => {
	const [loading, setLoading] = useState(false);
	const [isDisabled2fa, setIsDisabled2fa] = useState(false); 
	const user = useSelector((state) => state?.auth?.user);
	const hasCachePer = user?.permissions?.some((p) => p.name === "artisan");



	const handleCache = () => {
		setLoading(true);
		clearCache()
			.then(res => {
				PrimaryToast({type:"success",message:res?.data?.message});
			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false));

	};

	return (
		<React.Fragment>
			{
				loading && <ProgressPopup label={"Loading..."} />
			}
			<header id="page-topbar">
				<div className="navbar-header">
					<div className="d-flex">
						<div className="navbar-brand-box">
							<Link to="/" className="logo">
								<span className="logo-sm">
									<img src={logoLightSvg} alt="" height="22" />
								</span>
								<span className="logo-lg">
									<img src={logoLightPng} alt="inspire uplift logo" />
								</span>
							</Link>
						</div>
					</div>
					<div className="d-flex">
						{
							hasCachePer && (
								<img src={CacheClearIcon} alt="cache clear icon" className="cursor-pointer" onClick={handleCache} />
							)
						}
						<ProfileMenu setIsDisabled2fa= {setIsDisabled2fa}  />
					</div>
				</div>
			</header>
			<Disable2faPopup isOpen={isDisabled2fa} setIsDisabled2fa= {setIsDisabled2fa} />
		</React.Fragment>
	)
}

export default Header;
