import React, { useEffect } from 'react';
import TopHeading from 'components/Common/TopHeading';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import HistoryComponet from 'components/Orders/HistoryComponet';
import { setPageTitle } from 'utils/pageTitle';

function ProductHistory() {
    const { id } = useParams();
    const history = useNavigate();
    const data = [

        {
            admin
                :
                "Yaseen Rehman",
            created_at
                :
                "09-05-2023 03:21:10 am",
            id
                :
                500,
                comment
                :
                "Product Approved",
        },

        {
            admin
                :
                "HomeDecoreAndMOre",
            created_at
                :
                "09-05-2023 03:21:10 am",
            id
                :
                500,
                comment
                :
                "Edited Product",
        },

        {
            admin
                :
                "Arif liaqat",
            created_at
                :
                "09-05-2023 03:21:10 am",
            id
                :
                500,
                comment
                :
                "Product Approved",
        },


    ];
    useEffect(()=>{
		setPageTitle("Product Detail - Inspire Uplift")
	},[]);

    return (
        <div className='page-content'>
            <div className='mb-3 d-flex justify-content-between align-items-center'>
                <TopHeading heading={`SELLER PRODUCT ID#${id}`} />
                <Button onClick={()=>history(-1)}>
                    Go Back
                </Button>
            </div>
            <div className='mt-3'>
                <HistoryComponet data={data} />
            </div>
        </div>
    )
}

export default ProductHistory; 