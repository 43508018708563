import React, { useEffect, useState } from "react";
import { setPageTitle } from "utils/pageTitle";
import CreatedGroupForm from "components/SEO/CreatedGroupForm";
import { createRelatedGroups } from "services/SEOKeywordsServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { useNavigate } from "react-router-dom";
import PrimaryToast from "components/Common/Toast/ToastMessage";

const CreateRelatedGroup = () => {
  const [fields, setFields] = useState({
    type: "category",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle("Create Related Group - Inspire Uplift");
  }, []);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const fieldsObj = { ...fields };
    fieldsObj[name] = value;
    setFields(fieldsObj);
  };

  const handleValidation = () => {
    const { category_ids, collection_ids, name } = fields;
    if (!name) {
      PrimaryToast({
        type: "error",
        message: "Title field cannot be empty",
      });
      return false;
    }
    if (!category_ids?.length && !collection_ids?.length) {
      PrimaryToast({
        type: "error",
        message: "Please select some categories or collections",
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (handleValidation()) {
      const collectionIdsStrToArr = fields?.collection_ids
        ?.trim()
        .split(",")
        .map(item => +item);
      const payload = {
        ...fields,
        collection_ids: fields?.collection_ids
          ? collectionIdsStrToArr
          : undefined,
        _id: fields?._id ? Number(fields?._id) : undefined,
      };

      setLoading(true);
      try {
        const response = await createRelatedGroups(payload);
        navigate("/seo-keywords/related-groups");
      } catch (err) {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: err.response.data.message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <CreatedGroupForm
        loading={loading}
        fields={fields}
        path="create"
        setFields={setFields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};
export default CreateRelatedGroup;
