export function checkMediaType(url) {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.jfif'];
    const videoExtensions = ['.mp4', '.mov', ];
  
    const fileExtension = url?.slice(url?.lastIndexOf('.')).toLowerCase();
  
    if (imageExtensions?.includes(fileExtension)) {
      return 'image';
    } else if (videoExtensions.includes(fileExtension)) {
      return 'video';
    } else {
      return 'unknown';
    }
};