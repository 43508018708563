import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import PrimarySearch from "components/Common/PrimarySearch";
import {
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Card,
  Button,
} from "reactstrap";
import PlusIcon from "../../assets/images/plus-icon.svg";
import deleteIcon from "../../assets/images/delete-icon-white.svg";
import optionIcon from "../../assets/images/options-icon.svg";
import editIcon from "../../assets/images/edit-icon-white.svg";
import { useNavigate } from "react-router-dom";
import {
  getAllUsers,
  deleteStore,
  handleEmployeeAction,
} from "services/SettingsServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryTable from "components/Common/PrimaryTable";
import DeleteModal from "components/Common/Toast/DeleteModal";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";


function EmployeeListing() {
  const [employeesData, setEmployeesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [selectedId, setSelectedId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [search, setSearch] = useState(null);
  const user = useSelector(state => state.auth.user);
  const permissions = user?.permissions?.map(item => item.name);
  const history = useNavigate();
  const params = new URLSearchParams(window?.location?.search);


  useEffect(() => {
    setPageTitle("Employees - Inspire Uplift");
  }, []);

  let randomColor = () => {
    return Math.random().toString(16).substr(-6);
  };

  const employeesList = (page, search) => {
    setLoading(true);
    getAllUsers(page, search)
      .then(res => {
        setPageCount(res.data.last_page);
        setEmployeesData(res.data?.data);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  useEffect(() => {

    const searchParams = params.get("search") || undefined;
    const page = params?.get("page") || undefined;
    setPageNo(page || 1);
    setSearch(searchParams);

    employeesList(page, searchParams);
  }, [window?.location?.search]);

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>
      ),
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (cell, row) => (
        <div className="d-flex align-items-center">
          {row.image ? (
            <img
              src={row.image}
              height={32}
              width={32}
              className="rounded-circle me-2"
              alt="employee profile"
            />
          ) : (
            <div className="avatar-xs me-2">
              <span
                className="avatar-title rounded-circle text-uppercase"
                style={{
                  background: `#${randomColor()}`,
                }}
              >
                {row.name.charAt(0)}
              </span>
            </div>
          )}
          {row.name}
        </div>
      ),
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "roles",
      text: "Role",
      formatter: (cell, row) => (
        <div className="admin-role">{row.roles[0]?.name}</div>
      ),
    },
    {
      dataField: "is_active",
      text: "Status",
      formatter: (cell, row) => (
        <div
          className={`${
            row?.is_active === 0 ? "danger-status" : "success-status"
          } status w-max-content`}
        >
          {row.is_active == 1 ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row) => (
        <div className="d-flex  align-items-center">
          {permissions?.includes("user-edit") && (
            <div
              className="action-btn edit-icon-bg me-1"
              onClick={e => {
                e.stopPropagation();
                history(`/employees/edit/${row.id}`);
              }}
            >
              <img src={editIcon} className="" alt="edit icon" />
            </div>
          )}
          {permissions?.includes("user-delete") && (
            <div
              className="action-btn delete-btn-bg cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                setSelectedId(row?.id);
                setShowDeleteModal(true);
              }}
            >
              <img src={deleteIcon} className="" alt="delete icon" />
            </div>
          )}
          {permissions?.includes("user-action") ? (
            <UncontrolledDropdown className="">
              <DropdownToggle
                tag="div"
                className="card-drop px-3"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <img src={optionIcon} alt="rectangeArrow" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {row?.is_active == 1 && (
                  <DropdownItem
                    onClick={e => {
                      e.stopPropagation();
                      handleAction(row.id, 0);
                    }}
                  >
                    Disable
                  </DropdownItem>
                )}
                {row?.is_active == 0 && (
                  <DropdownItem
                    onClick={e => {
                      e.stopPropagation();
                      handleAction(row.id, 1);
                    }}
                  >
                    Enable
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : null}
        </div>
      ),
    },
  ];

  const handleAction = (id, type) => {
    toast.dismiss();
    setLoading(true);
    handleEmployeeAction(id, type)
      .then(res => {
        PrimaryToast({ type: "success", message: res.data?.message });
        employeesList(pageNo);
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response.data?.message });
      })
      .finally(res => setLoading(false));
  };
  const handleDelete = () => {
    const filterData = employeesData?.filter((item) => item.id !== selectedId);
    toast.dismiss();
    setLoading(true);
    deleteStore(selectedId)
      .then(res => {
        if(filterData?.length == 0 && pageNo > 1) {
          params.set('page',pageNo  -1);
          let newUrl = `${window?.location?.pathname}?${params.toString()}`;
          history(newUrl, { replace: true });
        }
        else {
          employeesList(pageNo, search);
        }
        setSelectedId();
        setShowDeleteModal(false);
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response.data?.message });
      })
      .finally(res => setLoading(false));
  };

  const data = employeesData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleSearch = e => {
    const val = e.target.value;
    setSearch(val);
  };


  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (params?.get("page")) {
        params?.delete("page");
      }
      if (search) {
        params?.set("search", search);
      } else {
        params?.delete("search");
      }
      history(`/employees/list?${params?.toString()}`);
    }
  };

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this user?"}
          onConfirm={handleDelete}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}

      <div className="d-flex justify-content-between align-items-center mb-3">
        <TopHeading heading={"Employees"} />
        {permissions?.includes("user-create") && (
          <Button
            color="primary"
            className="d-flex align-items-center"
            onClick={() => history("/employees/create")}
          >
            <img src={PlusIcon} className="me-2" alt="cretae employee" />
            Register new Employee
          </Button>
        )}
      </div>
      <Card>
        <CardBody>
          <PrimarySearch
            placeholder={"Search by name, Email"}
            handleChange={handleSearch}
            value={search}
            handleKeyPress={handleKeyPress}
          />
          <div className="mt-3">
            <PrimaryTable
              columns={columns}
              data={data}
              bordered={false}
              keyField="id"
              isPermission={true}
              rowUrl={'/user/detail'}
            />
          </div>
          {employeesData?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              setPageNo={setPageNo}
              pageNo={pageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default EmployeeListing;
