import React from "react";
import { CardBody, Card } from "reactstrap";

const PrimaryCard = ({title, count,icon}) => {
    return (
        <Card>
            <CardBody>
                <div className="d-flex justify-content-between align-items-center" style={{minHeight:"66px"}}>
                    <div>
                        <p className="font-medium">{title}</p>
                        <h4>{count}</h4>
                    </div>
                    <img src={icon} alt="channel icon" />
                </div>
            </CardBody>
        </Card>
    )
}
export default PrimaryCard;