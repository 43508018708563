import React from 'react';
import editIcon from "assets/images/edit-blue-icon.svg";

function BalanceCard({heading,balance, handlePopup}) {
  return (
    <div className='balance-card-container'> 
        <div className='d-flex text-15'>
            <div className='font-medium'>{heading}</div>
            <img src={editIcon} className="ms-2 cursor-pointer" alt="edit icon" onClick={()=>handlePopup(heading,balance)} />
        </div>
        <div className='fs-3 font-semi-bold '>
            ${parseFloat(balance)?.toFixed(2)  || 0}
        </div>
    </div>
  )
}

export default BalanceCard