import React, { Fragment } from "react";
import { Card, CardBody, } from "reactstrap";
import { useState } from "react";

import PrimaryInput from "components/Common/PrimaryInput";
import { forgotPassword } from "services/AuthServices";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import successIcon from "assets/images/tick-greenIcon.svg";
import { useNavigate } from "react-router-dom";

const ForgetPasswordPage = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [isEmailSend, setIsEmailSent] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        forgotPassword(email)
            .then(res => {
                console.log();
                setIsEmailSent(true);
                setEmail("");
            }).catch((err) => {
                console.log(err);
                PrimaryToast({ type: "error", message: err?.response?.data?.message });
            })
            .finally(res => {
                setLoading(false);
            })
    }

    return (
        <Fragment>
            {
                loading && <ProgressPopup label={"Loading..."} />
            }
            <div className="d-flex justify-content-center align-items-center h-screen w-100">
                <div>
                    {
                        !isEmailSend ? (
                            <Card className="overflow-hidden forgot-password-wrapper">
                                <h4 className="text-center">Forgot Password</h4>
                                <p className="text-center mb-0 text-13 text-gray-700 ">Enter the email associated with your account.</p>
                                <CardBody className="">
                                    <div className="">
                                        <form className="form-horizontal" onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <PrimaryInput
                                                    name={"email"}
                                                    type="email"
                                                    label={"Email"}
                                                    placeholder="Email Address"
                                                    handleChange={(e) => {
                                                        setEmail(e.target.value);
                                                    }}

                                                />
                                            </div>
                                            <button
                                                className="w-100 bg-primary text-white outline-none border-0 p-2 rounded"
                                                type="submit"
                                            >
                                                Send Reset Email
                                            </button>
                                        </form>
                                    </div>
                                </CardBody>
                            </Card>

                        ) : (
                            <Card className="forgot-password-wrapper d-flex justify-content-center align-items-center flex-column ">
                                <img src={successIcon} height={77} width={77} className="text-center" alt="success-icon" />
                                <h4 className="mt-3 text-center">Check Your Email</h4>
                                <p className="text-center text-13 text-gray-700 ">We have sent a password reset link.</p>
                                <button
                                    className="w-100 bg-primary text-white outline-none border-0 p-2 rounded"
                                    type="button"
                                    onClick={() => navigate("/login")}
                                >
                                    Done
                                </button>
                            </Card>
                        )
                    }
                </div>
            </div>

        </Fragment>
    )
}



export default ForgetPasswordPage;
