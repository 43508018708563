import React from "react";
import { CardBody, Card } from "reactstrap";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryTable from "components/Common/PrimaryTable";
import moment from "moment";

function CustomerOrdersTabel({ setPageNo, data, pageCount, pageNo }) {
  const columns = [
    {
      dataField: "id",
      text: "Order ID",
      formatter: (cell, row) => <div className="font-semi-bold">{row.id}</div>,
    },
    {
      dataField: "fulfillment_status",
      text: "Fulfillment Status",
      formatter: (cell, row) => {
        let fulfilment_status;

        switch (row.fulfillment_status) {
          case "0":
            fulfilment_status = "Unfulfilled";
            break;
          case "1":
            fulfilment_status = "Fulfilled";
            break;
          case "2":
            fulfilment_status = "Partially fulfilled";
            break;
          default:
            fulfilment_status = "Unfulfilled";
            break;
        }
        return (
          <div className="w-max-content fulfilment_status">
            {fulfilment_status}
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => {
        let status;
        switch (row.order_status) {
          case 0:
            status = "Unpaid";
            break;
          case 1:
            status = "Paid";
            break;
          case 2:
            status = "Refunded";
            break;
          default:
            status = "Unpaid";
            break;
        }
        return (
          <div
            className={`w-max-content status ${
              row.financial_status === "Paid"
                ? "success-status"
                : row.financial_status === "Unpaid" ||
                  row.financial_status === "Pending"
                ? "warning-status"
                : row.financial_status === "Refunded" ||
                  row.financial_status === "Partially Refunded"
                ? "danger-status"
                : ""
            }`}
          >
            {row.financial_status}
          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Order Date",
      formatter: (cell, row) => (
        <div>
          {moment(row?.created_at)
            .format("MM-DD-YYYY")}
        </div>
      ),
    },
    {
      dataField: "order_refunds",
      text: "Refund Total",
      formatter: (cell, row) =>
        row.order_refunds ? <div>${row?.order_refunds}</div> : null,
    },
    {
      dataField: "total_price",
      text: "Grand Total",
      formatter: (cell, row) => {
        return <div>${row.total_price}</div>;
      },
    },
  ];

  return (
    <div>
      <Card>
        <CardBody>
          <div className="h5 font-semi-bold">Orders</div>
          <div className="mt-3">
            <PrimaryTable
              columns={columns}
              bordered={false}
              data={data}
              keyField="order_number"
            />
          </div>
          {data?.length > 0 && (
            <PrimaryPagination
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default CustomerOrdersTabel;
