import React from "react";
import { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import { useLocation } from "react-router-dom";
import PrimaryInput from "components/Common/PrimaryInput";
import PrimarySelect from "components/Common/PrimarySelect";
import { createStakeHolder } from "services/SellerPayoutMethodService";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { useNavigate } from "react-router-dom";
import { setPageTitle } from "utils/pageTitle";
import { useSelector } from "react-redux";
import GlobalService from "services/GlobalService";
import moment from "moment";
import CalendarInput from "components/Common/CalenderInput";

function CreateStakeHolders() {
  const [fields, setFields] = useState({});
  const [errors, setErrror] = useState({});
  const [loading, setLoading] = useState(false);
  const [statesData, setStates] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [showCalender, setShowCalender] = useState(false);
  const [isProvinceText, setIsProvinceText] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sellerId = searchParams.get("sellerId");
  const navigate = useNavigate();
  const globalService = GlobalService();

  const permissions = useSelector(state => state?.auth?.user?.permissions);
  const hasStakeEditPer = permissions?.some(p => p.name === "stakeholder-edit");

  useEffect(() => {
    setPageTitle("Create Seller Stakeholder - Inspire Uplift");
    getStates();
    getCountries();
  }, []);

  const getStates = () => {
    globalService
      .getStates()
      .then(response => {
        setStates([
          { name: "Please select one...", value: "" },
          ...response?.data?.data,
        ]);
      })
      .catch(error => console.log(error.message));
  };

  const getCountries = () => {
    globalService
      .getCountries()
      .then(response => {
        setCountriesData([
          { name: "Please select one...", value: "" },
          ...response?.data?.data,
        ]);
      })
      .catch(error => console.log(error.message));
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const fieldsObj = { ...fields };
    setErrror({});

    if (name === "firstName" || name === "lastName") {
      if (value.length > 0 && !value.match(/^[a-zA-Z\s-.,'/]+$/)) {
        const errorsFields = { ...errors };
        errorsFields[name] =
          "Only alphabets and some special characters are allowed.";
        setErrror(errorsFields);
        return false;
      } else {
        fieldsObj[name] = value;
      }
    } else if (name === "phoneNumber") {
      if (!value.match(/^[0-9+()\- ]*$/)) {
        const errorsFields = { ...errors };
        errorsFields[name] =
          "Only numbers and some special characters are allowed.";
        setErrror(errorsFields);
        return false;
      } else {
        fieldsObj[name] = value;
      }
    } else {
      fieldsObj[name] = value;
    }

    if (name === "country") {
      if (value !== "US") {
        setIsProvinceText(true);
        fieldsObj["stateProvince"] = "";
      } else {
        setIsProvinceText(false);
      }
    }
    setFields(fieldsObj);
  };

  const handleValidation = () => {
    const fieldsObj = { ...fields };
    if (!fields?.firstName) {
      PrimaryToast({
        type: "error",
        message: "First name cannot be empty",
      });
      return false;
    }
    if (!fields?.lastName) {
      PrimaryToast({
        type: "error",
        message: "Last name cannot be empty",
      });
      return false;
    }

    if (!fields?.dateOfBirth) {
      PrimaryToast({
        type: "error",
        message: "Date of birth cannot be empty",
      });
      return false;
    }
    if (
      fields["dateOfBirth"] &&
      new Date(fields["dateOfBirth"]).getFullYear() >
        new Date().getFullYear() - 18
    ) {
      PrimaryToast({
        type: "error",
        message: "Date of birth must be a date before 18 years ago.",
      });
      return false;
    }

    if (!fields?.countryOfBirth) {
      PrimaryToast({
        type: "error",
        message: "Country of birth cannot be empty",
      });
      return false;
    }

    if (!fields?.countryOfNationality) {
      PrimaryToast({
        type: "error",
        message: "Country of nationality cannot be empty",
      });
      return false;
    }

    if (!fields?.isDirector) {
      PrimaryToast({
        type: "error",
        message: "Director contact cannot be empty",
      });
      return false;
    }

    if (!fields?.isUltimateBeneficialOwner) {
      PrimaryToast({
        type: "error",
        message: "Ultimate beneficial owner cannot be empty",
      });
      return false;
    }

    if (!fields?.isBusinessContact) {
      PrimaryToast({
        type: "error",
        message: "Business Contact cannot be empty",
      });
      return false;
    }

    if (
      (fieldsObj?.isDirector == "false" || fieldsObj?.isDirector == false) &&
      (fieldsObj?.isUltimateBeneficialOwner == "false" ||
        fieldsObj?.isUltimateBeneficialOwner == false)
    ) {
      PrimaryToast({
        type: "error",
        message: "Director or beneficially one of them should be yes",
      });
      return false;
    }

    if (!fields?.email) {
      PrimaryToast({
        type: "error",
        message: "Email cannot be empty",
      });
      return false;
    }

    if (
      !fields["email"].match(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      )
    ) {
      PrimaryToast({
        type: "error",
        message: "Email is not valid",
      });
      return false;
    }
    if (!fields?.phoneNumber) {
      PrimaryToast({
        type: "error",
        message: "Phone Number cannot be empty",
      });

      return false;
    }

    if (!fields?.addressLine1) {
      PrimaryToast({
        type: "error",
        message: "Address cannot be empty",
      });
      return false;
    }

    if (!fields?.city) {
      PrimaryToast({
        type: "error",
        message: "City cannot be empty",
      });
      return false;
    }

    if (!fields?.country) {
      PrimaryToast({
        type: "error",
        message: "Country cannot be empty",
      });
      return false;
    }

    if (!fields?.stateProvince) {
      PrimaryToast({
        type: "error",
        message: "State field cannot be empty",
      });
      return false;
    }

    if (!fields?.postalCode) {
      PrimaryToast({
        type: "error",
        message: "Zip cannot be empty",
      });
      return false;
    }

    return true;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const payload = {
      first_name: fields?.firstName || undefined,
      last_name: fields?.lastName || undefined,
      email: fields?.email || fields?.email || undefined,
      address: fields?.addressLine1 || undefined,
      province: !isProvinceText
        ? statesData?.find(
            state => state?.abbreviation == fields?.stateProvince
          )?.name
        : fields?.stateProvince || undefined,
      province_code: fields?.stateProvince || undefined,
      city: fields?.city || undefined,
      country_of_birth: fields?.countryOfBirth || undefined,
      country:
        countriesData?.find(country => country?.alpha2Code == fields?.country)
          ?.name || undefined,
      country_code: fields?.country || undefined,
      zip_code: fields?.postalCode || undefined,
      dob: fields?.dateOfBirth || undefined,
      country_of_nationality: fields?.countryOfNationality || undefined,
      phone_number: fields?.phoneNumber || undefined,
      is_business_contact: fields?.isBusinessContact
        ? fields?.isBusinessContact === true ||
          fields?.isBusinessContact === "true"
          ? true
          : false
        : undefined,
      is_director: fields?.isDirector
        ? fields?.isDirector == "true" || fields?.isDirector == true
          ? true
          : false
        : undefined,
      is_ultimate_beneficial_owner: fields?.isUltimateBeneficialOwner
        ? fields?.isUltimateBeneficialOwner == "true" ||
          fields?.isUltimateBeneficialOwner == true
          ? true
          : false
        : undefined,
    };
    if (handleValidation()) {
      setLoading(true);
      createStakeHolder(payload, sellerId)
        .then(res => {
          PrimaryToast({
            type: "success",
            message: "Stakeholder update successfully",
          });
          navigate(`/seller/stores/edit/${sellerId}`);
        })
        .catch(err => {
          PrimaryToast({
            type: "error",
            message: Array.isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message,
          });
        })
        .finally(res => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <TopHeading heading={"Payout Method Stake Holders"} />
            <div
              className={`ms-2 ${
                fields?.status === "DE_ACTIVATED"
                  ? "danger-status"
                  : "success-status"
              } status w-max-content`}
            >
              {fields?.status}
            </div>
          </div>

          <div className="flex align-items-center">
            <Button
              onClick={() => navigate(`/seller/stores/edit/${sellerId}`)}
              className="me-2"
            >
              Go Back
            </Button>
            {hasStakeEditPer ? (
              <Button color="primary" type="submit">
                Save
              </Button>
            ) : null}
          </div>
        </div>
        <Card>
          <CardBody className="w-50">
            <h4 className="font-semi-bold mb-3">Personal Information</h4>
            <div className="mb-3">
              <PrimaryInput
                label={"First Name"}
                isRequired
                name="firstName"
                placeholder="Enter First Name"
                value={fields?.firstName || ""}
                handleChange={handleChange}
                maxLength={50}
              />
              {errors["firstName"] && (
                <div className="text-11 text-danger">{errors["firstName"]}</div>
              )}
            </div>
            <div className="mb-3">
              <PrimaryInput
                label={"Last Name"}
                isRequired
                name="lastName"
                placeholder="Enter Last Name"
                value={fields?.lastName || ""}
                handleChange={handleChange}
                maxLength={50}
              />
              {errors["lastName"] && (
                <div className="text-11 text-danger">{errors["lastName"]}</div>
              )}
            </div>
            <div className="mb-3">
              <CalendarInput
			  	isRequired={true}
                label="Date of Birth"
                handleShowCalender={() => setShowCalender(!showCalender)}
                showCalender={showCalender}
                handleChange={newDate => {
                  setFields({
                    ...fields,
                    dateOfBirth: moment(newDate).format("YYYY-MM-DD"),
                  });
                  setShowCalender(!showCalender);
                }}
                date={
                  fields?.dateOfBirth
                    ? new Date(fields?.dateOfBirth)
                    : new Date()
                }
                value={
                  fields?.dateOfBirth &&
                  moment(fields?.dateOfBirth).format("YYYY-MM-DD")
                }
                placeholder={"YYYY-MM-DD"}
              />
            </div>

            <div className="mb-3">
              <PrimarySelect
                label={"Country of Birth"}
                name="countryOfBirth"
                isRequired
                handleChange={handleChange}
                value={fields?.countryOfBirth || ""}
                options={countriesData || []}
                path="country"
              />
            </div>
            <div className="mb-3">
              <PrimarySelect
                label={"Country of Nationality"}
                name="countryOfNationality"
                isRequired
                handleChange={handleChange}
                value={fields?.countryOfNationality || ""}
                options={countriesData || []}
                path="country"
              />
            </div>
            <div className="mb-3">
              <PrimarySelect
                label={"Director"}
                name="isDirector"
                isRequired
                handleChange={handleChange}
                value={fields?.isDirector}
                options={[
                  {
                    name: "Please select one",
                    value: "",
                  },
                  {
                    name: "Yes",
                    value: true,
                  },
                  {
                    name: "No",
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="mb-3">
              <PrimarySelect
                label={"Ultimate Beneficial Owner"}
                name="isUltimateBeneficialOwner"
                isRequired
                handleChange={handleChange}
                value={fields?.isUltimateBeneficialOwner}
                options={[
                  {
                    name: "Please select one",
                    value: "",
                  },
                  {
                    name: "Yes",
                    value: true,
                  },
                  {
                    name: "No",
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="mb-3">
              <PrimarySelect
                label={"Business Contact"}
                name="isBusinessContact"
                isRequired
                handleChange={handleChange}
                value={fields?.isBusinessContact}
                options={[
                  {
                    name: "Please select one",
                    value: "",
                  },
                  {
                    name: "Yes",
                    value: true,
                  },
                  {
                    name: "No",
                    value: false,
                  },
                ]}
              />
            </div>
            <h4 className="font-semi-bold mb-3">Contact Information</h4>
            <div className="mb-3">
              <PrimaryInput
                label={"Email"}
                isRequired
                name="email"
                placeholder="Enter email"
                value={fields?.email || ""}
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <PrimaryInput
                label={"Phone Number"}
                isRequired
                name="phoneNumber"
                placeholder="Enter phone number"
                value={fields?.phoneNumber || ""}
                handleChange={handleChange}
                maxLength={15}
              />
              {errors["phoneNumber"] && (
                <div className="text-11 text-danger">
                  {errors["phoneNumber"]}
                </div>
              )}
            </div>
            <h4 className="font-semi-bold mb-3">Home Address</h4>
            <div className="mb-3">
              <PrimaryInput
                name={"addressLine1"}
                label={"Address"}
                placeholder="Enter Address"
                type={"text"}
                isRequired
                value={fields?.addressLine1 || ""}
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <PrimaryInput
                name={"city"}
                label={"City"}
                placeholder="Enter City"
                type={"text"}
                isRequired
                value={fields?.city || ""}
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <PrimarySelect
                label={"Country"}
                name="country"
                isRequired
                handleChange={handleChange}
                value={fields?.country || ""}
                options={countriesData || []}
                path="country"
              />
            </div>
            {!isProvinceText ? (
              <div className="mb-3">
                <PrimarySelect
                  label={"State / Province"}
                  name="stateProvince"
                  isRequired
                  handleChange={handleChange}
                  value={fields?.stateProvince || ""}
                  options={statesData || []}
                  path="state"
                />
              </div>
            ) : (
              <div className="mb-3">
                <PrimaryInput
                  name={"stateProvince"}
                  label={"State / Province"}
                  placeholder="Enter State Code"
                  type={"text"}
                  isRequired
                  value={fields?.stateProvince || ""}
                  handleChange={handleChange}
                />
              </div>
            )}
            <div className="mb-3">
              <PrimaryInput
                name={"postalCode"}
                label={"Zip"}
                type={"text"}
                isRequired
                value={fields?.postalCode || ""}
                placeholder="Enter postal code"
                handleChange={handleChange}
              />
            </div>
          </CardBody>
        </Card>
      </form>
    </div>
  );
}

export default CreateStakeHolders;
