import React, { useEffect, useState } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import PrimaryInput from "components/Common/PrimaryInput";
import SecondaryInput from "components/Common/SecondaryInput";
import { useParams } from "react-router-dom";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { getSingleSellerGroup, updateSellerGroup } from "services/SellerGroupsServices";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import PrimaryRadio from "components/Common/PrimaryRadio";

function EditSellerGroup() {
	const [fields, setFields] = useState({});
	const [loading, setLoading] = useState(false);



	const history = useNavigate();
	const params = useParams();
	const { id } = params;

   useEffect(()=>{
		setPageTitle("Edit Seller Group - Inspire Uplift")
	},[]);

	useEffect(() => {

		setLoading(true);
		getSingleSellerGroup(id)
			.then(res => {
				setFields({
					group_name: res?.data?.name || undefined,
					seller_commission: res?.data?.commission_fee || undefined,
					seller_processing: res?.data?.processing_fee || undefined,
					product_auto_approve: res?.data?.product_auto_approve || 0,
					seller_in_review: res.data.seller_in_review === 1 ? true : undefined,
					holding_duration: res.data.holding_duration || undefined,
					holding_amount: res.data.holding_amount || undefined
				})
			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false));
	}, [])


	const handleChange = e => {
		const name = e.target.name;
		const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
		const fieldsObj = { ...fields };
		fieldsObj[name] = value;
		setFields(fieldsObj);
	};

	const handleValidation =()=>{
		toast.dismiss();
		if(fields?.seller_in_review && (!fields?.holding_duration)){
			PrimaryToast({type:'error',message:"Days field is request when seller is in review"});
			return false
		}
		else if(fields?.seller_in_review && (!fields?.holding_amount)){
			PrimaryToast({type:'error',message:"Amount Holding field is request when seller is in review"});
			return false
		}
		return true;
	}


	const handleSubmit = e => {
		e.preventDefault();
		const data = {
			...fields,
			holding_duration: (fields?.seller_in_review && fields?.holding_duration) || undefined,
			holding_amount: (fields?.seller_in_review && fields?.holding_amount) || undefined,
		};
		if(handleValidation()){
			toast.dismiss();
			setLoading(true);
			updateSellerGroup(data, id)
				.then(res => {
					if (res.status === 200) {
						history(-1);
						PrimaryToast({ type: 'success', message: res?.data?.message });
					}
				})
				.catch(err => {
					console.log(err);
					PrimaryToast({ type: 'error', message: err?.response?.data?.message[0] });
				})
				.finally(res => setLoading((false)));
		}


	};

	return (
		<div className="page-content">
			{
				loading && <ProgressPopup label={"Loading..."} />
			}
			<form onSubmit={handleSubmit}>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<TopHeading heading={"Edit Seller Group"} />
					<div className="d-flex">
						<Button className="me-2" onClick={() => history(-1)}>
							Cancel
						</Button>
						<Button color="primary" type="submit">
							Save
						</Button>
					</div>
				</div>
				<Card>
					<CardBody>
						<h4 className="font-semi-bold mb-3">Group Information</h4>
						<div className="w-50">
							<div className="mb-3">
								<PrimaryInput
									name={"group_name"}
									type={"text"}
									placeholder="Enter Group Name"
									label={"Group Name"}
									isRequired
									value={fields?.group_name || ''}
									handleChange={handleChange}
								/>
							</div>
							<div className="mb-3">
								<SecondaryInput
									text="%"
									label={"Seller Commission"}
									name="seller_commission"
									placeholder={"Enter Commission Fee"}
									isRequired
									value={fields?.seller_commission || ""}
									handleChange={handleChange}
								/>
							</div>
							<div className="mb-3">
								<SecondaryInput
									text="%"
									label={"Seller Processing"}
									name="seller_processing"
									placeholder={"Enter Processing Fee"}
									isRequired
									value={fields?.seller_processing || ""}
									handleChange={handleChange}
								/>
							</div>
                            <div className="">
                                <label className="form-check-label mb-2" htmlFor="productAutoApprove">
									Product Auto Approve<span className='primary-text'>*</span>
								</label>
                                <div className="mb-3">
                                        <PrimaryRadio
                                            label={"None"}
                                            id="productAutoApproveNone"
                                            name={"product_auto_approve"}
                                            value={0}
                                            checked={fields?.product_auto_approve == 0}
                                            handleChange={handleChange}
                                        />
                                    </div>
                                <div className="mb-3">
                                    <PrimaryRadio
                                        label={"Product Auto Approve"}
                                        id="productAutoApprove"
                                        name={"product_auto_approve"}
                                        value={1}
                                        checked={fields?.product_auto_approve == 1}
                                        handleChange={handleChange}
                                    />
                                </div>
                                <div className="mb-3">
                                    <PrimaryRadio
                                        label={"Fast Approval"}
                                        id="fastApproval"
                                        name={"product_auto_approve"}
                                        value={2}
                                        checked={fields?.product_auto_approve == 2}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
							<div className="form-check mb-3">
								<input className="form-check-input" checked={fields?.seller_in_review} onChange={handleChange} name='seller_in_review' type="checkbox" id="sellerInReview" />
								<label className="form-check-label" htmlFor="sellerInReview">
									Seller In-Review
								</label>
							</div>
							{
								fields?.seller_in_review && (
									<div>
										<div className="mb-3">
											<PrimaryInput
												name={"holding_duration"}
												value={fields?.holding_duration || ''}
												type={"text"}
												placeholder="Enter In Review Days"
												label={'Days'}
												isRequired
												handleChange={handleChange}
											/>
										</div>
										<div className="mb-3">
											<SecondaryInput
												text="%"
												label={"Amount Holding"}
												name="holding_amount"
												placeholder={'Enter Amount Holding'}
												value={fields?.holding_amount || ''}
												isRequired
												handleChange={handleChange}
											/>
										</div>
									</div>
								)
							}
						</div>
					</CardBody>
				</Card>
			</form>
		</div>
	);
}

export default EditSellerGroup;
