import React from "react";
import { Calendar } from "react-date-range";
import CalenderLogo from "assets/images/calender-icon.svg";

const CalendarInput = ({ handleChange, minDate, date, maxDate, label, showCalender, handleShowCalender, value, placeholder, styling, isRequired }) => {

    return (
        <div className="position-relative">
            <label>{label}{isRequired && <span className="primary-text">*</span>}</label>
            <div className="d-flex calender-input-container" onClick={handleShowCalender}>
                <div className="calender-date-section">
                    {value ? value : placeholder}
                </div>
                <div className="calender-icon-wrapper">
                    <img src={CalenderLogo} alt="calender icon" />
                </div>
            </div>
            {
                showCalender && (
                    <>
                        <div
                            className="position-fixed top-0 start-0 w-100 h-100 z-10"
                            onClick={handleShowCalender}
                        ></div>
                        <div
                            className={`position-absolute bg-white calendar-input-container  sm:overflow-visible overflow-auto ${styling}`}
                        >
                            <Calendar
                                onChange={handleChange}
                                minDate={minDate && minDate}
                                date={date}
                                maxDate={maxDate && maxDate}
                            />
                        </div>
                    </>

                )
            }

        </div>
    );
};

export default CalendarInput;
