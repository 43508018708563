import React from "react";
import { CardBody, Card } from "reactstrap";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";

function HistoryComponet({ data, pageNo, pageCount, setPageNo }) {
  return (
    <div>
      <Card>
        <CardBody>
          <div className="h5  font-semi-bold">History</div>
          {data.map((item, index) => {
            const { created_at, admin, comment, tracking_number, status } =
              item;

            return (
              <div
                key={index}
                className={`${
                  data.length - 1 === index
                    ? "order-history-container"
                    : "order-history-container border-apply"
                }`}
              >
                <div className="order-details-inner-wrapper">
                  <div className="font-semi-bold">
                    {tracking_number ? status : comment}
                  </div>
                  <div className="font-normal py-2">
                    {admin}{" "}
                    {tracking_number
                      ? `${status} ${
                          tracking_number.length > 0 &&
                          tracking_number.map(item => item)
                        }`
                      : comment}{" "}
                    on {created_at}.
                  </div>
                </div>
              </div>
            );
          })}
          {pageNo && data?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              setPageNo={setPageNo}
              pageCount={pageCount}
              pageNo={pageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default HistoryComponet;
