import React from "react";
import PrimaryPopup from "./PrimaryPopup";
import { Button } from "reactstrap";

function AdsApprovePopup({ isOpen,
    handleClick,
    setAdSetId,
    adSetId,
    campaignId,
    setCampaignId,
    handleApprove,
	isCamIdExist,
  loading
 }) {
  return (
    <PrimaryPopup
      isOpen={isOpen}
      handleClick={handleClick}
      title={"Approve Product Ad"}
      isScrollable={false}
      className="d-flex justify-content-center align-items-center h-100 w-100"
    >
      <div>
		{
			!isCamIdExist && (
				<div className="mb-3">
					<label htmlFor="inputEmail4">Campaign ID</label>
					<input
					type="text"
					className="form-control"
					id="inputEmail4"
					placeholder="Enter Campaign ID"
					value={campaignId}
					name="campaignId"
					onChange={(e)=>{
						setCampaignId(e.target.value);
					}}
					/>
			  	</div>
			) 
		}
        <div className="mb-3">
          <label htmlFor="inputEmail4">Ad Set ID</label>
          <input
            type="text"
            className="form-control"
            id="inputEmail4"
            placeholder="Enter Ad Set ID"
            value={adSetId}
            name="adSetId"
            onChange={(e)=>{
                setAdSetId(e.target.value);
            }}
          />
        </div>
        <div className="d-flex  justify-content-end">
          <Button disabled={loading} color="primary" onClick={handleApprove}>Approve</Button>
        </div>
      </div>
    </PrimaryPopup>
  );
}

export default AdsApprovePopup;
