import React from "react";
import PrimaryPopup from "./PrimaryPopup";
import { Button } from "reactstrap";

function ImportCSVFilePopup({
  handleClick,
  isOpen,
  handleFileUpload,
  handleSave,
  title,
  loading,
}) {
  return (
    <PrimaryPopup
      title={title}
      handleClick={handleClick}
      isScrollable={false}
      className={"d-flex justify-content-center align-items-center h-100 w-100"}
      isOpen={isOpen}
    >
      <form>
        <div className="mb-4">
          <label htmlFor="formFile" className="form-label">
            {title}(.csv file)
          </label>
          <input
            className="form-control"
            accept="text/csv"
            type="file"
            id="formFile"
            onChange={handleFileUpload}
          />
        </div>
        <div className="order-summary-action-section d-flex justify-content-end">
          <div className="d-flex align-items-center">
            <Button
              className="me-4"
              color="primary"
              type="button"
              disabled={loading}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button onClick={handleClick} type="button">
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </PrimaryPopup>
  );
}

export default ImportCSVFilePopup;
