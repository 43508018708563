import React, { useState } from "react";
import PrimaryTable from "components/Common/PrimaryTable";
import deleteIcon from "assets/images/cancle-icon.svg";
import tickIcon from "assets/images/tick-icon.svg";
import { Button, Card, CardBody } from "reactstrap";
import SeoProductsIdsPopup from "components/Common/Popups/SeoProductsIdsPopup";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import DeleteModal from "components/Common/Toast/DeleteModal";
import CreateCollectionPopup from "components/Common/Popups/CreateCollectionPopup";
import KeywordsFilters from "./KeywordsFilters";
import ExportButton from "components/Common/ExportButton";
import { downloadSeoKeywords } from "services/SEOKeywordsServices";

const KeywordsResultTable = ({
loading,
  data,
  pageNo,
  setKeywordsPage,
  pageCount,
  handleCreateCollection,
  handleDeleteKeyWords,
  totalResults,
  setLoading,
  handleCalenderClick,
  showCalendar,
  dates,
  filters,
  handleFilterChange,
  setFilters,
  handleApplyFilter,
  handleResetFilter,
  handleDatePicker,
  isAppliedFilter,
  setDates,
  selectRow,
  selectedRowKeys,
  setActionType,
  actionType,
  setSelectedRowKeys,
}) => {
  const [isApprove, setIsApprove] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState();
  const [isReject, setIsReject] = useState(false);
  const [fields, setFields] = useState({});

  const columns = [
    {
      dataField: "id",
      text: "ID",
      formatter: (cell, row) => (
        <div className="font-semi-bold text-13">{row.id}</div>
      ),
    },
    {
      dataField: "keyword",
      text: "Name",
      formatter: (cell, row) => (
        <div className="font-semi-bold text-13">{row.keyword}</div>
      ),
    },
    {
      dataField: "product_count",
      text: "Products Count",
    },
    {
      dataField: "created_at",
      text: "Completed At",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            <Button
              color="danger"
              className="me-1"
              onClick={e => {
                e.stopPropagation();
                setIsReject(!isReject);
                setSelectedKeyword(row.id);
                setActionType("single");
              }}
            >
              <img src={deleteIcon} className="pe-2" alt="reject icon" />
              <span>Reject</span>
            </Button>
            <Button
              color="primary"
              onClick={e => {
                e.stopPropagation();
                handleApprovedSeoProductPopup(row);
                setActionType("single");
              }}
            >
              <img src={tickIcon} className="pe-2" alt="approve icon" />
              <span>Approve</span>
            </Button>
          </div>
        );
      },
    },
  ];
  const [seoProductIdsOpen, setSeoProductIdsOpen] = useState(false);
  const resultsData = data?.map(item => {
    return {
      ...item,
    };
  });

  const handleApprovedSeoProductPopup = row => {
    setIsApprove(!isApprove);
	if(isApprove){
		if(actionType == "multiple"){
			setSelectedRowKeys([]);
		};
	}
    if (row) {
      let title = row.keyword.replace(/\b\w/g, match => match.toUpperCase());
      setSelectedKeyword(row.id);
      setFields({
        ...fields,
        title: title,
        slug: row?.keyword?.trim().replaceAll(" ", "-")?.toLowerCase(),
      });
    } else {
      setSelectedKeyword("");
      setFields({});
    }

  };

  const handleProductIdsPopup = id => {
    setSeoProductIdsOpen(!seoProductIdsOpen);
    setSelectedKeyword(id);
  };

  const handleCloseModal = () => {
    setIsReject(!isReject);
    setSelectedKeyword("");
	if(actionType == "multiple"){
		setSelectedRowKeys([]);
	};
	setActionType("");
 
  };

  const handleCreate = () => {
    const data = {
      ...fields,
      keyword_id: selectedKeyword,
    };
    handleCreateCollection([data], handleApprovedSeoProductPopup);
  };

  const handleChange = e => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const fieldsObj = { ...fields };
    fieldsObj[name] = value;
    setFields(fieldsObj);
  };

  const handleConfirm = () => {
    if (actionType == "single") {
      handleDeleteKeyWords([selectedKeyword], handleCloseModal);
    } else {
      const keywordIds = selectedRowKeys.map(keyword => keyword.keyword_id);
      handleDeleteKeyWords(keywordIds, handleCloseModal);
    }
  };

  const handleExport = async () => {
	const response  =await downloadSeoKeywords();
	return response;
  }

  return (
    <div>
      {isReject && (
        <DeleteModal
          onCancel={handleCloseModal}
          onConfirm={handleConfirm}
          title={"Are you sure you want to reject this keyword?"}
        />
      )}

      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="text-19 font-semi-bold mb-0">Result</h2>
		<ExportButton 
			isLoading={loading}
			setIsLoading={setLoading}
			fetchData={handleExport}
		/>
      </div>
      <Card>
        <CardBody>
          <div className="mb-4">
            <KeywordsFilters
              dates={dates}
              handleCalenderClick={handleCalenderClick}
              showCalendar={showCalendar}
              filters={filters}
              handleChange={handleFilterChange}
              setFilters={setFilters}
              handleApplyFilter={handleApplyFilter}
              handleResetFilter={handleResetFilter}
              handleDatePicker={handleDatePicker}
              isAppliedFilter={isAppliedFilter}
              setDates={setDates}
            />
          </div>
          {selectedRowKeys?.length > 0 && (
            <div className="d-flex align-items-center mb-4 justify-content-end">
              <Button
                color="danger"
                className="me-1"
                onClick={() => {
                  setActionType("multiple");
                  setIsReject(!isReject);
                }}
              >
                <img src={deleteIcon} className="pe-2" alt="reject icon" />
                <span>Reject</span>
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  setActionType("multiple");
                  handleCreateCollection(selectedRowKeys, null);
                }}
              >
                <img src={tickIcon} className="pe-2" alt="approve icon" />
                <span>Approve</span>
              </Button>
            </div>
          )}
          <PrimaryTable
            bordered={false}
            columns={columns}
            data={resultsData}
            keyField={"id"}
            selectRow={selectRow}
            // path={"seo-collections"}
            // handleRowClick={handleProductIdsPopup}

          />
          {
            <p className="text-right font-semi-bold h5">
              Total Results: {totalResults}
            </p>
          }
          {pageCount > 1 && (
            <div className="">
              <PrimaryPagination
                pageNo={pageNo}
                setPageNo={setKeywordsPage}
                pageCount={pageCount}
                paramName="r-page"
              />
            </div>
          )}
        </CardBody>
      </Card>

      <CreateCollectionPopup
        isOpen={isApprove}
        handleClick={handleApprovedSeoProductPopup}
        name={selectedKeyword}
        title={"Create Collection"}
        handleCreate={handleCreate}
        fields={fields}
        handleChange={handleChange}
      />
      {seoProductIdsOpen && (
        <SeoProductsIdsPopup
          isOpen={seoProductIdsOpen}
          handleClick={() => {
            setSelectedKeyword();
            setSeoProductIdsOpen(false);
          }}
          kId={selectedKeyword}
        />
      )}
    </div>
  );
};

export default KeywordsResultTable;
