import React, { useEffect, useState } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import PrimaryInput from "components/Common/PrimaryInput";
import { getSecretKey, generateSecretKey, enable2FA } from "services/2FAServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import SwitchButton from "components/Common/SwitchButton";
import { useNavigate } from "react-router-dom";
import { deleteSecretKey } from "services/2FAServices";
import { setPageTitle } from "utils/pageTitle";
import { useSelector } from "react-redux";

function TwoFactorAuthentication() {

  const [loading, setLoading] = useState(false);
  const [isEnable, setIsEnable] = useState(false);
  const [secretKey, setSecretKey] = useState("");
  const [secret, setSecret] = useState("");
  const [qrImageUrl, setQrImageUrl] = useState("");
  const navigate = useNavigate();
  const user = useSelector(state => state?.auth?.user);
  const permissions = user?.permissions?.map(item => item);
  const allPermissions = permissions && [
      { id: "550e8400", name: "my-task" },
      ...permissions,
  ];


  useEffect(()=>{
    setPageTitle("Enable Two-Factor Authentication - Inspire Uplift")
  },[])
 


  const handleGenerateSecretKey = () => {
    setLoading(true);
    generateSecretKey()
      .then((res) => {
        if (res?.status === 200) {
          getSecretKey()
            .then((res) => {
              setSecretKey(res?.data?.secret);
              setQrImageUrl(res?.data?.google2fa_url);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      })
      .catch((err) => {
		setLoading(false);
        console.log(err);
      })
  };

  const handleToggle = (e) => {
    const value = e.target.checked;
    if (value) {
		handleGenerateSecretKey(value);
        setIsEnable(value);
    }
	else {
		setIsEnable(value);
		deleteSecretKey()
		.then(res => console.log(res))
		.catch(err => console.log(err));
	}
  };

  const handleEnable = () => {
    setLoading(true);
    enable2FA(secret)
      .then((res) => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        setSecret((pre) => "");
        if (allPermissions?.some(p => p?.name === "dashboard")) {
            navigate("/");
        } else {
            navigate(`/user/detail/${user?.id}`);
        }
	
      })
      .catch((err) => {
        console.log(err);
        PrimaryToast({ type: "error", message: Array.isArray( err?.response?.data?.message) ? err?.response?.data?.message[0] :  err?.response?.data?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="d-flex align-items-center">
        <TopHeading heading={"Enable 2FA"} />
        <div className="ms-4">
          <SwitchButton checked={isEnable === true} label={isEnable === true ? "Enable" : "Disable"} name="is_active" value={isEnable} handleChange={handleToggle} />
        </div>
      </div>
      {isEnable && (
        <Card className="mt-4">
          <CardBody className="w-50">
            <h4>Two Factor Authentication</h4>
            <p>
              Two-factor authentication (2FA) strengthens access security by requiring two methods (also referred to as factors) to verify your identity. Two-factor authentication protects against phishing, social engineering, and password brute force attacks and secures your logins from attackers exploiting weak or stolen credentials.
            </p>
			<>
                <p>
                  <span className="fw-bold">1.</span> Scan this QR code with your Google Authenticator App. Alternatively, you can use the code:
                </p>
                <p className="text-danger">{secretKey}</p>
                {qrImageUrl ? <img src={qrImageUrl} alt="qr code scanner" /> : null}
                <p>
                  <span className="fw-bold">2.</span> Enter the pin from Google Authenticator app:
                </p>
                <div className="mb-4">
                  <PrimaryInput label={"Authenticator Code"} name="secret" value={secret} handleChange={(e) => setSecret(e.target.value)} isRequired />
                </div>
                <Button color="primary" onClick={handleEnable}>
                  Enable 2FA
                </Button>
              </>
          </CardBody>
        </Card>
      )}
    </div>
  );
}

export default TwoFactorAuthentication;
