import React from "react";

export default function SecondaryInput({
  handleChange,
  value,
  name,
  label,
  placeholder,
  text,
  isRequired,
  isDisabled,
  margin
}) {
  return (
    <div>
      {label && (
        <label className="form-label">
          {label}
          {isRequired && <span className="primary-text">*</span>}
        </label>
      )}
      <div className={`input-group  ${margin ? margin: "mb-3"}`}>
        <span className="input-group-text" id="basic-addon1">
          {text}
        </span>
        <input
          type="text"
          className={`form-control  ${isDisabled && "disabled-input"}`}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={handleChange}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
}
