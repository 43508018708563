import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";


export const getWithDrawRequestList = (page,search,status) => {
    return getApi({
        url: 'admin/seller/withdraws',
        data: {
            is_admin: true,
            page:page || undefined,
            search:search ||undefined,
            ...status,
        }
    }
    ).then(res => res.data)
}

export const getSingleWithDrawRequest = (id) => {
    return getApi({
        url: `admin/seller/withdraws/${id}`,
        data: {
            is_admin: true,
        }
    }
    ).then(res => res.data)
}

export const sellerWithdrawHistories = (id,page) => {
    return getApi({
        url: `admin/seller/withdraws/${id}/history`,
        data: {
            is_admin: true,
            page:page || undefined
        }
    }
    ).then(res => res.data)
};

export const sellerWithdrawReview = (data,id,status) => {
    return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/seller/withdraws/review/${id}/${status}`,
        withCredentials:true,
        data:{
            ...data,
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
};

export const handleWithdrawApprove = (withdrawIds) => {
    return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/seller/withdraws/approve`,
        withCredentials:true,
        data:{
            ...withdrawIds,
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
};

export const handleWithdrawReject = (withdrawIds,reason) => {
    return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/seller/withdraws/reject`,
        withCredentials:true,
        data:{
            ...withdrawIds,
            reason,
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
};


export const reviewWithdrawRequest = (data,id) => {
    return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/seller/withdraws/review/${id}/1`,
        withCredentials:true,
        data:{
            ...data,
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
}

export const getAllComments = (page,id, withdraw_id) => {
    return getApi({
        url: `admin/seller/withdraws/comments/${id}`,
        data: {
            is_admin: true,
            page:page || undefined,
            withdraw_id
        }
    }
    ).then(res => res.data)
};

export const submitWithdrawComment =  (data) => {
    return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/seller/withdraws/createComment`,
        withCredentials:true,
        data:{
            ...data,
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
}

export const exportWithdraws = (start_date, end_date, payout_method) => {
    return axiosInstance(
        {
            url: `${siteApiUrl}admin/seller/withdraws/export`,
            method:'get',
            params:{
                is_admin:true,
                start_date,
                end_date,
                payout_method,
            },
            withCredentials:true,
            responseType:'blob',
            headers: getHttpHeaders(),
        }
    );
};

export const withdrawRejectByNetwork =  (withdraw_ids) => {
    return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/seller/withdraws/reject-by-network`,
        withCredentials:true,
        data:{
            seller_withdraw_ids:withdraw_ids,
            is_admin:true,
        },
		headers:getHttpHeaders(),
	});
}