import React from "react";

function PrimaryInput({
  handleChange,
  label,
  placeholder,
  name,
  value,
  isRequired,
  type,
  isDisabled,
  maxLength,
  borderRadius,
  minValue,
  pattern,
  labelClassName
}) {
  return (
    <div className="w-100">
      {label && (
        <label className={`form-label ${labelClassName}`}>
          {label}
          {isRequired && <span className="primary-text">*</span>}
        </label>
      )}

      <input
        onChange={handleChange}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        className={`form-control ${borderRadius} ${
          isDisabled && "disabled-input"
        }`}
        disabled={isDisabled}
        maxLength={maxLength}
        min={minValue}
        pattern={pattern}
      />
    </div>
  );
}

export default PrimaryInput;
