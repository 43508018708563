import { LOAD_STATES } from "store/actions/states";
const initialState = {
    states:[]
};

const statesReducer = (state = initialState,action) => {
    const {type, payload}  = action;

    switch(type){
        case LOAD_STATES : {
            return {
                ...state,
                states:payload
            }
        }
        break;
        default : 
        return  state
    }
}
export default statesReducer;