export function downloadFile(data, headers) {
  const contentDisposition = headers["filename"];
  const defaultFileName = "data.csv";
  let fileName = defaultFileName;
  if (contentDisposition) {
    const matches = contentDisposition.match(/filename="(.*?)"/);
    if (matches.length > 1) {
      fileName = matches[1];
    }
  };
  const blob = new Blob([data], { type: "text/csv" });
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = fileName;
  a.click();
}
