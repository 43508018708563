import React from "react"
import ReactApexChart from "react-apexcharts"

import getChartColorsArray from "components/Common/ChartsDynamicColor";



const ChartApex = ({ dataColors, monthsData,amountsData }) => {
	
	const apaexlineChartColors = getChartColorsArray(dataColors);

	const chartData = {
		options: {
			xaxis: {
				categories: monthsData,
			},
		},
		series: [
			{
				name: "Order Count",
				data: amountsData,
			},
		],
		options: {
			chart: {
				height: 380,
				type: 'line',
				zoom: {
					enabled: false
				},
				toolbar: {
					show: false
				}
			},
			colors: apaexlineChartColors,
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'straight'
			},
			title: {
				text: 'Orders',
				align: 'left',
				style: {
					fontWeight: '600',
					color: "#495057 !important",
					fontSize: "15px",
				},
			},
			grid: {
				row: {
					colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
					opacity: 0.2
				},
				borderColor: '#f1f1f1'
			},
			xaxis: {
				categories: monthsData,
			},
			responsive: [{
				breakpoint: 600,
				options: {
					chart: {
						toolbar: {
							show: false
						}
					},
					legend: {
						show: false
					},
				}
			}]
		},
	};

	return (
		<ReactApexChart
			options={chartData.options}
			series={chartData.series}
			type="line"
			height="380"
			className="apex-charts-container"
		/>
	);
};

export default ChartApex;
