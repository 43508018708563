import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import {  Card, CardBody, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { getSingleRole } from "services/SettingsServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { setPageTitle } from "utils/pageTitle";

function RoleDetails() {
    const [roleDetail, setRoleDetail] = useState({});
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(()=>{
		setPageTitle("Roles Detail - Inspire Uplift")
	},[]);

    useEffect(() => {
        setLoading(true);
        getSingleRole(id)
            .then(res => {
                setRoleDetail(res.data)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(res => {
                setLoading(false);
            })

    }, []);

    return (
        <div className="page-content">
            {
                loading && <ProgressPopup label={"Loading..."} />
            }
            <div className="d-flex mb-3 justify-content-between align-items-center">
                <TopHeading heading={"Role Details"} />
            </div>
            <Card>
                <CardBody>
                    <div className="d-flex align-items-center">
                        <span className="font-semi-bold">Role Name:</span>
                        <span className="ps-4">{roleDetail?.name}</span>
                    </div>
                    {
                        roleDetail?.permissions?.length > 0 && (
                            <>
                                <div className="bg-light p-2 w-full font-semi-bold mt-3">
                                    Permissions
                                </div>
                                <Row className="p-2">
                                    {roleDetail?.permissions?.map((permission, index) => {
                                        return (
                                            <Col className="border-bottom p-2" sm="3" key={index}>
                                                {permission?.name}
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </>
                        )
                    }
                </CardBody>
            </Card>
        </div>
    );
}

export default RoleDetails;
