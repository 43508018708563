import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getAllComplaints = (page,search, filters) => {
    return getApi({
        url:`admin/complaints`,
        data: {
            is_admin: true,
            page:page || undefined,
            search: search || undefined,
            complaint_of:filters?.complaint_of || undefined,
            reason:filters?.reason || undefined,
            status:filters?.status ||undefined
        }
    })
    .then(res => res.data)

}

export const getSingleComplaint = (id) => {
    return getApi({
        url:`admin/complaints/detail/${id}`,
        data: {
            is_admin: true,
        }
    })
    .then(res => res.data)

}

export const updateComplaint = (complaint,id) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/complaints/update/${id}`,
        method: "PUT",
        data: {
            ...complaint,
            is_admin:true,
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
};