import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Button } from "reactstrap";

import TopHeading from "components/Common/TopHeading";
import {
  getSingleSellerKYCRecord,
  setKYCStatus,
} from "services/SellersKycServices";
import GlobalService from "services/GlobalService";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import ImagePreviewPopup from "components/Common/Popups/ImagePreviewPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import RejectKYCPopup from "components/Common/Popups/RejectKYCPopup";

const SingleKycRecord = () => {
  const globalService = GlobalService();
  const params = useParams();
  const [sellerKycRecord, setSellerKycRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [isPreviewFile, setIsPreviewFile] = useState(false);
  const [reason, setReason] = useState("");
  const [isShowPopup, setIsShowPopup] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getSingleSellerKYCRecord(params?.id);
      const countryData = await globalService.getCountries();
      const countryName = countryData.data.data.find(
        country =>
          country.alpha2Code?.toLowerCase() ==
          response.data?.proof_of_identity?.country?.toLowerCase()
      )?.name;
      setSellerKycRecord({
        ...response.data,
        proof_of_identity: {
          ...response.data.proof_of_identity,
          country: countryName,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFile = url => {
    setFileUrl(url);
    setIsPreviewFile(true);
  };

  const handleAction = async type => {
    setLoading(true);
    try {
      const response = await setKYCStatus(params?.id, type, reason);
      if (response.status == 200) {
        PrimaryToast({
          type: "success",
          message: `KYC ${type} successfully.`,
        });
        if (type == "reject") {
          handleRejectToggle();
        }
        setSellerKycRecord(pre => {
          return {
            ...pre,
            seller: {
              ...pre.seller,
              kyc_status: type == "approve" ? 1 : type == "reject" ? 2 : 0,
            },
          };
        });
      }
    } catch (error) {
      console.error(error);
      PrimaryToast({
        type: "error",
        message: Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message[0]
          : error?.response?.data?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRejectToggle = () => {
    setIsShowPopup(!isShowPopup);
    setReason("");
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"loading..."} />}
      <TopHeading heading={"Seller KYC"} />
      <div className="mt-4 d-flex align-items-start">
        <div className="w-75 me-3">
          <Card>
            <CardBody>
              <h4 className="font-semi-bold mt-3">Seller Information</h4>
              <Row className="py-2 border-bottom">
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">Seller Name :</div>
                    <div className="w-50">{sellerKycRecord?.seller?.name}</div>
                  </div>
                </Col>
                {sellerKycRecord?.proof_of_address?.attachment_front && (
                  <Col sm="6">
                    <div className="d-flex justify-content-between">
                      <div
                        className="font-semi-bold"
                        style={{
                          width: "fit-content",
                          flexShrink: 0,
                          marginRight: "5px",
                        }}
                      >
                        Store Name:
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        {sellerKycRecord?.seller?.shop_name}
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
              <Row className="py-2 border-bottom">
                <Col sm="6">
                  <div className="d-flex">
                    <div className="font-semi-bold w-50">Kyc Status :</div>
                    <div
                      className={`status w-max-content ${
                        sellerKycRecord?.seller?.kyc_status == 0 ||
                        sellerKycRecord?.seller?.kyc_status == 3
                          ? "warning-status"
                          : sellerKycRecord?.seller?.kyc_status == 1
                          ? "success-status"
                          : sellerKycRecord?.seller?.kyc_status == 2
                          ? "danger-status"
                          : ""
                      }`}
                    >
                      {sellerKycRecord?.seller?.kyc_status == 0
                        ? "Pending"
                        : sellerKycRecord?.seller?.kyc_status == 1
                        ? "Approved"
                        : sellerKycRecord?.seller?.kyc_status == 2
                        ? "Rejected"
                        : sellerKycRecord?.seller?.kyc_status == 3
                        ? "Submitted"
                        : ""}
                    </div>
                  </div>
                </Col>
              </Row>
              <h4 className="font-semi-bold mt-3">KYC</h4>
              <Row className="py-2 border-bottom border-top">
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">Document Type: </div>
                    <div className="w-50">
                      {sellerKycRecord?.proof_of_identity?.document_type}
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">Issue Country:</div>
                    <div className="w-50">
                      {sellerKycRecord?.proof_of_identity?.country}
                    </div>
                  </div>
                </Col>
              </Row>
              {(sellerKycRecord?.proof_of_identity?.attachment_front ||
                sellerKycRecord?.proof_of_identity?.attachment_back) && (
                <Row className="py-2">
                  {sellerKycRecord?.proof_of_identity?.attachment_front && (
                    <Col sm="12  border-bottom pb-2">
                      <div className="d-flex justify-content-between">
                        <div
                          className="font-semi-bold"
                          style={{
                            width: "fit-content",
                            flexShrink: 0,
                            marginRight: "5px",
                          }}
                        >
                          Front Of{" "}
                          {sellerKycRecord?.proof_of_identity?.document_type}:{" "}
                        </div>
                        <div style={{ flexGrow: 1 }}>
                          {sellerKycRecord?.proof_of_identity?.attachment_front?.match(
                            /\.(jpeg|jpg|gif|png|svg)$/i
                          ) ? (
                            <img
                              src={
                                sellerKycRecord?.proof_of_identity
                                  ?.attachment_front
                              }
                              style={{
                                width: "100%",
                                maxHeight: "400px",
                                objectFit: "contain",
                              }}
                              alt="front image"
                              onClick={() =>
                                handleFile(
                                  sellerKycRecord?.proof_of_identity
                                    ?.attachment_front
                                )
                              }
                            />
                          ) : sellerKycRecord?.proof_of_identity?.attachment_front?.match(
                              /\.(pdf|doc|docx)$/i
                            ) ? (
                            <div
                              style={{
                                maxHeight: "500px",
                                overflow: "auto",
                                height: "500px",
                              }}
                            >
                              <object
                                data={
                                  sellerKycRecord?.proof_of_identity
                                    ?.attachment_front
                                }
                                type="application/pdf"
                                width="100%"
                                height="100%"
                                style={{ border: "none" }}
                              ></object>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Col>
                  )}
                  {sellerKycRecord?.proof_of_identity?.attachment_back && (
                    <Col sm="12 mt-4 pb-2 border-bottom">
                      <div className="d-flex justify-content-between">
                        <div
                          className="font-semi-bold"
                          style={{
                            width: "fit-content",
                            flexShrink: 0,
                            marginRight: "5px",
                          }}
                        >
                          Back Of{" "}
                          {sellerKycRecord?.proof_of_identity?.document_type}:{" "}
                        </div>
                        <div style={{ flexGrow: 1 }}>
                          {sellerKycRecord?.proof_of_identity?.attachment_back?.match(
                            /\.(jpeg|jpg|gif|png|svg)$/i
                          ) ? (
                            <img
                              src={
                                sellerKycRecord?.proof_of_identity
                                  ?.attachment_back
                              }
                              style={{
                                width: "100%",
                                maxHeight: "400px",
                                objectFit: "contain",
                              }}
                              alt="front image"
                              onClick={() =>
                                handleFile(
                                  sellerKycRecord?.proof_of_identity
                                    ?.attachment_back
                                )
                              }
                            />
                          ) : sellerKycRecord?.proof_of_identity?.attachment_back?.match(
                              /\.(pdf|doc|docx)$/i
                            ) ? (
                            <div
                              style={{
                                maxHeight: "500px",
                                overflow: "auto",
                                height: "500px",
                              }}
                            >
                              <object
                                data={
                                  sellerKycRecord?.proof_of_identity
                                    ?.attachment_back
                                }
                                type="application/pdf"
                                width="100%"
                                height="100%"
                                style={{ border: "none" }}
                              ></object>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Col>
                  )}
                  {sellerKycRecord?.proof_of_identity?.attachment_selfie && (
                    <Col sm="12 mt-4 pb-2 border-bottom">
                      <div className="d-flex justify-content-between">
                        <div
                          className="font-semi-bold"
                          style={{
                            width: "171px",
                            flexShrink: 0,
                            marginRight: "5px",
                          }}
                        >
                          Selfie:
                        </div>
                        <div style={{ flexGrow: 1 }}>
                          {sellerKycRecord?.proof_of_identity?.attachment_selfie?.match(
                            /\.(jpeg|jpg|gif|png|svg)$/i
                          ) ? (
                            <img
                              src={
                                sellerKycRecord?.proof_of_identity
                                  ?.attachment_selfie
                              }
                              style={{
                                width: "100%",
                                maxHeight: "400px",
                                objectFit: "contain",
                              }}
                              alt="front image"
                              onClick={() =>
                                handleFile(
                                  sellerKycRecord?.proof_of_identity
                                    ?.attachment_selfie
                                )
                              }
                            />
                          ) : sellerKycRecord?.proof_of_identity?.attachment_selfie?.match(
                              /\.(pdf|doc|docx)$/i
                            ) ? (
                            <div
                              style={{
                                maxHeight: "500px",
                                overflow: "auto",
                                height: "500px",
                              }}
                            >
                              <object
                                data={
                                  sellerKycRecord?.proof_of_identity
                                    ?.attachment_selfie
                                }
                                type="application/pdf"
                                width="100%"
                                height="100%"
                                style={{ border: "none" }}
                              ></object>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              )}
              <h4 className="font-semi-bold mt-3">Proof of Address</h4>
              <Row className="py-2">
                <Col sm="6 pb-2 border-bottom">
                  <div className="d-flex justify-content-between">
                    <div className="font-semi-bold w-50">Document Type :</div>
                    <div className="w-50">
                      {sellerKycRecord?.proof_of_address?.document_type}
                    </div>
                  </div>
                </Col>
                {sellerKycRecord?.proof_of_address?.attachment_front && (
                  <Col sm="12 border-bottom pb-2 mt-2">
                    <div className="d-flex justify-content-between">
                      <div
                        className="font-semi-bold"
                        style={{
                          width: "fit-content",
                          flexShrink: 0,
                          marginRight: "5px",
                        }}
                      >
                        Front Of{" "}
                        {sellerKycRecord?.proof_of_address?.document_type}:{" "}
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        {sellerKycRecord?.proof_of_address?.attachment_front?.match(
                          /\.(jpe?g|gif|png|svg)$/i
                        ) ? (
                          <img
                            src={
                              sellerKycRecord?.proof_of_address
                                ?.attachment_front
                            }
                            style={{
                              width: "100%",
                              maxHeight: "400px",
                              objectFit: "contain",
                            }}
                            alt="front image"
                            onClick={() =>
                              handleFile(
                                sellerKycRecord?.proof_of_address
                                  ?.attachment_front
                              )
                            }
                          />
                        ) : sellerKycRecord?.proof_of_address?.attachment_front?.match(
                            /\.(pdf|doc|docx)$/i
                          ) ? (
                          <div
                            style={{
                              maxHeight: "500px",
                              overflow: "auto",
                              height: "500px",
                            }}
                          >
                            <object
                              data={
                                sellerKycRecord?.proof_of_address
                                  ?.attachment_front
                              }
                              type="application/pdf"
                              width="100%"
                              height="100%"
                              style={{ border: "none" }}
                            ></object>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </div>
        {(sellerKycRecord?.seller?.kyc_status == 0 ||
        sellerKycRecord?.seller?.kyc_status == 3) ? (
          <div className="w-25">
            <Button
              color="primary"
              className="w-100 mb-3"
              onClick={() => handleAction("approve")}
              disabled={loading}
            >
              Approved
            </Button>
            <Button
              color="danger"
              className="w-100 mb-3"
              onClick={handleRejectToggle}
              disabled={loading}
            >
              Reject
            </Button>
          </div>
        ) : null}
      </div>
      {isPreviewFile && (
        <ImagePreviewPopup
          isOpen={isPreviewFile}
          handleClick={() => {
            setIsPreviewFile(false);
            setFileUrl("");
          }}
          url={fileUrl}
        />
      )}
      {isShowPopup && (
        <RejectKYCPopup
          isOpen={isShowPopup}
          handleAction={handleAction}
          reason={reason}
          setReason={setReason}
          handleClick={handleRejectToggle}
        />
      )}
    </div>
  );
};

export default SingleKycRecord;
