import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import orderIcon from "assets/images/order-icon.svg";

function DashboardCountCard({title, amount, iconPath=orderIcon}) {
  return (
    <Col md="3">
    <Card className="mini-stats-wid">
        <CardBody>
            <div className="d-flex align-items-center">
                <img src={iconPath} className="" alt="order icon" />
                <p className="text-muted fw-medium mb-0 ms-4">
                    {title}
                </p>
            </div>
            <div className="mt-4 d-flex align-items-center">
                <p className="mini-stats-wid-price-section" style={{fontSize:"19px", fontWeight:"500"}}>
                    {amount}
                </p>
            </div>
        </CardBody>
    </Card>
</Col>
  )
}

export default DashboardCountCard