import React from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';

const PrimaryPagination = ({ pageCount, setPageNo, pageNo, skipUrlParam, paramName = "page" }) => {
  const navigate = useNavigate();

  const handlePage = ({ selected: selectedPage }) => {
    let count = selectedPage + 1;
    setPageNo(count);
    if(!skipUrlParam){
        let urlParams = new URLSearchParams(window.location.search);
        urlParams.set(paramName, count);
        navigate(`?${urlParams.toString()}`);
    }
  };

  return (
    <div className="mt-4 d-flex justify-content-center">
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePage}
        forcePage={pageNo - 1}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName="pagination align-items-center"
        activeClassName="active-page"
        pageClassName="me-3"
        previousLinkClassName="me-3 label-color"
        nextLinkClassName="label-color"
      />
    </div>
  );
};

export default PrimaryPagination;
