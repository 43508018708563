import React from 'react';

const KycVerificationBadge = () => {
  return (
    <div style={{
      backgroundColor:'rgba(52, 195, 143, 0.25)',
      padding:'2px 6px',
      fontWeight:'500',
      fontSize:'12px',
      color:'#34C38F',
      borderRadius:'3px',
      marginLeft:'1rem',

    }}>
        KYC Verified
    </div>
  )
}

export default KycVerificationBadge
