import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { getSingleComplaint } from "services/ComplaintsServices";
import { setPageTitle } from "utils/pageTitle";

function ComplaintDetails() {
  const [complaintDetail, setComplaintDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setPageTitle("Complaint - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    getSingleComplaint(id)
      .then(res => {
        setComplaintDetail(res.data);
      })
      .catch(err => console.log(err))
      .finally(err => setLoading(false));
  }, []);

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <TopHeading heading={"Complaint Information"} />
      </div>
      <Card>
        <CardBody>
          <Row className="py-2 border-bottom">
            <Col sm="6">
              <div className="d-flex justify-content-between">
                <div className="font-semi-bold w-25">Type:</div>
                <div className="w-75">{complaintDetail?.type}</div>
              </div>
            </Col>
            {complaintDetail?.product && (
              <Col sm="6">
                <div className="d-flex justify-content-between">
                  <div className="font-semi-bold w-25">Product Name:</div>
                  <div className="w-75">{complaintDetail?.product}</div>
                </div>
              </Col>
            )}
            {complaintDetail?.seller && (
              <Col sm="6">
                <div className="d-flex justify-content-between">
                  <div className="font-semi-bold w-25">Seller Name:</div>
                  <div className="w-75">{complaintDetail?.seller}</div>
                </div>
              </Col>
            )}
          </Row>
          <Row className="py-2 border-bottom">
            <Col sm="6">
              <div className="d-flex justify-content-between">
                <div className="font-semi-bold w-25">Reason:</div>
                <div className="w-75">{complaintDetail?.reason}</div>
              </div>
            </Col>
            <Col sm="6">
              <div className="d-flex justify-content-between">
                <div className="font-semi-bold w-25">Description:</div>
                <div className="w-75">{complaintDetail?.description}</div>
              </div>
            </Col>
          </Row>
          <Row className="py-2 border-bottom">
            <Col sm="6">
              <div className="d-flex justify-content-between">
                <div className="font-semi-bold w-25">Customer: </div>
                <div className="w-75">{complaintDetail?.customer}</div>
              </div>
            </Col>
            <Col sm="6">
              <div className="d-flex justify-content-between">
                <div className="font-semi-bold w-25">Decision:</div>
                <div className="w-75">{complaintDetail?.decision}</div>
              </div>
            </Col>
          </Row>
          <Row className="py-2 border-bottom">
            <Col sm="6">
              <div className="d-flex justify-content-between">
                <div className="font-semi-bold w-25">Action By:</div>
                <div className="w-75">{complaintDetail?.action_by?.name}</div>
              </div>
            </Col>
            {complaintDetail?.updated_at ? (
              <Col sm="6">
                <div className="d-flex justify-content-between">
                  <div className="font-semi-bold w-25">Updated At:</div>
                  <div className="w-75">{complaintDetail?.updated_at}</div>
                </div>
              </Col>
            ) : null}
          </Row>
          <Row className="pt-2">
            <Col sm="6">
              <div className="d-flex justify-content-between">
                <div className="font-semi-bold w-25">Status: </div>
                <div className="w-75">
                  <div
                    className={`w-max-content  status ${
                      complaintDetail?.status == 3
                        ? "success-status"
                        : complaintDetail?.status == 2
                        ? "danger-status"
                        : ""
                    } `}
                  >
                    {complaintDetail?.status == 3
                      ? "Complete"
                      : complaintDetail?.status == 2
                      ? "Ignore"
                      : ""}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}

export default ComplaintDetails;
