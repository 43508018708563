import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import tickIcon from "../../assets/images/tick-icon.svg";
import cancleIcon from "../../assets/images/cancle-icon.svg";
import { getGiftCardApprovalList, giftCardAction } from "services/GiftCardsService";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";

function GiftCardApproval() {
	const [approveGiftCards, setApproveGiftCards] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pageCount, setPageCount] = useState();
	const [pageNo, setPageNo] = useState(1);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [showDeleteModal, setShowDeleteModal] = useToggle(false);
	const user = useSelector((state) => state.auth.user);
	const permissions = user?.permissions?.map((item) => item.name);
	const params = new URLSearchParams(window?.location?.search);

	useEffect(()=>{
		setPageTitle("Gift Cards Approvals - Inspire Uplift")
	},[]);

	useEffect(() => {

		const page = params?.get('page') || undefined;
		setPageNo(page || 1);
		setLoading(true);
		getData(page);

	}, [window?.location?.search]);

	const getData = (page) => {
		getGiftCardApprovalList(page)
			.then(res => {
				if(res?.data?.data?.length == 0 && page > 1){
					params?.set('page', pageNo - 1);
					let newUrl = `${window?.location?.pathname}?${params?.toString()}`;
					history(newUrl);
				}else{
					setApproveGiftCards(res.data.data);
					setPageCount(res.data.last_page);
				}
		
			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false))
	}
	const columns = [

		{
			dataField: "amount",
			text: "Initial Amount",
		},
		{
			dataField: "recipient_name",
			text: "Recipient Name",
		},
		{
			dataField: "sender_name",
			text: "Created By",
		},
		{
			dataField: "description",
			text: "Messages",
			style: {
				maxWidth: "300px",
				whiteSpace: "break-spaces",
				wordBreak: "break-word",
			},
		},
	];

	const data = approveGiftCards?.map((item,index) => {
		return {
			...item,
		}
	})
	const handleOnSelectAll = (isSelected, rows) => {
		if (isSelected) {
			setSelectedRowKeys(rows.map((row) => row.id));
		} else {
			setSelectedRowKeys([]);
		}
	};

	const selectRow = {
		mode: "checkbox",
		onSelectAll: handleOnSelectAll,
		onSelect: (row, isSelect) => {
			if (isSelect) {
				setSelectedRowKeys([...selectedRowKeys, row.id]);
			} else {
				setSelectedRowKeys(selectedRowKeys.filter(key => key !== row.id));
			}
		},
		selected: selectedRowKeys
	};

	const handleAction = (type) => {
		let filteredData = approveGiftCards?.filter(item => !selectedRowKeys.includes(item.id));
		toast.dismiss();
		setLoading(true);
		giftCardAction(selectedRowKeys, type)
			.then(res => {
				setSelectedRowKeys([]);
				PrimaryToast({ type: 'success', message: res?.message });
				setShowDeleteModal(false);
				if(filteredData?.length == 0 && pageNo > 1) {
					params?.set('page', pageNo - 1);
					let newUrl = `${window?.location?.pathname}?${params?.toString()}`;
					history(newUrl);
				}
				else {
					getData(pageNo);
				}
	
			})
			.catch(err => {
				console.log(err)
				PrimaryToast({ type: 'error', message: err?.response?.data?.message });
			})
			.finally(res => setLoading(false));
	};


	return (
		<div className="page-content">
			{
				loading && <ProgressPopup label={"Loading..."} />
			}
			{
				showDeleteModal && <DeleteModal
					title={"Are you sure you want to reject gift card?"}
					onConfirm={()=>{
						handleAction(2)
					}}
					onCancel={() => {
						setSelectedRowKeys([]);
						setShowDeleteModal(!showDeleteModal);
					}}

				/>
			}
			<div className="mb-3">
				<TopHeading heading={"Gift Cards Approvals"} />
			</div>
			<Card>
				<CardBody>
					{
						permissions.includes("gift-approval") && selectedRowKeys?.length > 0 && (
							<div className="d-flex align-items-center justify-content-end">
								<Button className="rounded-full me-2" color="danger" onClick={() => {
									setShowDeleteModal(!showDeleteModal);
								}}>
									<img src={cancleIcon} alt="tick icon" className="pe-2" />
									Reject
								</Button>
								<Button className="rounded-full" color="primary" onClick={() => handleAction(1)}>
									<img src={tickIcon} alt="tick icon" className="pe-2" />
									Approve
								</Button>
							</div>
						)
					}

					<div className="mt-3">
						<PrimaryTable
							columns={columns}
							data={data}
							bordered={false}
							keyField="id"
							selectRow={selectRow}
						/>
					</div>
					{
						(approveGiftCards?.length > 0 && pageCount > 1 ) && (
							<PrimaryPagination
								pageCount={pageCount}
								pageNo={pageNo}
								setPageNo={setPageNo}
							/>
						)
					}
				</CardBody>
			</Card>
		</div>
	);
}

export default GiftCardApproval;
