import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import SwitchButton from "components/Common/SwitchButton";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import PrimaryInput from "components/Common/PrimaryInput";
import { createAppWellcome } from "services/MobileAppServices";
import PrograssPopup from "components/Common/Popups/ProgressPopup";
import PrimaryImageUploader from "components/Common/PrimaryImageUploder";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function CreateWelcome() {
	const history = useNavigate();
	const [fields, setFields] = useState({
		is_hidden: true,
	});
	const [loading, setLoading] = useState(false);
	const [media, setMedia] = useState();

	useEffect(()=>{
		setPageTitle("Add App Welcome - Inspire Uplift")
	},[]);


	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.type == "checkbox" ? e.target.checked : e.target?.value;
		const fieldsObj = { ...fields };
		fieldsObj[name] = value;
		setFields(fieldsObj);
	};

	const handleSubmit = e => {
		e.preventDefault();
		let data = {
			...fields,
			media: media
		}
		toast.dismiss();
		setLoading(true);
		createAppWellcome(data)
			.then(res => {
				history(-1);
				PrimaryToast({ type: 'success', message: res?.data?.message });
			})
			.catch(err => {
				console.log(err)
				PrimaryToast({ type: 'error', message: err?.response?.data?.message[0] });
			})
			.finally(res => setLoading(false))
	}



	return (
		<div className="page-content">
			{
				loading && <PrograssPopup label={"Loading..."} />
			}
			<form onSubmit={handleSubmit}>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<TopHeading heading={"Add App Welcome"} />
					<div className="d-flex align-items-center">
						<Button onClick={() => history(-1)}>Cancel</Button>
						<Button className="ms-2" color="primary">
							Create
						</Button>
					</div>
				</div>
				<Card>
					<CardBody className="w-50">
						<div className="mb-3">
							<PrimaryInput
								label={'Description'}
								isRequired
								handleChange={handleChange}
								name={'description'}
								value={fields?.description || ''}
								placeholder="Enter App Welcome Description"
							/>
						</div>
						<div className="mb-3">
							<PrimaryImageUploader label={"Media Image"} isRequired setFiles={setMedia} getFiles={media} />
						</div>
						<div>
							<label htmlFor="inputEmail4">Visibilty</label>
							<SwitchButton checked={fields.is_hidden === true ? true : false} label={fields.is_hidden === true ? "Show" : "Hide"} name="is_hidden" value={fields?.is_hidden} handleChange={handleChange} />
						</div>
					</CardBody>
				</Card>

			</form>
		</div>
	);
}

export default CreateWelcome;
