import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import SwitchButton from "components/Common/SwitchButton";
import PrimaryInput from "components/Common/PrimaryInput";
import { getSinglePayoutMethodData, updatePayoutMethodData } from "services/SellerPayoutMethodService";
import { useParams } from "react-router-dom";
import PrograssPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function EditPaymentMethod() {
	const [fields, setFields] = useState({});

	const [loading, setLoading] = useState(false);

	const params = useParams();

	useEffect(()=>{
		setPageTitle("Edit Payout Method - Inspire Uplift")
	},[]);

	useEffect(() => {

		const { id } = params;

		setLoading(true);
		getSinglePayoutMethodData(id)
			.then(res => {
				setFields({
					...res.data,
					is_active: res.data.is_active === 1 ? true : false,

				})
			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false))

	}, [])
	const history = useNavigate();
	const [status, setStatus] = useState("Inactive");


	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
		const fieldsObj = { ...fields };

		if (name === "programId" || name === "programToken") {
			fieldsObj["configuration"][name] = value
		}
		else {
			fieldsObj[name] = value;
		}



		setFields(fieldsObj);

	}

	const handleSubmit = e => {
		e.preventDefault();
		const data = {
			...fields,
			id: undefined,
		}
		const { id } = fields;
		toast.dismiss();
		setLoading(true);
		updatePayoutMethodData(data, id)
			.then(res => {
				if (res.status === 200) {
					PrimaryToast({ type: 'success', message: res?.data?.message });
					history(-1);
				}
			})
			.catch(err => {
				PrimaryToast({ type: 'error', message: err?.response?.data?.message[0] });
				console.log(err);
			})
			.finally(res => setLoading(false));
	};

	return (
		<div className="page-content">
			{
				loading && <PrograssPopup label={"Loading..."} />
			}
			<form onSubmit={handleSubmit}>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<TopHeading heading={"Edit Payout Method"} />
					<div className="d-flex">
						<Button className="me-2" onClick={() => history(-1)}>
							Cancel
						</Button>
						<Button color="primary" type="submit">
							Save
						</Button>
					</div>
				</div>
				<Card>
					<CardBody>
						<h5 className="font-semi-bold">Payout Method Information</h5>
						<div className="w-50">
							<div className="mb-3">
								<PrimaryInput
									name="name"
									type="text"
									value={fields?.name || ""}
									placeholder="Enter Payout Method Name"
									label={"Name"}
									isRequired
									handleChange={handleChange}
								/>
							</div>
							<div className="mb-3">
								<PrimaryInput
									name="slug"
									type="text"
									value={fields?.slug || ""}
									placeholder="Enter Payout Method Slug"
									label={"Slug"}
									isRequired
									handleChange={handleChange}
								/>
							</div>
							<div className="mb-3">
								<PrimaryInput
									name="class_name"
									type="text"
									placeholder="Enter Class Name"
									value={fields?.class_name || ""}

									label={"Class Name"}
									isRequired
									handleChange={handleChange}
								/>
							</div>
							<div className="mb-3">
								<PrimaryInput
									label={"Program ID"}
									name="programId"
									placeholder="Enter Program ID"
									type={"text"}
									value={fields?.configuration?.programId || ""}
									handleChange={handleChange}
								/>
							</div>
							<div className="mb-3">
								<PrimaryInput
									label={"Program Token"}
									name="programToken"
									placeholder="Enter Program Token"
									type={"text"}
									value={fields?.configuration?.programToken || ""}
									handleChange={handleChange}
								/>
							</div>
							<div>
								<label htmlFor="inputEmail4">Status</label>
								<SwitchButton checked={fields.is_active === true ? true : false} label={fields.is_active === true ? "Active" : "Inactive"} name="is_active" value={fields?.is_active} handleChange={handleChange} />
							</div>

						</div>
					</CardBody>
				</Card>
			</form>
		</div>
	);
}

export default EditPaymentMethod;
