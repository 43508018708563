import React, { useState } from 'react';
import questionMarkIcon from 'assets/images/question-mark-icon.svg';
import { Tooltip } from 'reactstrap';

function ClearCacheComponent({
  label,
  id,
  value,
  tooltipContent,
  handleChange,
  handleInputChange,
  selectedCache,
  inputValue,
  pIndex,
  selectedIndex
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const isDisabled = !(selectedCache === value && selectedIndex === pIndex);

  return (
    <div className="d-flex align-items-center mb-4">
      <div className="form-check form-check-inline me-3">
        <input
          className="form-check-input"
          type="checkbox"
          id={id}
          value={value}
          name={value}
          checked={(selectedCache === value) && (selectedIndex === pIndex)}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div>
      {tooltipContent && (
        <div className="position-relative">
          <input
            type="text"
            className="form-control position-relative"
            id="exampleFormControlInput1"
            name={value}
            onChange={handleInputChange}
            value={isDisabled ? '' : inputValue}
            disabled={isDisabled}
          />
          <span className="cache-tooltip-field-container">
            <img
              src={questionMarkIcon}
              alt="question mark icon"
              id={'Tooltip-' + id}
            />
            <Tooltip
              placement={'bottom'}
              isOpen={tooltipOpen}
              target={'Tooltip-' + id}
              toggle={toggle}
            >
              {tooltipContent}
            </Tooltip>
          </span>
        </div>
      )}
    </div>
  );
}

export default ClearCacheComponent;
