import React from "react"
import ReactDOM from 'react-dom/client';
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import 'rc-time-picker/assets/index.css';
import { store } from "./store";

import { Provider } from "react-redux";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>


);

