import {useState} from "react";

const useToggle = (initialValue) => {
    const [value, setValue]  = useState(initialValue);
    const handleToggle = (value) => {
        setValue(preVal => typeof value == "boolean" ? value : !preVal)
    }
    return [value, handleToggle];
};

export default useToggle;