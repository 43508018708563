const escapeRegExp = s => {
  return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
};

export const ltrim = (str, character = " ") => {
  const re = new RegExp("^" + escapeRegExp(character) + "+", "g");
  return str.replace(re, "");
};

export const rtrim = (str, character = " ") => {
  const re = new RegExp(escapeRegExp(character) + "+$", "g");
  return str.replace(re, "");
};

export const hashGenerater = () => {
  const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const ranletter1 = alphabet[Math.floor(Math.random() * alphabet.length)];
  const ranletter2 = alphabet[Math.floor(Math.random() * alphabet.length)];
  const ranNum = Math.floor(Math.random() * (99999 - 10000) + 10000);
  const ranCode = ranletter1 + ranNum + ranletter2 + ranNum + ranletter2;
  return ranCode;
};

export const  capitalizeString = (str) => {
  const words = str?.split(' ');
  const capitalizedWords = words?.map((word) => {
    if (word.length === 1) {
      return word.toUpperCase();
    } else {
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }
  });
  return capitalizedWords?.join(' ');
}



export const substractVariantTitle = (title, variant) => {
  if (title === variant) {
     return "";
  }
  return variant?.replace(`${title} -`, "");
 }