import React, { useEffect, useState } from "react";
import TopHeading from "components/Common/TopHeading";
import { CardBody, Card, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import PrimarySearch from "components/Common/PrimarySearch";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import editIcon from "../../../assets/images/edit-icon-white.svg";
import plusIcon from "../../../assets/images/plus-icon.svg";
import { useNavigate } from "react-router-dom";
import { getProductGroups, deleteGroup } from "services/ProductsServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";

function ProductGroups() {
  const [productGroups, setProductsGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [selectedId, setSelectedId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [searchVal, setSearch] = useState(null);
  const user = useSelector(state => state.auth.user);
  const permissions = user?.permissions?.map(item => item.name);
  const params = new URLSearchParams(window?.location?.search);

  const history = useNavigate();

  useEffect(() => {
    setPageTitle("Product Groups - Inspire Uplift");
  }, []);

  useEffect(() => {
    const page = params?.get("page") || undefined;
    const search = params?.get("search") || undefined;
    setPageNo(page || 1);
    setSearch(search);
    getProductsData(page, search);
  }, [window?.location?.search]);

  const getProductsData = (page, search) => {
    setLoading(true);
    getProductGroups(page, search)
      .then(res => {
        setProductsGroup(res.data.data);
        setPageCount(res.data.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const handleSearch = e => {
    const value = e.target.value;
    setSearch(value);
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => {
        return (
          <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>
        );
      },
    },
    {
      dataField: "name",
      text: "Group Name",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex align-items-center">
          {permissions?.includes("product-group-edit") && (
            <div
              className="action-btn edit-icon-bg me-1 cursor-pointer"
              onClick={() => history(`/products/groups/edit/${row.id}`)}
            >
              <img src={editIcon} className="" alt="edit icon" />
            </div>
          )}
          {permissions?.includes("product-group-delete") && (
            <div
              className="action-btn delete-btn-bg cursor-pointer"
              onClick={() => {
                setSelectedId(row.id);
                setShowDeleteModal(true);
              }}
            >
              <img src={deleteIcon} className="" alt="delete icon" />
            </div>
          )}
        </div>
      ),
    },
  ];

  const data = productGroups?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleDelete = () => {
    const filterArr = productGroups?.filter(item => item.id !== selectedId);
    toast.dismiss();
    setLoading(true);
    deleteGroup(selectedId)
      .then(res => {
        setProductsGroup(filterArr);
        setSelectedId();
        setShowDeleteModal(false);
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      })
      .finally(res => setLoading(false));
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (params?.get("page")) {
        params?.delete("page");
      }
      if (searchVal) {
        params?.set("search", searchVal);
      } else {
        params?.delete("search");
      }
      history(`/products/groups?${params?.toString()}`);
    }
  };

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this product group?"}
          onConfirm={handleDelete}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"loading..."} />}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <TopHeading heading={"Product Groups"} />
        {permissions?.includes("product-group-create") && (
          <Button
            color="primary"
            className="rounded"
            onClick={() => history("/products/groups/create")}
          >
            <img src={plusIcon} className="mdi mdi-plus-circle-outline me-1" />
            Create New Group
          </Button>
        )}
      </div>
      <Card>
        <CardBody>
          <PrimarySearch
            placeholder={"Search by group name"}
            value={searchVal}
            handleKeyPress={handleKeyPress}
            handleChange={handleSearch}
          />
          <div className="mt-3">
            <BootstrapTable
              keyField="id"
              data={data}
              columns={columns}
              classes={"table align-middle table-nowrap"}
              headerWrapperClasses={"thead-light"}
              responsive
              bordered={false}
            />
          </div>
          {productGroups?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageNo={pageNo}
              setPageNo={setPageNo}
              pageCount={pageCount}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default ProductGroups;
