import { LOAD_COUNTRIES } from "store/actions/countries";

const initialState = {
    countires :[]
};

const countiresReducer = (state = initialState,action) => {
    const {type, payload}  = action;

    switch(type){
        case LOAD_COUNTRIES : {
            return {
                ...state,
                countires:payload
            }
        }
        break;
        default : 
        return  state
    }
}
export default countiresReducer;