import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Card, CardBody, Button } from "reactstrap";
import PrimaryTable from "components/Common/PrimaryTable";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import deleteIcon from "assets/images/cancle-icon.svg";
import tickIcon from "assets/images/tick-icon.svg";
import PausedIcon from "assets/images/paused-icon.svg";
import PlayIcon from "assets/images/play-icon.svg";
import {
  getAllProductAds,
  changeProductStatus,
} from "services/MarketPlaceFeedService";
import { setPageTitle } from "utils/pageTitle";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import PrimarySearch from "components/Common/PrimarySearch";
import { useNavigate } from "react-router-dom";
import { openLinkInNewTab } from "utils/linkHandling";
import AdsApprovePopup from "components/Common/Popups/AdsApprovePopup";
import AdsRejectPopup from "components/Common/Popups/AdsRejectPopup";
import { getAllAdsPlatform } from "services/MarketPlaceFeedService";
import ResetButton from "components/Common/ResetButton";

function ProductsAd() {
  const [productsAdsData, setProductAdsData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [loading, setLoading] = useState(false);
  const [platForm, setPlatForm] = useState();
  const [status, setStatus] = useState();
  const [searchVal, setSearchVal] = useState(null);
  const [plateFormsData, setPlateFormsData] = useState([]);
  const [isApprove, setIsApprove] = useState(false);
  const [campaignId, setCampaignId] = useState();
  const [adSetId, setAdSetId] = useState();
  const [selectedId, setSelectedId] = useState();
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [isCampExist, setIsCampExist] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [reason, setReason] = useState();
  const history = useNavigate();
  const params = new URLSearchParams(window?.location?.search);

  useEffect(() => {
    setPageTitle("Products Ads - Inspire Uplift");
    getPlateForms();
  }, []);

  useEffect(() => {
    const page = params?.get("page") || undefined;
    const search = params?.get("search") || undefined;
    const platform = params?.get("platform") || undefined;
	const statusParam = params?.get("status") || undefined;
	let statusVal;
	switch(statusParam){
		case "Pending":
		statusVal= "0";
		break;
		case "Running":
			statusVal = 1;
			break;
		case "Completed":
			statusVal = 2;
			break;
		case "Rejected":
			statusVal = 3;
			break;
		case "Paused":
			statusVal = 4;
			break;
		case "Expired":
			statusVal = 5;
			break;
			default :
			statusVal = ""			 
	};
	
    setPlatForm(platform || "");
	setStatus(statusVal);
    setPageNo(page || 1);
    setSearchVal(search || "");
    setLoading(true);
    getProducts(page, search, platform, statusVal);
  }, [window?.location?.search]);


  const getPlateForms = () => {
    getAllAdsPlatform()
      .then(res => {
        setPlateFormsData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getProducts = (page, search, platform, status) =>
    getAllProductAds(page, search, platform, status)
      .then(res => {
        setProductAdsData(res?.data?.data);
        setPageCount(res?.data?.last_page);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(response => {
        setLoading(false);
      });

  const columns = [
    {
      dataField: "#",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(pageNo - 1) * 10 + row?.index}</div>
      ),
    },
    {
      dataField: "seller_name",
      text: "Store Name",
      formatter: (cell, row) => <div className="">{row?.seller_name}</div>,
    },
    {
      dataField: "product",
      text: "Product Name",
      formatter: (cell, row) => (
        <div
          className="font-semi-bold primary-text cursor-pointer"
          style={{ width: "230px", whiteSpace: "break-spaces" }}
          onClick={e => {
            e.stopPropagation();
            if (row?.product_url) {
              openLinkInNewTab(`${row?.product_url}`, "_blank");
            } else {
              return;
            }
          }}
        >
          {row?.product}
        </div>
      ),
    },
    {
      dataField: "platform",
      text: "Platform",
      formatter: (cell, row) => (
        <div>
          {row.platform == "Facebook" ? "Facebook & Instagram" : row.platform}
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => (
        <div
          className={`status w-max-content ${
            row.status == 1 || row.status == 2
              ? "success-status"
              : row.status === 0 || row.status === 4
              ? "warning-status"
              : row.status == 3 || row?.status == 5
              ? "danger-status"
              : ""
          }`}
        >
          {row.status == 0
            ? "Pending"
            : row?.status == 1
            ? "Running"
            : row?.status == 2
            ? "Completed"
            : row?.status == 3
            ? "Rejected"
            : row?.status == 4
            ? "Paused"
            : row?.status == 5
            ? "Expired"
            : ""}
        </div>
      ),
    },
    {
      dataField: "start_date",
      text: "Start Date",
    },
    {
      dataField: "end_date",
      text: "End Date",
    },
    {
      dataField: "per_day",
      text: "Daily Budget",
    },
    {
      dataField: "total_budget",
      text: "Total Budget",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="">
          {row.status == 0 && (
            <div className="d-flex align-items-center">
              <div
                className="action-btn delete-btn-bg cursor-pointer me-1"
                onClick={e => {
                  e.stopPropagation();
                  setIsReject(true);
                  setSelectedId(row?.id);
                }}
              >
                <img src={deleteIcon} className="" alt="reject icon" />
              </div>
              <div
                className={`action-btn approve-btn-bg`}
                onClick={e => {
                  e.stopPropagation();
                  if (row?.platform?.toLowerCase() == "facebook") {
                    setIsApprove(!isApprove);
                    setSelectedId(row?.id);
                    setSelectedPlatform(row?.platform?.toLowerCase());
                    setIsCampExist(row?.is_fb_campaign_id);
                  } else {
                    productAction(1, row?.id);
                  }
                }}
              >
                <img src={tickIcon} className="" alt="approve icon" />
              </div>
            </div>
          )}
          {row.status == 1 && (
            <div
              className="bg-secondary action-btn"
              onClick={e => {
                e.stopPropagation();
                productAction(4, row?.id);
              }}
            >
              <img src={PausedIcon} alt="paused icon" />
            </div>
          )}
          {row.status == 4 && (
            <div
              className="edit-icon-bg action-btn"
              onClick={e => {
                e.stopPropagation();
                productAction(1, row?.id);
              }}
            >
              <img src={PlayIcon} alt="paused icon" />
            </div>
          )}
        </div>
      ),
    },
  ];

  const data = productsAdsData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const productAction = (statusVal, id, setId, campId, selectedPlatform) => {
    let data = {
      status_id: statusVal,
      ad_set_id:
	  statusVal == 1 && selectedPlatform == "facebook" && setId
          ? setId
          : undefined,
      fb_campaign_id:
	  statusVal == 1 && selectedPlatform == "facebook" && campId
          ? campId
          : undefined,
    };

    setLoading(true);
     changeProductStatus(data, id)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        if (selectedPlatform == "facebook") {
          setCampaignId();
          setSelectedId();
          setAdSetId();
          setIsApprove(false);
          setIsCampExist(false);
        };
		getProducts(pageNo, searchVal, platForm, status);
        setSelectedId();
      })
      .catch(err => {
		setLoading(false);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
  };

  const handleApprove = () => {
    productAction(1, selectedId, adSetId, campaignId, selectedPlatform);
  };

  const handleFilter = () => {
    if (platForm) {
      params.set("platform", platForm);
    }
    if (!platForm && params.get("platform")) {
      params.delete("platform", platForm);
    }
	if(status){
		let statusVal;
		switch(Number(status)){
			case 0:
			statusVal= "Pending";
			break;
			case 1:
				statusVal = "Running";
				break;
			case 2:
				statusVal = "Completed";
				break;
			case 3:
				statusVal = "Rejected";
				break;
			case 4:
				statusVal = "Paused";
				break;
			case 5 :
				statusVal = "Expired";
				break;
				default :
				statusVal = ""			 
		}
		params.set("status", statusVal)
	}
	else {
		params.delete('status');
	}
    if (searchVal) {
      params.set("search", searchVal);
    }
    if (!searchVal && params.get("search")) {
      params.delete("search");
    }

    if (params.get("page")) {
      params.delete("page");
    }

    history(`?${params.toString()}`);
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      handleFilter("search");
    }
  };

  const handleSave = () => {
    const data = {
      status_id: 3,
      comment: reason,
    };
	let filterData = productsAdsData?.filter(item => item.id !== selectedId);
    setLoading(true);
    changeProductStatus(data, selectedId)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
		if(filterData?.length == 0) {
			params.set("page", pageNo -1);
			const newUrl = `${window.location.pathname}?${params.toString()}`;
			history(newUrl, { replace: true });
		}
		else {
			getProducts(pageNo, searchVal, platForm, status);
		}
        setSelectedId();
        setIsReject();
        setReason();
      })
      .catch(err => {
		setLoading(false);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <TopHeading heading={"Products Ad"} />
      <div className="mt-3">
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <PrimarySearch
                  placeholder={"Search by store name"}
                  value={searchVal || ""}
                  handleKeyPress={handleKeyPress}
                  handleChange={e => {
                    setSearchVal(e.target.value);
                  }}
                />
                <div className="d-flex ms-3">
                  <select
                    className="primary-select-option"
                    value={platForm || ""}
                    onChange={e => {
                      setPlatForm(e.target.value);
                    }}
                  >
                    <option value={""}>Search By Platform</option>
                    {plateFormsData?.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.name === "Facebook"
                            ? "Facebook & Instagram"
                            : item.name}
                        </option>
                      );
                    })}
                  </select>
                  <select 
				  	className="primary-select-option ms-2" 
					onChange={(e)=> setStatus(e.target.value)}
					value={status}
				>
                    <option value={""}>Search By Status</option>
                    <option value={0}>Pending</option>
                    <option value={1}>Running</option>
                    <option value={2}>Completed</option>
                    <option value={3}>Rejected</option>
                    <option value={4}>Paused</option>
                    <option value={5}>Expired</option>
                  </select>
                  <Button
                    color="primary"
                    className={`btn-rounded ms-3`}
                    onClick={() => handleFilter("platform")}
                  >
                    Apply Filter
                  </Button>
                  {params?.toString()?.length > 0 && <ResetButton />}
                </div>
              </div>
            </div>
            <div className="table-responsive mt-4">
              <PrimaryTable
                data={data}
                columns={columns}
                keyField="seller_product_id"
                responsive
                bordered={false}
                rowUrl={"/marketing/product-ads/details"}
                isPermission={true}
              />
              {productsAdsData?.length > 0 && (
                <PrimaryPagination
                  pageCount={pageCount}
                  pageNo={pageNo}
                  setPageNo={setPageNo}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </div>
      {isApprove && (
        <AdsApprovePopup
          isOpen={isApprove}
          handleClick={() => {
            setIsApprove(!isApprove);
            setAdSetId();
            setCampaignId();
            setSelectedId();
            setSelectedPlatform();
            setIsCampExist(false);
          }}
          campaignId={campaignId}
          setCampaignId={setCampaignId}
          adSetId={adSetId}
          setAdSetId={setAdSetId}
          handleApprove={handleApprove}
          isCamIdExist={isCampExist}
		  loading={loading}
        />
      )}
      {isReject && (
        <AdsRejectPopup
			loading={loading}
          isOpen={isReject}
          reason={reason}
          setReason={setReason}
          handleSave={handleSave}
          handleClose={() => {
            setIsReject(false);
            setSelectedId();
            setReason();
          }}
        />
      )}
    </div>
  );
}

export default ProductsAd;
