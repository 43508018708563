import React, { useState, useEffect } from "react";
import { CardBody, Card, Button } from "reactstrap";
import TopHeading from "components/Common/TopHeading";
import PrimaryInput from "components/Common/PrimaryInput";
import CalendarInput from "components/Common/CalenderInput";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import SwitchButton from "components/Common/SwitchButton";
import SecondaryInput from "components/Common/SecondaryInput";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import {
  getSingleCampaign,
  updateCampaign,
  getCampaignStoresList,
} from "services/CampaignsServices";
import { setPageTitle } from "utils/pageTitle";
import { useSelector } from "react-redux";
import CampaignParticipantsSection from "components/Campaign/CampaignParticipantsSection";
const EditCampaigns = () => {
  const [isViewCam, setIsViewCam] = useState(false);
  const [showRegistrationCalender, setShowRegistrationCalender] =
    useState(false);
  const [showStartDateCalender, setShowStartDateCalender] = useState(false);
  const [showEndDateCalendar, setShowEndDateCalender] = useState(false);
  const [registrationCloseIN, setRegistrationCloseIN] = useState("");
  const [cName, setCName] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isCampaignInvite, setIsCampaignInvite] = useState(false);
  const [storeIds, setStoreIds] = useState("");
  const [minConversion, setMinConversion] = useState("");
  const [maxProducts, setMaxProducts] = useState("");
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [slug, setSLug] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useSelector(state => state.auth.user);
  const permissions = user?.permissions?.map(item => item.name);
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 10);
  const searchParams = new URLSearchParams(window?.location?.search);
  const [participantsData, setParticipantsData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [statusChanged, setStatusChanged] = useState(false);
  const [searchValue, setSearchValue] = useState([]);

  useEffect(() => {
    setPageTitle("Edit Campaign - Inspire Uplift");
  }, []);

  useEffect(() => {
    if (isViewCam) {
      const pageParam = searchParams?.get("page") || undefined;
      const searchParam = searchParams.get('search') || undefined;
      setSearchValue(searchParam || '');
      setPageNo(pageParam || 1);
      getParticipants(pageParam, searchParam);
    }
  }, [window?.location?.search]);

  const getParticipants = (page, search) => {
    getCampaignStoresList(id, page, search)
      .then(res => {
        if (page > 1 && res?.data?.length == 0) {
          searchParams.set("page", page - 1);
          const newUrl = `${
            window.location.pathname
          }?${searchParams.toString()}`;
          navigate(newUrl, { replace: true });
        }
        setParticipantsData(res.data);
        setPageCount(res?.last_page);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    getSingleCampaignData();
  }, []);

  const getSingleCampaignData = () => {
    setLoading(true);
    getSingleCampaign(id)
      .then(res => {
        setCName(res.data.name);
        setRegistrationCloseIN(res?.data?.registration_end);
        setStartDate(res?.data?.start);
        setEndDate(res?.data?.end);
        setMinConversion(res?.data?.min_conversion);
        setMaxProducts(res?.data?.max_products);
        setIsCampaignInvite(res?.data?.invited_sellers != null ? true : false);
        setStatus(res?.data?.status);
        setStoreIds(
          String(
            res?.data?.invited_sellers !== null
              ? res?.data?.invited_sellers
              : ""
          )
        );
        setSLug(res?.data?.slug);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const handleSubmit = e => {
    e.preventDefault();
    let storeIdsArr =
      storeIds?.length > 0 ? storeIds?.split(",")?.map(id => Number(id)) : null;
	  let newStatus = statusChanged ? (status ? 1 : 2) : status;
    let payload = {
      name: cName || undefined,
      start: moment(startDate).format("YYYY-MM-DD") || undefined,
      end: moment(endDate).format("YYYY-MM-DD") || undefined,
      register_end:
        moment(registrationCloseIN).format("YYYY-MM-DD") || undefined,
      status: newStatus,
      min_conversion: minConversion || undefined,
      max_products: maxProducts || undefined,
      invited_sellers: isCampaignInvite ? storeIdsArr : null,
      slug,
    };
    setLoading(true);
    updateCampaign(id, payload)
      .then(res => {
        PrimaryToast({
          type: "success",
          message: "Campaign update successfully",
        });
        if (isViewCam) {
          getParticipants(pageNo);
        }
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => setLoading(false));
  };

  const handleStatusChange = (e) => {
    setStatusChanged(true);
    setStatus(e.target.checked);
  };

  const handleStoreSearch  = e => {
    const value =e.target.value;
    setSearchValue(value);

  }

  const handleKeyPress = e => {
    if (e.key === "Enter") {
        if (searchParams?.get("page")) {
            searchParams?.delete("page");
        }
        if (searchValue) {
            searchParams?.set("search", searchValue);
        } else {
            searchParams?.delete("search");
        }
        navigate(`/marketing/campaigns/edit/${id}?${searchParams?.toString()}`);
    }
  }

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <form onSubmit={handleSubmit}>
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <TopHeading heading={"Edit Campaign"} />
          <div className="d-flex">
            <Button
              className="me-2"
              disabled={loading}
              onClick={() => navigate("/marketing/campaigns")}
            >
              Cancel
            </Button>
            {permissions?.includes("campaign-update") ? (
              <Button color="primary" type="submit" disabled={loading}>
                Save
              </Button>
            ) : null}
          </div>
        </div>
        <Card>
          <CardBody className="d-flex">
            <div className="w-50">
              <h2 className="h4">Campaign Information</h2>
              <div className="mb-3">
                <PrimaryInput
                  label={"Name"}
                  isRequired
                  name="title"
                  placeholder={"Enter Campaign Name"}
                  value={cName || ""}
                  handleChange={e => setCName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <PrimaryInput
                  label={"Campaign Slug"}
                  isRequired
                  name="slug"
                  placeholder={"Enter Campaign slug"}
                  value={slug || ""}
                  handleChange={e => setSLug(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <CalendarInput
                  label="Registration Close In"
                  isRequired={true}
                  handleShowCalender={() =>
                    setShowRegistrationCalender(!showRegistrationCalender)
                  }
                  showCalender={showRegistrationCalender}
                  handleChange={newDate => {
                    setRegistrationCloseIN(newDate);
                    setShowRegistrationCalender(!showRegistrationCalender);
                  }}
                  minDate={moment().toDate()}
                  maxDate={maxDate}
                  date={
                    registrationCloseIN
                      ? new Date(registrationCloseIN)
                      : new Date()
                  }
                  value={
                    registrationCloseIN &&
                    moment(registrationCloseIN).format("MM-DD-YYYY")
                  }
                  styling={"registration-closein"}
                  placeholder={"Select Registration Close In"}
                />
              </div>
              <div className="mb-3 d-flex">
                <div className="w-50">
                  <CalendarInput
                    label="Start Date"
                    isRequired={true}
                    handleShowCalender={() =>
                      setShowStartDateCalender(!showStartDateCalender)
                    }
                    showCalender={showStartDateCalender}
                    handleChange={newDate => {
                      setStartDate(newDate);
                      setShowEndDateCalender(false);
                    }}
                    minDate={moment().toDate()}
                    maxDate={maxDate}
                    date={startDate ? new Date(startDate) : new Date()}
                    value={startDate && moment(startDate).format("MM-DD-YYYY")}
                    placeholder={"Select Start Date"}
                  />
                </div>
                <div className="ms-4 w-50">
                  <CalendarInput
                    label="End Date"
                    isRequired={true}
                    handleShowCalender={() =>
                      setShowEndDateCalender(!showEndDateCalendar)
                    }
                    showCalender={showEndDateCalendar}
                    handleChange={newDate => {
                      setEndDate(newDate);
                      setShowEndDateCalender(false);
                    }}
                    minDate={moment().toDate()}
                    maxDate={maxDate}
                    date={endDate ? new Date(endDate) : new Date()}
                    value={endDate && moment(endDate).format("MM-DD-YYYY")}
                    placeholder={"Select End Date"}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="w-50">
                  <label className="form-label">
                    Minimum Discount <span className="primary-text">*</span>
                  </label>
                  <SecondaryInput
                    name="min_conversion"
                    margin={"mb-0"}
                    placeholder={"Enter Min Discount"}
                    value={minConversion || ""}
                    text={"%"}
                    handleChange={e => setMinConversion(e.target.value)}
                  />
                </div>
                <div className="w-50 ms-4">
                  <PrimaryInput
                    label={"Seller Maximum Products"}
                    isRequired
                    name="max_products"
                    placeholder={"Enter Maximum Products"}
                    value={maxProducts || ""}
                    handleChange={e => setMaxProducts(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="inputEmail4">
                  Status<span className="primary-text">*</span>
                </label>
                <SwitchButton
                  checked={status == 1}
                  label={status == 1 ? "Active" : "Inactive"}
                  name="status"
                  value={status}
				  handleChange={handleStatusChange}
                />
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={isCampaignInvite}
                  id="isCampaignInvite"
                  name="isCampaignInvite"
                  checked={isCampaignInvite}
                  onChange={e => {
                    setIsCampaignInvite(e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="isCampaignInvite">
                  The campaign is invite only
                </label>
              </div>
              {isCampaignInvite ? (
                <div className="d-flex align-items-end w-100">
                  <div className="me-3 w-100">
                    <PrimaryInput
                      name="store_ids"
                      value={storeIds || ""}
                      label={"Seller Store Ids"}
                      isRequired
                      type={"text"}
                      placeholder="Enter Seller Store Ids*"
                      handleChange={e => {
                        setStoreIds(e.target.value);
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {permissions?.includes("campaign-update") ? (
                <Button
                  className="mt-3"
                  color="primary"
                  onClick={() => {
                    setIsViewCam(true);
                    getParticipants(pageNo);
                  }}
                >
                  View Campaign Stores
                </Button>
              ) : null}
            </div>
          </CardBody>
        </Card>
      </form>
      {isViewCam && (
        <CampaignParticipantsSection
          getParticipants={getParticipants}
          pageCount={pageCount}
          setPageNo={setPageNo}
          pageNo={pageNo}
          cId={id}
          participantsData={participantsData}
          setLoading={setLoading}
          storeIds={storeIds}
          setStoreIds={setStoreIds}
          searchValue={searchValue}
          handleStoreSearch={handleStoreSearch}
          handleKeyPress={handleKeyPress}
        />
      )}
    </div>
  );
};

export default EditCampaigns;
