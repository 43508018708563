import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getEmailsList = (page, search, status) => {
    return getApi({
        url:`admin/dmca-emails`,
        data:{
            is_admin:true,
            page,
            search:search || undefined,
            status: status || undefined,
        }
    }).then(res => res.data);
};

export const getEmailDetail = (id) => {
    return getApi({
        url:`admin/dmca-emails-detail`,
        data:{
            is_admin:true,
            id
        }
    }).then(res => res.data);
}

export const emailAction = (data) => {
    return axiosInstance({
        method:"POST",
        url:`${siteApiUrl}admin/dmca-emails-action`,
        withCredentials:true,
        data:{
            ...data,
            is_admin:true
        },
        headers:getHttpHeaders()
    })
};

export const dmcaDelete = (id) => {
    return axiosInstance({
        method:"POST",
        url:`${siteApiUrl}admin/dmca-emails-delete`,
        withCredentials:true,
        data:{
            id,
            is_admin:true
        },
        headers:getHttpHeaders()
    })
}

export const dmcaForm = (fields) => {
    return axiosInstance({
        method:"POST",
        url:`${siteApiUrl}admin/dmca-create`,
        withCredentials:true,
        data:{
            owns_copyrights: fields.copyright,
            first_name: fields.firstName,
            last_name: fields.lastName,
            company: fields.company,
            address: fields.address,
            telephone: fields.telephone,
            email: fields.email,
            original_urls: fields.originalUrls,
            original_description: fields.originalDescription,
            report_urls: fields.reportUrls,
            report_description: fields.reportDescription,
            copyright: fields.copyrightStatus,
            info_status: fields.infoStatus,
            acknowledgement: fields.acknowledgement,
            signature: fields.signature,
            is_admin: true,
        },
        headers:getHttpHeaders()
    })
}