import React from "react";
import { Button } from "reactstrap";

import PrimaryPopup from "./PrimaryPopup";


const RejectKYCPopup = ({ reason, setReason, isOpen, handleClick, handleAction }) => {
  return (
    <PrimaryPopup
      isOpen={isOpen}
      handleClick={handleClick}
      title={"KYC Reject Reason"}
    >
      <div className="mb-3">
        <label htmlFor="kyc-reject-reason" className="form-label">
         Reason<span className="primary-text">*</span>
        </label>
        <textarea
          className="form-control"
          id="kyc-reject-reason"
          rows="3"
          placeholder="Enter reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        ></textarea>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <Button  onClick={handleClick}>Cancel</Button>
        <Button color="primary" className="ms-2" onClick={() => handleAction("reject")}>
          Submit
        </Button>
      </div>
    </PrimaryPopup>
  );
};

export default RejectKYCPopup;
