import React from "react";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";

const SellerTransactionAdjustments = ({
  transactionData,
  pageNo,
  setPageNo,
  pageCount,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="">{(pageNo - 1) * 10 + row?.index}</div>
      ),
    },
    {
      dataField: "comment",
      text: "Message",
    },
    {
      dataField: "created_at",
      text: "Created At",
    },
    {
      dataField: "admin",
      text: "Created By",
    },
  ];

  const data = transactionData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  return (
    <div>
      <h5>Seller Transaction Adjustments</h5>
      <div className="table-responsive mt-4">
        <PrimaryTable
          keyField={"id"}
          responsive
          bordered={false}
          striped={false}
          columns={columns}
          data={data}
        />
      </div>
      {data?.length > 0 && (
        <PrimaryPagination
          pageCount={pageCount}
          pageNo={pageNo}
          setPageNo={setPageNo}
          skipUrlParam={true}
        />
      )}
    </div>
  );
};

export default SellerTransactionAdjustments;
