import React, { useEffect, useState } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import PrimarySearch from "components/Common/PrimarySearch";
import { useNavigate, useLocation } from "react-router-dom";
import rectangeArrow from "assets/images/rectangle-arrow.svg";
import { getAllComplaints } from "services/ComplaintsServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import { setPageTitle } from "utils/pageTitle";
import ResetButton from "components/Common/ResetButton";

function Complaints() {
  const [complaintsData, setComplaintsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [statusFields, setStatus] = useState({});
  const [searchVal, setSearch] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(window?.location?.search);
  const paramsLength = Array.from(searchParams.keys()).length;

  useEffect(() => {
    setPageTitle("Complaints - Inspire Uplift");
  }, []);

  useEffect(() => {
    const status = searchParams.get("status");
    const complaint = searchParams.get("complaint");
    const reason = searchParams.get("reason");
    const search = searchParams?.get("str") || null;
    const page = searchParams?.get("page") || undefined;
    setSearch(search || "");

    let filterObj = {};
    if (complaint && complaint == "product") {
      filterObj = {
        ...filterObj,
        complaint_of: "product",
      };
    }
    if (complaint && complaint == "seller") {
      filterObj = {
        ...filterObj,
        complaint_of: "seller",
      };
    }

    if (complaint && complaint == "customer") {
      filterObj = {
        ...filterObj,
        complaint_of: "customer",
      };
    }

    if (reason) {
      if (reason == 1) {
        filterObj = {
          ...filterObj,
          reason: 1,
        };
      } else if (reason == 2) {
        filterObj = {
          ...filterObj,
          reason: 2,
        };
      } else if (reason == 3) {
        filterObj = {
          ...filterObj,
          reason: 3,
        };
      } else if (reason == 4) {
        filterObj = {
          ...filterObj,
          reason: 4,
        };
      } else if (reason == 5) {
        filterObj = {
          ...filterObj,
          reason: 5,
        };
      }
    }

    if (status) {
      if (status === "Pending") {
        filterObj = {
          ...filterObj,
          status: 1,
        };
      }
      if (status === "Ignore") {
        filterObj = {
          ...filterObj,
          status: 2,
        };
      }
      if (status === "Complete") {
        filterObj = {
          ...filterObj,
          status: 3,
        };
      }
      if (status === "In Process") {
        filterObj = {
          ...filterObj,
          status: 4,
        };
      }
    }
    setPageNo(page || 1);
    setStatus(Object.keys(filterObj)?.length > 0 ? filterObj : {});
    getAllComplaintsList(page, search, filterObj);
  }, [window?.location?.search]);

  const getAllComplaintsList = (page, search, status) => {
    setLoading(true);
    getAllComplaints(page, search, status)
      .then(res => {
        setPageCount(res.data.last_page);
        setComplaintsData(res.data.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const history = useNavigate();

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => {
        return (
          <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>
        );
      },
    },
    {
      dataField: "name",
      text: "Store Name/Product Name/Customer",
      formatter: (cell, row) => (
        <div className={`${row?.product && "product-title-container"}`}>
          {row.product && row.product}
          {row.seller && row.seller}
          {row.customer && row.customer}
        </div>
      ),
    },
    {
      dataField: "type",
      text: "Type",
      formatter: (cell, row) => (
        <div>
          {row.product && "Product"}
          {row.seller && "Seller"}
          {!row.seller && !row.product ? "Customer" : ""}
        </div>
      ),
    },
    {
      dataField: "reason",
      text: "Reason",
    },
    {
      dataField: "created_at",
      text: "Date",
    },

    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => (
        <div
          className={`${
            row.status === 1 || row?.status == 4
              ? "warning-status"
              : row.status === 3
              ? "success-status"
              : row.status === 2
              ? "danger-status"
              : ""
          } status w-max-content `}
        >
          {row.status === 1
            ? "Pending"
            : row.status === 3
            ? "Complete"
            : row.status === 2
            ? "Ignore"
            : row?.status == 4
            ? "In Process"
            : "Pending"}
        </div>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return row.status === 1 || row.status == 4 ? (
          <Button
            color="primary"
            className="d-flex align-items-center"
            onClick={() => {
              window.open(`/others/complaints/respond/${row.id}`, "_blank");
            }}
          >
            <img src={rectangeArrow} />
            <div className="ms-2">Respond</div>
          </Button>
        ) : null;
      },
    },
  ];

  const data = complaintsData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleSearch = e => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleStatusChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const statusFieldsObj = { ...statusFields };
    statusFieldsObj[name] = value;
    setStatus(statusFieldsObj);
  };

  const handleFilter = () => {
    let statusFilter;

    if (statusFields?.status == 1) {
      statusFilter = "Pending";
    } else if (statusFields?.status == 2) {
      statusFilter = "Ignore";
    } else if (statusFields?.status == 3) {
      statusFilter = "Complete";
    } else if (statusFields?.status == 4) {
      statusFilter = "In Process";
    }

    if (statusFields?.complaint_of) {
      searchParams.set("complaint", statusFields?.complaint_of);
    } else {
      searchParams.delete("complaint");
    }
    if (statusFields?.reason) {
      searchParams.set("reason", statusFields?.reason);
    } else {
      searchParams.delete("reason");
    }
    if (statusFields?.status) {
      searchParams.set("status", statusFilter);
    }
    if (!statusFields?.status) {
      searchParams.delete("status");
    }
    if (searchVal) {
      searchParams.set("str", searchVal);
    } else {
      searchParams.delete("str");
    }
    if (searchParams.get("page")) {
      searchParams.delete("page");
    }

    history(`${location?.pathname}?${searchParams?.toString()}`);
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      handleFilter();
    }
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <TopHeading heading={"Complaints"} />
      <Card className="mt-3">
        <CardBody>
          <div className="mb-3 d-flex align-items-start">
            <div className="mt-3">
              <PrimarySearch
                handleChange={handleSearch}
                value={searchVal}
                handleKeyPress={handleKeyPress}
              />
            </div>
            <div className="flex flex-cloumn ms-2">
              <div className="d-flex flex-wrap">
                <select
                  className="primary-select-option mt-3"
                  name="complaint_of"
                  value={statusFields?.complaint_of || ""}
                  onChange={handleStatusChange}
                  onKeyPress={handleFilter}
                >
                  <option value={""}>Search By Complaint</option>
                  <option value={"product"}>Product</option>
                  <option value={"seller"}>Seller</option>
                  <option value={"customer"}>Customer</option>
                </select>
                <select
                  className="primary-select-option ms-1 mt-3"
                  name="reason"
                  value={statusFields?.reason || ""}
                  onChange={handleStatusChange}
                  onKeyPress={handleFilter}
                >
                  <option value={""}>Search By Reason</option>
                  {/* <option value={1}>There is a problem with my order</option>
                  <option value={2}>
                    It uses my intellectual property without permission
                  </option> */}
                  <option value={3}>
                    I don&apos;t think It meets Inspire Uplift policies
                  </option>
                  <option value={4}>Seller is not responding</option>
                  <option value={5}>
                    I have an issue in order with this seller
                  </option>
                </select>
                <select
                  className="primary-select-option ms-1 mt-3"
                  name="status"
                  value={statusFields?.status || ""}
                  onChange={handleStatusChange}
                  onKeyPress={handleFilter}
                >
                  <option value={""}>Search By Status</option>
                  <option value={1}>Pending</option>
                  <option value={2}>Ignore</option>
                  <option value={3}>Complete</option>
                  <option value={4}>In Process</option>
                </select>
                <Button
                  color="primary"
                  className={`btn-rounded ms-1 mt-3`}
                  onClick={handleFilter}
                >
                  Apply Filter
                </Button>
                {paramsLength > 0 && (
                  <div className="flex items-center mt-3">
                    <ResetButton />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <PrimaryTable
              columns={columns}
              data={data}
              bordered={false}
              isPermission={true}
              keyField="id"
              path="complaint"
            />
          </div>
          {complaintsData?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default Complaints;
