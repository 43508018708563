import React, { useState, useEffect } from "react";
import PrimaryPopup from "./PrimaryPopup";
import { Button } from "reactstrap";
import {
  deleteProductsByProductIds,
  deleteProductsBySellerId,
  getProductsBySku,
  updateProductCategory,
  getSimilarProducts,
  updateSimilarProducts,
  disableProductsByProductIds,
} from "services/ProductsServices";
import ProgressPopup from "./ProgressPopup";
import PrimaryToast from "../Toast/ToastMessage";
import { getCategories } from "services/ProductsServices";
import { approvalProductCatChange } from "services/ProductsServices";
import Select from "react-select";
import PrimaryRadio from "../PrimaryRadio";

function ProductActionPopup({
  isOpen,
  handleClick,
  inputValue,
  setInputValue,
  setProductDataBySku,
  productDataBySku,
  popupType,
  selectedId,
  path,
}) {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedOption, setSelectedOption] = useState("disable");
  const [selectedProductIdOption, setSelectedProductIdOption] = useState("delete");
  const [similarProductIds, setSimilarProductIds] = useState([]);
  const [disableProductIds, setDisableProductIds] = useState([]);

  useEffect(() => {
    if (popupType == "edit" || popupType == "edit-category") {
      getCategories()
        .then(res => {
          const categoriesList = [
            ...res.data.map(item => {
              return {
                label: item.name,
                value: item.id,
              };
            }),
          ];
          setCategories(categoriesList);
        })
        .catch(err => console.log(err))
        .finally(res => setLoading(false));
    }
  }, [popupType]);

  useEffect(() => {
    if (popupType == "edit-category") {
      getSimilarProducts(selectedId)
        .then(res => {
          setSimilarProductIds(res?.data?.data?.toString());
        })
        .catch(err => {
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        })
        .finally(res => setLoading(false));
    }
  }, []);

  const handleChange = e => {
    const regex = /^[0-9]+$/;
    const value = e.target.value;
    if (popupType === "seller id" && !regex.test(value) && value !== "") {
      return;
    }
    setInputValue(value);
  };

  const handleSimilarProductChange = e => {
    const value = e.target.value;
    setSimilarProductIds(value);
  };

  const handleDisableProductChange = e => {
    const value = e.target.value;
    setDisableProductIds(value);
  };

  const handleOptionChange = option => {
    setSelectedOption(option);
    setInputValue("");
  };

  const handleProductIdChange = option => {
    setSelectedProductIdOption(option);
    setInputValue("");
  };

  const handleAction = () => {
    setLoading(true);
    if (popupType == "sku") {
      getProductsBySku(inputValue)
        .then(res => {
          setProductDataBySku(res.data);
        })
        .catch(err => {
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        })
        .finally(res => setLoading(false));
    } else if (popupType == "seller id") {
      deleteProductsBySellerId(inputValue)
        .then(res => {
          PrimaryToast({
            type: "success",
            message: "Products Delete Successfully.",
          });
          handleClick();
        })
        .catch(err => {
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        })
        .finally(res => setLoading(false));
    } else if (popupType === "product id" && selectedProductIdOption == "delete") {
      const payload = inputValue?.split(",")?.map(id => Number(id));
      deleteProductsByProductIds(payload)
        .then(res => {
          PrimaryToast({
            type: "success",
            message: "Products Delete Successfully.",
          });
          handleClick();
        })
        .catch(err => {
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        })
        .finally(res => setLoading(false));
    } else if (
      (popupType === "edit" ||
        (popupType == "edit-category" && selectedOption === "category")) &&
      path !== "product-approval"
    ) {
      updateProductCategory(selectedId, inputValue)
        .then(res => {
          PrimaryToast({
            type: "success",
            message: "Products Update Successfully.",
          });
          handleClick();
        })
        .catch(err => {
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        })
        .finally(res => setLoading(false));
    } else if (popupType == "edit" && path == "product-approval") {
      approvalProductCatChange(selectedId, inputValue)
        .then(res => {
          PrimaryToast({
            type: "success",
            message: "Product Category Update Successfully.",
          });
          handleClick();
        })
        .catch(err => {
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        })
        .finally(res => setLoading(false));
    } else if (popupType === "edit-category" && selectedOption === "similar") {
        console.log(similarProductIds,"check similarProductIds");
      const sanitizedProductIds = similarProductIds?.length > 0 ? similarProductIds?.split(",")?.map(id => id) : [];
      updateSimilarProducts(selectedId, sanitizedProductIds)
        .then(res => {
          PrimaryToast({
            type: "success",
            message: "Similar Products Updated Successfully.",
          });
          handleClick();
        })
        .catch(err => {
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        })
        .finally(() => setLoading(false));
    } else if (
      (popupType === "product id" && selectedProductIdOption == "disable") ||
      selectedOption == "disable"
    ) {
        let sanitizedProductIds;
        if(!selectedId){
            sanitizedProductIds =  disableProductIds
            .split(",")
            .map(id => id)
        }

      const productIdsToDisable =
        sanitizedProductIds?.length >= 1 ? sanitizedProductIds : [selectedId];
      setLoading(true);

      disableProductsByProductIds(productIdsToDisable)
        .then(res => {
          PrimaryToast({
            type: "success",
            message: "Products Disabled Successfully.",
          });
          window.location.reload();
        })
        .catch(err => {
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <PrimaryPopup
      isOpen={isOpen}
      handleClick={handleClick}
      title={`${
        popupType == "sku"
          ? "Search by SKU"
          : popupType == "seller id"
          ? "Delete Products By Seller ID"
          : popupType == "product id"
          ? "Delete/Disable Products by ID"
          : popupType == "edit"
          ? "Assign Category"
          : popupType == "edit-category"
          ? "Edit Product"
          : ""
      }`}
      isScrollable={false}
      className={`d-flex justify-content-center align-items-center h-100 w-100 ${
        popupType.includes("id")
          ? "delete-product-by-sellerId-popup"
          : popupType == "sku"
          ? "product-sku-popup"
          : ""
      }`}
    >
      {loading && <ProgressPopup label={"Loading..."} />}
      <div>
        <div className="mb-3">
          <label htmlFor="inputValue">
            {popupType == "seller id"
              ? "Seller Id"
              : popupType == "edit" || popupType == "edit-category"
              ? "Select Category"
              : popupType == "product id"
              ? "Select Product Action"
              : "SKU"}
          </label>

          {/* Popup type 'edit-category' */}
          {popupType == "edit-category" && (
            <>
              <PrimaryRadio
                label={"Disable Product"}
                name="product-radio"
                id={"disable-radio"}
                checked={selectedOption == "disable"}
                handleChange={() => handleOptionChange("disable")}
              />
              <PrimaryRadio
                label={"Assign Category"}
                name="product-radio"
                id={"category-radio"}
                checked={selectedOption == "category"}
                handleChange={() => handleOptionChange("category")}
              />
              {selectedOption === "category" && (
                <Select
                  isMulti={false}
                  options={categories}
                  onChange={item => {
                    setInputValue(item?.value);
                  }}
                  name={"assign_category"}
                  className="react-select-container"
                  placeholder={"Please select category"}
                />
              )}
              <PrimaryRadio
                label={"Similar Product Ids"}
                name="product-radio"
                id={"similar-radio"}
                checked={selectedOption == "similar"}
                handleChange={() => handleOptionChange("similar")}
              />
              {selectedOption === "similar" && (
                <textarea
                  rows="5"
                  placeholder="Enter Similar Product IDs"
                  className="form-control mt-2"
                  value={similarProductIds}
                  onChange={handleSimilarProductChange}
                />
              )}
            </>
          )}

          {/* Popup type 'product id' */}
          {popupType === "product id" && (
            <>
              <PrimaryRadio
                label="Delete Products"
                name="product-id-radio"
                id="delete-radio"
                checked={selectedProductIdOption == "delete"}
                handleChange={() => handleProductIdChange("delete")}
              />
              {selectedProductIdOption == "delete" && (
                <textarea
                  rows="5"
                  placeholder="Enter product ids separated by comma( , )"
                  className="form-control"
                  value={inputValue || ""}
                  onChange={handleChange}
                />
              )}
              <PrimaryRadio
                label="Disable Products"
                name="product-id-radio"
                id="disable-radio"
                checked={selectedProductIdOption == "disable"}
                handleChange={() => handleProductIdChange("disable")}
              />
              {selectedProductIdOption == "disable" && (
                <textarea
                  rows="5"
                  placeholder="Enter product ids separated by comma( , )"
                  className="form-control"
                  value={disableProductIds}
                  onChange={handleDisableProductChange}
                />
              )}
            </>
          )}

          {/* Popup type 'edit' */}
          {popupType == "edit" && (
            <Select
              isMulti={false}
              options={categories}
              onChange={item => {
                setInputValue(item?.value);
              }}
              name={"assign_category"}
              className="react-select-container"
              placeholder={"Please select category"}
            />
          )}

          {/* Generic Input */}
          {popupType !== "edit-category" &&
            popupType !== "product id" &&
            popupType !== "edit" && (
              <input
                type="text"
                className={`form-control ${
                  popupType.includes("id") ? "w-100" : "w-50"
                }`}
                id="inputValue"
                placeholder={`${
                  popupType == "seller id"
                    ? "Enter Seller ID"
                    : popupType == "product id"
                    ? "Enter Product IDs separated by comma(,)"
                    : "Enter Product SKU"
                }`}
                value={inputValue || ""}
                name="product_sku"
                onChange={handleChange}
              />
            )}
        </div>

        <div className="d-flex justify-content-end mt-4">
          <Button
            disabled={
              popupType == "edit-category" || popupType == "product id"
                ? false
                : inputValue?.length == 0 || !inputValue
            }
            color="primary"
            onClick={handleAction}
          >
            Submit
          </Button>
        </div>

        {popupType === "sku" && (
          <div
            className="overflow-y-auto table-responsive mt-3"
            style={{ maxHeight: "600px" }}
          >
            {productDataBySku}
          </div>
        )}
      </div>
    </PrimaryPopup>
  );
}

export default ProductActionPopup;
