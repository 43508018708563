import React, { useState, useEffect } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  getSinglePayout,
  updatePayoutMethod,
} from "services/SellerStoreServices";
import GlobalService from "services/GlobalService";
import PrimaryInput from "components/Common/PrimaryInput";
import TopHeading from "components/Common/TopHeading";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimarySelect from "components/Common/PrimarySelect";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import SwitchButton from "components/Common/SwitchButton";
import { setPageTitle } from "utils/pageTitle";
import { capitalizeString } from "utils/string";

function EditPayoutMethod() {
  const history = useNavigate();
  const params = useParams();
  const { id } = params;
  const [fields, setFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [statesData, setStates] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [isProvinceText, setIsProvinceText] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [payoutStatus, setPayoutStatus] = useState();
  const globalService = GlobalService();

  useEffect(() => {
    setPageTitle("Edit Seller Payout - Inspire Uplift");
    getStates();
    getCountries();
  }, []);

  const getStates = () => {
    globalService
      .getStates()
      .then(response => {
        setStates([
          { name: "Please select one...", value: "" },
          ...response?.data?.data,
        ]);
      })
      .catch(error => console.log(error.message));
  };

  const getCountries = () => {
    globalService
      .getCountries()
      .then(response => {
        let countryData = response?.data?.data?.map(item => {
          return {
            name: item.name,
            alpha2Code: item?.alpha2Code?.toLowerCase(),
          };
        });
        setCountriesData([
          { name: "Please select one...", value: "" },
          ...countryData,
        ]);
      })
      .catch(error => console.log(error.message));
  };

  useEffect(() => {
    setLoading(true);
    getSinglePayout(id)
      .then(res => {
        setPayoutStatus(res?.data?.status);
        setInitialData({
          ...res?.data?.data,
          city: capitalizeString(res?.data?.data?.city),
          is_enabled: res?.data?.is_enabled == 1 ? true : false,
        });
        setFields({
          ...res?.data?.data,
          is_enabled: res?.data?.is_enabled == 1 ? true : false,
          city: capitalizeString(res?.data?.data?.city),
          //   dob: moment(res?.data[0]?.data?.dob).format("MM-DD-YYYY"),
          status:
            res?.data?.status === 0
              ? "Pending"
              : res?.data?.status === 1
              ? "Approved"
              : res?.data?.status === 2
              ? "Rejected"
              : res?.data?.status === 3
              ? "Freeze"
              : "Pending",
        });

        if (res?.data?.data?.country_code === "US") {
          setIsProvinceText(false);
        } else {
          setIsProvinceText(true);
        }
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, []);

  const handleChange = e => {
    const name = e.target.name;
    const value =
      e.target.type == "checkbox" ? e.target.checked : e.target.value;
    const fieldsObj = { ...fields };
    if (name == "branch_id") {
      if (value.length <= 9) {
        if (/^[0-9]{0,9}$/.test(value)) {
          fieldsObj[name] = value;
        }
      } else if (value > 9) {
        return false;
      }
    } else {
      fieldsObj[name] = value;
    }

    if (name === "country_code") {
      if (value?.toLowerCase() !== "us") {
        setIsProvinceText(true);
        fieldsObj["province_code"] = "";
        fieldsObj["province"] = "";
      } else {
        setIsProvinceText(false);
      }
    }
    setFields(fieldsObj);
  };

  const handleValidation = () => {
    if (!fields["dob"]) {
      PrimaryToast({
        message: "Date of birth field is required.",
        type: "error",
      });
      return false;
    }

    if (
      fields["dob"] &&
      new Date(fields["dob"]).getFullYear() > new Date().getFullYear() - 18
    ) {
      PrimaryToast({
        message: "Date of birth must be date before 18 years ago.",
        type: "error",
      });
      return false;
    }

    if (fields["branch_id"] && fields["branch_id"].length < 9) {
      PrimaryToast({ message: "Invalid routing number.", type: "error" });
      return false;
    }
    return true;
  };

  const handleSubmit = e => {
    e.preventDefault();
    toast.dismiss();
    let updatedFields = {};
    Object.keys(fields)?.forEach(key => {
      if (key !== "status" && fields[key] !== initialData[key]) {
        if (fields[key] !== "") {
          updatedFields[key] = fields[key];
        }
      }
    });
    updatedFields = {
      ...updatedFields,
      city: updatedFields?.city?.toLowerCase() || undefined,
      address: updatedFields?.address?.toLowerCase() || undefined,
      is_enabled:
        updatedFields?.is_enabled == true
          ? 1
          : updatedFields?.is_enabled == false
          ? 0
          : undefined,
      country: updatedFields?.country_code
        ? countriesData
            ?.find(item => item?.alpha2Code === fields?.country_code)
            ?.name?.toLowerCase()
        : undefined,
      province: updatedFields?.province_code
        ? isProvinceText
          ? fields?.province_code?.toLowerCase()
          : statesData
              ?.find(item => item.abbreviation === fields?.province_code)
              ?.name?.toLowerCase()
        : undefined,

      country_code: undefined,
      province_code: undefined,
    };
    if (handleValidation()) {
      setLoading(true);
      updatePayoutMethod(updatedFields, id)
        .then(res => {
          PrimaryToast({ type: "success", message: res?.data?.message });
          history(-1);
        })
        .catch(err => {
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        })
        .finally(res => setLoading(false));
    }
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <form onSubmit={handleSubmit}>
        <div className="mb-3 d-flex justify-content-between">
          <TopHeading heading="Edit Payout Method" />
          <div className="d-flex">
            <Button onClick={() => history(-1)} className="me-2">
              Cancel
            </Button>
            <Button color="primary">Save</Button>
          </div>
        </div>
        <Card>
          <CardBody>
            <div className="w-50">
              <div className="mb-3">
                <PrimaryInput
                  label={"Payout Method Name"}
                  isRequired
                  name="name"
                  placeholder="Enter Name"
                  value={fields?.name || ""}
                  handleChange={handleChange}
                />
              </div>
              {(payoutStatus == 1 || payoutStatus == 5) && (
                <div className="mb-3">
                  <label>Status</label>
                  <SwitchButton
                    checked={fields.is_enabled === true ? true : false}
                    label={fields.is_enabled === true ? "Enabled" : "Disabled"}
                    name="is_enabled"
                    value={fields?.is_enabled}
                    handleChange={handleChange}
                  />
                </div>
              )}
              <h4 className="font-semi-bold mb-3">Address Information</h4>
              <div className="mb-3">
                <PrimaryInput
                  label={"First Name"}
                  isRequired
                  name="first_name"
                  placeholder="Enter First Name"
                  value={fields?.first_name || ""}
                  handleChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <PrimaryInput
                  label={"Last Name"}
                  isRequired
                  name="last_name"
                  placeholder="Enter Last Name"
                  value={fields?.last_name || ""}
                  handleChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <PrimaryInput
                  label={"Address"}
                  isRequired
                  name="address"
                  placeholder="Enter Address"
                  value={fields?.address || ""}
                  handleChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <PrimarySelect
                  label={"Country"}
                  name="country_code"
                  isRequired
                  handleChange={handleChange}
                  value={fields?.country_code?.toLowerCase() || ""}
                  options={countriesData || []}
                  path="country"
                />
              </div>
              {!isProvinceText ? (
                <div className="mb-3">
                  <PrimarySelect
                    label={"Province"}
                    name="province_code"
                    isRequired
                    handleChange={handleChange}
                    value={fields?.province_code || ""}
                    options={statesData || []}
                    path="state"
                  />
                </div>
              ) : (
                <div className="mb-3">
                  <PrimaryInput
                    label={"Province"}
                    isRequired
                    name="province_code"
                    placeholder="Enter Province Code"
                    value={fields?.province_code || ""}
                    handleChange={handleChange}
                  />
                </div>
              )}
              <div className="mb-3">
                <PrimaryInput
                  label={"City"}
                  isRequired
                  name="city"
                  placeholder="Enter city"
                  value={fields?.city || ""}
                  handleChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <PrimaryInput
                  label={"Zip Code"}
                  isRequired
                  name="zip_code"
                  placeholder="Enter Zip Code"
                  value={fields?.zip_code || ""}
                  handleChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <PrimaryInput
                  label={"Date of Birth"}
                  isRequired
                  name="dob"
                  placeholder="Enter dob (YYYY-MM-DD)"
                  value={fields?.dob || ""}
                  handleChange={handleChange}
                />
              </div>
              {fields?.nationality && (
                <div className="mb-3">
                  <PrimarySelect
                    label={"Nationality"}
                    name="nationality"
                    isRequired
                    handleChange={handleChange}
                    value={fields?.nationality?.toLowerCase() || ""}
                    options={countriesData || []}
                    path="country"
                  />
                </div>
              )}
              <h4 className="font-semi-bold mb-3">Business Information</h4>
              <div className="mb-3">
                <PrimarySelect
                  label={"Business Entity"}
                  isRequired
                  name="business_entity"
                  placeholder="Enter Business Entity"
                  value={fields?.business_entity?.toLowerCase()}
                  handleChange={handleChange}
                  options={[
                    {
                      name: "Individual",
                      value: "individual",
                    },
                    {
                      name: "Business",
                      value: "business",
                    },
                  ]}
                />
              </div>
              {fields?.payment_method_type !== "usdc" &&
                fields?.payment_method_type !== "payoneer" &&
                fields?.payment_method_type !== "usdt" && (
                  <>
                    <h4 className="font-semi-bold mb-3">Tax Information</h4>

                    {fields?.business_name && (
                      <div className="mb-3">
                        <PrimaryInput
                          label={"Business Name"}
                          isRequired
                          name="business_name"
                          placeholder="Enter Store Name"
                          value={fields?.business_name || ""}
                          handleChange={handleChange}
                        />
                      </div>
                    )}
                    {fields?.business_entity?.toLowerCase() == "individual"
                      ? fields?.ssn && (
                          <div className="mb-3">
                            <PrimaryInput
                              label={"SSN"}
                              isRequired
                              name="ssn"
                              placeholder="Enter SSN"
                              value={fields?.ssn || ""}
                              handleChange={handleChange}
                            />
                          </div>
                        )
                      : fields?.ein && (
                          <div className="mb-3">
                            <PrimaryInput
                              label={"Ein"}
                              isRequired
                              name="ein"
                              placeholder="Enter Ein"
                              value={fields?.ein || ""}
                              handleChange={handleChange}
                            />
                          </div>
                        )}
                  </>
                )}
              <h4 className="font-semi-bold mb-3">Payout Information</h4>
              <div className="mb-3">
                <PrimaryInput
                  label={"Payout Method Type"}
                  isRequired
                  name="payment_method_type"
                  placeholder="Enter Payment Method Type"
                  value={fields?.payment_method_type || ""}
                  handleChange={handleChange}
                  isDisabled
                />
              </div>
              {fields?.payment_method_type === "payoneer" && (
                <div>
                  <div className="mb-3">
                    <PrimaryInput
                      label={"Payoneer Name"}
                      isRequired
                      name="payoneer_name"
                      placeholder="Enter Payoneer Name"
                      value={fields?.payoneer_name || ""}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <PrimaryInput
                      label={"Payoneer Email"}
                      isRequired
                      name="payoneer_email"
                      type={"email"}
                      placeholder="Enter Payoneer Email"
                      value={fields?.payoneer_email || ""}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              )}
              {fields?.payment_method_type === "usdt" && (
                <div>
                  <div className="mb-3">
                    <PrimaryInput
                      label={"Usdt Address"}
                      isRequired
                      name="usdt_address"
                      placeholder="Enter Usdt Address"
                      value={fields?.usdt_address || ""}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <PrimarySelect
                      name="usdt_network"
                      label={"Usdt network"}
                      value={fields?.usdt_network || ""}
                      isRequired
                      handleChange={handleChange}
                      options={[
                        {
                          name: "BEP20",
                          value: "BEP20",
                        },
                        {
                          name: "Solana",
                          value: "Solana",
                        },
                        {
                          name: "Tron",
                          value: "Tron",
                        },
                      ]}
                    />
                  </div>
                </div>
              )}
              {fields?.payment_method_type === "usdc" && (
                <div>
                  <div className="mb-3">
                    <PrimaryInput
                      label={"Usdc Address"}
                      isRequired
                      name="usdc_address"
                      placeholder="Enter Usdc Address"
                      value={fields?.usdc_address || ""}
                      handleChange={handleChange}
                      isDisabled={true}
                    />
                  </div>
                  <div className="mb-3">
                    <PrimarySelect
                      name="usdc_network"
                      label={"Usdc network"}
                      value={fields?.usdc_network || ""}
                      isRequired
                      handleChange={handleChange}
                      options={[
                        {
                          name: "Select Usdc Network",
                        },
                        {
                          name: "Polygon",
                          value: "Polygon",
                        },
                        {
                          name: "Solana",
                          value: "Solana",
                        },
                        {
                          name: "Tron",
                          value: "Tron",
                        },
                        {
                          name: "Flow",
                          value: "Flow",
                        },
                        {
                          name: "Algorand",
                          value: "Algorand",
                        },
                        {
                          name: "Stellar",
                          value: "Stellar",
                        },
                        {
                          name: "BEP20",
                          value: "BEP20",
                        },
                        {
                          name: "Avalanche",
                          value: "Avalanche",
                        },
                      ]}
                    />
                  </div>
                </div>
              )}
              {fields?.payment_method_type === "hyperwallet_wire" && (
                <>
                  <div className="mb-3">
                    <PrimaryInput
                      label={"Owner Account Name"}
                      isRequired
                      name="owner_bank_account_name"
                      placeholder="Enter Owner Account Name"
                      value={fields?.owner_bank_account_name || ""}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <PrimaryInput
                      label={"Bank Routing Number"}
                      isRequired
                      name="branch_id"
                      placeholder="Enter Bank Routing Number"
                      value={fields?.branch_id || ""}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <PrimaryInput
                      label={"Account Number"}
                      isRequired
                      name="account_number"
                      placeholder="Enter Account Number"
                      value={fields?.account_number || ""}
                      handleChange={handleChange}
                    />
                  </div>

                  <div className="mb-3">
                    <PrimarySelect
                      name="bank_account_purpose"
                      label={"Bank Account Type"}
                      isRequired
                      value={fields?.bank_account_purpose}
                      handleChange={handleChange}
                      options={[
                        {
                          name: "Checking",
                          value: "CHECKING",
                        },
                        {
                          name: "Savings",
                          value: "SAVINGS",
                        },
                      ]}
                    />
                  </div>
                </>
              )}
              {fields?.payment_method_type === "hyperwallet_paypal" && (
                <div className="mb-3">
                  <PrimaryInput
                    label={"Paypal Email"}
                    isRequired
                    name="paypal_email"
                    placeholder="Enter Paypal Email"
                    value={fields?.paypal_email}
                    handleChange={handleChange}
                  />
                </div>
              )}

              <div className="mb-3">
                <PrimaryInput
                  label={"Transfer Currency"}
                  isRequired
                  name="transfer_currency"
                  placeholder="Enter Transfer Currency"
                  value={fields?.transfer_currency || ""}
                  handleChange={handleChange}
                  isDisabled
                />
              </div>

              <div className="mb-3">
                <PrimaryInput
                  label={"Status"}
                  isRequired
                  name="status"
                  placeholder="Enter status"
                  value={fields?.status || ""}
                  handleChange={handleChange}
                  isDisabled
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </form>
    </div>
  );
}

export default EditPayoutMethod;
