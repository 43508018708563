import React, { useEffect, useState } from "react";
import TopHeading from "components/Common/TopHeading";
import { Card, CardBody, Button } from "reactstrap";
import {
  createRestrictSeller,
  getAllRestrictedSeller,
} from "services/SellerRestrictService";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { setPageTitle } from "utils/pageTitle";

function SellerRestriction() {
  const [sellerIds, setSellerIds] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPageTitle("Seller Restriction - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    getAllRestrictedSeller()
      .then(res => {
        setSellerIds(res.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    createRestrictSeller(sellerIds)
      .then(res => {
        PrimaryToast({
          type: "success",
          message: "Seller Restrict Successfully",
        });
      })
      .catch(err => {
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message[0],
        });
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center">
          <TopHeading heading={"Seller Restriction"} />
          <Button color="primary" type="submit">
            Save
          </Button>
        </div>
        <Card className="mt-4">
          <CardBody className="w-50">
            <div className="mb-3">
              <label htmlFor="inputEmail4">
                Seller Restriction <span className="primary-text">*</span>
              </label>
              <textarea
                rows={3}
                type="text"
                className="form-control"
                value={sellerIds}
                id="inputEmail4"
                name="seller_restriction_ids"
                placeholder="Enter Seller Ids"
                onChange={e => {
                  setSellerIds(e.target.value);
                }}
              />
              <span className="text-11">
                Seller ids should be with comma separated e.g: 10,11,12,13,14...
              </span>
            </div>
          </CardBody>
        </Card>
      </form>
    </div>
  );
}

export default SellerRestriction;
