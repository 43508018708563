import React from "react";
import PrimaryInput from "components/Common/PrimaryInput";
import { Button, Row, Col } from "reactstrap";
import SecondarySelect from "components/Common/SecondarySelect";

function SellerInformationForm({ fields, handleChange, handleResetPassword, handleSelectChange, selectedOptions }) {
  return (
    <div className="w-50">
      <h4 className="font-semi-bold">Store Information</h4>
      <div className="">
        <Row className="mb-3">
          <Col className="form-group col-md-6">
            <PrimaryInput
              name={"first_name"}
              label={"First Name"}
              value={fields?.first_name || ""}
              placeholder="Enter First Name"
              type={"text"}
              isRequired
              handleChange={handleChange}
              maxLength={50}
            />
          </Col>
          <Col className="form-group col-md-6">
            <PrimaryInput
              name={"last_name"}
              value={fields?.last_name || ""}
              label={"Last Name"}
              placeholder="Enter Last Name"
              type={"text"}
              handleChange={handleChange}
              isRequired
              maxLength={50}
            />
          </Col>
        </Row>
        <div className="mb-3">
          <PrimaryInput
            name={"store_email"}
            label={"Store Email"}
            value={fields?.store_email || ""}
            placeholder="Enter Store Email"
            type={"email"}
            handleChange={handleChange}
            isRequired
          />
        </div>
        <div className="mb-3">
          <PrimaryInput
            name={"store_name"}
            label={"Store Name"}
            placeholder="Enter Store Name"
            type={"text"}
            handleChange={handleChange}
            isRequired
            value={fields?.store_name || ""}
            maxLength={40}
          />
        </div>
        <div className="mb-3">
          <PrimaryInput
            name={"slug"}
            label={"Slug"}
            placeholder="Enter Store Slug"
            type={"text"}
            handleChange={handleChange}
            isRequired
            value={fields?.slug || ""}
          />
        </div>
        <div className="mb-3">
          <Button
            className="passowrd-reset-btn"
            type="button"
            color="primary"
            onClick={handleResetPassword}
          >
            Send password reset link
          </Button>
        </div>
        <div className="mb-3">
          <SecondarySelect
            isRequired={true}
            label={"Select Group ID"}
            isMulti={false}
            hideSelectedOptions={false}
            value={selectedOptions}
            name="seller_groups_ids"
            placeholder={"Enter  Select Group ID"}
            handleSelect={handleSelectChange}
          />
        </div>
        <div className="mb-3">
          <PrimaryInput
            name={"seo_title"}
            label={"SEO Title"}
            placeholder="Enter SEO title"
            type={"text"}
            isRequired
            value={fields?.seo_title || ""}
            handleChange={handleChange}
            maxLength={60}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="inputEmail4">
            SEO Description <span className="primary-text">*</span>
          </label>
          <textarea
            type="text"
            name="seo_description"
            className="form-control"
            placeholder="Enter SEO Description"
            rows={3}
            value={fields?.seo_description || ""}
            onChange={handleChange}
            maxLength={160}
          />
        </div>
      </div>
    </div>
  );
}

export default SellerInformationForm;
