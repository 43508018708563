import React from "react";
import { Row, Col } from "reactstrap";
import PrimaryCard from "components/Common/PrimaryCard";
import totalOrders from "../../assets/images/total-revenue.svg";
import leavedAllowed from "../../assets/images/leaves-icon.svg";
import scheduleIcon from "../../assets/images/schedule-icon.svg";

const EmployeeCardsSection = ({ employeeData }) => {
  return (
    <Row>
      <Col sm="4">
        <PrimaryCard
          title="Salary"
          count={employeeData?.salary ? `$${employeeData?.salary}` : 0}
          icon={totalOrders}
        />
      </Col>
      <Col sm="4">
        <PrimaryCard
          title="Leaves Allowed"
          count={
            employeeData?.allowed_leaves ? employeeData?.allowed_leaves : 0
          }
          icon={leavedAllowed}
        />
      </Col>
      <Col sm="4">
        <PrimaryCard
          title="Schedule"
          count={employeeData?.schedule ? employeeData?.schedule : ""}
          icon={scheduleIcon}
        />
      </Col>
    </Row>
  );
};

export default EmployeeCardsSection;
