import { getApi } from "./BaseService";
import  axiosInstance  from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";



export const orderPaymentApproveList = (page,search,filter) => {
    return getApi({
        url:'admin/seller/order-payment',
        data:{
            is_admin:true,
            page:page || undefined,
            search:search || undefined,
            ...filter,
        }
    }).then(res => (res.data))
};

export const orderPaymentReject = (id) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/seller/order-payment/reject/${id}`,
        method: "POST",
        data: {
            is_admin:true,
        },
		withCredentials:true,
       
        headers: getHttpHeaders(),
    });
};
export const orderPaymentApprove = (id) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/seller/order-payment/approve/${id}`,
        method: "POST",
        data: {
            is_admin:true,
        },
		withCredentials:true,
       
        headers: getHttpHeaders(),
    });
    
};

