import React, { useState } from "react";
import { Button, Card, CardBody } from "reactstrap";
import MessageSection from "./TaskMessage";
import attchementLogo from "../../assets/images/attachment.svg";
import sendLogo from "../../assets/images/send.svg";
import closeIcon from "assets/images/cross-icon.svg";
import ImageGallery from "components/Common/ImageGallerySlider";
import InfiniteScroll from "react-infinite-scroll-component";

const TaskTimeline = ({
  data,
  createTimelineComment,
  comment,
  setComment,
  handleImageClick,
  fileInputRef,
  handleImageChange,
  mediaPreview,
  handleClear,
  taskDetail,
  handleKeyPress,
  getTimeLine,
  page,
  hasMore,
}) => {
  const decodeHTML = htmlString => {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(htmlString, "text/html").body
      .innerHTML;

    // Remove all <img> tags from the decoded string
    const withoutImages = decodedString.replace(/<img\b[^>]*>/gi, match => {
      // Add the className "description-image" and set dynamic height and width
      return match.replace("<img", '<img class="description-image" height="100" width="200"');
    });
    

    // Open links in new tabs
    const withNewTabs = withoutImages.replace(
      /<a\b([^>]+?)>/gi,
      '<a $1 target="_blank">'
    );

    return withNewTabs;
  };

  const decodedString = decodeHTML(taskDetail?.details);

  const [isGallery, setGallery] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const handleShowGallery = index => {
    setSelectedIndex(index);
    setGallery(true);
  };

  return (
    <Card>
      <CardBody className="">
        <div className="font-semi-bold text-15">Task Timeline</div>
        <div className="mb-3">
          <MessageSection
            description={decodedString || ""}
            isImage={true}
            data={taskDetail}
            handleShowGallery={handleShowGallery}
          />
        </div>
        <InfiniteScroll
          dataLength={data.length}
          next={() => getTimeLine(page + 1)}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
        >
          {data?.map((item, index) => (
            <MessageSection isImage={true} key={index} data={item} />
          ))}
        </InfiniteScroll>

        <div className="py-4 d-flex justify-content-between align-items-center">
          <div className="message-type-field" style={{ width: "85%" }}>
            <input
              type={"text"}
              name="comment"
              value={comment}
              onChange={e => setComment(e.target.value)}
              className="bg-transparent outline-none border-0 w-75"
              placeholder="Write a comment..."
              onKeyPress={handleKeyPress}
              disabled={taskDetail?.status === "completed"}
            />
            <>
              {mediaPreview && (
                <div className="position-relative">
                  <img
                    src={closeIcon}
                    height={13}
                    width={13}
                    className="ps-1 cursor-pointer preview-remove-btn"
                    onClick={handleClear}
                    alt="close icon"
                  />
                  <img
                    src={mediaPreview}
                    alt="media image"
                    className="object-fit-cover"
                    height={40}
                    width={50}
                  />
                </div>
              )}
              <img
                src={attchementLogo}
                height={20}
                width={20}
                alt="attachment Logo"
                onClick={handleImageClick}
                style={{ cursor: "pointer" }}
              />
              <input
                type="file"
                id="attachment"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleImageChange}
                disabled={taskDetail?.status === "completed"}
              />
            </>
          </div>
          <Button
            color="primary"
            className="rounded-full"
            onClick={createTimelineComment}
            disabled={taskDetail?.status === "completed"}
          >
            Send
            <img src={sendLogo} alt="send Logo" className="ps-1" />
          </Button>
        </div>
        {isGallery && (
          <ImageGallery
            images={taskDetail?.task_media_urls}
            selectedIndex={selectedIndex}
            isGallery={isGallery}
            setSelectedIndex={setSelectedIndex}
            setGallery={setGallery}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default TaskTimeline;
