import DashBoardIcon from "../../../assets/images/sidebar-icons/dashboard.svg";
import OrdersIcon from "../../../assets/images/sidebar-icons/order.svg";
import NotificationIcon from "../../../assets/images/sidebar-icons/notification.svg";
import ThemeIcon from "../../../assets/images/sidebar-icons/theme.svg";
import CouponIcon from "../../../assets/images/sidebar-icons/coupon.svg";
import CustomerIcon from "../../../assets/images/sidebar-icons/customer.svg";
import EmployeIcon from "../../../assets/images/sidebar-icons/employee.svg";
import GiftCardIcon from "../../../assets/images/sidebar-icons/gift-card.svg";
import MarketingIcon from "../../../assets/images/sidebar-icons/marketing.svg";
import OtherIcon from "../../../assets/images/sidebar-icons/other.svg";
import ProductIcon from "../../../assets/images/sidebar-icons/product.svg";
import ReportIcon from "../../../assets/images/sidebar-icons/reports.svg";
import SellerIcon from "../../../assets/images/sidebar-icons/seller.svg";
import SettingIcon from "../../../assets/images/sidebar-icons/settings.svg";
import FlagsIcon from "../../../assets/images/sidebar-icons/flag-icon.svg";
import cacheIcon from "assets/images/sidebar-icons/cache-icon.svg";
import seoIcon from "assets/images/sidebar-icons/seo.svg";

export const sidebarIcons = {
    DashBoardIcon,
    OrdersIcon,
    NotificationIcon,
    ThemeIcon,
    CouponIcon,
    CustomerIcon,
    EmployeIcon,
    GiftCardIcon,
    MarketingIcon,
    OtherIcon,
    ProductIcon,
    ReportIcon,
    SellerIcon,
    SettingIcon,
    FlagsIcon,
    cacheIcon,
    seoIcon
}