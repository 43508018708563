import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopHeading from "components/Common/TopHeading";
import { CardBody, Card, Button } from "reactstrap";
import PrimarySearch from "components/Common/PrimarySearch";
import editIcon from "../../../assets/images/edit-icon-white.svg";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import {
  getAllCollections,
  deleteCollection,
  downloadCollectionCsv,
  importCollectionsCsv,
} from "services/ProductsServices";
import PrimaryTable from "components/Common/PrimaryTable";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";
import ExportButton from "components/Common/ExportButton";
import plusIcon from "../../../assets/images/plus-icon.svg";
import ImportCSVFilePopup from "components/Common/Popups/ImportCSVFilePopup";

export default function ProductCollection() {
  const history = useNavigate();

  const [collectionsData, setCollectionsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [isImport, setIsImport] = useToggle(false);
  const [importFile, setImportFile] = useState();
  const [searchVal, setSearch] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const user = useSelector(state => state.auth?.user);
  const permissions = user?.permissions?.map(item => item.name);
  const params = new URLSearchParams(window?.location?.search);

  useEffect(() => {
    setPageTitle("Product Collections - Inspire Uplift");
  }, []);

  useEffect(() => {
    const page = params.get("page") || undefined;
    const search = params?.get("search") || undefined;
    setPageNo(page || 1);
    setSearch(search);
    getCollectionsList(page, search);
  }, [window.location?.search]);

  const getCollectionsList = (page, search) => {
    setLoading(true);
    getAllCollections(page, search)
      .then(res => {
        setCollectionsData(res.data.data);
        setPageCount(res.data.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const handleSearch = e => {
    const val = e.target?.value;
    setSearch(val);
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>
      ),
    },
    {
      dataField: "title",
      text: "Title",
    },
    {
      dataField: "slug",
      text: "Slug",
      formatter: (cell, row) => (
        <a
          target="__blank"
          href={
            window.location.hostname.includes(".itdeptiu")
              ? `https://staging.itdeptiu.com/collection/${row.slug}`
              : `https://www.inspireuplift.com/collection/${row.slug}`
          }
        >
          {row.slug}
        </a>
      ),
    },
    {
      dataField: "is_hidden",
      text: "Status",
      formatter: (cell, row) => (
        <div
          className={`status ${
            row?.is_hidden == 0
              ? "success-status"
              : row?.is_hidden === 1
              ? "danger-status"
              : ""
          } w-max-content`}
        >
          {row?.is_hidden == 0 ? "Activate" : "Deactivate"}
        </div>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex">
          {permissions?.includes("collection-edit") && (
            <div
              className="action-btn edit-icon-bg me-1 cursor-pointer"
              onClick={() => history(`/products/collections/edit/${row.id}`)}
            >
              <img src={editIcon} className="" alt="edit icon" />
            </div>
          )}
        </div>
      ),
    },
  ];

  const data = collectionsData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleDelete = () => {
    const filterArr = collectionsData?.filter(item => !selectedRowKeys?.includes(item.id));
    toast.dismiss();
    setLoading(true);
    deleteCollection(selectedRowKeys)
      .then(res => {
        setSelectedRowKeys([]);
        setShowDeleteModal(false);
        if (filterArr?.length == 0 && pageNo > 1) {
          params.set("page", pageNo - 1);
          let newUrl = `${window?.location?.pathname}?${params.toString()}`;
          history(newUrl, { replace: true });
        } else {
          getCollectionsList(pageNo, searchVal);
        }

        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data });
      })
      .finally(res => setLoading(false));
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (params?.get("page")) {
        params?.delete("page");
      }
      if (searchVal) {
        params?.set("search", searchVal);
      } else {
        params?.delete("search");
      }
      history(`/products/collections?${params?.toString()}`);
    }
  };

  const downloadCsvFile = async () => {
    try {
      const response = await downloadCollectionCsv(searchVal);
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const handleImportCollections = () => {
    setIsImport(!isImport);
  };

  const handleFileUpload = event => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      setImportFile(file);
    } else {
      alert("Please select a CSV file.");
    }
  };

  const saveImportFile = () => {
    setLoading(true);
    importCollectionsCsv(importFile)
      .then(res => {
        setIsImport(false);
        PrimaryToast({ type: "success", message: res?.data?.message });
        setImportFile("");
        if (pageNo > 1) {
          history("/products/collections");
        } else if (pageNo == 1) {
          getCollectionsList(pageNo, searchVal);
        }
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const handleOnSelectAll = (isSelected, rows, e) => {
    if (isSelected) {
      setSelectedRowKeys(rows.map(row => row.id));
    } else {
      setSelectedRowKeys([]);
    }
  };

  const selectRow = permissions?.includes("collection-delete")
    ? {
        mode: "checkbox",
        onSelect: (row, isSelect, rowIndex, e) => {
          if (isSelect) {
            setSelectedRowKeys([...selectedRowKeys, row.id]);
          } else {
            setSelectedRowKeys(selectedRowKeys.filter(key => key !== row.id));
          }
        },
        onSelectAll: handleOnSelectAll,
        selected: selectedRowKeys,
      }
    : undefined;

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this collection?"}
          onConfirm={handleDelete}
          onCancel={() => {
            setSelectedRowKeys([]);
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <TopHeading heading={"Product Collection"} />
        {permissions?.includes("collection-create") && (
          <Button
            color="primary"
            className="rounded"
            onClick={() => history("/products/collections/create")}
          >
            <img src={plusIcon} className="mdi mdi-plus-circle-outline me-1" />
            Create New Collection
          </Button>
        )}
      </div>
      <Card>
        <CardBody>
          <div className="me-2 mb-3 d-flex align-items-center justify-content-between">
            <PrimarySearch
              placeholder={"Search by title, slug"}
              handleChange={handleSearch}
              handleKeyPress={handleKeyPress}
              value={searchVal}
            />
            <div className="d-flex">
              {permissions?.includes("collection-list") ? (
                <Button
                  type="button"
                  color="primary"
                  className="me-3"
                  onClick={handleImportCollections}
                >
                  <img src={plusIcon} alt="import icon" className="pe-2" />
                  import Collections
                </Button>
              ) : null}
              <ExportButton
                isLoading={loading}
                setIsLoading={setLoading}
                fetchData={downloadCsvFile}
              />
              {selectedRowKeys?.length > 0 && (
                <Button
                  className="rounded-full ms-2"
                  onClick={()=>setShowDeleteModal(true)}
                  color="danger"
                  
                >
					<img src={deleteIcon} alt="collection delete" className="pe-2" />
                  Delete Collection
                </Button>
              )}
            </div>
          </div>
          <div className="mt-3">
            <PrimaryTable
              columns={columns}
              data={data}
              bordered={false}
              keyField="id"
              selectRow={selectRow}
            />
          </div>
          {collectionsData?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageNo={pageNo}
              pageCount={pageCount}
              setPageNo={setPageNo}
            />
          )}
        </CardBody>
      </Card>
      {isImport && (
        <ImportCSVFilePopup
          handleSave={saveImportFile}
          isOpen={isImport}
          handleClick={handleImportCollections}
          handleFileUpload={handleFileUpload}
          title={"Import Tracking"}
        />
      )}
    </div>
  );
}
