import React, { useEffect } from "react";

import { Route, Routes } from "react-router-dom";

// Import Routes
import { authProtectedRoutes } from "./routes/";

// layouts
import StoreLayout from "layouts/StoreLayout";
import { useSelector } from "react-redux";

// Import scss
import "./assets/scss/theme.scss";
import "react-toastify/dist/ReactToastify.css";
import AuthWrapper from "hoc/AuthWrapper";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const App = () => {
  const user = useSelector(state => state?.auth?.user);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let isDashboardPerExist = user?.permissions?.some(
      permission => permission.name === "dashboard"
    );
    if (
      user?.id &&
      (location.pathname === "/" || location.pathname === "/dashboard")
    ) {
      if (!isDashboardPerExist) {
        navigate(`/user/detail/${user?.id}`);
      }
    }
  }, [user?.id]);

  return (
    <React.Fragment>
      <AuthWrapper>
        <StoreLayout>
          <Routes>
            {authProtectedRoutes.map((route, idx) => {
              let Component = route.component;
              return (
                <Route
                  key={idx}
                  exact={true}
                  path={route.path}
                  element={<Component />}
                />
              );
            })}
          </Routes>
        </StoreLayout>
      </AuthWrapper>
    </React.Fragment>
  );
};

export default App;
