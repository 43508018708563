import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleComplaint, updateComplaint } from "services/ComplaintsServices";
import PrimaryInput from "components/Common/PrimaryInput";
import PrimaryRadio from "components/Common/PrimaryRadio";
import ProgressPopup from "components/Common/Popups/ProgressPopup";

import PrimaryToast from "components/Common/Toast/ToastMessage";

import { setPageTitle } from "utils/pageTitle";
import { useSelector } from "react-redux";

function RespondComplaint() {
	const [fields, setFields] = useState({});
	const [complaintFields, setComplaintsFields] = useState({});
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [loading, setLoading] = useState(false);
	const { id } = useParams();
	const history = useNavigate();
	const userInfo = useSelector((state)=> state?.auth?.user);

	useEffect(()=>{
		setPageTitle("Respond Complaint - Inspire Uplift")
	},[]);

	useEffect(() => {
		setLoading(true);
		getSingleComplaint(id)
			.then(res => {
				setFields(res.data);
				const isDisabled = (res?.data?.action_by?.id != userInfo?.id && res?.data?.status == 4 );
				setIsButtonDisabled(isDisabled);
				setComplaintsFields({
					...complaintFields,
					note:res?.data?.decision,
				})
			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false));


	}, []);


	const handleChange = e => {
		const name = e.target.name;
		const value = e.target.value;
		const fieldsObj = { ...complaintFields };
		fieldsObj[name] = value;
		setComplaintsFields(fieldsObj);
	}

	const handleSubmit = e => {
		e.preventDefault();
		console.log(complaintFields,"check complaint fields");
		if(complaintFields?.status){
		setLoading(true);
		updateComplaint(complaintFields, id)
			.then(res => {
				history("/others/complaints");
				PrimaryToast({type:'success', message:res?.data?.message});
			})
			.catch(err =>{
				console.log(err)
				PrimaryToast({type:'error',  message: Array.isArray(err?.response?.data?.message) ? err?.response?.data?.message[0] : err?.response?.data?.message});
			})
			.finally(res => setLoading(false));
		}
		else {
			PrimaryToast({type:'error',  message:"Status field is required"});
		}
	};

	return (
		<div className="page-content">
			{
				loading && <ProgressPopup label={"Loading..."} />
			}
			<form onSubmit={handleSubmit}>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<div className="d-flex align-items-center">
						<TopHeading heading={"Respond Complaint"} />
						{
							(fields?.status == 4) ? (
								<div className="status warning-status ms-2">In Process</div>
							) : null
						}
					</div>
					<div className="d-flex align-items-center">
						<Button onClick={() => history("/others/complaints")}>Cancel</Button>
						<Button className="ms-3" color="primary" disabled={isButtonDisabled}>
							Save
						</Button>
					</div>
				</div>
				<Card>
					<CardBody className="w-50">
						<div className="mb-3 mt-3 d-flex align-items-center">
							<label className="mb-0 text-14" >Type:</label>
							<div className="ms-2 mb-0 font-semi-bold text-capitalize text-14">
								{fields?.type}
							</div>
						</div>
						<div className="mb-3">
							<PrimaryInput
								label={`${fields?.type === "seller" ? "Seller" : fields?.type == "product" ? "Product" : ""} Name`}
								name={'product_name'}
								
								value={fields?.product ? fields.product : fields?.seller ? fields?.seller : undefined}
								isDisabled={true}
							/>

						</div>
						<div className="mb-3">
							<PrimaryInput
								label={"Reason"}

								name={'reason'}
								
								value={fields?.reason || undefined}
								isDisabled={true}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="descrtiptionField">Description</label>
							<textarea
								type="text"
								className={`form-control form-disable-form`}
								rows={3}
								value={fields?.description || undefined}
								disabled
								id="descrtiptionField"
								
							/>
						</div>
						<div className="mb-3">
							<PrimaryInput
								label={'Customer'}

								name={'customer'}
							
								value={fields?.customer || undefined}
								isDisabled={true}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="inputEmail4">Decision{complaintFields?.status != 4 ? <span className="primary-text">*</span> : null}</label>
							<textarea
								type="text"
								className="form-control"
								rows={4}
								id="inputEmail4"
								name="note"
								value={complaintFields?.note || ""}
								onChange={handleChange}
								placeholder="Decision / Additional Information"
							/>
						</div>
						{
							fields?.action_by?.name ? (
								<div className="d-flex p-2 border-top border-bottom  mb-3">
									<div className="w-50">
										<span className="fw-bold">Action By: </span>
										<span className="ms-3">{fields?.action_by?.name}</span>
									</div>
									{
										fields?.updated_at && (
											<div className="w-50 ms-2">
												<span className="fw-bold">Updated On: </span>
												<span className="ms-3">{fields?.updated_at}</span>
											</div>
										)
									}
								</div>
							) : null
						}
						<div className="">
							<label>Status<span className="primary-text">*</span></label>
							<div className="mb-2">
								<PrimaryRadio
									label={"Complete"}
									id="completeStatus"
									name={"status"}
									value={3}
									checked={complaintFields?.status == 3 || ""}
									handleChange={handleChange}
								/>
							</div>
							<div className="">
								<PrimaryRadio
									label={"Ignore"}
									id="ignoreStatus"
									name={"status"}
									value={2}
									checked={complaintFields?.status == 2 || ""}
									handleChange={handleChange}
								/>
							</div>
							{
								fields?.status !== 4 ? (
									<div className="mt-2">
									<PrimaryRadio
										label={"In Process"}
										id="inProcessStatus"
										name={"status"}
										value={4}
										checked={complaintFields?.status == 4 || ""}
										handleChange={handleChange}
	
									/>
								</div>
								) : null
							}
							
						</div>
					</CardBody>
				</Card>
			</form>

		</div>
	);
}

export default RespondComplaint;
