import React, { useState, useEffect } from "react";
import PrimaryInput from "components/Common/PrimaryInput";
import { getCategories } from "services/ProductsServices";
import PrimaryImageUploader from "components/Common/PrimaryImageUploder";
import PrimarySelect from "components/Common/PrimarySelect";
import { Button } from "reactstrap";
import plusIcon from "assets/images/plus-icon.svg";
import deleteIcon from "assets/images/delete-icon.svg";
import Select from "react-select";
import RelatedGroupsField from "../RelatedGroupsField";
import RichTextEditor from "components/Common/TextEditor/RichTextEditor";
import SwitchButton from "components/Common/SwitchButton";

function CategoryForm({
  fields,
  handleChange,
  setImage,
  handleEditor,
  image,
  description,
  faq,
  handleAddQuestion,
  handleDelete,
  handleFaq,
  setLoading,
  setFields,
  path
}) {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    getCategories()
      .then(res => {
        const categoriesList = [
          ...res.data.map(item => {
            return {
              label: item.name,
              value: item.id,
            };
          }),
        ];
        setCategories(categoriesList);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, []);

  let subCat = categories?.find(item => item.value == fields?.parent_id);

  return (
    <div>
      <h4 className="font-semi-bold">Category Information</h4>
      <div className="w-50">
        <div className="mb-3">
          <PrimaryInput
            label={"Category Name"}
            isRequired
            name="name"
            value={fields?.name || ""}
            handleChange={handleChange}
            type={"text"}
            placeholder="Enter Category Name"
          />
        </div>
        <div className="mb-3">
          <PrimaryInput
            label={"Category Slug"}
            isRequired
            name="slug"
            value={fields?.slug || ""}
            handleChange={handleChange}
            type={"text"}
            placeholder="Enter Category Slug"
          />
        </div>
        <div className="mb-3">
          <PrimarySelect
            label={"Category Type"}
            name="type"
            isRequired
            value={fields?.type || ""}
            options={[
              {
                name: "Please Select Category Type",
                value: "",
              },
              {
                name: "Parent",
                value: "parent",
              },
              {
                name: "Sub Category",
                value: "sub_category",
              },
            ]}
            handleChange={handleChange}
          />
        </div>
        {fields?.type === "sub_category" && (
          <div className="mb-3" style={{ minWidth: "200px" }}>
            <label htmlFor="inputEmail4">
              Parent Category<span className="primary-text">*</span>
            </label>
            <Select
              isMulti={false}
              options={categories}
              onChange={item => {
                setFields({
                  ...fields,
                  parent_id: item?.value,
                });
              }}
              value={subCat}
              name={"parent_id"}
              className="react-select-container"
              placeholder={"Please select category"}
            />
          </div>
        )}
        <div className="mb-3">
          <RelatedGroupsField
            label={"Related Groups"}
            fields={fields}
            setFields={setFields}
          />
        </div>
        <div className="mb-3">
          <PrimaryInput
            label={"Google Category ID"}
            name="google_category_id"
            type={"text"}
            value={fields?.google_category_id || ""}
            handleChange={handleChange}
            placeholder="Enter Google Category ID"
          />
        </div>
        <div className="mb-3">
          <PrimaryImageUploader
            label={"Image"}
            getFiles={image}
            setFiles={setImage}
          />
        </div>
        <div className="mb-3">
          <PrimaryInput
            label={"SEO Title"}
            isRequired
            name="seo_title"
            type={"text"}
            value={fields?.seo_title || ""}
            handleChange={handleChange}
            placeholder="Enter SEO Title"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="inputEmail4">
            SEO Description<span className="primary-text">*</span>
          </label>
          <textarea
            rows={4}
            type="text"
            className="form-control"
            id="inputEmail4"
            placeholder="Enter SEO Description"
            onChange={handleChange}
            value={fields?.seo_description}
            name="seo_description"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description">Description</label>
          <RichTextEditor
            name="description"
            maxChar={4000}
            value={description}
            disablePlugins={
              "about,paste-storage,add-new-line,ai-assistant,color,copyformat,drag-and-drop,drag-and-drop-element,dtd,file,fullsize,hr,iframe,indent,key-arrow-outside,line-height,media,mobile,placeholder,powered-by-jodit,preview,print,resize-cells,resize-handler,search,select-cells,source,speech-recognize,spellcheck,sticky,symbols,table-keyboard-navigation,video,wrap-nodes,xpath"
            }
            buttons={
              "bold,underline,italic,strikethrough,ul,ol,paragraph,table,image,link,left, right, center"
            }
            uploader={true}
            handleChange={e => handleEditor(e)}
          />
        </div>
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            name="is_hidden"
            id="isHiddenCategory"
            checked={fields?.is_hidden || ""}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="isHiddenCategory">
            Category hidden
          </label>
        </div>
        {path == "edit" ? (
          <div className="mb-3">
            <label>Auto Update Related Categories</label>
            <SwitchButton
              checked={fields?.non_updatable === true ? true : false}
              name="non_updatable"
              value={fields?.non_updatable}
              handleChange={handleChange}
            />
          </div>
        ) : null}
        <div className="">
          <h4>Category FAQ&apos;s</h4>
          {faq?.map((item, index) => {
            const label1 = `FAQ ${index + 1} `;
            return (
              <div className="mb-3" key={index}>
                <div className="">
                  <div className="d-flex justify-content-between mb-0">
                    <div className="mb-0 font-medium faq-label-section">
                      {label1}
                    </div>
                    <div
                      onClick={() => handleDelete(index)}
                      className="cursor-pointer text-danger d-flex align-items-center font-medium"
                    >
                      <img
                        src={deleteIcon}
                        alt="delete icon"
                        height={12}
                        width={13}
                        className="pe-1"
                      />
                      Remove this FAQ
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-text">Question</span>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e => handleFaq(e, index)}
                      name="question"
                      value={item.question}
                      placeholder="Type here"
                    />
                  </div>
                </div>
                <div className="">
                  <div className="mb-3">
                    <label htmlFor="answer">Answer</label>
                    <div>
                      <RichTextEditor
                        maxChar={4000}
                        value={item?.answer}
                        disablePlugins={
                          "about,paste-storage,add-new-line,ai-assistant,color,copyformat,drag-and-drop,drag-and-drop-element,dtd,file,fullsize,hr,iframe,indent,key-arrow-outside,line-height,media,mobile,placeholder,powered-by-jodit,preview,print,resize-cells,resize-handler,search,select-cells,source,speech-recognize,spellcheck,sticky,symbols,table-keyboard-navigation,video,wrap-nodes,xpath"
                        }
                        buttons={
                          "bold,underline,italic,strikethrough,ul,ol,paragraph,link,left, right, center"
                        }
                        name={"answer"}
                        handleChange={e => handleFaq(e, index)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <Button className="" color="primary" onClick={handleAddQuestion}>
            <img src={plusIcon} className="mdi mdi-plus-circle-outline me-1" />
            Add FAQ
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CategoryForm;
