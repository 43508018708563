import  axiosInstance  from "./axiosInstance";
import { siteApiUrl, getApi, getHttpHeaders } from "./BaseService";



export function loginUser(data,device_data ) {
	return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/login`,
		data: {
			...data,
			device_data
		},
		withCredentials: true,
		headers:{
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		}
	});
};

export const logoutUser = () =>{
	return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/logout`,
		withCredentials: true,
		data:{
			is_admin:true
		},
		headers:{
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		}
	});
};

export function clearCache() {
	return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/cache-clear`,
		data: {
			is_admin:true,
		},
		withCredentials: true,
		headers:{
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		}
	});
};

export function userInfo() {
	return getApi({
		url:`admin/user-info`,
		headers:getHttpHeaders(),
		data:{
			is_admin:true,
		}
	}).then(res => res.data)
}

export const forgotPassword = (email) => {
	return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/forgot-password`,
		data: {
			is_admin:true,
			email,
		},
		withCredentials:true,
		headers:{
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		}
	});
};

export const checkResetPassword = (data) => {
	return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/reset-check`,
		data: {
			is_admin:true,
			...data,
		},
		withCredentials:true,
		headers:{
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		}
	});
};

export const resetPassword = (email,password,confirm_password,token) => {
	return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/reset-password`,
		data: {
			is_admin:true,
			email,
			password,
			confirm_password,
			token,
		},
		withCredentials:true,
		headers:{
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		}
	});
};

