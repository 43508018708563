import React from "react";
import PrimaryTable from "components/Common/PrimaryTable";
import editIcon from "assets/images/edit-icon-white.svg";
import deleteIcon from "assets/images/delete-icon-white.svg";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";

const RelatedGroupTable = ({
  setPageNo,
  pageNo,
  pageCount,
  data,
  handleDeleteModal,
}) => {
  const columns = [
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "created_at",
      text: "Created At",
      formatter: (cellContent, row) => (
        <div>{row.created_at}</div>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row) => (
        <div className="d-flex align-items-center">
          <div
            className="action-btn edit-icon-bg me-1 cursor-pointer"
            onClick={() =>
              window.open(
                `/seo-keywords/related-groups/edit/${row.id}`,
                "_blank"
              )
            }
          >
            <img src={editIcon} className="" alt="edit icon" />
          </div>
          <div
            className="action-btn delete-btn-bg cursor-pointer"
            onClick={() => {
              handleDeleteModal(row.id);
            }}
          >
            <img src={deleteIcon} className="" alt="delete icon" />
          </div>
        </div>
      ),
    },
  ];
  return (
    <div>
      <PrimaryTable
        keyField={"id"}
        bordered={false}
        columns={columns}
        data={data}
      />
      {pageCount > 1 && (
        <PrimaryPagination
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageCount={pageCount}
        />
      )}
    </div>
  );
};

export default RelatedGroupTable;
