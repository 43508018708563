import React, { useState, useEffect } from 'react';
import TopHeading from 'components/Common/TopHeading';
import PrimaryInput from 'components/Common/PrimaryInput';
import { CardBody, Card, Button } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import SwitchButton from 'components/Common/SwitchButton';
import PrimarySelect from 'components/Common/PrimarySelect';
import PrimaryImageUploader from 'components/Common/PrimaryImageUploder';
import { userResetPasswordLink, getAllEmployeeRoles, getSingleUser, updateUser } from 'services/SettingsServices';
import PrimaryToast from 'components/Common/Toast/ToastMessage';
import CalendarInput from "components/Common/CalenderInput";
import moment from 'moment';
import ProgressPopup from 'components/Common/Popups/ProgressPopup';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { setPageTitle } from 'utils/pageTitle';
import Select from "react-select";


let timmer;
function EditEmployee() {
	const history = useNavigate();
	const [fields, setFields] = useState({
		is_active: true,
	});
	const [roles, setRoles] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedRole, setSelected] = useState([]);
	const [file, setFile] = useState(undefined);
	const [cnic, setCnic] = useState(undefined);
	const { id } = useParams();
	const [showStartDateCalender, setShowStartDateCalender] = useState(false);
	const [showEndDateCalendar, setShowEndDateCalender] = useState(false);
	const [dob, setDob] = useState("");
	const [doj, setDoj] = useState("");
	const permissions = useSelector((state) => state?.auth?.user?.permissions);
	const hasActionPermission = permissions?.some((p) => p.name === "user-action");

	useEffect(()=>{
		setPageTitle("Edit Employee - Inspire Uplift")
	},[]);

	useEffect(() => {
		setLoading(true);
		getAllEmployeeRoles()
			.then(res => {
				let options = res?.data?.map((item) => {
					return {
						label: item.name,
						value: item.name
					}
				});
				setRoles([
					...options
				]);
			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false));
	}, []);

	useEffect(() => {

		setLoading(true);
		getSingleUser(id)
			.then(res => {
				setFields({
					...res.data,
					roles: res?.data?.roles[0].name,
					is_active: res?.data?.is_active === 1 ? true : false,
				});
				setSelected({
					label:res?.data?.roles[0].name,
					value:res?.data?.roles[0].name,
				})
				setDob(res?.data?.date_of_birth ? moment(res?.data?.date_of_birth).format("YYYY-MM-DD") : undefined);
				setDoj(res?.data?.date_of_joining ? moment(res?.data?.date_of_joining).format("YYYY-MM-DD") : undefined);
				if(res?.data?.image){
					setFile({
						preview: res?.data?.image || "",
						name: res?.data?.image ? "media_image" : null
					});
				}
				if(res?.data?.cnic){
					setCnic({
						preview: res?.data?.cnic || "",
						name: res?.data?.cnic ? "media_image" : null
					})
				}

			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false));

	}, []);

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target?.type === "checkbox" ? e.target.checked : e.target.value;
		const fieldsObj = { ...fields };
		if(!hasActionPermission){
			return 
		}
		else {
			fieldsObj[name] = value;
			setFields(fieldsObj);
		}

	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const data = {
			...fields,
			roles:selectedRole?.value || undefined,
			cnic:(cnic && cnic?.name !== "media_image" )? cnic : undefined,
			media:(file &&  file?.name !== "media_image" )? file : undefined,
			date_of_birth: dob && moment(dob).format("YYYY-MM-DD") || undefined,
			date_of_joining: doj && moment(doj).format("YYYY-MM-DD") || undefined,
		};
		toast.dismiss();

		setLoading(true);
		updateUser(data, id)
			.then(res => {
				history(-1);
				PrimaryToast({ type: 'success', message: res?.data?.message });
			})
			.catch(err => {
				PrimaryToast({ type: 'error', message: Array.isArray(err?.response?.data?.message) ? err?.response?.data?.message[0] : err?.response?.data?.message });
				console.log(err);
			})
			.finally(res => {
				setLoading(false);
			})

	};

	const handleDateSelect = (date, name) => {
		if (name === "dob") {
			setDob(date);
			setShowStartDateCalender(false);
		}
		if (name === "doj") {
			setDoj(date);
			setShowEndDateCalender(false);
		}
	};

	const handleReset = () => {
		setLoading(true);
		userResetPasswordLink(id)
			.then(res => {

			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false));
	};

	const handleSelectChange = (item) => {
		setSelected(item);
	};

	const loadOptions = (search) => {
		clearTimeout(timmer);
		timmer = setTimeout(()=>{
			getAllEmployeeRoles(search)
			.then(res => {
				let options = res?.data?.map((item) => {
					return {
						label: item.name,
						value: item.name
					}
				});
				setRoles([
					{
						label: 'Please select',
						value: ''
					},
					...options
				]);
			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false));
		},500)
	};

	return (
		<div className='page-content'>
			{
				loading && <ProgressPopup label={"Loading..."} />
			}
			<form onSubmit={handleSubmit}>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<TopHeading heading={"Edit Employee"} />
					<div>
						<Button onClick={() => history(-1)}>Cancel</Button>
						<Button className="ms-2" color="primary">
							Save
						</Button>
					</div>
				</div>
				<Card>
					<CardBody className="w-50">
						<div className="mb-3">
							<PrimaryInput
								label={'First Name'}
								name="first_name"
								isRequired
								value={fields?.first_name}
								type={'text'}
								handleChange={handleChange}
								placeholder="Enter First Name"
							/>
						</div>
						<div className="mb-3">
							<PrimaryInput
								label={'Last Name'}
								name="last_name"
								isRequired
								type={'text'}
								value={fields?.last_name}
								handleChange={handleChange}
								placeholder="Enter Last Name"
							/>
						</div>
						<div className="mb-3">
							<PrimaryInput
								label={'Email'}
								name="email"
								isRequired
								value={fields?.email}
								type={'text'}
								handleChange={handleChange}
								placeholder="Enter Employee Email"
							/>
						</div>
						<div className="mb-3">
							<PrimarySelect
								name={"gender"}
								label={"Gender"}
							
								value={fields?.gender || ""}
								handleChange={handleChange}
								options={[
									{
										name: 'Select Gender',
										value: ''
									},
									{
										name: 'Male',
										value: 'male'
									},
									{
										name: 'Female',
										value: 'female'

									}
								]}
							/>
						</div>
						<div className="mb-3">
							<CalendarInput
								label="Date of Birth"
								handleShowCalender={() => setShowStartDateCalender(!showStartDateCalender)}
								showCalender={showStartDateCalender}
								handleChange={(newDate) =>
									handleDateSelect(
										newDate,
										"dob"
									)
								}
								date={
									dob
										? new Date(dob)
										: new Date()
								}

								value={dob && moment(dob).format("MM-DD-YYYY")}
								placeholder={"MM-DD-YYYY"}
							/>
						</div>
						<div className="mb-3">
							<CalendarInput
								label="Date of Joining "
								handleShowCalender={() => setShowEndDateCalender(!showEndDateCalendar)}
								showCalender={showEndDateCalendar}
								handleChange={(newDate) =>
									handleDateSelect(
										newDate,
										"doj"
									)
								}
						
								date={
									doj
										? new Date(doj)
										: new Date()
								}
								value={doj && moment(doj).format("MM-DD-YYYY")}
								placeholder={"MM-DD-YYYY"}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="">Department</label>
							<input
								type="text"
								className="form-control"
								name="department"
								value={fields?.department}
								onChange={handleChange}
								placeholder="Enter Department"
							/>
						</div>
						<div className="mb-3">
							<PrimaryImageUploader getFiles={cnic} setFiles={setCnic} label="CNIC" />
						</div>
						<div className="mb-3">
							<PrimaryInput
								label={'Phone'}
								name="phone"
								type={'text'}
								value={fields?.phone || ""}
								handleChange={handleChange}
								placeholder="Enter Phone Number"
							/>
						</div>
						<div className="mb-3">
							<PrimaryInput
								label={'Salary'}
								name="salary"
					
								value={fields?.salary}
								type={'text'}
								handleChange={handleChange}
								placeholder="Enter Salary"
							/>
						</div>
						<div className="mb-3">
							<PrimaryInput
								label={'Leaves Allowed'}
								name="allowed_leaves"
				
								type={'text'}
								value={fields?.allowed_leaves}
								handleChange={handleChange}
								placeholder="Enter Allowed Leaves"
							/>
						</div>
						<div className="mb-3">
							<PrimarySelect
								name={"schedule"}
								label={"Schedule"}
								value={fields?.schedule || ""}
								handleChange={handleChange}
								options={[
									{
										name: 'Select Employee Schedule',
										value: ''
									},
									{
										name: 'Morning',
										value: 'morning'
									},
									{
										name: 'Evening',
										value: 'evening'

									}
								]}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="exampleFormControlTextarea1" className="form-label">
								Bank Account Details
							</label>
							<textarea
								name="bank_account_detail"
								value={fields?.bank_account_detail}
								onChange={handleChange}
								className="form-control"
								id="exampleFormControlTextarea1"
								rows="3"
								placeholder="Enter Employee Bank Account"
							></textarea>
						</div>
						
						<div className="mb-2">
							<label htmlFor="inputEmail4">Status</label>
							<SwitchButton checked={fields.is_active === true ? true : false} label={fields.is_active === true ? "Active" : "Inactive"} name="is_active" value={fields?.is_active} handleChange={handleChange} />
						</div>
						<div className='mb-3'>
							<label className="form-label">Role<span className='primary-text'>*</span></label>
							<Select 
								isMulti={false}
								options={roles}
								onChange={handleSelectChange}
								value={selectedRole}
								name={"roles"}
								placeholder={"Select Select Role"}
								
								onInputChange={inputValue => loadOptions(inputValue)}
							/>
						</div>
						<div className="mb-3">
							<Button type="button" color="primary" onClick={handleReset}>
								Send Password Reset Link
							</Button>
						</div>
						<div className="mb-3">
							<PrimaryImageUploader getFiles={file} setFiles={setFile} label="Profile Picture" />
						</div>
					</CardBody>
				</Card>
			</form>
		</div>
	)
}

export default EditEmployee