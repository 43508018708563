import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

// All Products Page Api

export const getAllProducts = (page, search, startDate, endDate) => {
  return getApi({
    url: "admin/products",
    data: {
      is_admin: true,
      page: page || undefined,
      search: search || undefined,
      start_date: startDate || undefined,
      end_date: endDate || undefined,
    },
  }).then(res => res.data);
};

export const deleteProduct = id => {
  return axiosInstance({
    url: `${siteApiUrl}admin/products/${id}`,
    method: "DELETE",
    data: {
      is_admin: true,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

//  Products Approval Page Api

export const getProductApprovals = (page, search) => {
  return getApi({
    url: "admin/product-approval",
    data: {
      is_admin: true,
      page: page || undefined,
      search: search || undefined,
    },
  }).then(res => res.data);
};

export const revertDeletedProduct = product_ids => {
  return axiosInstance({
    url: `${siteApiUrl}admin/products/revert-deleted`,
    method: "POST",
    data: {
      is_admin: true,
      product_ids: product_ids || undefined,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

export const productAction = (product_ids, status, comment) => {
  return axiosInstance({
    url: `${siteApiUrl}admin/product-approval/action/${status}`,
    method: "POST",
    data: {
      is_admin: true,
      comment: comment || undefined,
      product_ids: product_ids || undefined,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

export const productApprovedBySeller = (seller, status, comment) => {
  return axiosInstance({
    url: `${siteApiUrl}admin/product-approval/seller-products`,
    method: "POST",
    data: {
      is_admin: true,
      seller,
      limit: 300,
      status: status || undefined,
      comment: comment || undefined,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

// Product Group Api

export const getProductGroups = (page, search) => {
  return getApi({
    url: "admin/product-group",
    data: {
      is_admin: true,
      page: page || undefined,
      search: search || undefined,
    },
  }).then(res => res.data);
};

export const getSingleProductGroup = id => {
  return getApi({
    url: `admin/product-group/edit/${id}`,
    data: {
      is_admin: true,
    },
  }).then(res => res.data);
};

export const createProudctGroup = data => {
  return axiosInstance({
    url: `${siteApiUrl}admin/product-group/create`,
    method: "POST",
    data: {
      is_admin: true,
      ...data,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

export const updateProudctGroup = (data, id) => {
  return axiosInstance({
    url: `${siteApiUrl}admin/product-group/update/${id}`,
    method: "PUT",
    data: {
      is_admin: true,
      ...data,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

export const getAllProductGroupIds = (id, page) => {
  return getApi({
    url: `admin/product-group/edit/${id}/products`,
    data: {
      is_admin: true,
      page: page || undefined,
    },
  }).then(res => res.data);
};

export const deleteGroupProduct = id => {
  return axiosInstance({
    url: `${siteApiUrl}admin/product-group/product/${id}`,
    method: "DELETE",
    data: {
      is_admin: true,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

export const deleteGroup = id => {
  return axiosInstance({
    url: `${siteApiUrl}admin/product-group/${id}`,
    method: "DELETE",
    data: {
      is_admin: true,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

// Product Categories Api

export const getAllCategories = (page, search, hidden) => {
  return getApi({
    url: `admin/categories`,
    data: {
      is_admin: true,
      page: page || undefined,
      search: search || undefined,
      hidden: hidden || undefined,
    },
  }).then(res => res.data);
};

export const getCategories = () => {
  return getApi({
    url: `admin/categories/list`,
    data: {
      is_admin: true,
    },
  }).then(res => res.data);
};

export const createCategory = data => {
  const formData = new FormData();
  if (data?.name) {
    formData.append("name", data.name);
  }
  if (data?.slug) {
    formData.append("slug", data?.slug);
  }
  if (data?.parent_id != undefined && data?.parent_id != null) {
    formData.append("parent_id", data?.parent_id);
  }

  if (data?.google_category_id) {
    formData.append("google_category_id", data?.google_category_id);
  }
  if (data?.image) {
    formData.append("image", data?.image);
  }
  if (data?.seo_title) {
    formData.append("seo_title", data?.seo_title);
  }
  if (data?.seo_description) {
    formData.append("seo_description", data?.seo_description);
  }
  if (data?.description) {
    formData.append("description", data?.description);
  }
  if (data?.faq?.length > 0) {
    formData.append("faq", JSON.stringify(data?.faq));
  }
  if (data?.related_group_id) {
    for (let i = 0; i < data.related_group_id.length; i++) {
      formData.append("related_group_id[]", data.related_group_id[i]);
    }
  }
  if (data?.related_group_id) {
    formData.append("related_group_id", data.related_group_id);
  }
  formData.append("is_hidden", data?.is_hidden);
  formData.append("is_admin", true);

  return axiosInstance({
    url: `${siteApiUrl}admin/categories/create`,
    method: "POST",
    data: formData,
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

export const getSingleCategory = id => {
  return getApi({
    url: `admin/categories/edit/${id}`,
    data: {
      is_admin: true,
    },
  }).then(res => res.data);
};

export const updateCategory = (data, id) => {
  const formData = new FormData();
  if (data?.name) {
    formData.append("name", data.name);
  }
  if (data?.slug) {
    formData.append("slug", data?.slug);
  }

  if (data?.related_group_id) {
    for (let i = 0; i < data.related_group_id.length; i++) {
      formData.append("related_group_id[]", data.related_group_id[i]);
    }
  }
  if (data?.parent_id != undefined) {
    formData.append("parent_id", data?.parent_id);
  }

  if (data?.image) {
    formData.append("image", data?.image);
  }
  if (data?.seo_title) {
    formData.append("seo_title", data?.seo_title);
  }
  if (data?.seo_description) {
    formData.append("seo_description", data?.seo_description);
  }
  formData.append("description", data?.description || " ");
  if (data?.faq?.length > 0) {
    formData.append("faq", JSON.stringify(data?.faq));
  }
  if (data?.google_category_id) {
    formData.append("google_category_id", data?.google_category_id);
  }
  if (data?.related_group_id) {
    formData.append("related_group_id", data.related_group_id);
  }
  formData.append(
    "non_updatable",
    data?.non_updatable == 1 ? Number(1) : Number(0)
  );
  formData.append("is_hidden", data?.is_hidden);
  formData.append("is_admin", true);

  return axiosInstance({
    url: `${siteApiUrl}admin/categories/update/${id}`,
    method: "POST",
    data: formData,
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

export const deleteCategory = cId => {
  return axiosInstance({
    url: `${siteApiUrl}admin/categories`,
    method: "DELETE",
    data: {
      is_admin: true,
      category_id: cId,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

export const downloadCategoriesCsv = search => {
  return getApi({
    url: `admin/categories/export`,
    data: {
      is_admin: true,
      search: search || undefined,
    },
  });
};

// Product Collections

export const importCollectionsCsv = file => {
  const formData = new FormData();
  if (file) {
    formData?.append("file", file);
  }
  formData.append("is_admin", true);
  return axiosInstance({
    url: `${siteApiUrl}admin/collections/import`,
    method: "POST",
    data: formData,
    withCredentials: true,
    responseType: "text",
    headers: getHttpHeaders(),
  });
};

export const downloadCollectionCsv = search => {
  return getApi({
    url: `admin/collections/export`,
    data: {
      is_admin: true,
      search: search || undefined,
    },
  });
};

export const getAllCollections = (page, search) => {
  return getApi({
    url: `admin/collections`,
    data: {
      is_admin: true,
      page: page || undefined,
      search: search || undefined,
    },
  }).then(res => res.data);
};

export const getSingleCollection = id => {
  return getApi({
    url: `admin/collections/edit/${id}`,
    data: {
      is_admin: true,
    },
  }).then(res => res.data);
};

export const createCollection = data => {
  const formData = new FormData();
  if (data?.title) {
    formData.append("title", data.title);
  }
  if (data?.slug) {
    formData.append("slug", data?.slug);
  }
  if (data?.price_range) {
    formData.append("price_range", data?.price_range);
  }
  if (data?.media) {
    formData.append("media", data?.media);
  }
  if (data?.seo_title) {
    formData.append("seo_title", data?.seo_title);
  }
  if (data?.seo_description) {
    formData.append("seo_description", data?.seo_description);
  }
  if (data?.description) {
    formData.append("description", data?.description);
  }
  if (data?.product_type) {
    formData.append("product_type", data?.product_type);
  }
  formData.append("is_hidden", data?.is_hidden == true ? 0 : 1);
  if (data?.related_group_id) {
    formData.append("related_group_id", data.related_group_id);
  }
  if (data?.faq?.length > 0) {
    formData.append("faq", JSON.stringify(data?.faq));
  }
  if (data?.tags?.length > 0) {
    data?.tags.forEach((tag, index) => {
      formData.append("tags[]", tag?.text);
    });
  }
  formData.append("is_admin", true);

  return axiosInstance({
    url: `${siteApiUrl}admin/collections/create`,
    method: "POST",
    data: formData,
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

export const updateCollection = (data, id) => {
  const formData = new FormData();
  if (data?.title) {
    formData.append("title", data.title);
  }
  if (data?.slug) {
    formData.append("slug", data?.slug);
  }
  if (data?.price_range) {
    formData.append("price_range", data?.price_range);
  }
  if (data?.media) {
    formData.append("media", data?.media);
  }
  if (data?.seo_title) {
    formData.append("seo_title", data?.seo_title);
  }
  if (data?.seo_description) {
    formData.append("seo_description", data?.seo_description);
  }
  formData.append("description", data?.description || " ");
  formData.append("is_hidden", data?.is_hidden == true ? 0 : 1);
  if (data?.related_group_id) {
    formData.append("related_group_id", data.related_group_id);
  }
  if (data?.product_type) {
    formData.append("product_type", data?.product_type);
  }
  if (data?.faq?.length > 0) {
    formData.append("faq", JSON.stringify(data?.faq));
  }
  console.log(data?.tags, "check tags", data);
  if (data?.tags?.length > 0) {
    data?.tags.forEach((tag, index) => {
      formData.append("tags[]", tag?.text);
    });
  } else {
    formData.append("tags[]", []);
  }

  formData.append(
    "non_updatable",
    data?.non_updatable == 1 ? Number(1) : Number(0)
  );
  formData.append("is_admin", true);

  return axiosInstance({
    url: `${siteApiUrl}admin/collections/update/${id}`,
    method: "POST",
    data: formData,
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

export const deleteCollection = collectionIds => {
  return axiosInstance({
    url: `${siteApiUrl}admin/collections/delete`,
    method: "DELETE",
    data: {
      is_admin: true,
      collection_ids: collectionIds,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

// Product Trending Api

export const getAllTrending = (page, search) => {
  return getApi({
    url: `admin/trending`,
    data: {
      is_admin: true,
      page: page || undefined,
      search: search || undefined,
    },
  }).then(res => res.data);
};

export const deleteTrendingProduct = id => {
  return axiosInstance({
    url: `${siteApiUrl}admin/trending/${id}`,
    method: "DELETE",
    data: {
      is_admin: true,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

export const createTrendingProduct = product_ids => {
  return axiosInstance({
    url: `${siteApiUrl}admin/trending/create`,
    method: "POST",
    data: {
      is_admin: true,
      product_ids,
    },
    withCredentials: true,

    headers: getHttpHeaders(),
  });
};

// get products by sku

export const getProductsBySku = sku => {
  return getApi({
    url: `admin/products/feed-status`,
    data: {
      is_admin: true,
      sku,
    },
  }).then(res => res.data);
};

export const markProductsAsAdult = productId => {
  return axiosInstance({
    url: `${siteApiUrl}admin/products/adult-status/${productId}`,
    method: "POST",
    data: {
      is_admin: true,
    },
    withCredentials: true,

    headers: getHttpHeaders(),
  });
};

export const banFromMerchant = (productId, status) => {
  return axiosInstance({
    url: `${siteApiUrl}admin/products/feed/${productId}`,
    method: "POST",
    data: {
      is_admin: true,
      is_enable: status,
    },
    withCredentials: true,

    headers: getHttpHeaders(),
  });
};

export const deleteProductsBySellerId = sellerId => {
  return axiosInstance({
    url: `${siteApiUrl}admin/seller/products-delete/${sellerId}`,
    method: "DELETE",
    data: {
      is_admin: true,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

export const importDisableGMCProduct = (file, is_enable) => {
  const formData = new FormData();
  if (file) {
    formData?.append("import", file);
  }
  formData.append("is_admin", true);
  formData.append("is_enable", is_enable);
  return axiosInstance({
    url: `${siteApiUrl}admin/orders/import-csv-pids`,
    method: "POST",
    data: formData,
    withCredentials: true,
    responseType: "text",
    headers: getHttpHeaders(),
  });
};

// delete products by  ids in popup

export const deleteProductsByProductIds = productIds => {
  return axiosInstance({
    url: `${siteApiUrl}admin/products/multi-delete`,
    method: "DELETE",
    data: {
      is_admin: true,
      product_ids: productIds,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

// disable products by ids in popup

export const disableProductsByProductIds = productIds => {
    return axiosInstance({
      url: `${siteApiUrl}admin/products/deactivate`,
      method: "POST",
      data: {
        is_admin: true,
        product_ids: productIds,
      },
      withCredentials: true,
      headers: getHttpHeaders(),
    });
  };

//  update collection products

export const updateCollectionProducts = (cId, productIds, type) => {
  return axiosInstance({
    url: `${siteApiUrl}admin/collections/${cId}/products`,
    method: "POST",
    data: {
      is_admin: true,
      product_ids: productIds,
      type,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

// update product category

export const updateProductCategory = (pId, cId) => {
  return axiosInstance({
    url: `${siteApiUrl}admin/products/update-category`,
    method: "POST",
    data: {
      product_id: pId || undefined,
      category_id: cId || undefined,
      is_admin: true,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

// get similar product by id

export const getSimilarProducts = pId => {
  return axiosInstance({
    url: `${siteApiUrl}admin/products/similar-products/${pId}`,
    params: {
      is_admin: true,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

// update similar products

export const updateSimilarProducts = (productId, productIds) => {
  return axiosInstance({
    url: `${siteApiUrl}admin/products/similar-products/${productId}/update`,
    method: "POST",
    params: {
      is_admin: true,
    },
    data: {
      product_ids: productIds,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

// update gmc product

export const updateGMCProduct = (sellerId, enableStatus) => {
  return axiosInstance({
    url: `${siteApiUrl}admin/products/update-gmc`,
    method: "POST",
    data: {
      seller_id: sellerId || undefined,
      is_enable: enableStatus,
      is_admin: true,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

// Add tags on products

export const addTagsToProducts = payload => {
  return axiosInstance({
    url: `${siteApiUrl}admin/products/add-tags`,
    method: "POST",
    data: {
      ...payload,
      is_admin: true,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

// Duplicate products

export const duplicateSellerProducts = payload => {
  return axiosInstance({
    url: `${siteApiUrl}admin/products/clone`,
    method: "POST",
    data: {
      ...payload,
      is_admin: true,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};

// download digital files

export const downloadDigitalFile = productId => {
  return getApi({
    url: `admin/products/download-files/${productId}`,
    data: {
      is_admin: true,
    },
  });
};

// approval product category edit

export const approvalProductCatChange = (product_id, category_id) => {
  return axiosInstance({
    url: `${siteApiUrl}admin/products/update-category-spid`,
    method: "POST",
    data: {
      category_id: category_id || undefined,
      product_id: product_id || undefined,
      is_admin: true,
    },
    withCredentials: true,
    headers: getHttpHeaders(),
  });
};
