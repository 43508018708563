import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import PrimaryInput from "components/Common/PrimaryInput";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { useNavigate } from "react-router-dom";
import { enable2FA } from "services/2FAServices";
import BackIcon from "assets/images/back-icon.svg";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { logoutUser } from "services/AuthServices";
import { userInfo } from "services/AuthServices";
import { loadUser } from "store/actions/auth";
import { useDispatch } from "react-redux";

function TwoFaAtuh() {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleTwoFA = e => {
    e.preventDefault();
    setLoading(true);
    enable2FA(code)
      .then(res => {
        PrimaryToast({ type: "success", message: "2FA enable successfully" });
        userInfo()
          .then(res => {
            dispatch(loadUser(res.data));
            if (res?.data?.permissions?.some(p => p.name === "dashboard")) {
              navigate("/dashboard");
            } else {
              navigate(`/user/detail/${res?.data?.id}`);
            }
          })
          .catch(err => console.log(err));
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err.response.data.message)
            ? err.response.data.message[0]
            : err.response.data.message,
        });
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const handleLogout = () => {
    setLoading(true);
    logoutUser()
      .then(res => {
        localStorage.removeItem("adminToken");
        navigate("/login");
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };
  
  return (
    <div className="d-flex justify-content-center align-items-center h-screen w-100">
      {loading && <ProgressPopup label={"Loading..."} />}
      <Card className="overflow-hidden">
        <CardBody>
          <div
            className="flex align-items-center fs-5 font-medium cursor-pointer"
            onClick={handleLogout}
            style={{ color: "#919191" }}
          >
            <img src={BackIcon} className="pe-2" alt="back btn" />
            Back
          </div>
          <h4 className="text-center mt-3">Two Factor Authentication</h4>
          <p className="text-center mb-0 text-13 text-gray-700 mb-3">
            Enter the code generated by your authenticator app
          </p>
          <form className="form-horizontal" onSubmit={handleTwoFA}>
            <div className="mb-3">
              <PrimaryInput
                name={"code"}
                type="text"
                placeholder="6-digit code"
                handleChange={e => {
                  setCode(e.target.value);
                }}
              />
            </div>
            <button
              className="w-100 bg-primary text-white outline-none border-0 p-2 rounded"
              type="submit"
            >
              Verify
            </button>
          </form>
        </CardBody>
      </Card>
    </div>
  );
}

export default TwoFaAtuh;
