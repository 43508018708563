import React, { useState } from "react";
import PrimaryPopup from "./PrimaryPopup";
import { Button } from "reactstrap";
import ProgressPopup from "./ProgressPopup";
import PrimaryToast from "../Toast/ToastMessage";
import { emailAction } from "services/DMCAEmailsServices";

function DmcaActionPopup({
  isOpen,
  handleClick,
  actionType,
  selectedId,
}) {
  const [loading, setLoading] = useState(false);
  const [sellerProductIds, setSellerProductIds] = useState([]);

  const handleSellerProductIds = (e) => {
    setSellerProductIds(e.target.value);
  };

  const handleAction = () => {
    const SellerProductIdsArr = sellerProductIds.split(",");
    let payload = {
      status: actionType,
      id: selectedId,
      seller_ids: SellerProductIdsArr,
    };
    setLoading(true);
    emailAction(payload)
      .then(res => {
        PrimaryToast({
          type: "success",
          message: res?.data?.message,
        });
        handleClick();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        PrimaryToast({
          type: "error",
          message: err?.response?.data?.message,
        });
      })
        .finally(() => {
            setLoading(false);
        });
  };

  return (
    <PrimaryPopup
      isOpen={isOpen}
      handleClick={handleClick}
      title={"DMCA Approved"}
      isScrollable={false}
      className={`d-flex justify-content-center align-items-center h-100 w-100`}
    >
      {loading && <ProgressPopup label={"Loading..."} />}
      <div>
        <div className="mb-3">
          <label htmlFor="inputValue">Seller IDs</label>
          <textarea
            rows="5"
            placeholder="Enter Seller Product IDs separated by comma(,)"
            className="form-control mt-2"
            value={sellerProductIds}
            onChange={handleSellerProductIds}
          />

          <div className="d-flex justify-content-end mt-4">
            <Button
              disabled={
                sellerProductIds.length === 0
              }
              color="primary"
              onClick={handleAction}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </PrimaryPopup>
  );
}

export default DmcaActionPopup;
