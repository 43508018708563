import axiosInstance from "./axiosInstance";
import { ltrim, rtrim } from "../utils/string";

export const siteApiUrl = `${process.env.REACT_APP_APIKEY}v1/`;

export const getHttpHeaders = () => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
};

export const getApi = ({ url, data = {}, headers = {}, options }) => {
  const requestUrl = rtrim(siteApiUrl, "/") + "/" + ltrim(url, "/");
  const _headers = { ...getHttpHeaders(), ...headers };
  if (Object.keys(data).length > 0) {
    return axiosInstance({
      method: "GET",
      url: requestUrl,
      params: data,
      headers: _headers,
      withCredentials: true,
      ...options,
    });
  }
  return axiosInstance.get(requestUrl, {
    headers: _headers,
    withCredentials: true,
  });
};
