import React, { useState, lazy, Suspense } from "react";
import { Row, Col, Tooltip, Button } from "reactstrap";
import BlueTick from "../../assets/images/blue-tick-rounded.svg";
import MinusRed from "../../assets/images/minus-red-rounded.svg";
import MinusYellow from "../../assets/images/minus-yellow-rounded.svg";
import { useDispatch, useSelector } from "react-redux";
import { getTrackingNumber } from "store/actions/orders";
import downLoadIcon from "assets/images/download-icon.svg";
import tickIcon from "assets/images/tick-rounded-icon.svg";
import { substractVariantTitle } from "utils/string";
const PrimaryAccordion = lazy(() =>
  import("components/Common/Accordion/PrimaryAccordion")
);

function ProductDetail({
  handleUpdateTracking,
  data,
  setApprovePayment,
  setSellerId,
  setFulfilmentIds,
  handleDigitalFiles,
}) {
  const [tooltipOpen, setTooltipOpen] = useState("");
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.auth.user);
  const hasPaymentPermission = userInfo?.permissions?.some(
    item => item.name === "seller-payment-approval"
  );
  const hasDigitalPermission = userInfo?.permissions?.some(
    p => p.name === "digital-product"
  );

  const handleTooltipToggle = index => {
    if (tooltipOpen === index) {
      setTooltipOpen("");
    } else {
      setTooltipOpen(index);
    }
  };

  const handleTooltipClose = () => {
    setTooltipOpen("");
  };

  return (
    <div className="">
      <Row className="header bg-secondoary p-3 text-secondary font-semi-bold">
        <Col sm="5" className="d-flex align-items-center">
          <div style={{ width: "20px" }} className="me-1">
            #
          </div>
          <div>Product</div>
        </Col>
        <Col sm="3">Variant</Col>
        <Col sm="1">Price</Col>
        <Col sm="1" className="text-center">
          Qty
        </Col>
        <Col sm="2">Tracking</Col>
      </Row>
      {data && Object.keys(data).length > 0
        ? Object.keys(data).map((sellerKey, index) => {
            const seller = data[sellerKey];
            let status;
            if (
              data[sellerKey].is_verify === 0 &&
              data[sellerKey].badge === 1
            ) {
              status = "Not Verified";
            }
            if (
              (data[sellerKey].is_verify === 0 ||
                data[sellerKey].is_verify === 1) &&
              data[sellerKey].badge === 0
            ) {
              status = "Temporary Ban";
            }
            if (
              (data[sellerKey].is_verify === 0 ||
                data[sellerKey].is_verify === 1) &&
              data[sellerKey].badge === 2
            ) {
              status = "Banned";
            }
            if (
              data[sellerKey].is_verify === 1 &&
              data[sellerKey].badge === 1
            ) {
              status = "Verified";
            }

            return (
              <div key={index} className="">
                <Row>
                  <Col
                    sm="12"
                    className="p-3  primary-text fs-5 border-bottom d-flex align-items-center justify-content-between"
                  >
                    <div className="d-flex align-items-center font-semi-bold">
                      <div>
                        <div className="d-flex">
                          {sellerKey}
                          <div>
                            <img
                              src={
                                status === "Not Verified"
                                  ? MinusYellow
                                  : status === "Verified"
                                  ? BlueTick
                                  : status === "Temporary Ban" ||
                                    status === "Banned"
                                  ? MinusRed
                                  : BlueTick
                              }
                              alt="blue tick icon"
                              className="ms-1"
                              onMouseEnter={() => handleTooltipToggle(index)}
                              onMouseLeave={handleTooltipClose}
                              id={`tooltipTarget_${index}`}
                            />

                            <Tooltip
                              placement="bottom"
                              isOpen={tooltipOpen === index}
                              target={`tooltipTarget_${index}`}
                              toggle={() => handleTooltipToggle(index)}
                              delay={{ show: 0, hide: 0 }}
                            >
                              {status}
                            </Tooltip>
                          </div>
                        </div>
                        {seller?.seller_group && (
                          <div className="bg-primary rounded text-white product-group-name-container">
                            {seller?.seller_group}
                          </div>
                        )}
                      </div>

                      {hasPaymentPermission &&
                        seller?.lineItems[0]?.payment_approve === 0 && (
                          <Button
                            className="ms-2"
                            color="primary"
                            onClick={() => {
                              setApprovePayment(true);
                              setSellerId(seller?.seller_id);
                            }}
                          >
                            Approve Payment
                          </Button>
                        )}
                      {seller?.lineItems[0]?.payment_approve === 1 && (
                        <div className="bg-success text-success py-1 px-2 rounded text-11 ms-1">
                          Payment Approved
                        </div>
                      )}
                    </div>
                    {seller?.discount > 0 && (
                      <div className="primary-text fst-normal text-13">
                        Discount:${seller?.discount}
                        <span className="text-gray-600">
                          ({seller?.discount_code})
                        </span>
                      </div>
                    )}
                  </Col>
                </Row>
                {seller?.lineItems?.map((lineItem, index1) => {
                  return (
                    <div className="border-bottom p-3 " key={index1}>
                      <Row className="align-items-center">
                        <Col sm="5" className="d-flex align-items-start">
                          <div
                            className="font-semi-bold me-1"
                            style={{ width: "20px" }}
                          >
                            {index1 + 1}
                          </div>
                          <div className="" style={{ maxWidth: "90%" }}>
                            {lineItem?.product}
                            {lineItem?.group && (
                              <span className="ms-2 bg-primary rounded text-white product-group-name-container">
                                {lineItem?.group}
                              </span>
                            )}
                            {
                                lineItem?.order_line_item_dispute_status  && (
                                    <div className="status w-max-content warning-status">
                                        Dispute
                                    </div>
                                )
                            }
                          </div>
                        </Col>
                        <Col sm="3">
                          {" "}
                          {lineItem?.product_type == "configurable"
                            ? substractVariantTitle(
                                lineItem.product,
                                lineItem?.variant
                              ) || "-"
                            : null}
                        </Col>
                        <Col sm="1">${lineItem?.price?.toFixed(2)}</Col>
                        <Col sm="1" className="text-center">
                          {lineItem?.quantity}
                        </Col>
                        <Col sm="2" className={`d-flex flex-wrap`}>
                          {lineItem?.tracking_numbers?.length > 0 ? (
                            lineItem?.tracking_numbers?.map((item, index) => {
                              if (item !== "") {
                                return (
                                  <div
                                    className={`${""} status text-white p-1 ms-1 mb-1 w-max-content cursor-pointer`}
                                    style={{
                                      fontSize: "11px",
                                      background: "#34c38f",
                                      wordBreak: "break-word",
                                    }}
                                    onClick={e => {
                                      setFulfilmentIds(
                                        lineItem.fulfilment_id[index]
                                      );
                                      handleUpdateTracking();
                                      dispatch(getTrackingNumber(item));
                                    }}
                                    key={index}
                                  >
                                    {item}
                                  </div>
                                );
                              }
                            })
                          ) : (
                            <div className="unlimited-status p-1">
                              Unfulfilled
                            </div>
                          )}
                          {lineItem?.ali_express_numbers?.length > 0
                            ? lineItem?.ali_express_numbers?.map(
                                (item, index) => {
                                  return (
                                    item !== null && (
                                      <div
                                        key={index}
                                        className={
                                          "bg-primary text-white p-1 status mb-1 ms-1 cursor-pointer"
                                        }
                                        style={{
                                          fontSize: "11px",
                                          wordBreak: "break-word",
                                        }}
                                        onClick={e => {
                                          setFulfilmentIds(
                                            lineItem.fulfilment_id[index]
                                          );
                                          handleUpdateTracking();
                                        }}
                                      >
                                        {item}
                                      </div>
                                    )
                                  );
                                }
                              )
                            : null}
                          {(lineItem?.product_type === "digital" ||
                            lineItem?.digital_product_id) &&
                          hasDigitalPermission ? (
                            <div
                              className="primary-text text-13 fw-semibold cursor-pointer d-flex alig-items-center"
                              onClick={() =>
                                handleDigitalFiles(
                                  lineItem?.digital_product_id,
                                  lineItem?.product_id
                                )
                              }
                            >
                              <img
                                src={downLoadIcon}
                                alt="download icon"
                                className="me-1"
                                height={16}
                                width={16}
                              />{" "}
                              Download
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      {lineItem?.order_promotion?.promotion?.on_quantity
                        ?.quantity > 0 ? (
                        <p className="mb-1 ml-5">
                          <img
                            src={tickIcon}
                            height={16}
                            width={16}
                            alt="tick image"
                          />{" "}
                          <span className="ps-1">
                            Applied Purchase{" "}
                            {
                              lineItem?.order_promotion?.promotion?.on_quantity
                                ?.quantity
                            }{" "}
                            or more products from this shop and Receive{" "}
                            {
                              lineItem?.order_promotion?.promotion?.on_quantity
                                ?.discount
                            }
                            % off!
                          </span>
                        </p>
                      ) : null}
                      {lineItem?.discount && !lineItem?.promotion ? (
                        <p className="mb-1 ml-5">
                          <img
                            src={tickIcon}
                            height={16}
                            width={16}
                            alt="tick image"
                          />{" "}
                          <span className="ps-1 discount-text">
                            Discount Applied
                          </span>
                        </p>
                      ) : null}
                      {lineItem?.order_promotion?.promotion?.campaign ? (
                        <p className={"mb-1 ml-5"}>
                          <img
                            src={tickIcon}
                            height={16}
                            width={16}
                            alt="tick image"
                          />{" "}
                          <span className="ps-1 discount-text">
                            Applied Purchase from Flash Sale
                          </span>
                        </p>
                      ) : null}
                      {lineItem?.personalization_description?.length > 0 ? (
                        <Suspense fallback={<div></div>}>
                          <div className="ml-5">
                            <PrimaryAccordion
                              data={lineItem?.personalization_description}
                            />
                          </div>
                        </Suspense>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            );
          })
        : null}
    </div>
  );
}

export default ProductDetail;
