import React from "react";
import { Row, Col, Button } from 'reactstrap';
import PrimaryPopup from "./PrimaryPopup";

function EmailUpdatePopup({
    isEmailUpdate,
    handleEmailUpdate,
    setEmail,
    email,
    handleEmail,
    loading
}) {
    return (
        <PrimaryPopup title={"Update Email Address"} isOpen={isEmailUpdate} className={"d-flex justify-content-center align-items-center"} isScrollable={true} handleClick={handleEmail}>
            <Row className="mb-3 align-items-center">
                <Col className="form-group" sm={12}>
                    <label htmlFor="phone">Email</label>
                    <input type="text" className="form-control" id="phone" onChange={(e) => setEmail(e.target?.value)} value={email} name="email" placeholder="Enter email" />
                </Col>
            </Row>
            <div className="d-flex  justify-content-end">
                <Button color="primary" type="button" disabled={loading} onClick={handleEmailUpdate}>
                    Save Changes
                </Button>
            </div>
        </PrimaryPopup>

    )
}

export default EmailUpdatePopup