import ProgressPopup from "components/Common/Popups/ProgressPopup";
import CreatedGroupForm from "components/SEO/CreatedGroupForm";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RelatedGroupsTable from "components/SEO/RelatedGroupsTable";
import {
  updateRelatedGroups,
  geCollectionsAndCategories,
  getSingleRelatedGroup,
  removeCollectionsAndCategories,
} from "services/SEOKeywordsServices";
import { useParams } from "react-router-dom";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { toast } from "react-toastify";
import PrimaryToast from "components/Common/Toast/ToastMessage";

export const EditRelatedGroup = () => {
  const [fields, setFields] = useState({
    type: "category",
  });
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState();
  const [isShowDeleteModal, setShowDeleteModal] = useState(false);
  const params = new URLSearchParams(window.location.search);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const pageParam = params.get("page") || undefined;
    setPageNo(pageParam || 1);
    getData(pageParam);
  }, [window.location.search]);

  useEffect(() => {
    setLoading(true);
    getSingleData();
  }, []);

  const getSingleData = async () => {
    try {
      const response = await getSingleRelatedGroup(id);
      setFields({
        ...response.data,
        collection_ids: response.data.collection_ids?.join(",") || "",
        category_ids: response?.data?.category_ids || [],
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getData = async page => {
    try {
      const response = await geCollectionsAndCategories(page, id);
      setData(response?.data?.data);
      setPageCount(response?.data?.last_page);
      console.log(response, "check response");
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const fieldsObj = { ...fields };
    fieldsObj[name] = value;
    setFields(fieldsObj);
  };

  const handleValidation = () => {
    const { category_ids, collection_ids, name } = fields;
    if (!name) {
      PrimaryToast({
        type: "error",
        message: "Title field cannot be empty",
      });
      return false;
    }
    if (
      (!category_ids?.length || category_ids?.length === 0) &&
      (!collection_ids?.length || collection_ids?.length === 0)
    ) {
      PrimaryToast({
        type: "error",
        message: "Please select some categories or collections",
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (handleValidation()) {
      const collectionIdsStrToArr = fields?.collection_ids
        ?.trim()
        .split(",")
        .map(item => +item);
      const payload = {
        ...fields,
        collection_ids:
          fields?.collection_ids?.length > 0
            ? collectionIdsStrToArr
            : undefined,
        _id: fields?._id ? Number(fields?._id) : undefined,
      };
      setLoading(true);
      try {
        const response = await updateRelatedGroups(payload, id);
        if (response.status == 200) {
          PrimaryToast({ type: "success", message: response.data.message });
          navigate("/seo-keywords/related-groups");
        }
      } catch (err) {
        console.log(err);
        PrimaryToast({ type: "error", message: err.response.data.message });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = () => {
    const filterData = data?.filter(item => item.id !== selected.id);
    toast.dismiss();
    setLoading(true);
    removeCollectionsAndCategories(selected, id)
      .then(res => {
        if (res.status === 200) {
          handleDeleteModal("");
          if (selected.type == "collection") {
            const filterArr = fields?.collection_ids
              ?.split(",")
              .filter(col => col != selected.id);
            setFields({
              ...fields,
              collection_ids: filterArr.join(","),
            });
          } else if (selected.type == "category") {
            const filterArr = fields?.category_ids.filter(
              col => col !== selected.id
            );
            setFields({
              ...fields,
              category_ids: filterArr,
            });
          }
          if (filterData?.length === 0 && pageNo > 1) {
            params.set("page", pageNo - 1);
            let newUrl = `${window?.location?.pathname}?${params.toString()}`;
            navigate(newUrl, { replace: true });
          } else {
            getData(pageNo);
          }
        }
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      });
  };

  const handleDeleteModal = row => {
    setSelected(row);
    setShowDeleteModal(!isShowDeleteModal);
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      {isShowDeleteModal && (
        <DeleteModal
          title={"Are you sure you want to delete this?"}
          onCancel={() => handleDeleteModal("")}
          onConfirm={handleDelete}
        />
      )}
      <CreatedGroupForm
        fields={fields}
        setFields={setFields}
        path="edit"
        loading={loading}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
      <div>
        <RelatedGroupsTable
          data={data}
          handleDeleteModal={handleDeleteModal}
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageCount={pageCount}
        />
      </div>
    </div>
  );
};
