import React from "react";
import { useNavigate } from "react-router-dom";

const AnalyticsNav = () => {
  const history = useNavigate();
  const params = new URLSearchParams(window.location.search);

  const navData = [
    {
      name: "Sales by Country",
      link: "/reports/analytics?type=country",
    },
    {
      name: "Sales by Referrals",
      link: "/reports/analytics?type=referral",
    },
  ];

  return (
    <nav className="d-flex">
      {navData?.map((item, index) => {
        let checkType = params.get("type");
        const isActive = item.link.includes(checkType);
        return (
          <div
            key={index}
            href={item.link}
            onClick={() => history(item.link)}
            className={`p-3 h5 mb-0 ${
              isActive ? "bg-white border-dark" : "bg-body-gray"
            } fw-bold border-bottom border-2 cursor-pointer`}
          >
            {item.name}
          </div>
        );
      })}
    </nav>
  );
};

export default AnalyticsNav;
