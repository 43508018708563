import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getReviewDisputes = () => {
  return getApi({
    url: `admin/reviewit`,
    data: {
      is_admin: true,
    },
  }).then(res => res.data);
};

export const createReviewDisputeAction = (dispute_id, action) => {
  return axiosInstance({
    method: "POST",
    url: `${siteApiUrl}admin/reviewit/action?is_admin=true`,
    withCredentials: true,
    data: {
      dispute_id,
      action,
      is_admin: true,
    },
    headers: getHttpHeaders(),
  });
};

export const getReviewDisputeDetail = dispute_id => {
  return getApi({
    url: `admin/reviewit/get-review/${dispute_id}`,
    data: {
      is_admin: true,
    },
  }).then(res => res.data);
};
