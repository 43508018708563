import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import rectangleIcons from "../../../assets/images/rectangle.svg";
import { sidebarLinks } from "./sidebarLinks";


const PrimarySidebar = ({ handleShowSidebar }) => {
	const location = useLocation();
	const history = useNavigate();
	const [expandIndex, setExpandIndex] = useState(null);
	const [isExpand, setExpand] = useState(false);
	const [subExpand, setSubExpand] = useState(false);
	const user = useSelector(state => state?.auth?.user);
	const permissions = user?.permissions?.map(item => item);
	const allPermissions = permissions && [
		{ id: "550e8400", name: "my-task" },
		...permissions,
	];

	useEffect(() => {
		const path = location.pathname.slice(1);
		const activeIndex = sidebarLinks.findIndex(item => {
			const matchUrl = location.pathname.slice(
				0,
				path.includes("/") ? path.indexOf("/") : path.length
			);
			if (item?.link?.startsWith(matchUrl)) {
				return item.link.startsWith(matchUrl);
			}
			return matchUrl.startsWith(item.link);
		});
		setExpandIndex(activeIndex);
		if(window.location.pathname.includes("/ads")){
			setSubExpand(true);
		}
		setExpand(true);
	}, [location.pathname]);

	const hasPermission = item => {
		if (!item) {
			return true; // No permission required, accessible by default
		}
		if (user || !item.permission) {
			return allPermissions?.some(p => p.name === item.permission);
		}
		return false; // No user available, not accessible
	};

	const handleExpand = (e, index, link) => {
		if (
			!hasPermission(sidebarLinks[index]) &&
			sidebarLinks[index]?.name !== "Dashboard"
		) {
			// Main link is not accessible, find the first accessible sublink
			const sublink = sidebarLinks[index].sublinks.find(hasPermission);
			const hasSublink = sidebarLinks[index].sublinks.some((sub) => sub?.subLinks?.find(hasPermission));
			if (sublink || hasSublink) {
				// history(sublink.link);
				setExpandIndex(index);
				setExpand(true);
				setSubExpand(false);
				return;
			}
		}
		history(link);
		setExpandIndex(index);
		setExpand(true);
		handleShowSidebar();
		setSubExpand(false);
	};

	const handleSubExpand = (e, item) => {
		if (item?.subLinks?.length > 0) {
			setSubExpand(true);
		} else {
			setSubExpand(false);
		}
	};

	const  hasAdsSublinkPermission = (item, allPermissions) => {
		return item.subLinks.some((subItem) => {
		  return  allPermissions?.some(p => p.name === subItem.permission);
		});
	  }

	return (
		<div className="">
			<nav className={`nav-menu-container`} id="nav-menu">
				<ul className="nav-links">
					{sidebarLinks.map((item, index) => {
						let activeLink = expandIndex == index && isExpand;

						if (item.name !== "Dashboard") {
							if (
								item.permission &&
								!allPermissions?.some(p => p.name === item.permission)
							) {
								return null;
							}
						}
						const hasSublinkPermission =
							item.sublinks &&
							item.sublinks.some(
								subItem =>
									subItem.name == "Ads" ? subItem?.subLinks?.some((subSubItem) => allPermissions?.some(p => p.name === subSubItem.permission)) : 
									!subItem.permission ||
									allPermissions?.some(p => p.name === subItem.permission)
							);

						if (item.sublinks) {
							if (!hasSublinkPermission) {
								return null;
							}
						};

						let mainLink;
						if (item.name === "Dashboard") {
							if (allPermissions?.some(p => p?.name === "dashboard")) {
								mainLink = item.link;
							} else {
								mainLink = `/user/detail/${user?.id}`;
							}
						} else {
							mainLink = item.link;
						}
					
						return (
							<div key={index}>
								<div className="relative">
									<li
										className={`nav-link relative d-flex align-items-center justify-content-between  ps-4 pe-4 py-2  text-white text-base cursor-pointer`}
										onClick={e => handleExpand(e, index, mainLink)}
									>
										<a className={"d-flex aling-items-center"}>
											<span>
												<img src={item.icon} />
											</span>
											<span
												className={`w-24  ms-3 text-[15px] me-4 capitalize hidden md:inline ${activeLink ? "text-white" : ""
													} `}
											>
												{item.name}
											</span>
										</a>
										{item.sublinks?.length > 0 && (
											<span
												className={`hidden md:inline transition ${expandIndex === index && isExpand && "rotate-180"
													}`}
											>
												<img src={rectangleIcons} />
											</span>
										)}
									</li>
									<li
										className={`
                                            block  transition delay-200    ms-3 md:bg-transparent sub-menu-container font-bold
                                            ${expandIndex === index && isExpand
												? "!h-auto !overflow-auto !opacity-100 "
												: "h-0 overflow-hidden opacity-0"
											}
                                        `}
									>
										{item?.sublinks?.map((subItem, index1) => {
											const activeLink = location.pathname.indexOf(
												subItem.link?.includes('?type=country') ? subItem.link?.replace('?type=country',"") : subItem?.link
											);
										
											if(subItem.subLinks?.length > 0){
												
												let hasAdsSublinkPer = hasAdsSublinkPermission(subItem, allPermissions);
												
												if (!hasAdsSublinkPer) {
												  return null;
												}
											}
											else {
												if (
													subItem.permission &&
													!allPermissions?.some(
														p => p.name === subItem.permission
													)
												) {
													return null;
												}
	
											}
											
											
											return (
												<div
													key={index1}
													className="nav-sublink-box nav-sublink-box-products"
												>
													{
														subItem?.subLinks?.length > 0 ? (
															<a
															onClick={e => handleSubExpand(e, subItem)}
															className={`relative nav-sublink d-flex ${subItem?.subLinks?.length > 0
																	? "justify-content-between pe-4"
																	: "justify-content-start"
																}  align-items-center text-white text-sm ps-6 md:ps-9 pe-0 py-1 border-l-4 border-transparent nav-link-hover-bg w-full ${subExpand && "active"
																}`}
														>
															<span
																className={`w-0 md:w-32 capitalize text-xs md:text-sm inline-block relative navlink-innertext`}
															>
																{subItem.name}
															</span>
															{subItem?.subLinks?.length > 0 && (
																<span
																	className={`hidden md:inline transition ${subExpand && "rotate-180"
																		}`}
																>
																	<img src={rectangleIcons} />
																</span>
															)}
														</a>
														) : 
														(
															<Link to={subItem.link}>
															<a
																className={`relative nav-sublink d-flex ${subItem?.subLinks?.length > 0
																		? "justify-content-between pe-4"
																		: "justify-content-start"
																	}  align-items-center text-white text-sm ps-6 md:ps-9 pe-0 py-1 border-l-4 border-transparent nav-link-hover-bg w-full ${activeLink === 0 && "active"
																	}`}
																	onClick={e => handleSubExpand(e, subItem)}
															>
																<span
																	className={`w-0 md:w-32 capitalize text-xs md:text-sm inline-block relative navlink-innertext`}
																>
																	{subItem.name}
																</span>
																{subItem?.subLinks?.length > 0 && (
																	<span
																		className={`hidden md:inline transition ${subExpand && "rotate-180"
																			}`}
																	>
																		<img src={rectangleIcons} />
																	</span>
																)}
															</a>
														</Link>
														)
													}
										
													{subExpand &&
														subItem?.subLinks?.map((subLinkItem, index) => {
															const mainLink = window?.location.pathname.slice(
																0,
																window.location.pathname.lastIndexOf("/")
															);

															const isExpand =
																window?.location.pathname.includes(mainLink);
															const activeLink = location.pathname.includes(
																subLinkItem?.link
															);

															if(subLinkItem.permission && !allPermissions.some(p => p.name === subLinkItem.permission)) {
																return null
															}

															return (
																<Link to={subLinkItem.link} key={index}>
																	<a
																		className={`relative nav-sublink d-flex justify-content-start md:justify-content-center align-items-center text-white text-sm ps-6 md:ps-9 pe-0 py-1 border-l-4 border-transparent nav-link-hover-bg w-full ms-3 ${isExpand} ${isExpand ? "d-block" : "d-none"
																			} ${activeLink && "active"}`}
																	>
																		<span
																			className={`w-0 md:w-32 capitalize text-xs md:text-sm inline-block relative navlink-innertext`}
																		>
																			{subLinkItem.name}
																		</span>
																	</a>
																</Link>
															);
														})}
												</div>
											);
										})}
									</li>
								</div>
							</div>
						);
					})}
				</ul>
			</nav>
		</div>
	);
};
export default PrimarySidebar;
