import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import deleteIcon from "assets/images/delete-icon-white.svg";
import editIcon from "assets/images/edit-icon-white.svg";
import PrimarySearch from "components/Common/PrimarySearch";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import PrimaryTable from "components/Common/PrimaryTable";
import { getAllCenters } from "services/MarketPlaceFeedService";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { deleteCenter } from "services/MarketPlaceFeedService";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";

const AdsCenter = () => {
    const [loading, setLoading] = useState(false);
    const [centerData, setCenterData] = useState([]);
    const [pageCount, setPageCount] = useState();
    const [pageNo, setPageNo] = useState(1);
    const [selectedId, setSelectedId] = useState();
    const [showDeleteModal, setShowDeleteModal] = useToggle(false);
    const [searchVal, setSearch] = useState(null);
    const params = new URLSearchParams(window?.location?.search);

    const history = useNavigate();

    useEffect(()=>{
		setPageTitle("Ads Center - Inspire Uplift")
	},[]);

    useEffect(() => {

        const page = params?.get("page") || undefined;
        const search = params?.get('search') || undefined;
        setPageNo(page || 1);
        setSearch(search || '');
        getCenter(page, search);

    }, [window?.location?.search]);

    const getCenter = (page, search) => {
        setLoading(true);
        getAllCenters(page, search)
            .then(res => {
                setCenterData(res?.data?.data);
                setPageCount(res?.data?.last_page);
            })
            .catch(err => {
                console.log(err)
            })
            .finally(res => {
                setLoading(false);
            })
    }

    const columns = [
        {
            dataField: 'id',
            text: '#',
            formatter:(cell,row)=>(
                <div>
                    {((pageNo - 1) * 10) + row.index}
                </div>
            )

        },
        {
            dataField: 'seller_name',
            text: 'Store Name',
            formatter: (cell, row) => {
                return (
                    <div>
                        {row?.seller?.name}
                    </div>
                )
            }
        },
        {
            dataField: 'balance',
            text: 'Balance',
            formatter:(cell,row) => <div>{Number(row?.balance)?.toFixed(2)}</div>
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (cell, row) => {
                let status;
                if (row?.status === 0) {
                    status = "Inactive"
                }
                if (row?.status == 1) {
                    status == "Active"
                }
                return (
                    <div className={`${row?.status == 0 && "danger-status"}  status w-max-content ${row?.status == 1 && "success-status"}`}>{ row?.status == 1 ? "Active" : row?.status == 0 ? "Inactive" : ''}</div>
                )
            }
        },
        {
            dataField: "action",
            text: 'Action',
            formatter: (cell, row) => {
                return (
                    <div className="d-flex align-items-center">
                        <div
                            className="action-btn edit-icon-bg me-1 cursor-pointer"
                            onClick={() =>
                                history(`/marketing/ads/centers/edit/${row.id}`)
                            }
                        >
                            <img src={editIcon} className="" alt="edit icon" />
                        </div>
                        <div className="action-btn delete-btn-bg cursor-pointer" onClick={() => {
                            setSelectedId(row.id);
                            setShowDeleteModal(true);
                        }}>
                            <img src={deleteIcon} className="" alt="delete icon" />
                        </div>
                    </div>
                )
            }
        }
    ];

    const data = centerData?.map((item,index) => {
        return {
            ...item,
            index:index + 1,
        }
    });

    const handleDelete = () => {
        const filterData = centerData?.filter((item) => item.id !== selectedId);
        toast.dismiss();
        setLoading(true)
        deleteCenter(selectedId)
            .then(res => {
                setSelectedId('');
                PrimaryToast({ type: "success", message: 'Platform delete successfully' });
                setShowDeleteModal(!showDeleteModal);
                if(pageNo > 1 && filterData.length === 0){
                    params.set('page',pageNo - 1);
                    let newUrl = `${window?.location?.pathname}?${params.toString()}`;
                    history(newUrl, { replace: true });
                }
                else {
                    getCenter(pageNo, searchVal); 
                }
               

            })
            .catch(err => {
                console.log(err)
            })
            .finally(res => {
                setLoading(false);
            });

    };

    const handleSearch = (e) => {
        const val = e.target.value;
        setSearch(val);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            if (params?.get("page")) {
              params?.delete("page");
            }
            if (searchVal) {
              params?.set("search", searchVal);
            } else {
              params?.delete("search");
            }

            history(`/marketing/ads/centers?${params?.toString()}`);
        }
    };

    return (
        <div className="page-content">
            {
                loading && <ProgressPopup label={"Loading..."} />
            }
            {
                showDeleteModal && <DeleteModal
                    title={"Are you sure to delete this Ad Center?"}
                    onConfirm={handleDelete}
                    onCancel={() => {
                        setSelectedId('');
                        setShowDeleteModal(!showDeleteModal);
                    }}

                />
            }
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <TopHeading heading={"Ad Centers"} />
            </div>
            <Card>
                <CardBody>
                    <div className="mb-3">
                        <PrimarySearch value={searchVal} handleKeyPress= {handleKeyPress} placeholder={"Search by name"} handleChange={handleSearch} />
                    </div>
                    <div>
                        <PrimaryTable
                            data={data}
                            columns={columns}
                            bordered={false}
                            keyField="id"
                        />
                        {
                            (centerData?.length > 0 && pageCount > 1 )&& (
                                <PrimaryPagination
                                    pageNo={pageNo}
                                    pageCount={pageCount}
                                    setPageNo={setPageNo}
                                />
                            )
                        }
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}
export default AdsCenter;