import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";


export const getALlTasks = (page,search, status, assigned_to, assigned_by) => {
    return getApi({
        url: 'admin/tasks',
        data: {
            is_admin: true,
            page: page || undefined,
            search: search || undefined,
            status: status || undefined,
            assigned_to:assigned_to || undefined,
            assigned_by:assigned_by || undefined,
        }
    })
        .then(res => res.data);
}

export const createTask = (data) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/tasks/store`,
        withCredentials: true,
        data: {
            ...data,
            is_admin: true,
        },
        headers: getHttpHeaders(),
    })

};

export const getSingleTask = (id) => {
    return getApi({
        url: `admin/tasks/${id}/edit`,
        data: {
            is_admin: true,
        }
    }).then(res => res.data)

};

export const updateTask = (data, id) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/tasks/${id}/update`,
        withCredentials: true,
        data: {
            ...data,
            is_admin: true,
        },
        headers: getHttpHeaders(),
    })

};

export const deleteTask = (ids) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/tasks/delete`,
        withCredentials: true,
        data: {
            taskids:ids ,
            is_admin: true,
        },
        headers: getHttpHeaders(),
    })
};


export const getAllEmployees = (search) => {
    return getApi({
        url: `admin/tasks/all-employees`,
        data: {
            is_admin: true,
            search: search || undefined,
        }
    }).then(res => res.data)
};

export const uploadTaskImage = (image) => {
    const formData = new FormData();
    if (image) {
        formData.append("galleryImage", image)
    };
    formData.append("is_admin", true);

    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/tasks/upload-image`,
        withCredentials: true,
        data: formData,
        headers: getHttpHeaders(),
    })
};

export const getMyTasks = (page,search, status, assigned_by) => {
    return getApi({
        url: `admin/tasks/show`,
        data: {
            is_admin: true,
            page: page || undefined,
            search:search || undefined,
            status:status || undefined,
            assigned_by:assigned_by || undefined,
        }
    }).then(res => res.data)
}

export const getMyTaskDetail = (id) => {
    return getApi({
        url: `admin/tasks/${id}/show`,
        data: {
            is_admin: true,
        }
    }).then(res => res.data)
}


export const getTaskTimeline = (id,page) =>{
    return getApi({
        url: `admin/task-timeline/${id}/show`,
        data: {
            is_admin: true,
            page:page || undefined
        }
    }).then(res => res.data)
};

export const createComment = (data) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/task-timeline/store`,
        withCredentials: true,
        data: {
            ...data,
            is_admin: true,
        },
        headers: getHttpHeaders(),
    })
};

export const comoeleteTask = (ids) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/tasks`,
        withCredentials: true,
        data: {
            ids,
            is_admin: true,
        },
        headers: getHttpHeaders(),
    })
};



// employee task

export const getEmployeeTask = (id,page,start,end) => {
    return getApi({
        url: `admin/employee/${id}`,
        data: {
            is_admin: true,
            page :page || undefined,
            start:start || undefined,
            end:end || undefined,
        }
    }).then(res => res.data)
}