import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import deleteIcon from "../../assets/images/delete-icon-white.svg";
import { CardBody, Card, Button } from "reactstrap";
import PrimaryInput from "components/Common/PrimaryInput";
import { getAllTrending, deleteTrendingProduct, createTrendingProduct } from "services/ProductsServices";
import PrimaryTable from "components/Common/PrimaryTable";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function TrendingProducts() {
	const [trendingProductsData, setTrendingProductData] = useState();
	const [loading, setLoading] = useState(false);
	const [productIds, setProductIds] = useState("");
	const [selectedId, setSelectedId] = useState();
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	useEffect(()=>{
		setPageTitle("Trending Products - Inspire Uplift")
	},[]);

	useEffect(() => {

		trendingProducts()
	}, []);

	const trendingProducts = () => {
		setLoading(true);
		getAllTrending()
			.then(res => {
				setTrendingProductData(res?.data);
			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false));
	}

	const columns = [
		{
			dataField: "id",
			text: "Product ID",
			formatter: (cell, row) => (
				<div className="font-semi-bold">{row.id}</div>
			),
		},
		{
			dataField: "name",
			text: "Product Name",
		},
		{
			dataField: "action",
			text: "Action",
			formatter: (cell, row) => (
				<div className="action-btn delete-btn-bg cursor-pointer" onClick={() => {
	
					setSelectedId(row.id);
					setShowDeleteModal(true);
				}}>
					<img src={deleteIcon} className="" alt="delete icon" />
				</div>
			),
		},
	];

	const handleCreateProduct = (e) => {
		e.preventDefault();

		setLoading(true);
		createTrendingProduct(productIds)
			.then(res => {
				trendingProducts()
				PrimaryToast({ type: 'success', message: res?.data?.message });
			})
			.catch(err => {
				console.log(err)
				PrimaryToast({ type: 'error', message: err?.response?.data?.message[0] });
			})
			.finally(res => setLoading(false));

	}

	const data = trendingProductsData?.map((item) => {
		return {
			...item
		}
	});


	const hanldeDeleteProduct = () => {
		let filteredId = trendingProductsData?.filter((item) => item.id !== selectedId);
		toast.dismiss();
		setLoading(true);
		deleteTrendingProduct(selectedId)
			.then(res => {
				setTrendingProductData(filteredId);
				setShowDeleteModal(false);
				setSelectedId("");
				PrimaryToast({ type: 'success', message: res?.data?.message });
			})
			.catch(err => {
				console.log(err)
				PrimaryToast({ type: 'error', message: err?.response?.data?.message[0] });
			})
			.finally(res => setLoading(false));
	}

	return (
		<div className="page-content">
			{
				showDeleteModal && <DeleteModal
					title={"Are you sure to delete this product?"}
					onConfirm={hanldeDeleteProduct}
					onCancel={() => {
						setSelectedId('');
						setShowDeleteModal(!showDeleteModal);
					}}

				/>
			}
			{
				loading && <ProgressPopup label={"Loading..."} />
			}
			<div className="mb-3">
				<TopHeading heading={"Trending Products"} />
			</div>
			<Card>
				<CardBody>
					<div className="w-50 mb-3">
						<div className="d-flex align-items-end w-100">
							<div className="me-3 w-100">
								<PrimaryInput
									name="product_id"
									label={"Product IDs"}
									isRequired
									type={"text"}
									placeholder="Enter Product Ids"
									handleChange={(e) => { setProductIds(e.target.value) }}
								/>
							</div>
							<Button onClick={handleCreateProduct} style={{ height: "36px" }} color="primary">
								Add
							</Button>
						</div>
						<span className="text-11">Product ids should be separated with commas e.g: 10,11,12,13,14...</span>
					</div>
					<div>
						<div className="font-semi-bold fs-5 mb-3">
							Products
						</div>
						<PrimaryTable
							columns={columns}
							data={data}
							keyField="product_id"
							classes={"table align-middle table-nowrap"}
							headerWrapperClasses={"thead-light"}
							responsive
							bordered={false}
						/>
					</div>
				</CardBody>
			</Card>
		</div>
	);
}

export default TrendingProducts;
