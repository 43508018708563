import React from "react";
import { DateRangePicker } from "react-date-range";

const DateRangeSelect = ({
	handleDatePicker,
	dates,
	width,
    handleSubmit,
	minDate,
	maxDate
}) => {
	return (
		<div
			className={`position-absolute  ${
				width ? width : ""
			} date-range-container right-0 sm:overflow-visible overflow-auto`}
		>
			<DateRangePicker
				onChange={handleDatePicker}
				ranges={dates}
				direction="horizontal"
				showSelectionPreview={true}
				moveRangeOnFirstSelection={false}
				inputRanges={[]}
				minDate={minDate}
				maxDate={maxDate}
			/>
			<button
				onClick={handleSubmit}
				className="calender-submit-button"
			>
				Submit
			</button> 
		</div>
	);
};
export default DateRangeSelect;