import React from "react";

function PrimaryRadio({ label, name, id, value, handleChange, checked, className }) {
  return (
    <div className={`form-check w-max-content ${className}`}>
      <input
        className="form-check-input"
        type="radio"
        name={name}
        id={id}
        checked={checked}
        onChange={handleChange}
        value={value}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

export default PrimaryRadio;
