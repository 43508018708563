import React, { useState } from "react";
import PrimarySelect from "components/Common/PrimarySelect";
import PrimaryInput from "components/Common/PrimaryInput";
import DateRangePicker from "components/Common/DateRangePicker";
import moment from "moment";
import { limitOptions } from "utils/constant";
import { countOptions } from "utils/constant";
import downwradIcon from "assets/images/downword-white.svg";
import { Button } from "reactstrap";

const KeywordsFilters = ({
  dates,
  showCalendar,
  handleCalenderClick,
  filters,
  handleChange,
  setFilters,
  handleApplyFilter,
  handleResetFilter,
  handleDatePicker,
  isAppliedFilter,
  setDates
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleClicked = value => {
    setFilters(prevFilters => ({ ...prevFilters, ["count_sign"]: value }));
    setIsOpen(false);
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <div className="w-100px me-2">
          <PrimaryInput
            name={"search_id"}
            placeholder={"By ID"}
            borderRadius="rounded-full"
            value={filters?.search_id}
            handleChange={handleChange}
          />
        </div>
        <div className="w-100px me-2">
          <PrimaryInput
            name={"search_name"}
            placeholder={"By Name"}
            borderRadius="rounded-full"
            value={filters?.search_name}
            handleChange={handleChange}
          />
        </div>
        <div className="position-relative me-2 seo-collections-date-section">
          <div
            className="form-control cursor-pointer rounded-full"
            style={{ color: "#74788D", width: "max-content" }}
            onClick={()=>{
                setDates([{
                    startDate:new Date(),
                    endDate:new Date(),
                    key:'selection'
                }])
                handleCalenderClick()
            }}
          >
            {moment(dates[0].startDate || new Date()).format("DD MMM YYYY")} -{" "}
            {moment(dates[0].endDate || new Date()).format("DD MMM YYYY")}
          </div>

          {showCalendar && (
            <>
              <div
                className="position-fixed top-0 start-0 w-100 h-100"
                style={{ zIndex: "10" }}
                onClick={handleCalenderClick}
              ></div>
              <DateRangePicker
                dates={dates}
                handleDatePicker={handleDatePicker}
                handleSubmit={handleCalenderClick}
                maxDate={new Date()}
              />
            </>
          )}
        </div>
        <PrimarySelect
          name={"limit"}
          options={limitOptions}
          radius={"rounded-full"}
          value={filters?.limit}
          handleChange={handleChange}
        />
        <div className="input-group rounded-full w-240 ms-2">
          <input
            type="text"
            className="form-control"
            placeholder="By Product Count"
            name="product_count"
            value={filters?.product_count}
            onChange={handleChange}
          />
          {filters?.count_sign && (
            <button type="button" className="btn btn-primary">
              {filters?.count_sign == "equal" ? "=" : filters?.count_sign == "greater" ? ">" : filters?.count_sign == "smaller" ? "<" : "" }
            </button>
          )}

          <button
            type="button"
            className="btn btn-primary dropdown-toggle dropdown-toggle-split"
            onClick={toggleOpen}
          >
            <img src={downwradIcon} alt="downword icon" />
          </button>
          <ul
            className={`dropdown-menu dropdown-menu-end  ${isOpen && "show"}`}
          >
            {countOptions.map((option, index) => {
              return (
                <li
                  className=""
                  key={index}
                  onClick={() => handleClicked(option.value)}
                >
                  <span className="dropdown-item fs-6 fw-bold">
                    {option.name}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="ms-2">
        <Button
          color="primary"
          className={`btn-rounded  me-2`}
          onClick={handleApplyFilter}
        >
          Apply Filter
        </Button>
        {
            isAppliedFilter &&        ( <Button className="btn-rounded" onClick={handleResetFilter}>
            Reset
          </Button>)
        }
      </div>
      </div>
    </div>
  );
};

export default KeywordsFilters;
