import React from "react";

const ImageGallery = ({
  images,
  selectedIndex,
  setGallery,
  setSelectedIndex,
  setGalleryImages,
  path,
}) => {
  const totalImages = images.length;

  const handleNext = e => {
    e.stopPropagation();
    setSelectedIndex((selectedIndex + 1) % totalImages);
  };

  const handlePrev = e => {
    e.stopPropagation();
    setSelectedIndex((selectedIndex - 1 + totalImages) % totalImages);
  };

  const handleClose = () => {
    setSelectedIndex(0);
    setGallery(false);
    if (path == "dispute") {
      setGalleryImages([]);
    }
  };

  return (
    <div className="image-preview" onClick={handleClose}>
      {totalImages > 1 && (
        <div className="nav-icons">
          <button onClick={handlePrev} className="prev-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="20"
              height="34"
            >
              <path
                d="m 19,3 -2,-2 -16,16 16,16 1,-1 -15,-15 15,-15 z"
                fill="#FFF"
              />
            </svg>
          </button>
          <button onClick={handleNext} className="next-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="20"
              height="34"
            >
              <path
                d="m 1,3 2,-2 16,16 -16,16 -1,-1 15,-15 -15,-15 z"
                fill="#FFF"
              />
            </svg>
          </button>
        </div>
      )}
      <img
        src={images[selectedIndex]}
        alt={`Image ${selectedIndex + 1}`}
        className="preview-image"
        onClick={e => e.stopPropagation()}
      />
      <button onClick={handleClose} className="close-button">
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.4624 0.36C17.9824 -0.12 17.1824 -0.12 16.7024 0.36L8.29244 8.77C8.19974 8.86251 8.12619 8.9724 8.07601 9.09338C8.02583 9.21435 8 9.34403 8 9.475C8 9.60597 8.02583 9.73565 8.07601 9.85662C8.12619 9.9776 8.19974 10.0875 8.29244 10.18L16.7024 18.59C17.1924 19.08 17.9824 19.08 18.4724 18.59C18.9624 18.1 18.9624 17.31 18.4724 16.82L11.1224 9.48L18.4724 2.13C18.9524 1.64 18.9524 0.85 18.4624 0.36Z"
            fill="#fff"
          />
          <path
            d="M0.377399 18.597C0.857399 19.077 1.6574 19.077 2.1374 18.597L10.5474 10.187C10.6401 10.0945 10.7137 9.98463 10.7638 9.86366C10.814 9.74268 10.8398 9.613 10.8398 9.48203C10.8398 9.35106 10.814 9.22138 10.7638 9.10041C10.7137 8.97943 10.6401 8.86955 10.5474 8.77703L2.1374 0.36703C1.6474 -0.122969 0.857401 -0.12297 0.367401 0.36703C-0.122599 0.85703 -0.122599 1.64703 0.367401 2.13703L7.7174 9.47703L0.367399 16.827C-0.1126 17.317 -0.1126 18.107 0.377399 18.597Z"
            fill="#fff"
          />
        </svg>
      </button>
    </div>
  );
};

export default ImageGallery;
