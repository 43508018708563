import React from "react";
import { Button } from "reactstrap";
import { useState, useEffect } from "react";
import { getTrackingLineItemsDetail } from "services/OrderManagementService";
import ProgressPopup from "./ProgressPopup";
import { openLinkInNewTab } from "utils/linkHandling";
import { substractVariantTitle } from "utils/string";

const DeleteTrackingPopup = ({
  id,
  handleDeleteTracKing,
  handleUpdateTrackingNumber,
  hasEditPermission,
  hasDeletePermission,
}) => {
  const [lineItems, setLineItems] = useState([]);
  const [isRequesting, setIsRequesting] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState();
  const [aliExpressNumber, setAliExpressNumber] = useState();

  useEffect(() => {
    setIsRequesting(true);
    getTrackingLineItemsDetail(id)
      .then(res => {
        setLineItems(res.data);
        setAliExpressNumber(
          res?.data?.ali_express_numbers &&
            res?.data?.ali_express_numbers.length > 0
            ? res?.data?.ali_express_numbers[0]
            : ""
        );
        setTrackingNumber(res?.data?.tracking_number);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setIsRequesting(false);
      });
  }, []);

  const handleUpdateTracking = e => {
    const data = {
      order_id: lineItems?.order_id,
      fulfilment_id: lineItems?.id,
      tracking: trackingNumber || undefined,
      aliExpressNumbers: aliExpressNumber,
    };
    handleUpdateTrackingNumber(data);
  };

  return (
    <>
      {isRequesting && <ProgressPopup label={"Loading..."} />}
      <div className="order-refund-popup-table-header d-flex align-items-center mb-4">
        <div className="me-4">#</div>
        <div className="d-flex justify-content-between align-items-center flex-grow-1">
          <div className="flex-grow-1" style={{ width: "300px" }}>
            Product
          </div>
          <div className="product-title-container flex-grow-1">Variant</div>
          <div>Quantity</div>
        </div>
      </div>
      {lineItems?.line_item?.map((item, index) => (
        <div
          className="d-flex align-items-center w-100 p-3 border-bottom"
          key={index}
        >
          <div className="me-4 fw-bold">{index + 1}</div>
          <div className="flex-grow-1" style={{ width: "300px" }}>
            {item?.product}
          </div>
          <div className="product-title-container flex-grow-1">
            {item?.type === "configurable"
              ? substractVariantTitle(item.product, item.variant_title)
              : null}
          </div>
          <div className="text-center">{item?.quantity}</div>
        </div>
      ))}

      <div className="refund-order-summary-section-container my-4 d-flex justify-content-end border-bottom">
        <div className="refund-order-summary-section-inner-wrapper">
          <div className="font-semi-bold h5 mb-0 py-3 border-bottom">
            Tracking
          </div>
          <div className=" mb-3">
            <label htmlFor="inputEmail4" className="form-label">
              Tracking Number
            </label>
            <div
              className={`position-relative tracking-number-wrapper d-flex align-items-center ${
                lineItems?.ali_express_numbers &&
                lineItems?.ali_express_numbers.length > 0 &&
                lineItems?.tracking_number?.length === 0 &&
                "field-editable"
              } `}
            >
              <input
                type={"text"}
                placeholder="Enter tracking number"
                value={trackingNumber}
                onChange={e => setTrackingNumber(e.target.value)}
                disabled={
                  lineItems?.ali_express_numbers &&
                  lineItems?.ali_express_numbers.length > 0 &&
                  lineItems?.tracking_number?.length === 0
                    ? false
                    : true
                }
                className="tracking-input-field border-none bg-transparent"
              />

              <div className="">
                {lineItems?.tracking_urls &&
                  lineItems.tracking_urls.length > 0 && (
                    <span
                      className="primary-text cursor-pointer"
                      onClick={() =>
                        openLinkInNewTab(
                          lineItems?.tracking_urls &&
                            lineItems.tracking_urls.length > 0
                            ? lineItems.tracking_urls[0]
                            : ""
                        )
                      }
                    >
                      Track
                    </span>
                  )}

                {lineItems?.tracking_number && (
                  <span
                    className="primary-text cursor-pointer ps-2"
                    onClick={() => {
                      navigator.clipboard.writeText(lineItems?.tracking_number);
                    }}
                  >
                    Copy
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className=" mb-3">
            <label htmlFor="inputEmail4" className="form-label">
              Ali Express Number
            </label>

            <div
              className={`tracking-number-wrapper ${
                lineItems?.ali_express_numbers &&
                lineItems?.ali_express_numbers.length > 0 &&
                lineItems?.tracking_number?.length === 0 &&
                "field-editable"
              } `}
            >
              <input
                className="ali-express-number border-none bg-transparent"
                placeholder="Enter ali express number"
                value={aliExpressNumber}
                onChange={e => setAliExpressNumber(e.target.value)}
                disabled={
                  lineItems?.ali_express_numbers &&
                  lineItems?.ali_express_numbers.length > 0 &&
                  lineItems?.tracking_number?.length === 0
                    ? false
                    : true
                }
              />

              {lineItems?.ali_express_numbers?.length > 0 && (
                <span
                  className="primary-text cursor-pointer"
                  onClick={() =>
                    navigator.clipboard.writeText(aliExpressNumber)
                  }
                >
                  Copy
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="order-summary-action-section d-flex justify-content-end mt-3">
        <div className="d-flex align-items-center">
          {hasEditPermission
            ? lineItems?.ali_express_numbers &&
              lineItems?.ali_express_numbers.length > 0 &&
              lineItems?.tracking_number?.length === 0 && (
                <Button
                  color="primary"
                  className="me-4"
                  onClick={handleUpdateTracking}
                >
                  Update Tracking
                </Button>
              )
            : null}

          {hasDeletePermission && (
            <Button color="danger" onClick={handleDeleteTracKing}>
              Delete Tracking
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default DeleteTrackingPopup;
