import React from 'react';

function PrimarySearch({handleChange,value, placeholder, handleKeyPress, searchValRef, handleBlur}) {
  const getPageWidth = () => {
    const url = window?.location?.href;
    const width = (url?.includes('orders/list') || url?.includes('seller/withdraw-requests')) ? '200px' : (url?.includes('products/approval') || url?.includes("others/schedule-command") ) ? "300px" : '260px';
    return width;
  };
  return (
    <div className='d-flex align-items-center border form-control rounded-pill' style={{ width: getPageWidth() }}>
        <i className="bx bx-search-alt search-icon" />
        <input className='border-none ps-2 w-100' type={"search"} ref={searchValRef} value={value} placeholder={placeholder ?  placeholder  :"Search here..."} onKeyPress={handleKeyPress} onBlur={handleBlur} onChange={handleChange}  />
    </div>
  )
}

export default PrimarySearch;