import React, { useState, useEffect } from 'react';
import TopHeading from 'components/Common/TopHeading';
import PrimarySearch from 'components/Common/PrimarySearch';
import { Button, Card, CardBody } from 'reactstrap';
import PlusIcon from "../../../assets/images/plus-icon.svg";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import editIcon from "../../../assets/images/edit-icon-white.svg";
import { useNavigate } from 'react-router-dom';
import PrimaryTable from "components/Common/PrimaryTable";
import { getWidgetsList, deleteWidget } from "services/WidgetsServices";
import ProgressPopup from 'components/Common/Popups/ProgressPopup';
import PrimaryPagination from 'components/Common/Pagination/PrimaryPagination';
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { toast } from 'react-toastify';
import { setPageTitle } from "utils/pageTitle";
import { useLocation } from 'react-router-dom';
import useToggle from 'hooks/useToggle';

function WigetsListing() {
    const history = useNavigate();
    const [widgetsData, setWidgetsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState();
    const [pageNo, setPageNo] = useState(1);
    const [showDeleteModal, setShowDeleteModal] = useToggle(false);
    const [selectedId, setSelectedId] = useState('');
    const [searchVal, setSearch] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(()=>{
		setPageTitle("Widgets  - Inspire Uplift")
	  },[]);

    useEffect(() => {
        
        const pageValue = queryParams.get('page') || undefined;
        const searchValue = queryParams?.get('search') || undefined;
        setPageNo(pageValue || 1);
        setSearch(searchValue);
        getAllWidgets(pageValue,searchVal);
    }, [location])

    const getAllWidgets = (page, search) => {
        setLoading(true);
        getWidgetsList(page, search)
            .then(res => {
                setWidgetsData(res.data.data);
                setPageCount(res.data.last_page)
            })
            .catch(err => console.log(err))
            .finally(res => setLoading(false));
    };

    const handleSearch = (e) => {
        const val = e.target.value;
        setSearch(val);

    }

    const columns = [
        {
            dataField: 'id',
            text: '#',
            formatter: (cell, row) => (
                <div className='font-semi-bold'>
                    {((pageNo - 1) * 10 ) + row.index}
                </div>
            )
        },
        {
            dataField: 'name',
            text: 'Name'
        },
        {
            dataField: 'slug',
            text: 'Slug'
        },
        {
            dataField: 'action',
            text: 'Action',
            formatter: (cell, row) => (
                <div className='d-flex align-items-center'>
                    <div className='action-btn edit-icon-bg me-1' onClick={() => history(`/theme/widgets/edit/${row.id}`)}>
                        <img src={editIcon} className="" alt="edit icon" />
                    </div>
                    <div className='action-btn delete-btn-bg me-1 cursor-pointer' onClick={() => {
                        setSelectedId(row.id);
                        setShowDeleteModal(true);
                    }}>
                        <img src={deleteIcon} className="" alt="delete icon" />
                    </div>

                </div>
            ),
        }
    ];

    const data = widgetsData?.map((item,index) => {
        return {
            ...item,
            index:index + 1
        }
    });

    const handleDelete = () => {
        const filterData = widgetsData?.filter((item) => item.id !== selectedId);
        toast.dismiss();
        setLoading(true);
        deleteWidget(selectedId)
            .then(res => {
                if(filterData.length === 0 && pageNo > 1){
                    queryParams?.set("page", pageNo - 1);
					let newUrl = `${window?.location?.pathname}?${queryParams.toString()}`;
					history(newUrl, { replace: true });
                }
                else {
                    setWidgetsData(filterData);
                }
                setSelectedId();
                setShowDeleteModal(false);
                PrimaryToast({ type: 'success', message: res?.data?.message });
            })
            .catch(err => {
                console.log(err)
                PrimaryToast({ type: 'error', message: err?.response?.data?.message });
            })
            .finally(res => setLoading(false))
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            if (queryParams?.get("page")) {
              queryParams?.delete("page");
            }
            if (searchVal) {
              queryParams?.set("search", searchVal);
            } else {
              queryParams?.delete("search");
            }
            history(`/theme/widgets?${queryParams?.toString()}`);
        }
    };
    

    return (
        <div className='page-content'>
            {
                showDeleteModal && <DeleteModal
                    title={"Are you sure to delete this widget?"}
                    onConfirm={handleDelete}
                    onCancel={() => {
                        setSelectedId('');
                        setShowDeleteModal(!showDeleteModal);
                    }}

                />
            }
            {
                loading && <ProgressPopup label={"loading"} />
            }
            <div className='d-flex align-items-center justify-content-between mb-3'>
                <TopHeading heading={"Widgets"} />
                <Button className='d-flex align-items-center' color='primary' onClick={() => history('/theme/widgets/create')}>
                    <img src={PlusIcon} alt="plus icon" className="pe-2" />
                    Create New Widget
                </Button>
            </div>
            <Card>
                <CardBody>
                    <PrimarySearch value={searchVal} handleKeyPress={handleKeyPress} handleChange={handleSearch} />
                    <div className='mt-3'>
                        <PrimaryTable
                            columns={columns}
                            data={data}
                            bordered={false}
                            keyField="id"
                        />
                    </div>
                    {
                        (widgetsData?.length > 0 && pageCount > 1 ) && (
                            <PrimaryPagination
                                pageCount={pageCount}
                                pageNo={pageNo}
                                setPageNo={setPageNo}
                            />
                        )
                    }
                </CardBody>
            </Card>
        </div>
    )
}

export default WigetsListing