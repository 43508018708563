import React, { useState, useEffect } from 'react';
import TopHeading from 'components/Common/TopHeading';
import { Button, Card, CardBody } from 'reactstrap';
import plusIcon from "../../assets/images/plus-icon.svg";
import { useNavigate } from 'react-router-dom';
import PrimarySearch from 'components/Common/PrimarySearch';
import editIcon from "../../assets/images/edit-icon-white.svg";
import deleteIcon from "../../assets/images/delete-icon-white.svg";
import { getAllCoupons, deleteCoupon } from "services/CouponServices";
import PrimaryTable from 'components/Common/PrimaryTable';
import PrimaryPagination from 'components/Common/Pagination/PrimaryPagination';
import moment from 'moment';
import ProgressPopup from 'components/Common/Popups/ProgressPopup';
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { setPageTitle } from "utils/pageTitle";
import useToggle from 'hooks/useToggle';

function CouponsList() {
    const [coupounsData, setCouponsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [showDeleteModal, setShowDeleteModal] = useToggle(false);
    const [selectedId, setSelectedId] = useState("");
    const [searchVal, setSearch] = useState(null);
    const history = useNavigate();
    const user = useSelector((state) => state.auth.user);
    const permissions = user?.permissions?.map((item) => item.name);
    const params = new URLSearchParams(window?.location?.search);

    useEffect(() => {
   
        const page = params?.get("page") || undefined;
        const search = params?.get("search") ||undefined;
        setPageNo(page || 1);
        setSearch(search);  
        setLoading(true);
        getCouponsData(page, search);
    }, [window?.location?.search]);

    useEffect(()=>{
		setPageTitle("Coupons - Inspire Uplift")
	},[]);


    const getCouponsData = (page, search) => {
    
        getAllCoupons(page, search)
            .then(res => {
                setCouponsData(res?.data?.data);
                setPageCount(res.data.last_page)
            })
            .catch(err => console.log(err))
            .finally(res => setLoading(false));
    }

    const columns = [
        {
            dataField: 'id',
            text: '#',
            formatter: (cell, row) => {
                return (
                    <div className='font-semi-bold'>{((pageNo - 1) * 15) + row.index}</div>
                )
            }
        },
        {
            dataField: 'code',
            text: 'Coupon',
        },
        {
            dataField: 'seller_name',
            text: 'Store Name',
        },
        {
            dataField: 'start_date',
            text: 'Start Date',
            formatter: (cell, row) => (
                <div>{moment(row.start_date).format("MM-DD-YYYY")}</div>
            )
        },
        {
            dataField: 'end_date',
            text: 'End Date',
            formatter: (cell, row) => {
                return (
                    <div className={`${!row.end_date && "status w-max-content unlimited-status"}`}>{row.end_date ? moment(row?.end_date).format("MM-DD-YYYY") : "Unlimited"}</div>
                )
            }
        },
        {
            dataField: 'redemption_time',
            text: 'Redemptions',
            formatter: (cell, row) => {
                return (
                    <div className={`status w-max-content unlimited-status`}>{row.redemption_time === 1 ? "One Time" : "Unlimited"}</div>
                )
            }
        },
        {
            dataField: 'is_active',
            text: 'Availability',
            formatter: (cell, row) => {
                let status;
                switch (row.is_active) {
                    case 0:
                        status = "Inactive";
                        break;
                    case 1:
                        status = "Active";
                        break;
                    default:
                        status = "Active";

                }
                return (
                    <div className={`status w-max-content ${status === "Active" ? "success-status" : "danger-status"}`}>{status}</div>
                )
            }
        },
        {
            dataField: 'value',
            text: 'Amount',
            formatter: (cell, row) => <div>{row.type_id == 2 && "$"}{row?.type_id == 2 ? row?.value?.toFixed(2) :row.value}{row.type_id == 1 && "%" }</div>
        },
        {
            dataField: 'platform',
            text: 'Platform',
            formatter: (cell, row) => {
                let plateform;
                if (row.platform == 0) {
                    plateform = "Both";
                }
                if (row.platform == 1) {
                    plateform = "Desktop";
                }
                if (row.platform == 2) {
                    plateform = "Mobile";
                }
                return (
                    <div>{plateform}</div>
                )

            }
        },
        {
            dataField: 'action',
            text: 'Action',
            formatter: (cellContent, row) => (
                <div className='d-flex align-items-center'>
                    {
                        permissions?.includes("coupon-edit") && (
                            <div className='action-btn edit-icon-bg me-1 cursor-pointer' onClick={() => history(`/coupons/edit/${row.id}`)}>
                                <img src={editIcon} className="" alt="edit icon" />
                            </div>
                        )
                    }
                    {
                        permissions?.includes("coupon-delete") && (
                            <div className='action-btn delete-btn-bg cursor-pointer' onClick={() => {
                                setSelectedId(row.id);
                                setShowDeleteModal(true);
                            }}>
                                <img src={deleteIcon} className="" alt="delete icon" />
                            </div>
                        )
                    }

                </div>
            ),

        },

    ];

    const data = coupounsData?.map((item, index) => {
        return {
            ...item,
            index:index + 1
        }
    });

    const handleSearch = e => {
        const value = e.target.value;
        setSearch(value);
    };


    const handleDelete = () => {
        const filterData  = coupounsData?.filter((item) => item.id !== selectedId);

        toast.dismiss();
        setLoading(true);
        deleteCoupon(selectedId)
            .then(res => {
                if (res.status === 200) {
                    setSelectedId();
                    setShowDeleteModal(false);
                    if(filterData?.length === 0 && pageNo > 1) {
                        params.set('page',pageNo  -1);
                        let newUrl = `${window?.location?.pathname}?${params.toString()}`;
                        history(newUrl, { replace: true });
                    }
                    else {
                        getCouponsData(pageNo,searchVal);
                    }    
                }
                PrimaryToast({ type: 'success', message: res?.data?.message });
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                PrimaryToast({ type: 'error', message: err?.response?.data?.message });
            })
    };
    
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            if (params?.get("page")) {
              params?.delete("page");
            }
            if (searchVal) {
              params?.set("search", searchVal);
            } else {
              params?.delete("search");
            }
            history(`/coupons/list?${params?.toString()}`);
        }
    };
    
    return (
        <div className='page-content'>
            {
                showDeleteModal && <DeleteModal
                    title={"Are you sure to delete this coupon?"}
                    onConfirm={handleDelete}
                    onCancel={() => {
                        setSelectedId('');
                        setShowDeleteModal(!showDeleteModal);
                    }}

                />
            }
            {
                loading && <ProgressPopup label={"Loading..."} />
            }
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <TopHeading heading={"All Coupons"} />
                {
                    permissions?.includes("coupon-create") && (
                        <Button className='d-flex align-items-center' color='primary' onClick={() => history('/coupons/create')}>
                            <img src={plusIcon} alt="plus icon" className="me-2" />
                            Create New Coupon
                        </Button>)
                }
            </div>
            <div>
                <Card>
                    <CardBody>
                        <PrimarySearch handleKeyPress={handleKeyPress} placeholder={"Search by Coupon, store name"} value={searchVal} handleChange={handleSearch} />
                        <div className='mt-3'>
                            <PrimaryTable
                                columns={columns}
                                data={data}
                                bordered={false}
                                keyField="id"
                            />
                        </div>
                        {
                           (coupounsData?.length > 0 && pageCount > 1 ) && (
                                <PrimaryPagination
                                    pageCount={pageCount}
                                    setPageNo={setPageNo}
                                    pageNo={pageNo}
                                />
                            )
                        }
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default CouponsList