import React  from 'react';
import warningIcon from "../../../assets/images/warning-icon.svg";

import { Button } from 'reactstrap';
import PrimaryPopup from './PrimaryPopup';

function HoldOrderPopup({ 
    handleClick,
    isOpen, 
    isScrollable, 
    heading,
    handleYes,
    description,
    loading
}) {
    return (
        <PrimaryPopup isOpen={isOpen} isScrollable={isScrollable} handleClick={handleClick}>
            <div>
                <div className='d-flex justify-content-end'>
                    <button
                        type="button"
                        onClick={handleClick}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        id='close-popup'
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>

                </div>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <div className='my-3'>
                        <img src={warningIcon} alt="warning-icon" />
                    </div>
                    <div className='h6 font-semi-bold'>
                        {heading}
                    </div>
                    <div className='font-normal mt-3'>
                        {description}
                    </div>
                    <div className='d-flex justify-between w-100 mt-3'>
                        <Button onClick={handleClick} disabled={loading} id="cancle-action" color='danger' className='w-50 me-3'>
                            Cancel
                        </Button>
                        <Button onClick={handleYes} disabled={loading} id="yes" color='secondary' className='w-50'>
                            Yes
                        </Button>

                    </div>
                </div>


            </div>
        </PrimaryPopup>

    )
}

export default HoldOrderPopup;