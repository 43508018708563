import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Card, CardBody } from "reactstrap";
import PrimaryTable from "components/Common/PrimaryTable";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { setPageTitle } from "utils/pageTitle";
import {
  createReviewDisputeAction,
  getReviewDisputeDetail,
  getReviewDisputes,
} from "services/review-dispute";
import deleteIcon from "assets/images/cancle-icon.svg";
import tickIcon from "assets/images/tick-icon.svg";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import PrimaryPopup from "components/Common/Popups/PrimaryPopup";

function DisputeReview() {
  const [disputeReviews, setDisputeReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disputeDetail, setDisputeDetail] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    setPageTitle("Disputed Reviews - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    getReviewDisputes()
      .then(res => {
        // console.log(res, "res.data.data");
        setDisputeReviews(res);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, []);

  const handleAction = (e, dispute_id, action) => {
    if (!loading) {
      e.currentTarget.setAttribute("disabled", true);
      setLoading(true);
      createReviewDisputeAction(dispute_id, action)
        .then(res => {
          getReviewDisputes()
            .then(res => {
              // console.log(res, "res.data.data");
              setDisputeReviews(res);

              if (e.target.parentNode.hasAttribute("disabled")) {
                e.target.parentNode.removeAttribute("disabled");
              } else {
                try {
                  e.target.parentNode
                    .querySelector(".action-btn")
                    .removeAttribute("disabled");
                } catch (error) {
                  console.log(error);
                }
              }
            })
            .catch(err => console.log(err))
            .finally(res => setLoading(false));
        })
        .catch(err => {
          setLoading(false);
          if (e.target.parentNode.hasAttribute("disabled")) {
            e.target.parentNode.removeAttribute("disabled");
          } else {
            try {
              e.target.parentNode
                .querySelector(".action-btn")
                .removeAttribute("disabled");
            } catch (error) {
              console.log(error);
            }
          }

          PrimaryToast({
            type: "error",
            message: Array.isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message,
          });
        });
    }
  };

  const columns = [
    {
      dataField: "order_id",
      text: "Order ID",
      style: {
        width: "200px",
      },
    },
    {
      dataField: "product_id",
      text: "Product ID",
      style: {
        width: "200px",
      },
    },
    {
      dataField: "reason",
      text: "Reason",
      style: {
        maxWidth: "400px",
        whiteSpace: "break-spaces",
        wordBreak: "break-word",
      },
    },
    {
      //   dataField: "created_at",
      text: "Action",
      style: {
        width: "300px",
      },
      formatter: (cell, row) => (
        <div className="d-flex align-items-center">
          <button
            className="action-btn delete-btn-bg cursor-pointer me-1"
            onClick={e => {
              e.stopPropagation();
              const dispute_id = row.id;

              handleAction(e, dispute_id, 0);
            }}
          >
            <img src={deleteIcon} className="" alt="reject icon" />
          </button>
          <button
            className={`action-btn approve-btn-bg `}
            onClick={e => {
              e.stopPropagation();
              const dispute_id = row.id;
              handleAction(e, dispute_id, 1);
            }}
          >
            <img src={tickIcon} className="" alt="approve icon" />
          </button>
        </div>
      ),
    },
  ];

  const data = disputeReviews?.map((item, index) => {
    return {
      ...item,
      order_id:  item.order_id,
      product_id: (
        <span
          className="font-semi-bold primary-text cursor-pointer"
          onClick={() => {
            getDisputeDetail(item.id);
          }}
        >
          {item.product_id}
        </span>
      ),
    };
  });

  const getDisputeDetail = id => {
    if (id === disputeDetail?.id) {
      openReviewDisputeDetail();
    } else {
      setLoading(true);
      getReviewDisputeDetail(id).then(res => {
        setDisputeDetail({ ...res, id });
        setLoading(false);
        openReviewDisputeDetail();
      });
    }
  };

  const openReviewDisputeDetail = () => {
    setOpenPopup(!openPopup);
  };

  return (
    <div className="page-content disputed_review">
      {loading && <ProgressPopup label={"Loading..."} />}
      {openPopup ? (
        <PrimaryPopup
          title={"Product Review"}
          isOpen={true}
          handleClick={openReviewDisputeDetail}
        >
          <div className="dispute_review">
            <div className="dispute_review_header">
              <div>
                <img
                  src={disputeDetail?.product?.image}
                  alt={disputeDetail?.product?.title}
                  className="dispute_review_header_img"
                />
              </div>
              <div className="dispute_review_header_title">
                {disputeDetail?.product?.title}
              </div>
            </div>
            <div className="dispute_review_body">
              <div className="dispute_review_body_header">
                <div className="dispute_review_body_header_user">
                  <div className="dispute_review_body_header_user_initial">
                    {disputeDetail?.review?.customer?.[0]}
                  </div>
                  <div className="dispute_review_body_header_user_name">
                    {disputeDetail?.review?.customer}
                  </div>
                </div>
                <div className="dispute_review_body_header_review_date">
                  {disputeDetail?.review?.created_at}
                </div>
              </div>
              <div className="dispute_review_body_rating">
                {Array.from({ length: 5 }).map((item, index) => {
                  if (index + 1 <= disputeDetail?.review?.rating) {
                    return (
                      <svg
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        key={index}
                        className="dispute_review_body_rating_svg"
                      >
                        <path
                          d="M8.68425 0.525047C8.92759 -0.175015 9.91765 -0.175016 10.161 0.525047L11.9396 5.64189C12.047 5.95072 12.3352 6.16012 12.662 6.16678L18.0781 6.27715C18.8191 6.29225 19.125 7.23384 18.5344 7.68161L14.2176 10.9544C13.9571 11.1519 13.847 11.4907 13.9417 11.8036L15.5104 16.9887C15.725 17.6981 14.924 18.28 14.3156 17.8567L9.86911 14.7625C9.60074 14.5758 9.2445 14.5758 8.97613 14.7625L4.52959 17.8567C3.92124 18.28 3.12027 17.6981 3.33489 16.9887L4.90356 11.8036C4.99824 11.4907 4.88816 11.1519 4.62762 10.9544L0.31083 7.68161C-0.279772 7.23385 0.0261701 6.29225 0.767166 6.27715L6.1832 6.16678C6.51008 6.16012 6.79829 5.95072 6.90564 5.64189L8.68425 0.525047Z"
                          fill="#FFC200"
                        />
                      </svg>
                    );
                  } else {
                    return (
                      <svg
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        key={index}
                        className="dispute_review_body_rating_svg"
                      >
                        <path
                          d="M8.68425 0.525047C8.92759 -0.175015 9.91765 -0.175016 10.161 0.525047L11.9396 5.64189C12.047 5.95072 12.3352 6.16012 12.662 6.16678L18.0781 6.27715C18.8191 6.29225 19.125 7.23384 18.5344 7.68161L14.2176 10.9544C13.9571 11.1519 13.847 11.4907 13.9417 11.8036L15.5104 16.9887C15.725 17.6981 14.924 18.28 14.3156 17.8567L9.86911 14.7625C9.60074 14.5758 9.2445 14.5758 8.97613 14.7625L4.52959 17.8567C3.92124 18.28 3.12027 17.6981 3.33489 16.9887L4.90356 11.8036C4.99824 11.4907 4.88816 11.1519 4.62762 10.9544L0.31083 7.68161C-0.279772 7.23385 0.0261701 6.29225 0.767166 6.27715L6.1832 6.16678C6.51008 6.16012 6.79829 5.95072 6.90564 5.64189L8.68425 0.525047Z"
                          fill="#D7DADE"
                        />
                      </svg>
                    );
                  }
                })}
              </div>
              <div className="dispute_review_body_review">
                {disputeDetail?.review?.review}
              </div>
            </div>
          </div>
        </PrimaryPopup>
      ) : null}

      <TopHeading heading={"Disputed Reviews"} />
      <Card className="mt-3">
        <CardBody>
          <PrimaryTable
            columns={columns}
            data={data}
            bordered={false}
            keyField="id"
          />
        </CardBody>
      </Card>
    </div>
  );
}

export default DisputeReview;
