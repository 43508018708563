import React, { useState, useEffect } from 'react';
import TopHeading from 'components/Common/TopHeading';
import { Button, Card, CardBody } from 'reactstrap';
import {  useNavigate } from 'react-router-dom';
import SwitchButton from 'components/Common/SwitchButton';
import PrimaryInput from 'components/Common/PrimaryInput';
import { createPayoutMethod } from 'services/SellerPayoutMethodService';
import PrograssPopup from 'components/Common/Popups/ProgressPopup';
import PrimaryToast from 'components/Common/Toast/ToastMessage';
import { toast } from 'react-toastify';
import { setPageTitle } from 'utils/pageTitle';

function CreatePaymentMethod() {

    useEffect(()=>{
		setPageTitle("Create Payout Method - Inspire Uplift")
	},[]);

    const history = useNavigate();
    const [fields, setFields] = useState({
        is_active: true,
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

        if (name === "programId" || name === "programToken") {
            setFields(fields => ({
                ...fields,
                configuration: {
                    ...fields.configuration,
                    [name]: value
                }
            }));
        } else {
            setFields(fields => ({
                ...fields,
                [name]: value
            }));
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        toast.dismiss();
        setLoading(true)
        createPayoutMethod(fields)
            .then(res => {
                if (res.status === 200) {
                    PrimaryToast({type:'success',message:res?.data?.message});
                    history(-1);
                }
            })
            .catch(err => {
                PrimaryToast({type:'error',message:err?.response?.data?.message[0]});
                console.log(err);
            })
            .finally((res) => setLoading(false));

    }

    return (
        <div className='page-content'>
            {
                loading && <PrograssPopup label={'Loading...'} />
            }
            <form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <TopHeading heading={"Create Payout Method"} />
                    <div className="d-flex">
                        <Button className="me-2" onClick={() => history(-1)}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit">
                            Create
                        </Button>
                    </div>
                </div>
                <Card>
                    <CardBody>
                        <h5 className='font-semi-bold'>Payout Method Information</h5>
                        <div className='w-50'>
                            <div className="mb-3">
                                <PrimaryInput
                                    name="name"
                                    label={"Name"}
                                    value={fields?.name || ''}
                                    isRequired
                                    placeholder="Enter Payout Method Name"
                                    handleChange={handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <PrimaryInput
                                    name="slug"
                                    label={"Slug"}
                                    value={fields?.slug || ''}
                                    isRequired
                                    placeholder="Enter Payout Method Slug"
                                    handleChange={handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <PrimaryInput
                                    name="class_name"
                                    label={"Class Name"}
                                    value={fields?.class_name || ""}
                                    isRequired
                                    placeholder="Enter Class Name"
                                    handleChange={handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <PrimaryInput
                                    name="programId"
                                    label={"Program ID"}
                                    value={fields?.configuration?.programId || ''}
                                    placeholder="Enter Program ID"
                                    handleChange={handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <PrimaryInput
                                    name="programToken"
                                    value={fields?.configuration?.programToken || ''}
                                    label={"Program Token"}
                                    handleChange={handleChange}
                                    placeholder="Enter  Program Token"
                                />
                            </div>
                            <div>
                                <label htmlFor="inputEmail4">Status</label>
                                <SwitchButton checked={fields.is_active === true ? true : false} label={fields.is_active === true ? "Active" : "Inactive"} name="is_active" value={fields?.is_active} handleChange={handleChange} />
                            </div>

                        </div>

                    </CardBody>
                </Card>
            </form>
        </div>
    )
}

export default CreatePaymentMethod;