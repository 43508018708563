import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";


export const getAllCustomers = (page, search, start_date, end_date) => {
    return getApi({
        url: 'admin/customers',
        data: {
            is_admin: true,
            page:page ||undefined,
            search:search || undefined,
            start_date: start_date || undefined,
            end_date:end_date || undefined
        }
    }
    ).then(res => res.data)
}

export const getSingleCustomer = (id) => {
    return getApi({
        url: `admin/customers/edit/${id}`,
        data: {
            is_admin: true,
        }
    }
    ).then(res => res.data)
}

export const updateCustomer = (data,id) => {
    return axiosInstance({
		method: "PUT",
		url: `${siteApiUrl}admin/customers/update/${id}`,
        withCredentials:true,
		data: {
            is_admin:true,
            ...data
        },
		headers:getHttpHeaders(),
    })

}

export const deleteCustomer = (id) => {
    return axiosInstance({
		method: "DELETE",
		url: `${siteApiUrl}admin/customers/${id}`,
        withCredentials:true,
		data: {
            is_admin:true,
        },
		headers:getHttpHeaders(),
    })

}

export const customerAction = (id,status) => {
    return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/customers/action/${id}/${status}`,
        withCredentials:true,
		data: {
            is_admin:true,
        },
		headers:getHttpHeaders(),
    })

}

export const getCustomerDetail = (id) => {
    return getApi({
        url: `admin/customers/detail/${id}`,
        data: {
            is_admin: true,
        }
    }
    ).then(res => res.data)

}



export const getCustomerOrdersList = (id,page) => {
    return getApi({
        url: `admin/customers/${id}/orders`,
        data: {
            is_admin: true,
            page:page || undefined,
        }
    }
    ).then(res => res.data)

};

export const customerLogin = (id,device_data) => {
    return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/customers/customer-login/${id}`,
        withCredentials:true,
		data: {
            is_admin:true,
            device_data
        },
		headers:getHttpHeaders(),
    })
};

export const subscriptionCancle = (id) => {
    return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}admin/customers/subscription-cancel/${id}`,
        withCredentials:true,
		data: {
            is_admin:true,
        },
		headers:getHttpHeaders(),
    })
};


