import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import PrimarySearch from "components/Common/PrimarySearch";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";

import plusIcon from "../../../assets/images/plus-icon.svg";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import editIcon from "../../../assets/images/edit-icon-white.svg";
import {
  getAllPromotionsList,
  deletePromotion,
} from "services/PromotionsService";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";

function Promotions() {
  const [promotionsData, setPromotionsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [selectedId, setSelectedId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [searchVal, setSearch] = useState(null);
  const params = new URLSearchParams(window?.location?.search);

  const history = useNavigate();

  useEffect(() => {
    setPageTitle("Promotions - Inspire Uplift");
  }, []);

  useEffect(() => {
    const search = params.get("search") || undefined;
    const page = params?.get("page") || undefined;
    setPageNo(page || 1);
    setSearch(search);
    getPromotions(page, search);
  }, [window?.location?.search]);

  const getPromotions = (page, search) => {
    setLoading(true);
    getAllPromotionsList(page, search)
      .then(res => {
        setPromotionsData(res?.data?.promotions?.data);
        setPageCount(res?.data?.promotions?.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const handleDelete = () => {
    const filterArr = promotionsData?.filter(item => item.id !== selectedId);
    toast.dismiss();
    setLoading(true);

    deletePromotion(selectedId)
      .then(res => {
        if (res.status === 200) {
          setSelectedId();

          setShowDeleteModal(false);
          if (pageNo > 1 && filterArr.length === 0) {
            params.set("page", pageNo - 1);
            let newUrl = `${window?.location?.pathname}?${params.toString()}`;
            history(newUrl, { replace: true });
          } else {
            getPromotions(pageNo, searchVal);
          }
        }
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      })
      .finally(res => setLoading(false));
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => {
        return (
          <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>
        );
      },
    },
    {
      dataField: "title",
      text: "Title",
    },
    {
      dataField: "description",
      text: "Description",
      style: {
        display: "-webkit-box",
        webkitLineClamp: "3",
        webkitBoxOrient: "vertical",
        maxWidth: "600px",
        whiteSpace: "break-spaces",
        wordBreak: "break-word",
        overflow: "hidden",
        height: "75px",
      },
    },
    {
      dataField: "start_at",
      text: "Start Date",
      formatter: (cell, row) => <div>{row.start_at && row.start_at}</div>,
    },
    {
      dataField: "end_at",
      text: "End Date",
      formatter: (cell, row) => <div>{row.end_at && row.end_at}</div>,
    },
    {
      dataField: "is_hidden",
      text: "Status",
      formatter: (cell, row) => {
        let status;
        switch (row.is_hidden) {
          case 0:
            status = "Show";
            break;
          case 1:
            status = "Hide";
            break;
          default:
            status = "Show";
        }

        return (
          <div
            className={`status ${
              status === "Show"
                ? "success-status"
                : status === "Hide"
                ? "danger-status"
                : ""
            } w-max-content`}
          >
            {status}
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <div
              className="action-btn edit-icon-bg me-1 cursor-pointer"
              onClick={() => history(`/marketing/promotions/edit/${row.id}`)}
            >
              <img src={editIcon} className="" alt="edit icon" />
            </div>
            <div
              className="action-btn delete-btn-bg cursor-pointer"
              onClick={() => {
                setSelectedId(row.id);
                setShowDeleteModal(true);
              }}
            >
              <img src={deleteIcon} className="" alt="delete icon" />
            </div>
          </div>
        );
      },
    },
  ];

  const data = promotionsData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleSearch = e => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (params?.get("page")) {
        params?.delete("page");
      }
      if (searchVal) {
        params?.set("search", searchVal);
      } else {
        params?.delete("search");
      }
      history(`/marketing/promotions?${params?.toString()}`);
    }
  };

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this promotion?"}
          onConfirm={handleDelete}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <TopHeading heading={"Promotions"} />
        <Button
          className="rounded-full"
          color="primary"
          onClick={() => history("/marketing/promotions/create")}
        >
          <img alt="plus icon" src={plusIcon} className="me-2" />
          Create New Promotion
        </Button>
      </div>
      <Card>
        <CardBody>
          <div className="me-2 mb-3 d-flex align-items-center">
            <PrimarySearch
              placeholder={"search by title"}
              value={searchVal}
              handleKeyPress={handleKeyPress}
              handleChange={handleSearch}
            />
          </div>
          <div className="mt-3">
            <PrimaryTable
              columns={columns}
              data={data}
              responsive
              bordered={false}
              keyField="id"
            />
          </div>
          {promotionsData?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageNo={pageNo}
              pageCount={pageCount}
              setPageNo={setPageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default Promotions;
