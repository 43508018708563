import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CardBody, Card, Button } from "reactstrap";
import TopHeading from "components/Common/TopHeading";
import { getSingleKeywords, updateKeyword } from "services/KeywordDetectServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { useParams } from "react-router-dom";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function EditKeyword() {

  const [keywords, setKeywords] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const { id } = useParams();

  useEffect(()=>{
		setPageTitle("Edit Keywords - Inspire Uplift")
	},[]);

  useEffect(() => {

    setLoading(true);
    getSingleKeywords(id)
      .then(res => {
        setKeywords(res.data?.keyword);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));

  }, []);


  const handleSubmit = e => {
    e.preventDefault();
    toast.dismiss();
    setLoading(true);
    updateKeyword(keywords, id)
      .then(res => {
        history(-1)
        PrimaryToast({ type: 'success', message: res?.data?.message });
      })
      .catch(err => {
        console.log(err)
        PrimaryToast({ type: 'error', message: err?.response?.data?.message[0] });
      })
      .finally(res => setLoading(false));
  }


  return (
    <div className="page-content">
      {
        loading && <ProgressPopup label={"Loading..."} />
      }
      <form onSubmit={handleSubmit}>
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <TopHeading heading={"Edit Keyword"} />
          <div className="d-flex align-items-center">
            <Button onClick={() => history(-1)}>Cancel</Button>
            <Button color="primary" className="ms-4">
              Save
            </Button>
          </div>
        </div>
        <Card>
          <CardBody>
            <div className="w-50">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  Keywords<span className="primary-text">*</span>
                </label>
                <textarea
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                
                ></textarea>
              </div>
            </div>
          </CardBody>
        </Card>

      </form>
    </div>
  );
}

export default EditKeyword;
