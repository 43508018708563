import React from "react";
import PrimaryPopup from "./PrimaryPopup";
import { Button } from "reactstrap";

function BalanceChangePopup({
  isOpen,
  handleClose,
  title,
  balance,
  setBalance,
  handleUpdateBalance,
  loading
}) {
  return (
    <PrimaryPopup
      isOpen={isOpen}
      handleClick={handleClose}
      title={`Edit ${title}`}
      isScrollable={false}
      className="d-flex justify-content-center align-items-center h-100 w-100"
    >
      <div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            {title}:${balance}
          </label>
          <div className="input-group mb-3">
            <span className="input-group-text">$</span>
            <input
              type="text"
              className="form-control"
              aria-label="Amount (to the nearest dollar)"
              onChange={(e) => setBalance(e.target.value)}
              value={balance}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="secondary" onClick={handleClose}>
            Cancel
          </Button>

          <Button disabled={loading} color="primary" onClick={handleUpdateBalance}>Confirm</Button>
        </div>
      </div>
    </PrimaryPopup>
  );
};

export default BalanceChangePopup;
