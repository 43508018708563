import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import SwitchButton from 'components/Common/SwitchButton';
import TopHeading from 'components/Common/TopHeading';
import PrimaryInput from 'components/Common/PrimaryInput';
import { getSignleMarketplaceFeed,updateMarkeplaceFeed } from 'services/MarketPlaceFeedService';
import { useParams } from 'react-router-dom';
import PrograssPopup from 'components/Common/Popups/ProgressPopup';
import PrimaryToast from 'components/Common/Toast/ToastMessage';
import { toast } from 'react-toastify';
import { setPageTitle } from 'utils/pageTitle';

function EditMarketplaceFeed() {
    const history = useNavigate();
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState({
        is_active: true,
    });
    const { id } = useParams();

    useEffect(()=>{
		setPageTitle("Edit Marketplace Feed - Inspire Uplift")
	},[]);

    useEffect(() => {
        
        setLoading(true);
        getSignleMarketplaceFeed(id)
            .then(res => {
                setFields({
                    name: res.data.name || undefined,
                    is_active: res.data.is_active === 1 ? true : false,
                    type: res?.data?.feed_types || undefined,
                    group_ids: res?.data?.group_ids || undefined,
                });
            })
            .catch(err => console.log(err))
            .finally(res => setLoading(false));
            

    }, [])

    const handleChange = e => {
        const name = e.target.name;
        const value = e.target?.type === "checkbox" ? e.target.checked : e.target.value;
        const fieldsObj = { ...fields };
        fieldsObj[name] = value;
        setFields(fieldsObj);
    };

    const  handleSubmit = (e) => {
        
        e.preventDefault();
        const data = {
            ...fields,
            type:fields?.type?.map((item)=>item.id) || undefined,
            group_ids:fields?.group_ids?.map((item)=>item?.id) || undefined,
        }
        toast.dismiss();
        setLoading(true);
        updateMarkeplaceFeed(data,id) 
            .then(res => {
                history(-1)
				PrimaryToast({type:'success', message:res?.data?.message});
			})
			.catch(err =>{
				console.log(err)
				PrimaryToast({type:'error', message:err?.response?.data?.message[0]});
			})
            .finally(res => setLoading(false));
    };

    return (
        <div className='page-content'>
            {
                loading && <PrograssPopup label={"Laoding.."} />
            }
            <form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <TopHeading heading={"Edit Marketplace Feed"} />
                    <div className="d-flex">
                        <Button className="me-2" onClick={() => history(-1)}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit">
                            Save
                        </Button>
                    </div>
                </div>
                <Card className='w-50'>
                    <CardBody>
                        <div className="mb-3">
                            <PrimaryInput
                                label={"Name"}
                                isRequired
                                name="name"
                                placeholder={'Enter Feed Name'}
                                value={fields?.name || ""}
                                handleChange={handleChange}

                            />
                        </div>
                        <div className='mb-3'>
                            <PrimaryInput
                                label={"Type"}
                                isRequired
                                name="name"
                                placeholder={'Enter Feed Name'}
                                value={fields?.type?.map((item=>item.name)) || ""}
                                isDisabled

                            />
                        </div>
                        <div className='mb-3'>
                            <PrimaryInput
                                label={"Seller Groups"}
                                isRequired
                                name="name"
                                placeholder={'Enter Feed Name'}
                                isDisabled
                                value={fields?.group_ids?.map((item => item.name)) || ""}
                            />
                        </div>
                        <div className="">
                            <label htmlFor="inputEmail4">Status<span className="primary-text">*</span></label>
                            <SwitchButton checked={fields.is_active === true ? true : false} label={fields.is_active === true ? "Active" : "Inactive"} name="is_active" value={fields?.is_active} handleChange={handleChange} />
                        </div>
                    </CardBody>
                </Card>

            </form>

        </div>
    )
}

export default EditMarketplaceFeed;