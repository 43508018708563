import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import HoldOrderPopup from "components/Common/Popups/HoldOrderPopup";
import DisputeMessagePopup from "components/Common/Popups/DisputeMessagePopup";
import useToggle from "hooks/useToggle";
import {
  getDisputeDetail,
  closeDispute,
  sentMessage,
} from "services/DisputeServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DisputeHistorySection from "components/Dispute/DisputeHistorySection";
import DisputeSummary from "components/Dispute/DisputeSummary";
import DisputeActions from "components/Dispute/DisputeActions";
import moment from "moment";
import { useSelector } from "react-redux";
import ImageGallery from "components/Common/ImageGallerySlider";
import ImagePreviewPopup from "components/Common/Popups/ImagePreviewPopup";

function ResponseDispute() {
  const [isRefund, setIsRefund] = useToggle(false);
  const [isMessage, setIsMessage] = useToggle(false);
  const [isClose, setIsClose] = useToggle(false);
  const [disputeData, setDisputeData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [isGallery, setGallery] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [galleyImages, setGalleryImages] = useState([]);

  const user = useSelector(state => state.auth?.user);

  const handleShowGallery = (index, images) => {
    setSelectedIndex(index);
    if (galleyImages?.length == 0) {
      setGalleryImages(images);
    }
    setGallery(true);
  };

  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    getDisputeDetail(id)
      .then(res => {
        setDisputeData(res);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, []);

  const handleValidation = popupType => {
    if (popupType === "message") {
      if (!type) {
        PrimaryToast({
          type: "error",
          message: "Message To field is required",
        });
        return false;
      }
      if (!message) {
        PrimaryToast({ type: "error", message: "Message field is required" });
        return false;
      }
    }
    return true;
  };

  const handleSave = popupType => {
    let data;
    if (type == "customer") {
      data = {
        customer_id: disputeData?.customer?.customer_id,
        message,
      };
    } else if (type == "seller") {
      data = {
        seller_id: disputeData?.seller?.seller_id,
        message,
      };
    } else if (type == "public") {
      data = {
        seller_id: disputeData?.seller?.seller_id,
        customer_id: disputeData?.customer?.customer_id,
        message,
      };
    }
    if (handleValidation(popupType)) {
      setLoading(true);
      if (popupType == "message") {
        sentMessage(id, data)
          .then(res => {
            PrimaryToast({
              type: "success",
              message: "Message sent successfully",
            });
            setType("");
            setMessage("");
            const newData = {
              ...disputeData,
              dispute_discussions: {
                ...disputeData?.dispute_discussions,
                data: [
                  {
                    message,
                    id: disputeData?.dispute_discussions?.data[0]?.id + 1,
                    created_on: moment(new Date()).format("YYYY-MM-DD"),
                    sender: "admin",
                    admin_name: user?.name,
                    seller_name:
                      (type == "seller" || type == "public") &&
                      disputeData?.seller?.seller_name,
                    customer_name:
                      (type == "customer" || type == "public") &&
                      disputeData?.customer?.customer_name,
                  },
                  ...disputeData?.dispute_discussions?.data,
                ],
              },
            };
            setDisputeData(newData);
            setIsMessage(false);
          })
          .catch(err => {
            console.log(err);
            PrimaryToast({
              type: "error",
              message: err?.response?.data?.message,
            });
          })
          .finally(res => {
            setLoading(false);
          });
      } else if (popupType == "close") {
        let data = {
          seller_id: disputeData?.seller?.seller_id,
          customer_id: disputeData?.customer?.customer_id,
          message,
        };
        closeDispute(id, data)
          .then(res => {
            PrimaryToast({
              type: "success",
              message: "Dispute Close successfully",
            });
            setIsClose(false);
            setMessage("");
            const newData = {
              ...disputeData,
              dispute_discussions: {
                ...disputeData?.dispute_discussions,
                data: [
                  {
                    message,
                    id: disputeData?.dispute_discussions?.data[0]?.id + 1,
                    created_on: moment(new Date()).format("YYYY-MM-DD"),
                    sender: "admin",
                    admin_name: user?.name,
                  },
                  ...disputeData?.dispute_discussions?.data,
                ],
              },
              dispute_details: {
                ...disputeData?.dispute_details,
                dispute_status: "Close",
              },
            };
            setDisputeData(newData);
          })
          .catch(err => {
            console.log(err);
            PrimaryToast({
              type: "error",
              message: err?.response?.data?.message,
            });
          })
          .finally(res => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      {disputeData?.dispute_details?.dispute_iu_action == "1" && (
        <div className="rounded px-4 py-3 warning-order-container mb-4 font-sem0bold">
          Response Required
        </div>
      )}
      <div className="font-semi-bold d-flex align-items-center">
        DISPUTE#: {id}
        <div
          className={`status  w-max-content ms-3 ${
            disputeData?.dispute_details?.dispute_status == "Close" ||
            disputeData?.dispute_details?.dispute_iu_action == 1
              ? "danger-status"
              : disputeData?.dispute_details?.dispute_status == "In Process" &&
                disputeData?.dispute_details?.dispute_iu_action != 1
              ? "warning-status"
              : disputeData?.dispute_details?.dispute_status == "Complete"
              ? "success-status"
              : ""
          }`}
        >
          {disputeData?.dispute_details?.dispute_iu_action == 1
            ? "Response Required"
            : disputeData?.dispute_details?.dispute_status}
        </div>
      </div>
      <Row className="mt-4">
        <Col sm="8">
          <DisputeHistorySection
            disputeData={disputeData}
            handleShowGallery={handleShowGallery}
          />
        </Col>
        <Col sm="4">
          {disputeData?.dispute_details?.dispute_status == "In Process" && (
            <DisputeActions
              setIsRefund={setIsRefund}
              isRefund={isRefund}
              setIsMessage={setIsMessage}
              isMessage={isMessage}
              setIsClose={setIsClose}
              isClose={isClose}
            />
          )}

          <DisputeSummary
            disputeDetail={disputeData?.dispute_details}
            customerName={disputeData?.customer?.customer_name}
          />
        </Col>
        {isGallery && (
          <ImageGallery
            images={galleyImages}
            selectedIndex={selectedIndex}
            isGallery={isGallery}
            setSelectedIndex={setSelectedIndex}
            setGallery={setGallery}
            setGalleryImages={setGalleryImages}
            path={"dispute"}
          />
        )}
        {isRefund && (
          <HoldOrderPopup
            isOpen={isRefund}
            heading={"Refund"}
            isScrollable={false}
            handleClick={() => setIsRefund(!isRefund)}
            description={"Are you sure you want to refund this dispute"}
          />
        )}
        {isMessage && (
          <DisputeMessagePopup
            handleClick={() => {
              setIsMessage(!isMessage);
              setMessage("");
              setType("");
            }}
            isOpen={isMessage}
            isScrollable={false}
            className={"primary-popup-container"}
            heading="Dispute Message"
            type={type}
            setType={setType}
            handleSave={handleSave}
            message={message}
            setMessage={setMessage}
          />
        )}
        {isClose && (
          <DisputeMessagePopup
            handleClick={() => {
              setIsClose(!isClose);
              setMessage("");
            }}
            isOpen={isClose}
            isScrollable={false}
            className={"primary-popup-container"}
            heading="Close Dispute"
            message={message}
            setMessage={setMessage}
            handleSave={handleSave}
          />
        )}
      </Row>
    </div>
  );
}

export default ResponseDispute;
