import React from "react";
import PrimaryInput from "components/Common/PrimaryInput";
import { Button } from "reactstrap";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimarySelect from "components/Common/PrimarySelect";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import deleteIcon from "../../assets/images/delete-icon-white.svg";

const TransactionAdjusmentComponent = ({
    transactionAdjusmentData,
    handleAdjustment,
    adjustFields,
    handleCreateAdjust,
    setPageNo,
    pageNo,
    pageCount,
    setShowDeleteModal,
    handleDeleteAdjusment,
    setSelectedId,
    setPopupTitle,
    loading
}) => {
    


    const columns =

        [
            {
                dataField: "id",
                text: "#",
                
                formatter: (cell, row) => <div className="font-semi-bold">{((pageNo - 1) * 10 + row.index)}</div>,
            },
            {
                dataField: "others",
                text: "Amount",
            },
            {
                dataField: "description",
                text: "Description",
                formatter:(cell,row) => (
                    <div style={{width:"100px", whiteSpace:"break-spaces"}}>{row?.description}</div>
                )
            },
            {
                dataField: "effect",
                text: "Effect",
                formatter:(cell,row)=>{
                    let status;
                    if(row.effect == 0){
                        status  = "Pending"
                    }
                    else if(row.effect == 1) {
                        status  = "Available"
                    }
                    else if(row.effect == 2) {
                        status = "No Effect"
                    }
                    else {
                        status = row.effect;
                    }
                    ;
                    return (
                        <div>{status}</div>
                    )
                }
            },
            {
                dataField:"action",
                text:"Action",
                formatter:(cell,row)=>{
                    return (
                        <div
                        className="action-btn delete-btn-bg me-1 cursor-pointer"
                        onClick={()=>{
                            setShowDeleteModal(true);
                            setPopupTitle('Are you sure you want to delete this Transaction Adjustment?');
                            setSelectedId(row?.id);
                            // handleDeleteAdjusment(row?.id)
                        }}
                      >
                        <img src={deleteIcon} className="" alt="delete icon" />
                      </div>
                    )
                }
            }
        ];


    const data = transactionAdjusmentData?.map((item,index)=>{
        return {
            ...item,
            amount:item.balance,
            index : index + 1
        }
    }) 

    return (
        <div>
            <div className="mt-3">
                <h4 className="font-semi-bold">
                    Seller Transaction Adjustment
                </h4>
            </div>
            <div className="mt-3 d-flex">
                <div className="w-50 me-4">
                    <div className="mb-3">
                        <label htmlFor="inputEmail4">Description <span className="primary-text">*</span></label>
                        <textarea
                            name="description"
                            rows="4"
                            type="text"
                            className="form-control"
                            id="inputEmail4"
                            placeholder="Enter Description"
                            onChange={handleAdjustment}
                            value={adjustFields?.description || ""}
                        />
                    </div>
                    <div className="mb-3">
                        <PrimaryInput
                            name={"amount"}
                            label={"Amount"}
                            type={"text"}
                            isRequired
                            value={adjustFields?.amount || ""}
                            handleChange={handleAdjustment}
                            placeholder="Enter -ve amount deduct and +ve amount induct from the balance"
                        />
                    </div>
                    <div className="mb-3">
                        <PrimarySelect
                            label={"Effect To"}
                            name="effect"
                            value={adjustFields?.effect || ""}
                            isRequired={true}
                            handleChange={handleAdjustment}
                            options={[
                                {
                                    name:'Pending',
                                    value:0
                                },
                                {
                                    name:'Available',
                                    value:1,
                                },
                                {
                                    name:'No Effect',
                                    value:2,
                                }
                            ]}
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button disabled={loading} color="primary" type="button" onClick={handleCreateAdjust}>Save</Button>
                    </div>
                </div>
                <div className="w-50 ">
                    <PrimaryTable
                        keyField={"order_id"}
                        responsive
                        bordered={false}
                        striped={false}
                        columns={columns}
                        data={data}
                    />
                    {
                        transactionAdjusmentData?.length > 0 && (
                            <PrimaryPagination 
                                pageNo={pageNo}
                                pageCount={pageCount}
                                setPageNo={setPageNo}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default TransactionAdjusmentComponent;