import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getAllKeywordsList = (page,search) => {
    return getApi({
        url:`admin/keywords/feed`,
        data: {
            is_admin: true,
            page:page || undefined,
            search:search || undefined,
        }
    })
    .then(res => res.data)

}

export const createNewKeyword = (keywords) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/keywords/feed/create`,
        method: "POST",
        data: {
            keywords,
            is_admin:true,
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
}

export const getSingleKeywords = (id) => {
    return getApi({
        url: `admin/keywords/feed/edit/${encodeURI(id)}`,
        data: {
            is_admin: true,
        }
    })
    .then(res => res.data)
};


export const updateKeyword = (keywords,id) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/keywords/feed/update/${id}`,
        method: "PUT",
        data: {
            keywords,
            is_admin:true,
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
}

export const deleteKeyword = (id) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/keywords/feed/${id}`,
        method: "DELETE",
        data: {
            is_admin:true,
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
}

export const getAdultKeywords = (page,search) => {
    return getApi({
        url:`admin/keywords/detect`,
        data: {
            is_admin: true,
            page:page || undefined,
            search:search || undefined
        }
    })
    .then(res => res.data)

}


export const createNewAdultKeyword = (keywords) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/keywords/detect/create`,
        method: "POST",
        data: {
            ...keywords,
            is_admin:true,
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
};

export const getSingleAdultKeyword = (id) => {
    return getApi({
        url:`admin/keywords/detect/edit/${id}`,
        data: {
            is_admin: true,
        }
    })
    .then(res => res.data)
}

export const updateAdultKeyword = (keywords,id) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/keywords/detect/update/${id}`,
        method: "PUT",
        data: {
            ...keywords,
            is_admin:true,
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
}



export const deleteAdultKeyword = (id) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/keywords/detect/${id}`,
        method: "DELETE",
        data: {
            is_admin:true,
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
}
