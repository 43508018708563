import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import AnalyticsNav from "components/Common/Navbar/AnalyticsNav";
import {
  getSalesByCountry,
  getSalesByReferrals,
} from "services/ReportsServices";
import TopHeading from "components/Common/TopHeading";
import { useSearchParams } from "react-router-dom";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { Card, CardBody } from "reactstrap";
import DateRangePicker from "components/Common/DateRangePicker";
import PrimaryTable from "components/Common/PrimaryTable";
import PolygonIcon from "assets/images/polygon.svg";
import GlobalService from "services/GlobalService";
import viewIcon from "assets/images/rectangle-arrow.svg";
import { openLinkInNewTab } from "utils/linkHandling";

function Analytics() {
  const globalService = GlobalService();
  const [urlParams, setUrlParams] = useSearchParams();
  const [search, setSearch] = useState();
  const [pageNo, setPageNo] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [statsData, setStatsData] = useState([]);
  const [sortInfo, setSortInfo] = useState({ field: "session", order: "desc" });
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [countriesData, setCountriesData] = useState([]);

  useEffect(() => {
    globalService
      .getCountries()
      .then(response => {
        setCountriesData(response?.data?.data);
      })
      .catch(error => console.log(error.message));
  }, []);

  useEffect(() => {
    const searchParam = urlParams?.get("search") || undefined;
    setSearch(searchParam);
    const pageParam = urlParams?.get("page") || undefined;
    setPageNo(pageParam || 1);
    let startParam = urlParams?.get("start")
      ? moment(urlParams?.get("start"))
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
    let endParam = urlParams?.get("end")
      ? moment(urlParams?.get("end")).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      :  moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
    setDates([
      {
        startDate: moment(startParam)?.toDate() || new Date(),
        endDate: moment(endParam).toDate() || new Date(),
        key: "selection",
      },
    ]);

    const sortByParam = urlParams?.get("sort_by");
    const sortOfParam = urlParams?.get("sort_of");
    const typeParam = urlParams?.get("type");

    setSortInfo(prevSortInfo => ({
      ...prevSortInfo,
      order: sortByParam,
      field: sortOfParam,
    }));

    setLoading(true);
    if (typeParam == "referral") {
      getSalesByReferrals(
        startParam,
        endParam,
        pageParam,
        searchParam,
        sortOfParam,
        sortByParam
      )
        .then(res => {
          setStatsData(res.data.data);
          setPageCount(res?.data?.last_page);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(res => {
          setLoading(false);
        });
    } else {
      getSalesByCountry(
        startParam,
        endParam,
        pageParam,
        searchParam,
        sortOfParam,
        sortByParam
      )
        .then(res => {
          setStatsData(res.data.data);
          setPageCount(res?.data?.last_page);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(res => {
          setLoading(false);
        });
    }
  }, [urlParams]);

  const handleFilter = (key, value, key2, value2) => {
    const existingParams = Object.fromEntries(urlParams?.entries());
    if (key && value) {
      existingParams[key] = value;
      if (key2) {
        existingParams[key2] = value2;
      }
    } else {
      delete existingParams[key];
    }
    if (existingParams?.hasOwnProperty("page")) {
      delete existingParams["page"];
    }
    setUrlParams(existingParams);
  };

  const handleDatePicker = item => {
    setDates([
      {
        startDate: moment(item.selection.startDate)
          .local()
          .startOf("day")
          .toDate(),
        endDate: moment(item.selection.endDate).local().endOf("day").toDate(),
        key: "selection",
      },
    ]);
  };

  const countryCol = [
    {
      dataField: "country",
      text: "Country",
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Purchases{" "}
          {sortInfo.field === "country" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
      formatter: (cell, row) => <div className="">{row?.countryName}</div>,
      headerStyle: { width: "35%" },
    },
    {
      dataField: "views",
      text: "Views",
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Views{" "}
          {sortInfo.field === "views" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
      formatter: (cell, row) => {
        return <div>{row?.views}</div>;
      },
    },
    {
      dataField: "purchase",
      text: "Purchases",
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Purchases{" "}
          {sortInfo.field === "purchase" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
      formatter: (cell, row) => {
        return <div>{row?.purchaseCount}</div>;
      },
    },
    {
      dataField: "conversion",
      text: "Conversion",
      formatter: (cell, row) => {
        return <div>{row?.conversion_rate?.toFixed(2)}%</div>;
      },
    },
    {
      dataField: "revenue",
      text: "Revenue",
      formatter: (cell, row) => <div>${row?.revenue}</div>,
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Revenue{" "}
          {sortInfo.field === "revenue" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
    },
  ];

  const handleColumnClick = column => {
    handleFilter(
      "sort_of",
      column.dataField,
      "sort_by",
      column.dataField == sortInfo?.field
        ? sortInfo?.order === "asc"
          ? "desc"
          : "asc"
        : "desc"
    );
  };

  const referralCol = [
    {
      dataField: "referrer",
      text: "Referral",
      formatter: (cell, row) => (
        <div className="primary-text referral-link">{row?.referrer}</div>
      ),
      headerStyle: { width: "35%" },
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Referral{" "}
          {sortInfo.field === "referrer" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "views",
      text: "Views",
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Views{" "}
          {sortInfo.field === "views" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
      formatter: (cell, row) => {
        return <div>{row?.views}</div>;
      },
    },
    {
      dataField: "purchase",
      text: "Purchases",
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Purchases{" "}
          {sortInfo.field === "purchase" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
      formatter: (cell, row) => {
        return <div>{row?.purchaseCount}</div>;
      },
    },
    {
      dataField: "conversion",
      text: "Conversion",
      formatter: (cell, row) => {
        return <div>{row?.conversion_rate?.toFixed(2)}%</div>;
      },
    },
    {
      dataField: "revenue",
      text: "Revenue",
      formatter: (cell, row) => <div>${row?.revenue}</div>,
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Revenue{" "}
          {sortInfo.field === "revenue" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div
          className="action-btn edit-icon-bg cursor-pointer"
          onClick={() => handleView(row?.referrer)}
        >
          <img src={viewIcon} height={18} width={18} alt="view icon" />
        </div>
      ),
    },
  ];

  const handleView = useCallback(
    referral => {
      const startDateElement = document.querySelector(
        ".product-purchase-start-date"
      );
      const endDateElement = document.querySelector(
        ".product-purchase-end-date"
      );
      const startDate = startDateElement
        ? startDateElement.textContent.trim()
        : new Date();
      const endDate = endDateElement
        ? endDateElement.textContent.trim()
        : new Date();
      const url = `${
        window?.location?.origin
      }/reports/analytics/${referral}?start=${moment(startDate)?.format(
        "YYYY-MM-DD"
      )}&end=${moment(endDate)?.format("YYYY-MM-DD")}`;
      openLinkInNewTab(url, "_blank");
    },
    [dates]
  );
  const data = statsData?.map(item => {
    const countryCode = item?.country?.toLowerCase();
    let countryName = "";

    if (countryCode === "ru") {
      countryName = "Russia";
    } else {
      countryName =
        countriesData?.find(
          country => country.alpha2Code?.toLowerCase() === countryCode
        )?.name || "";
    }
    return {
      ...item,
      countryName: countryName,
    };
  });

  const statsType = urlParams?.get("type");

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-4">
        <TopHeading heading={"Analytics"} />
      </div>
      <div className="">
        <AnalyticsNav />
      </div>
      <Card>
        <CardBody>
          <div className="d-flex">
            <div className="position-relative">
              <div
                className="form-control cursor-pointer rounded-full"
                style={{ color: "#74788D", width: "max-content" }}
                onClick={() => setShowCalendar(true)}
              >
                <span className="product-purchase-start-date">
                  {moment(dates[0].startDate).format("DD MMM YYYY")}
                </span>
                -{" "}
                <span className="product-purchase-end-date">
                  {moment(dates[0].endDate).format("DD MMM YYYY")}
                </span>
              </div>

              {showCalendar && (
                <>
                  <div
                    className="position-fixed top-0 start-0 w-100 h-100"
                    style={{ zIndex: "10" }}
                    onClick={() => setShowCalendar(false)}
                  ></div>
                  <DateRangePicker
                    dates={dates}
                    handleDatePicker={handleDatePicker}
                    handleSubmit={() => {
                      handleFilter(
                        "start",
                        moment(dates[0].startDate).format("YYYY-MM-DD"),
                        "end",
                        moment(dates[0].endDate).format("YYYY-MM-DD")
                      );
                      setShowCalendar(false);
                    }}
                    // minDate={new Date()}
                    maxDate={new Date()}
                  />
                </>
              )}
            </div>
          </div>
          <div className="table-responsive mt-4">
            <PrimaryTable
              columns={statsType == "referral" ? referralCol : countryCol}
              data={data}
              bordered={false}
              keyField="order_number"
            />
          </div>
          {data?.length > 0 && pageCount > 1 ? (
            <PrimaryPagination
              pageCount={pageCount}
              setPageNo={setPageNo}
              pageNo={pageNo}
            />
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
}

export default Analytics;
