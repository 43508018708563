import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getAllPromotionsList = (page, search) => {
    return getApi({
        url: 'admin/marketing/promotions',
        data: {
            is_admin: true,
            page: page || undefined,
            search: search || undefined
        }
    }).then(res => (res.data))
}

export const createPromotions = (data) => {
    
    const formData = new FormData();
    if (data?.title) {
        formData.append("title", data?.title)
    }

    if (data?.slug) {
        formData.append("slug", data?.slug)
    }
    if (data?.position) {
        formData.append("position", data?.position)
    }
    if (data?.description) {
        formData.append("description", data?.description)
    }
    if (data?.seo_title) {
        formData.append("seo_title", data?.seo_title)
    }
    if (data?.seo_description) {
        formData.append("seo_description", data?.seo_description)
    }
    if (data?.start_at) {
        formData?.append("start_at", data?.start_at)
    }
    if (data?.end_at) {
        formData?.append('end_at', data?.end_at)
    }
    if (data?.action) {
        formData.append("action", data?.action)
    }
    if (data?.style) {
        formData.append("style", JSON.stringify({color:data.style}))
    }
    if (data?.productIds) {
        formData?.append("productIds", data?.productIds)
    }
    if (data?.media) {
        formData.append('media', data?.media)
    }
    if (data?.mobile_media) {
        formData.append("mobile_media", data?.mobile_media)
    }
    formData.append("is_admin", true);
    formData.append("hidden_title", data?.hidden_title === true ? 0 : 1);
    formData?.append("is_hidden", data?.is_hidden === true ? 0 : 1);

    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/marketing/promotions/create`,
        withCredentials: true,
        data: formData,
        headers: getHttpHeaders(),
    });

}

export const getSinglePromotion = (id) => {
    return getApi({
        url: `admin/marketing/promotions/edit/${id}`,
        data: {
            is_admin: true
        }
    })
        .then(res => res.data);
};


export const updatePromotion = (data,id) => {
    const formData = new FormData();
    if (data?.title) {
        formData.append("title", data?.title)
    }
    formData.append("hidden_title", data?.hidden_title === true  ? 0 : 1);
    formData?.append("is_hidden", data?.is_hidden === true ? 0 : 1);
    if (data?.slug) {
        formData.append("slug", data?.slug)
    }
    if (data?.position) {
        formData.append("position", data?.position)
    }
    if (data?.description) {
        formData.append("description", data?.description)
    }
    if (data?.seo_title) {
        formData.append("seo_title", data?.seo_title)
    }
    if (data?.seo_description) {
        formData.append("seo_description", data?.seo_description)
    }
    if (data?.start_at) {
        formData?.append("start_at", data?.start_at)
    }
    if (data?.end_at) {
        formData?.append('end_at', data?.end_at)
    }
    if (data?.action) {
        formData.append("action", data?.action)
    }
    if (data?.style) {
        formData.append("style", JSON.stringify({color:data.style}))
    }
    if (data?.productIds) {
        formData?.append("productIds", data?.productIds)
    }
    if (data?.media) {
        formData.append('media', data?.media)
    }
    if (data?.mobile_media) {
        formData.append("mobile_media", data?.mobile_media)
    }
    if(data?.media_id_delete){
        formData.append("media_id_delete",data?.media_id_delete)
    }
    if(data?.mobile_media_id_delete){
        formData.append("mobile_media_id_delete",data?.mobile_media_id_delete)
    }
    formData.append("is_admin", true);

    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/marketing/promotions/update/${id}`,
        withCredentials: true,
        data: formData,
        headers: getHttpHeaders(),
    });
}


export const deletePromotion = (id) => {
    return axiosInstance({
        method: "DELETE",
        url: `${siteApiUrl}admin/marketing/promotions/${id}`,
        withCredentials: true,
        data: {
            is_admin:true,
        },
        headers: getHttpHeaders(),
    });
} 

