import React from "react";
import PrimaryPopup from "./PrimaryPopup";
import downLoadIcon from "assets/images/download-icon.svg";


function DownloadDigalFile({ files, isOpeFiles, handleFilePopup }) {
  return (
    <PrimaryPopup
      isOpen={isOpeFiles}
      handleClick={handleFilePopup}
      title={"Download"}
      isScrollable={false}
      className="d-flex justify-content-center align-items-center h-100 w-100"
    >
      <div>
        {files && files?.length > 0
          ? files?.map((file, index) => {
              return (
                <div className="d-flex align-items-center fw-semibold text-13 mb-4" key={index + file.name}>
                  <div className="me-4">{index + 1}.</div>
                  <a className="d-flex align-items-center" rel="noreferrer" target={"_blank"} href={file?.url} download>
                    <img
                      src={downLoadIcon}
                      alt="download icon"
                      className="me-3"
                      height={16}
                      width={16}
                    />
                    <div className="text-truncate-2" style={{width:"400px"}}>{file.name}</div>
                  </a>
                </div>
              );
            })
          : null}
      </div>
    </PrimaryPopup>
  );
}

export default DownloadDigalFile;
