import React from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import Login from "pages/Authentication/Login";

import PrimarySidebar from "components/Common/Sidebar/Sidebar";
import Header from "components/Common/Header/Header";
import ForgetPasswordPage from "pages/Authentication/ForgetPassword";
import {  ToastContainer } from 'react-toastify';
import ResetPasswordPage from "pages/Authentication/ResetPassword";
import TwoFaAtuh from "pages/Authentication/2FaAtuh";

const StoreLayout = ({ children }) => {
    const [showSidebar, setShowSidebar] = useState(false);
    const location = useLocation();

    const handleShowSidebar = () => {
        setShowSidebar(!showSidebar);
    }

    return (
        <>
            <ToastContainer limit={2} />
            <div>
                {
                    location.pathname === "/login" ? (
                        <Login />
                    ) 
                    :
                    location.pathname === "/forgot-password" ? (
                        <ForgetPasswordPage />
                    ) 
                    :
                    location.pathname === "/admin/reset-pass" ? (
                        <ResetPasswordPage />
                    ):
                    location.pathname === "/2fa-auth" ? (
                        <TwoFaAtuh />
                    )
                    :
                     (
                        <div className="" id="layout-wrapper">
                            <Header />
                            <PrimarySidebar handleShowSidebar={handleShowSidebar} />
                            <main className="main-content">{children}</main>
                        </div>
                    )
                }

            </div>

        </>

    );
}

export default StoreLayout;