import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import SwitchButton from "components/Common/SwitchButton";
import PrimaryInput from "components/Common/PrimaryInput";
import { createNewCommand } from "services/ScheduleCommandsService";
import PrimaryTimePicker from "components/Common/TimePicker";
import moment from 'moment';
import PrograssPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function CreateCommand() {
	const str = 'HH:mm';
	const [fields, setFields] = useState({
		is_active: true,
		processing_time: moment(),
        schedule: ""
	});
	const [loading, setLoading] = useState(false);

	const history = useNavigate();

	useEffect(()=>{
		setPageTitle("Create Schedule Command - Inspire Uplift")
	},[]);



	const handleChange = e => {
		const name = e.target.name;
		const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
		const fieldsObj = { ...fields };

		fieldsObj[name] = value;
		setFields(fieldsObj);

	};

	const handleTimeChange = (val) => {
		
		setFields({
			...fields,
			processing_time: val
		});

	}

	const handleSubmit = e => {
		e.preventDefault();
		const data = {
			...fields,
			processing_time: fields?.processing_time ? moment(fields?.processing_time).format("HH:mm") : undefined,
		};
		toast.dismiss();
		setLoading(true);
		createNewCommand(data)
			.then(res => {
				history(-1)
				PrimaryToast({ type: 'success', message: res?.data?.message });
			})
			.catch(err => {
				console.log(err)
				PrimaryToast({ type: 'error', message: err?.response?.data?.message[0] });
			})
			.finally(res => {
				setLoading(false);
			})
	};

	return (
		<div className="page-content">
			{
				loading && <PrograssPopup label={"Laoding..."} />
			}
			<form onSubmit={handleSubmit}>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<TopHeading heading={"Create Schedule Command"} />
					<div>
						<Button onClick={() => history(-1)}>Cancel</Button>
						<Button className="ms-3" color="primary">
							Create
						</Button>
					</div>
				</div>
				<Card>
					<CardBody className="w-50">
						<h4 className="font-semi-bold">Schedule Command Information</h4>
						<div className="mb-3">
							<PrimaryInput
								label={'Name'}
								isRequired
								name={'name'}
								placeholder="Enter Name"
								handleChange={handleChange}

							/>
						</div>
						<div className="mb-3">
							<PrimaryInput
								label={'Command'}
								isRequired
								name={'command'}
								placeholder="Enter Comand"
								handleChange={handleChange}
							/>
						</div>
                        <div className="mb-3">
							<PrimaryInput
								label={'Schedule'}
								isRequired
								name={'schedule'}
								placeholder="Enter Schedule"
								handleChange={handleChange}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="inputEmail4">Processing Time</label>
							<PrimaryTimePicker
								name="processing_time"
								handleTimeChange={handleTimeChange}
								value={fields?.processing_time}
							/>
						</div>
						<div className="">
							<label htmlFor="inputEmail4">Status<span className="primary-text">*</span></label>
							<SwitchButton checked={fields.is_active === true ? true : false} label={fields.is_active === true ? "Active" : "Inactive"} name="is_active" value={fields?.is_active} handleChange={handleChange} />
						</div>
					</CardBody>
				</Card>
			</form>
		</div>
	);
}

export default CreateCommand;
