import { combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import  authReducer  from "./reducers/auth";
import ordersReducer from "./reducers/orders";
import statesReducer from "./reducers/states";
import countiresReducer from "./reducers/countries";
import buttonStateReducer from "./reducers/buttonState";

const rootReducer = combineReducers({
  auth: authReducer,
  ordersReducer:ordersReducer,
  statesReducer:statesReducer,
  countiresReducer:countiresReducer,
  buttonStateReducer:buttonStateReducer,
});

export const store = createStore(
  rootReducer,
  process.env.NODE_ENV !== "production" && composeWithDevTools()
);
