import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getAllCampaigns = (page) => {
    return getApi({
        url:`admin/campaigns`,
        data: {
            is_admin: true,
            page:page || undefined,
        }
    })
    .then(res => res.data)
};

export const createCampaign = (payload) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/campaigns/create`,
        method: "POST",
        data: {
            ...payload,
            is_admin:true,
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
};

export const updateCampaign = (id,payload) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/campaigns/update/${id}`,
        method: "POST",
        data: {
            ...payload,
            is_admin:true,
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
};

export const deleteCampaign = (id) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/campaigns/${id}`,
        method: "DELETE",
        data: {
            is_admin:true,
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
};

export const getSingleCampaign = (id) => {
    return getApi({
        url:`admin/campaigns/${id}`,
        data: {
            is_admin: true,
        }
    })
    .then(res => res.data)
};

export const getCampaignStoresList = (id, page, search) => {
    return getApi({
        url:`admin/campaigns/stores/${id}`,
        data: {
            is_admin: true,
            page:page || undefined,
            search: search || undefined,
        }
    })
    .then(res => res.data)
};

export const deleteStoreFromCampaign = (campaignId,storeId) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/campaigns/storeRemove/${campaignId}`,
        method: "DELETE",
        data: {
            is_admin:true,
            store_id:storeId
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
};

export const getCampaignParticipants = (id,page) => {
    return getApi({
        url:`admin/campaigns/participants/${id}`,
        data: {
            is_admin: true,
            page:page || undefined,
        }
    })
    .then(res => res.data)
};

export const deleteCampaignParticipants = (campaignId,storeId) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/campaigns/storeRemove/${campaignId}`,
        method: "DELETE",
        data: {
            is_admin:true,
            store_id:storeId
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
}

