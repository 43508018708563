import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getAppWellcomeData = (page, search) =>{
    return getApi({
        url:`admin/app-welcome`,
        data: {
            is_admin: true,
            page:page || undefined,
            search:search || undefined,
        }
    })
    .then(res => res.data)
};

export const getSingleAppWellcome = (id) =>{
    return getApi({
        url:`admin/app-welcome/edit/${id}`,
        data: {
            is_admin: true,
        }
    })
    .then(res => res.data)
};

export const createAppWellcome = (data) => {
    let formData = new FormData();
    if(data?.description){
        formData.append("description",data?.description)
    }
    if(data?.media){
        formData.append("media",data?.media)
    }
    formData.append("is_hidden",data?.is_hidden == true ? 0 : 1);
    formData.append('is_admin',true);
    return axiosInstance({
        url: `${siteApiUrl}admin/app-welcome/create`,
        method: "POST",
        data: formData,
		withCredentials:true,
        headers: getHttpHeaders(),
    });
}

export const updateAppWellcome = (data,id) => {
    let formData = new FormData();
    if(data?.description){
        formData.append("description",data?.description)
    }
    if(data?.media){
        formData.append("media",data?.media)
    }
    formData.append("is_hidden",data?.is_hidden == true ? 0 : 1);
    formData.append('is_admin',true);
    return axiosInstance({
        url: `${siteApiUrl}admin/app-welcome/update/${id}`,
        method: "POST",
        data: formData,
		withCredentials:true,
        headers: getHttpHeaders(),
    });
}

export const deleteAppWellcome = (id) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/app-welcome/${id}`,
        method: "DELETE",
        data: {
            is_admin:true,
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
}

