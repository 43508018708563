import React, { useState } from "react";

import { Card, CardBody, Col, Container, Row } from "reactstrap";

import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadUser } from "store/actions/auth";

// import images

import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/inspire-uplft-logo.svg";

import { loginUser } from "services/AuthServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { userInfo } from "services/AuthServices";
import { userAgentLog } from "utils/userAgentLog";

const Login = props => {
  const [fields, setFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [authenticated, setAuthenticated] = useState(false);
  const history = useNavigate();
  const dispatch = useDispatch();

  const handleChange = e => {
    let name = e.target.name;
    const value = e.target.value;
    const fieldObj = { ...fields };
    fieldObj[name] = value;
    setFields(fieldObj);
  };

  const handleValidation = () => {
    if (!fields?.email) {
      PrimaryToast({
        type: "error",
        message: "The email field is required.",
      });
	  return
    }
	else if(!fields?.password){
		PrimaryToast({
			type: "error",
			message: "The password field is required.",
		});
		return
	}
	return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
  
    if (handleValidation()) {
      setLoading(true);
  
      try {
        const userData = await userAgentLog(); // Get user data using userAgentLog
        const userDataJsonString = JSON.stringify(userData); // Convert user data to JSON string
        const loginResponse = await loginUser(fields, userDataJsonString); // Pass the stringified user data
  
        PrimaryToast({ type: "success", message: "login successful" });
  
        localStorage.setItem("adminToken", loginResponse.data.token);
        setAuthenticated(true);
  
        try {
          const userInfoResponse = await userInfo();
  
          dispatch(loadUser(userInfoResponse.data));
  
          if (userInfoResponse?.data?.permissions?.some(p => p.name === "dashboard")) {
            history("/dashboard");
          } else {
            history(`/user/detail/${userInfoResponse?.data?.id}`);
          }
        } catch (userInfoError) {
          if (userInfoError.response?.data?.message === "2fa is disabled") {
            history("/2fa-auth");
          } else if (userInfoError.response?.data?.message === "key not generated") {
            history("/two-factor-authentication");
          } else if (userInfoError?.response?.data?.message === "2fa not enabled") {
            history("/2fa-auth?enable-2fa");
          }
  
          if (userInfoError?.response?.status === 401) {
            localStorage.removeItem("adminToken");
          }
        } finally {
          setAuthenticated(false);
        }
      } catch (loginError) {
        console.error(loginError);
        PrimaryToast({
          type: "error",
          message: Array.isArray(loginError?.response?.data?.message)
            ? loginError?.response?.data?.message[0]
            : loginError?.response?.data?.message,
        });
      } finally {
        setLoading(false);
      }
    }
  };
  
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        {loading && <ProgressPopup label={"Loading..."} />}
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="primary-text p-4">
                        <h5 className="primary-text">Welcome Back !</h5>
                        <p style={{ whiteSpace: "nowrap" }}>
                          MarketPlace Management System.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <div className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <form className="form-horizontal" onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <div className="input-group auth-pass-inputgroup">
                          <input
                            name="password"
                            type={passwordType}
                            autoComplete="true"
                            onChange={handleChange}
                            className={"form-control"}
                          />
                          <button
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                            onClick={togglePassword}
                          >
                            {passwordType === "password" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="currentColor"
                                  fillRule="evenodd"
                                  d="M20.53 4.53a.75.75 0 0 0-1.06-1.06l-16 16a.75.75 0 1 0 1.06 1.06l2.847-2.847c1.367.644 2.94 1.067 4.623 1.067c2.684 0 5.09-1.077 6.82-2.405c.867-.665 1.583-1.407 2.089-2.136c.492-.709.841-1.486.841-2.209c0-.723-.35-1.5-.841-2.209c-.506-.729-1.222-1.47-2.088-2.136c-.263-.201-.54-.397-.832-.583L20.53 4.53ZM16.9 8.161l-1.771 1.771a3.75 3.75 0 0 1-5.197 5.197l-1.417 1.416A9.25 9.25 0 0 0 12 17.25c2.287 0 4.38-.923 5.907-2.095c.762-.585 1.364-1.218 1.77-1.801c.419-.604.573-1.077.573-1.354c0-.277-.154-.75-.573-1.354c-.406-.583-1.008-1.216-1.77-1.801c-.313-.24-.65-.47-1.008-.684Zm-5.87 5.87a2.25 2.25 0 0 0 3-3l-3 3Z"
                                  clipRule="evenodd"
                                />
                                <path
                                  fill="currentColor"
                                  d="M12 5.25c1.032 0 2.024.16 2.951.431a.243.243 0 0 1 .1.407l-.824.825a.254.254 0 0 1-.237.067A8.872 8.872 0 0 0 12 6.75c-2.287 0-4.38.923-5.907 2.095c-.762.585-1.364 1.218-1.77 1.801c-.419.604-.573 1.077-.573 1.354c0 .277.154.75.573 1.354c.354.51.858 1.057 1.488 1.577c.116.095.127.27.02.377l-.708.709a.246.246 0 0 1-.333.016a9.52 9.52 0 0 1-1.699-1.824C2.6 13.5 2.25 12.723 2.25 12c0-.723.35-1.5.841-2.209c.506-.729 1.222-1.47 2.088-2.136C6.91 6.327 9.316 5.25 12 5.25Z"
                                />
                                <path
                                  fill="currentColor"
                                  d="M12 8.25c.118 0 .236.005.351.016c.197.019.268.254.129.394l-1.213 1.212a2.256 2.256 0 0 0-1.395 1.395L8.66 12.48c-.14.14-.375.068-.394-.129A3.75 3.75 0 0 1 12 8.25Z"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="currentColor"
                                  d="M12 9a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-4.5c5 0 9.27 3.11 11 7.5c-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12a9.821 9.821 0 0 0 17.64 0a9.821 9.821 0 0 0-17.64 0Z"
                                />
                              </svg>
                            )}
                          </button>
                        </div>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" /> Forgot your
                          password?
                        </Link>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
