import React from "react";
import { toast } from "react-toastify";
import errorIcon from "assets/images/error-icon.svg";
import successIcon from "assets/images/success-icon.svg";

const PrimaryToast = ({ type, message }) => {
  const errorImg = (
    <img
      src={errorIcon}
      className="me-2"
      alt="error icon"
      height="30"
      width="30"
    />
  );
  const successImg = (
    <img
      src={successIcon}
      className="me-2"
      alt="error icon"
      height="30"
      width="30"
    />
  );

  const showToast = () => {
    if (type === "success") {
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-success",
        icon: successImg,
      });
    } else if (type === "error") {
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-error",
        icon: errorImg,
      });
    }
  };

  return showToast();
};

export default PrimaryToast;
