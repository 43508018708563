import React from "react";
import { Card, CardBody } from "reactstrap";

import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryTable from "components/Common/PrimaryTable";

const AdjusmentHistoryTable = ({
  tableData,
  adjustmentPageCount,
  adjustmentPageNo,
  setAdjustmentPageNumber,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => <div className="font-semibold">{row.id}</div>,
    },
    {
      dataField: "message",
      text: "Message",
      formatter: (cell, row) => (
        <div className="product-title-container" style={{ width: "300px" }}>
          {row.message}
        </div>
      ),
    },
    {
      dataField: "from",
      text: "From",
    },
    {
      dataField: "to",
      text: "To",
    },
    {
      dataField: "updated_by",
      text: "Changed By",
    },
    {
      dataField: "updated_at",
      text: "Date",
    },
  ];
  const data = tableData?.map(item => {
    return {
      ...item,
    };
  });
  return (
    <Card>
      <CardBody>
        <h5 className="mb-4">Balance Adjustment</h5>
        <PrimaryTable
          columns={columns}
          data={data}
          bordered={false}
          keyField={"id"}
        />
        {adjustmentPageCount > 1 && (
          <PrimaryPagination
            pageCount={adjustmentPageCount}
            pageNo={adjustmentPageNo}
            setPageNo={setAdjustmentPageNumber}
            skipUrlParam={true}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default AdjusmentHistoryTable;
