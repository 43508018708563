import React, { useState } from "react";
import { Button } from "reactstrap";
import PrimaryInput from "components/Common/PrimaryInput";
import { sellerBalanceAdjustment } from "services/SellerStoreServices";
import PrimaryToast from "components/Common/Toast/ToastMessage";


const BalanceAdjusmentComponent = ({ setLoading, id, loading }) => {
  const [fields, setFields] = useState({});
  const handleChange = e => {
    const { name, value, type } = e.target;
    const updatedValue = type === "number" ? parseFloat(value) || 0 : value;
    setFields(prevFields => ({
      ...prevFields,
      [name]: updatedValue,
    }));
  };

  const handleSave = e => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      ...fields,
      seller_id: Number(id),
    };
    sellerBalanceAdjustment(payload)
      .then(res => {
        setFields({});
        PrimaryToast({
          type: "success",
          message: "Balance adjusted successfully",
        });
      })
      .catch(err => {
        PrimaryToast({
          type: "error",
          message: err.response.data.message,
        });
      })
      .finally(res => {
        setLoading(false);
      });
  };
  return (
    <div className="w-50">
      <h4>Balance Adjustment</h4>

      <PrimaryInput
        label={"Transaction Id"}
        placeholder={"Enter Transaction Id"}
        name="transaction_id"
        isRequired={true}
        handleChange={handleChange}
        value={fields?.transaction_id || ""}
        type={"number"}
      />
      <div className="mt-4">
        <PrimaryInput
          label={"Balance"}
          placeholder={"Enter Balance"}
          name={"balance"}
          isRequired={true}
          handleChange={handleChange}
          type={"number"}
          value={fields?.balance || ""}
        />
      </div>
      <div className="d-flex justify-content-end mt-4">
        <Button disabled={loading} color="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default BalanceAdjusmentComponent;
