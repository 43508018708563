import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import AdInformation from "components/Products/ProductsAds/AdInformation";
import AudienceDetails from "components/Products/ProductsAds/AudienceDetails";
import AdCreative from "components/Products/ProductsAds/AdCreative";
import { getSingleProductAd } from "services/MarketPlaceFeedService";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { setPageTitle } from "utils/pageTitle";
import { changeProductStatus } from "services/MarketPlaceFeedService";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import AdsApprovePopup from "components/Common/Popups/AdsApprovePopup";
import AdsRejectPopup from "components/Common/Popups/AdsRejectPopup";
import WarningIcon from "assets/images/red-info-icon.svg";

function AdsDetails() {
  const [adsData, setAdsData] = useState();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [adsStatus, setAdsStatus] = useState();
  const [isApprove, setIsApprove] = useState(false);
  const [campaignId, setCampaignId] = useState();
  const [adSetId, setAdSetId] = useState();
  const [isReject, setIsReject] = useState(false);
  const [reason, setReason] = useState();

  useEffect(() => {
    setPageTitle("Product Ad Detail - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    getSingleProductAd(id)
      .then(res => {
        setAdsStatus(res?.data?.ad_information?.status);
        setAdsData(res?.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleStatus = (status, adSetId, campaignId, reason) => {
    let data = {
      status_id: status,
      ad_set_id:
        status == 1 &&
        adsData?.ad_information?.platform?.toLowerCase() == "facebook" &&
        adSetId
          ? adSetId
          : undefined,
      fb_campaign_id:
        status == 1 &&
        adsData?.ad_information?.platform?.toLowerCase() == "facebook" &&
        campaignId
          ? campaignId
          : undefined,
      comment: reason || undefined,
    };
    setLoading(true);
    changeProductStatus(data, id)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        setAdsStatus(status);
        if (
          adsData?.ad_information?.platform?.toLowerCase() == "facebook" &&
          status == 1
        ) {
          setIsApprove(false);
          setCampaignId();
          setAdSetId();
          getSingleProductAd(id)
            .then(res => {
              setAdsStatus(res?.data?.ad_information?.status);
              setAdsData(res?.data);
            })
            .catch(err => {
              console.log(err);
            })
			.finally(res => setLoading(false))
        } else {
          setIsReject(false);
          setReason();
          setAdsData({
            ...adsData,
            ad_information: {
              ...adsData.ad_information,
              status,
              fb_campaign_id: campaignId
                ? campaignId
                : adsData?.ad_information?.fb_campaign_id || undefined,
              ad_set_id: adSetId ? adSetId : adsData?.ad_information?.ad_set_id,
            },
          });
		  setLoading(false);
        }
      })
      .catch(err => {
		setLoading(false);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
  };

  const handleApprove = () => {
    handleStatus(1, adSetId, campaignId);
  };

  const handleSave = () => {
    handleStatus(3, "", "", reason);
  };


  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      {adsData?.ad_information?.comment ? (
        <div className="ads-warning-section">
          <img src={WarningIcon} alt="warning icn" />
          <p className="ms-2 mb-0">
            Reject Reason: {adsData?.ad_information?.comment}
          </p>
        </div>
      ) : null}
      <Row className="mt-4">
        <Col sm="9">
          {adsData && (
            <>
              <AdInformation data={adsData?.ad_information} />
              {(adsData?.ad_information?.platform?.toLowerCase() ===
                "facebook" ||
                adsData?.ad_information?.platform?.toLowerCase() ===
                  "instagram") && (
                <div className="mt-3">
                  <AudienceDetails data={adsData?.audience_details} />
                </div>
              )}
              {adsData?.ad_information?.platform !== "InspireUpLift" &&
                (adsData?.ad_creative &&
                Object.values(adsData.ad_creative).filter(
                  value => value !== null
                ).length > 0 ? (
                  <div className="mt-3">
                    <AdCreative data={adsData.ad_creative} />
                  </div>
                ) : null)}
            </>
          )}
        </Col>
        <Col sm="3">
          {adsStatus == 1 && (
            <Button
              size="lg"
              color="primary"
              block
              className="mb-3"
              onClick={() => {
                handleStatus(4);
              }}
              disabled={loading}
            >
              Pause
            </Button>
          )}
          {adsStatus == 4 && (
            <Button
              size="lg"
              color="primary"
              block
              className="mb-3"
              onClick={() => {
                handleStatus(1);
              }}
              disabled={loading}
            >
              Play
            </Button>
          )}
          {adsStatus == 0 && (
            <>
              <Button
                size="lg"
                color="primary"
                block
                className="mb-3"
                disabled={loading}
                onClick={() => {
                  if (
                    adsData?.ad_information?.platform?.toLowerCase() ===
                    "facebook"
                  ) {
                    setIsApprove(!isApprove);
                  } else {
                    handleStatus(1);
                  }
                }}
              >
                Approve
              </Button>
              <Button
                disabled={loading}
                size="lg"
                color="primary"
                block
                className="w-100"
                onClick={() => {
                  setIsReject(true);
                }}
              >
                Reject
              </Button>
            </>
          )}
        </Col>
      </Row>
      {isApprove && (
        <AdsApprovePopup
          loading={loading}
          isOpen={isApprove}
          handleClick={() => {
            setIsApprove(!isApprove);
            setAdSetId();
            setCampaignId();
          }}
          isCamIdExist={adsData?.ad_information?.fb_campaign_id ? true : false}
          campaignId={campaignId}
          setCampaignId={setCampaignId}
          adSetId={adSetId}
          setAdSetId={setAdSetId}
          handleApprove={handleApprove}
        />
      )}
      {isReject && (
        <AdsRejectPopup
          loading={loading}
          isOpen={isReject}
          reason={reason}
          setReason={setReason}
          handleSave={handleSave}
          handleClose={() => {
            setIsReject(false);
            setReason();
          }}
        />
      )}
    </div>
  );
}

export default AdsDetails;
